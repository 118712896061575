import React, { useState, useEffect } from 'react';
import { create_production_plan } from "../../../api/index";
import { convertDateYYMMDD, calculateWorkHours } from "../../../common/Functions/CommonFunctions"
import { toast } from "react-toastify";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate } from 'react-router-dom';
import {
    get_workorder_product_routes, getProdConfig, getOperatorList_availability,
    getOperatorList, getGlobalRouting
} from "../../../api/index"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DatePicker from "react-datepicker";
import JobCardModalCalender from './JobCardModalCalender';
import { useSelector, useDispatch } from 'react-redux';
import { handle_date, handle_data, enable_loading, save_job_card } from '../../../slices/production_planning/production_plan_slice';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, } from "@fortawesome/free-solid-svg-icons";
import { Edit } from "react-feather";
import refresh from "../../../assets/images/refresh.png";
import Calendar from 'react-calendar';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { getWeekRange, create_job_obj } from "../../../common/Functions/CommonFunctions";

const localizer = momentLocalizer(moment)

const schema = yup
    .object()
    .shape({
        route: yup
            .array()
            // .min(1, "Please select route !")
            .notRequired("Please select route !"),
        global_route: yup
            .array()
            // .min(1, "Please select global route !")
            .notRequired("Please select global route !"),
        operator: yup
            .array()
            // .min(1, "Please select global route !")
            .notRequired("Please select global route !"),
        startDate: yup
            .date()
            .typeError("Please select date!")
            .required("Please select date!"),
        endDate: yup
            .date()
            .typeError("Please select end date!")
            .required("Please select end date!"),
    })
    .required().test('at-least-one', 'Please select either route or global route!', function (value) {
        const { route, global_route } = value;
        return (route && route.length > 0) || (global_route && global_route.length > 0);
    });

function CalenderView(props) {
    const { nextStep, prevStep } = props;
    const dispatch = useDispatch();
    const StepOneSavedData = useSelector((state) => state.steps.StepOne);
    const StepTwoSavedData = useSelector((state) => state.steps.StepTwo);
    const StepFourSavedData = useSelector((state) => state.steps.StepFour);
    const base_data = useSelector((state) => state.steps.base_data);
    const loading = useSelector((state) => state.steps.loading);

    const [constLabel, setConstLabel] = useState({});
    const [productRoutes, setProductRoutes] = useState({ data: [], loading: false, isSearched: false });
    const [productGlobalRoutes, setProductGlobalRoutes] = useState({ data: [], loading: false });

    const navigate = useNavigate();

    const [prodOpers, setProdOpers] = useState({ data: [], loading: false, buttonClick: false });
    const [empListWithAvail, setEmpListWithAvail] = useState({ data: [], loading: false });
    const [calender_events, set_calender_events] = useState({ data: [], loading: false });
    const [currProduct, setCurrProduct] = useState({ data: base_data.data[0], index: 0 });
    const [routeType, setRouteType] = useState("product_routes");

    const [visible, setVisible] = useState({ state: false, data: null, displayData: null });
    // const [routeType, setRouteType] = useState("product_routes");

    const {
        register,
        handleSubmit,
        formState,
        control,
        watch,
        getValues,
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            global_route: [],
            route: [],
        },
        mode: "onChange",
    });
    let { errors } = formState;


    useEffect(() => {

        dispatch(handle_date({
            start_date: new Date(StepOneSavedData.formData.plan_date),
            end_date: new Date(StepOneSavedData.formData.delivery_date),
            big_calender_date: new Date(),
            view: "month"
        }));
    }, []);

    useEffect(() => {
        console.log("StepOneSavedData", StepOneSavedData);
        console.log("StepTwoSavedData", StepTwoSavedData);
        console.log("StepFourSavedData", StepFourSavedData);
        console.log("base_data", base_data);

        fetchProdRoutes(currProduct.data);
        console.log("currProduct", currProduct);

        dispatch(enable_loading(true));
    }, [currProduct.data]);


    useEffect(() => {
        if (productRoutes.data.length > 0) {
            dispatch(enable_loading(true));
            handle_route("route", [productRoutes.data[0]]);
        }

        if (productGlobalRoutes.data.length > 0) {
            dispatch(enable_loading(true));
            handle_route("global_route", [productGlobalRoutes.data[0]]);
        }
    }, [productRoutes.data, productGlobalRoutes.data,
    base_data.start_date, base_data.end_date]);

    useEffect(() => {
        console.log("base_data", base_data);
    }, [visible.state])


    function handle_route(called_by, val) {

        if (val && val.length > 0) {
            if (called_by == "global_routes") {
                // setValue("global_route", val);
                setRouteType("global_routes");
                setProdOpers({
                    ...prodOpers, loading: false, buttonClick: true,
                    data: val[0].routeData
                });


                if (val[0].routeData.length > 0) {

                    fetchEmp(val[0].routeData);
                }
            } else {
                // setValue("route", val);
                setRouteType("product_routes");



                const payload = {
                    product_id: currProduct.data.uuid,
                    routes_uuid: val[0].routes_id
                }


                setProdOpers({ ...prodOpers, loading: true, buttonClick: false });
                getProdConfig(payload).then((res) => {
                    console.log("res", res);

                    setProdOpers({
                        ...prodOpers,
                        loading: false,
                        buttonClick: true,
                        data: res.data.data
                    });

                    if (res.data.data.length > 0) {
                        fetchEmp(res.data.data);
                    }
                }, (err) => {

                    console.log("err", err);
                    setProdOpers({ ...prodOpers, loading: false, buttonClick: true, data: [], });
                })
            }
        }
    }

    function find_active_prod() {
        const activeProd = base_data.data[currProduct.index];
        return activeProd
    }


    function fetchProdRoutes(product_details) {
        console.log("product_details", product_details);
        setProductRoutes({ ...productRoutes, data: [], loading: true, isSearched: false });
        const activeProd = find_active_prod()
        console.log("activeProd", activeProd);

        get_workorder_product_routes({ product_id: product_details.uuid }).then((res) => {
            console.log("res", res);

            if (res.data.data.length > 0) {
                setProductRoutes({
                    ...productRoutes,
                    data: res.data.data,
                    loading: false,
                    isSearched: true
                });

                console.log('setting route', res.data.data[0]);



                if (activeProd.selected_route) {
                    setValue("route", activeProd.selected_route, { shouldValidate: true });
                } else {
                    setValue("route", [res.data.data[0]], { shouldValidate: true });
                }

                setValue("global_route", []);
            } else {

                setValue("global_route", []);

                setProductGlobalRoutes({ ...productGlobalRoutes, data: [], loading: true });
                getGlobalRouting().then((groutes) => {
                    console.log("groutes", groutes);
                    if (groutes.data.data.length > 0) {
                        console.log('setting global_route', groutes.data.data[0]);
                        setValue("route", []);



                        if (activeProd.selected_route) {
                            setValue("global_route", activeProd.selected_route, { shouldValidate: true });
                        } else {
                            setValue("global_route", [groutes.data.data[0]], { shouldValidate: true });
                        }

                    }

                    setProductGlobalRoutes({
                        ...productGlobalRoutes,
                        data: groutes.data.data,
                        loading: false,
                    });
                    console.log("groutes", groutes);
                }, (err) => {
                    console.log("err", err);
                    toast.error("Error fetching global routes", {
                        autoClose: 3000,
                    });
                });

            }
        }, (err) => {
            toast.error("Error fetching product routes", {
                autoClose: 3000,
            });
            setProductRoutes({
                ...productRoutes,
                data: [],
                loading: false,
                isSearched: true
            });
            console.log("err", err);
        })
    }


    function fetchEmp(opr_list, sel_oper) {
        console.log("opr_list", opr_list);
        console.log("productRoutes", productRoutes);
        console.log("productGlobalRoutes", productGlobalRoutes);
        const activeProd = find_active_prod();

        const oper_category_id_list = [];

        for (const opr of opr_list) {
            // here we need to check if operations are of product operations or coming from global routes
            if (productRoutes.data.length > 0) {
                if (!opr.operation_details || !opr.operation_details?.operation_category_id) {
                    console.log("opr", opr);
                    dispatch(enable_loading(false));
                    toast.error("selected operation doesnt have operation category", {
                        autoClose: 3000,
                    });
                    return
                } else {
                    // setValue('operation', [opr]);
                    oper_category_id_list.push(opr.operation_details.operation_category_id);
                }

            } else if (productGlobalRoutes.data.length > 0) {
                if (!opr.operation_category_id) {
                    dispatch(enable_loading(false));
                    toast.error("selected operation doesnt have operation category", {
                        autoClose: 3000,
                    });
                    return
                } else {
                    // setValue('operation', [opr]);
                    oper_category_id_list.push(opr.operation_category_id);
                }
            }
        }

        if (!sel_oper || sel_oper.length == 0) {

            if (activeProd?.selected_route && activeProd?.selected_route[0]?.route_operation_list[0]) {

                setValue('operation', [activeProd.selected_route[0].route_operation_list[0]]);
            } else {
                setValue('operation', [opr_list[0]]);
            }
        }

        setEmpListWithAvail({ ...empListWithAvail, data: [], loading: true });

        const weeklyConfig = {
            monday: StepOneSavedData.formData.monday,
            tuesday: StepOneSavedData.formData.tuesday,
            wednesday: StepOneSavedData.formData.wednesday,
            thursday: StepOneSavedData.formData.thursday,
            friday: StepOneSavedData.formData.friday,
            saturday: StepOneSavedData.formData.saturday,
            sunday: StepOneSavedData.formData.sunday
        }
        console.log("startDate", base_data.start_date);
        console.log("endDate", base_data.end_date);
        console.log("weeklyConfig", weeklyConfig);
        console.log("activeProd", activeProd);

        const params = {
            startDate: convertDateYYMMDD(base_data.start_date, "-"),
            endDate: convertDateYYMMDD(base_data.end_date, "-"),
            oper_category: JSON.stringify(oper_category_id_list),
            weeklyConfig: JSON.stringify(weeklyConfig)
        }

        if (activeProd.prod_family_id && activeProd.prod_family_id?.uuid) {
            params.product_family_id = activeProd.prod_family_id.uuid;
        }

        console.log("params", params);

        getOperatorList_availability(params).then((res) => {
            console.log("res", res);


            res.data.data.map((val) => {
                val.FullName = val.FirstName + ' ' + val.LastName;
                val.selected_emp = [];
                val.selected_startdate = new Date().setHours(9, 0, 0, 0); // null

                val.selected_enddate = new Date().setHours(18, 0, 0, 0);   // null
            })

            setEmpListWithAvail({ ...empListWithAvail, data: res.data.data, loading: false });


            if (res.data.data.length > 0) {
                console.log("activeProd", activeProd);

                if (activeProd.selected_route && activeProd.selected_route[0]?.route_operation_list &&
                    activeProd.selected_route[0]?.route_operation_list[0]?.create_job_card) {

                    setValue("operator",
                        activeProd.selected_route[0].route_operation_list[0].create_job_card.operator);

                    // const curr_operation = getValues("operation");
                    const curr_operator = getValues("operator");

                    const local_present_jobcards = [];
                    activeProd.selected_route[0].route_operation_list.forEach(
                        (val) => {
                            // if (val.create_job_card !== undefined && val.uuid == curr_operation[0].uuid) {
                            if (val.create_job_card !== undefined &&
                                curr_operator[0].uuid == val.create_job_card.operator[0].uuid) {
                                const job_card_to_append_in_calender = create_job_obj(val.create_job_card);
                                local_present_jobcards.push(job_card_to_append_in_calender);
                            }
                        });

                    console.log("local_present_jobcards", local_present_jobcards);
                    console.log("...res.data.data[0].jobcard_list, ...local_present_jobcards", ...res.data.data[0].jobcard_list, ...local_present_jobcards);

                    set_calender_events({
                        ...calender_events,
                        loading: false, data: [...res.data.data[0].jobcard_list, ...local_present_jobcards]
                    });

                    store_selected_data_in_store(activeProd.selected_route[0].route_operation_list);
                } else {
                    setValue("operator", [res.data.data[0]]);
                    set_calender_events({
                        ...calender_events,
                        loading: false, data: res.data.data[0].jobcard_list
                    })
                    store_selected_data_in_store(opr_list);


                }
            }


        }, (err) => {
            console.log("err", err);
            setEmpListWithAvail({ ...empListWithAvail, data: [], loading: false });

            if (err.response.status !== 404) {
                toast.error(err.response.data.message, {
                    autoClose: 3000,
                });
            }
        });

    }

    function store_selected_data_in_store(opr_list) {
        const route = getValues("route");
        const global_route = getValues("global_route");
        const operation = getValues("operation");
        const operator = getValues("operator");

        console.log("route", route);
        console.log("global_route", global_route);
        console.log("operation", operation);
        console.log("operator", operator);
        console.log("base_data", base_data);

        const data = {
            prod_index: currProduct.index,
            route: JSON.parse(JSON.stringify(route)),
            global_route: JSON.parse(JSON.stringify(global_route)),
            operation: JSON.parse(JSON.stringify(operation)),
            operator: JSON.parse(JSON.stringify(operator)),
            base_data: base_data,
            opr_list: JSON.parse(JSON.stringify(opr_list)),
            routeType: routeType
        };
        console.log("data", data);

        dispatch(handle_data(data));
    }


    function handle_b_calender_navigation(d, view) {
        let fd = null;
        let ld = null


        switch (view) {
            case "week":
                const { firstDate, lastDate } = getWeekRange(d);
                fd = firstDate;
                ld = lastDate;
                break

            case "day":
                fd = new Date(d);
                ld = new Date(d);
                break

            default:
                fd = new Date(d.getFullYear(), d.getMonth(), 1);
                ld = new Date(d.getFullYear(), d.getMonth() + 1, 0);
        }

        console.log("fd", fd);
        console.log("ld", ld);
        console.log("d", d);

        dispatch(handle_date({
            start_date: fd,
            end_date: ld,
            big_calender_date: new Date(d),
            view: view
        }));
    }


    function validateStepFourData() {

        for (const prod of base_data.data) {
            if (!prod.selected_route) {
                toast.error("Every prod must have a selected_route.", {
                    autoClose: 3000,
                });
                return
            }

            if (!Array.isArray(prod.selected_route[0].route_operation_list) ||
                prod.selected_route[0].route_operation_list.length === 0) {
                toast.error("Every selected_route must have a route_operation_list", {
                    autoClose: 3000,
                });
                return
            }

            const isJobCardPresent = prod.selected_route[0].route_operation_list.find(
                (val) => val.create_job_card !== null && val.create_job_card !== undefined);

            if (!isJobCardPresent) {
                toast.error("Each product should have atleast one job card", {
                    autoClose: 3000,
                });
                return
            }

        }

        return true; // If validation passes
    }


    function delete_added_job_card() {
        console.log("base_data", base_data);
        console.log("calender_events", calender_events);
        console.log("visible", visible);


        const foundedIndex =
            calender_events.data.findIndex((val) =>
                val.uuid == null
                && val.productOperDetails.uuid == visible.displayData.productOperDetails.uuid
                && val.productOperDetails.routesDetails.uuid == visible.displayData.productOperDetails.routesDetails.uuid
            );

        console.log("foundedIndex", foundedIndex);

        if (foundedIndex !== -1) {
            const temp_calender_events = [...calender_events.data];
            temp_calender_events.splice(foundedIndex, 1);

            set_calender_events({ ...calender_events, data: temp_calender_events, loading: false });
        }


        const data_required = {
            data: null,
            operation: visible.displayData.productOperDetails,
            base_data: JSON.parse(JSON.stringify(base_data))
        };
        console.log("data_required", data_required);
        dispatch(save_job_card(data_required));

        setVisible({ ...visible, state: false, data: null, displayData: null });

    }

    function submitStep() {
        console.log("base_data", base_data);
        const isValidated = validateStepFourData();
        if (isValidated) {
            nextStep();
            console.log("go to next step");
        }
    }


    const customFormats = {
        agendaHeaderFormat: ({ start, end }) =>
            `${moment(start).format("DD-MM-YYYY")} – ${moment(end).format("DD-MM-YYYY")}`, // Custom range format
        agendaDateFormat: (date) => moment(date).format("DD-MM-YYYY"),         // Custom single date format
    };

    return (
        <React.Fragment>
            <div className="calender_layout mt-2 stepFour">
                <div className='row' id='row1'>
                    <div className='col-12 mb-4'>
                        <div className="product_container">
                            {base_data.data && base_data.data.length > 0 &&
                                base_data.data.map((val, ind) => (
                                    <div className={val.selected ? "card item active" : "card item shadow-sm"} key={ind}
                                        onClick={async () => {
                                            setCurrProduct({ data: val, index: ind });
                                            // await fetchProdRoutes(val, ind);
                                        }}>
                                        <p className='mb-0 product_name'>
                                            {`${val.productName}`}
                                        </p>
                                        <p className='mb-0 bom_id'>
                                            {`${val.selected_bom[0]?.bomId}`}
                                        </p>
                                    </div>
                                ))}
                        </div>
                    </div>


                    {loading == true ?
                        <div className="text-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        :
                        <>


                            <div className='col-12 col-md-3'>
                                <Calendar
                                    onChange={(seldate) => {
                                        console.log("seldate", seldate);
                                        dispatch(handle_date({
                                            start_date: seldate[0],
                                            end_date: seldate[1],
                                            big_calender_date: base_data.big_calender_date,
                                            view: base_data.view
                                        }));
                                    }}
                                    selectRange={true}
                                    value={[base_data.start_date, base_data.end_date]}
                                    minDate={new Date(StepOneSavedData.formData.plan_date)}
                                    maxDate={new Date(StepOneSavedData.formData.delivery_date)}
                                />



                                {productRoutes.isSearched && productRoutes.data.length == 0 ? null :
                                    <div className="form-group mt-md-0 mt-3 typeahead-container">
                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="col-form-label roboto-bold"
                                        >
                                            {constLabel?.lbl_routes
                                                ? constLabel.lbl_routes
                                                : "Select Route"}
                                            <span className="text-danger"> *</span>
                                        </label>


                                        <Controller
                                            name="route"
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="routesname"
                                                    multiple={false}
                                                    // size="sm"
                                                    options={productRoutes.data}
                                                    isLoading={productRoutes.loading}
                                                    placeholder="Select route"
                                                    // onChange={onChange}
                                                    onChange={(selected) => {
                                                        onChange(selected);
                                                        handle_route("route", selected);
                                                        setValue("operation", []);
                                                        setValue("operator", []);
                                                    }}
                                                    onBlur={onBlur}
                                                    selected={value}
                                                    isInvalid={errors.route ? true : false}
                                                    emptyLabel="Routes not available"
                                                />
                                            )}
                                        />

                                        {!errors.route && !productRoutes.loading &&
                                            <span className="arrow-icon"></span>}

                                        <span className="text-danger err-msg">
                                            {errors.route?.message}
                                        </span>


                                    </div>
                                }



                                {productRoutes.isSearched && productRoutes.data.length == 0 &&

                                    <div className="form-group mt-md-0 mt-3 typeahead-container">
                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="col-form-label roboto-bold"
                                        >
                                            {constLabel?.lbl_routes
                                                ? constLabel.lbl_routes
                                                : "Select Route"}
                                            <span className="text-danger"> *</span>
                                        </label>


                                        <Controller
                                            name="global_route"
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="routeName"
                                                    multiple={false}
                                                    // size="sm"
                                                    options={productGlobalRoutes.data}
                                                    isLoading={productGlobalRoutes.loading}
                                                    placeholder="Select route"
                                                    // onChange={onChange}
                                                    onChange={(selected) => {
                                                        onChange(selected);
                                                        handle_route("global_route", selected);
                                                        setValue("operation", []);
                                                        setValue("operator", []);
                                                    }}
                                                    onBlur={onBlur}
                                                    selected={value}
                                                    isInvalid={errors.global_route ? true : false}
                                                    positionFixed
                                                    emptyLabel="Routes not available"
                                                />
                                            )}
                                        />

                                        {!errors.global_route && !productGlobalRoutes.loading &&
                                            <span className="arrow-icon"></span>}

                                        <span className="text-danger err-msg">
                                            {errors.global_route?.message}
                                        </span>
                                    </div>
                                }

                                <div className="form-group mt-md-0 mt-3 typeahead-container">
                                    <label
                                        style={{ fontSize: "14px" }}
                                        className="col-form-label roboto-bold"
                                    >
                                        {constLabel?.lbl_routes
                                            ? constLabel.lbl_routes
                                            : "Select Operation"}
                                        <span className="text-danger"> *</span>
                                    </label>


                                    <Controller
                                        name="operation"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <Typeahead
                                                id="basic-typeahead-single"
                                                labelKey="operationname"
                                                multiple={false}
                                                // size="sm"
                                                options={prodOpers.data}
                                                isLoading={prodOpers.loading}
                                                placeholder="Select operation"
                                                onChange={(selected) => {
                                                    onChange(selected);

                                                    if (selected.length > 0) {
                                                        fetchEmp(prodOpers.data, selected);
                                                    } else {
                                                        setValue("operator", []);
                                                    }
                                                }}
                                                onBlur={onBlur}
                                                selected={value}
                                                isInvalid={errors.operation ? true : false}
                                                positionFixed
                                                emptyLabel="Operations not available"
                                            />
                                        )}
                                    />

                                    {!errors.operation && !prodOpers.loading &&
                                        <span className="arrow-icon"></span>}

                                    <span className="text-danger err-msg">
                                        {errors.operation?.message}
                                    </span>
                                </div>


                                <div className="form-group mt-md-0 mt-3 typeahead-container">
                                    <label
                                        style={{ fontSize: "14px" }}
                                        className="col-form-label roboto-bold"
                                    >
                                        {constLabel?.lbl_routes
                                            ? constLabel.lbl_routes
                                            : "Select Employee"}
                                        <span className="text-danger"> *</span>
                                    </label>


                                    <Controller
                                        name="operator"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <Typeahead
                                                id="basic-typeahead-single"
                                                labelKey="FullName"
                                                multiple={false}
                                                // size="sm"
                                                options={empListWithAvail.data}
                                                isLoading={empListWithAvail.loading}
                                                placeholder="Select employee"
                                                onChange={
                                                    (selected) => {
                                                        console.log("selected", selected);
                                                        setValue("operator", selected);

                                                        if (selected.length > 0) {
                                                            const activeProd = find_active_prod();
                                                            if (activeProd.selected_route) {
                                                                store_selected_data_in_store(activeProd.selected_route[0].route_operation_list);
                                                            }
                                                            // else {
                                                            //     store_selected_data_in_store(prodOpers.data);
                                                            // }

                                                            const local_present_jobcards = [];
                                                            activeProd.selected_route[0].route_operation_list.forEach(
                                                                (val) => {
                                                                    // if (val.create_job_card !== undefined && val.uuid == curr_operation[0].uuid) {
                                                                    if (val.create_job_card !== undefined &&
                                                                        selected[0].uuid == val.create_job_card.operator[0].uuid) {
                                                                        const job_card_to_append_in_calender = create_job_obj(val.create_job_card);
                                                                        local_present_jobcards.push(job_card_to_append_in_calender);
                                                                    }
                                                                });

                                                            set_calender_events({
                                                                ...calender_events,
                                                                loading: false,
                                                                data: [...selected[0].jobcard_list,
                                                                ...local_present_jobcards]
                                                            });

                                                            // set_calender_events({
                                                            //     ...calender_events,
                                                            //     data: selected[0].jobcard_list, loading: false
                                                            // });
                                                        } else {
                                                            set_calender_events({
                                                                ...calender_events,
                                                                data: [], loading: false
                                                            });
                                                        }
                                                    }
                                                }
                                                onBlur={onBlur}
                                                selected={value}
                                                isInvalid={errors.operator ? true : false}
                                                positionFixed
                                                emptyLabel="Operators not available"
                                            />
                                        )}
                                    />

                                    {!errors.operator && !empListWithAvail.loading &&
                                        <span className="arrow-icon"></span>}
                                </div>

                            </div>



                            <div className='col-12 col-md-9'>
                                {/* {JSON.stringify(base_data.big_calender_date)} */}
                                <div className='pb-2'>
                                    <i><p className="mb-0">
                                        <span className="text-danger roboto-bold">Note - </span>
                                        Click on the empty space in the date box to create a job card.</p></i>
                                </div>

                                <BigCalendar
                                    localizer={localizer}
                                    events={calender_events.data}
                                    startAccessor={(event) => new Date(event.start_time)} // Convert start to Date
                                    endAccessor={(event) => new Date(event.end_time)}
                                    titleAccessor={(event) => event.job_card_no}            // Use "task" for title
                                    tooltipAccessor={(event) => `status - ${event.status}`}
                                    view={base_data.view}
                                    onNavigate={(d, view, action) => {
                                        console.log(d);
                                        console.log("view", view);
                                        console.log("action", action);
                                        handle_b_calender_navigation(d, view);
                                    }}
                                    onView={(view) => {
                                        handle_b_calender_navigation(
                                            new Date(base_data.big_calender_date),
                                            view);
                                    }}
                                    date={base_data.big_calender_date}
                                    onSelectEvent={(event) => {
                                        setVisible({
                                            state: true, data: null,
                                            displayData: event
                                        });
                                        console.log("event", event);
                                    }}
                                    selectable={true}
                                    popup={true}
                                    // onShowMore={(event) => {
                                    //     console.log("event", event);
                                    // }}
                                    onSelectSlot={(slot) => {

                                        console.log("slot", slot);
                                        // const today = new Date(); // Current date and time
                                        // today.setHours(0, 0, 0, 0);
                                        const plan_date = new Date(StepOneSavedData.formData.plan_date);
                                        plan_date.setHours(0, 0, 0, 0);
                                        const delivery_date = new Date(StepOneSavedData.formData.delivery_date);
                                        delivery_date.setHours(0, 0, 0, 0);


                                        console.log("plan_date", plan_date);

                                        // if (today > new Date(slot.slots[0])) {
                                        if (new Date(slot.slots[0]) < plan_date ||
                                            new Date(slot.slots[0]) > delivery_date) {
                                            toast.error(`Please select a date that is 
                                                greater than plan date & less than delivery date`,
                                                { autoClose: 3000, });
                                            return
                                        }

                                        const sel_operation = getValues("operation");
                                        const sel_operator = getValues("operator");

                                        const data = {
                                            slot: slot.slots[0],
                                            base_data: base_data,
                                            sel_operation: sel_operation,
                                            sel_operator: sel_operator
                                        }
                                        setVisible({
                                            state: true, data: data,
                                            displayData: null
                                        });
                                    }}
                                    // length={base_data.end_date}
                                    // date={new Date(base_data.start_date)}
                                    // max={new Date(base_data.start_date)}
                                    // min={new Date(base_data.end_date)}
                                    style={{ height: "500px" }}
                                    formats={customFormats}
                                // style={{ height: "100%" }}
                                />

                            </div>
                        </>
                    }
                </div>


            </div>
            <div className='d-flex justify-content-end mt-4'>
                <button className='btn btn-grey px-4'
                    onClick={() => {
                        prevStep();
                    }}
                >Previous</button>
                <button
                    onClick={() => submitStep()}
                    className='btn button-primary px-4 ms-0 ms-md-2'
                >Next</button>
            </div>



            {visible.state &&
                <JobCardModalCalender
                    visible={visible}
                    setVisible={setVisible}
                    set_calender_events={set_calender_events}
                    calender_events={calender_events}
                    delete_added_job_card={delete_added_job_card}
                    empListWithAvail={empListWithAvail}
                    setEmpListWithAvail={setEmpListWithAvail}

                />}
        </React.Fragment>
    )
}

export default CalenderView