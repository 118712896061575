import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tooltip } from "react-tooltip";
import { Edit, Edit2, Trash2 } from "react-feather";
import { Modal } from "react-bootstrap";
import {
  getOperationCat,
  updateOperation,
  getMachineList,
  getOperation,
} from "../../../api/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { updateGlobalRouting } from "../../../api";

const schema = yup
  .object()
  .shape({
    routeName: yup.string().required("Route name is required !"),
    items: yup.array().of(
      yup.object().shape({
        sequenceNo: yup
          .string()
          .required("Sequence no is required !")
          .matches(/^[0-9]*$/, "Enter sequence no"),
        operation: yup
          .array()
          .min(1, "Please select operation !")
          .required("Please select operation !"),
        operationCost: yup
          .string()
          .matches(/^$|^\d+(\.\d+)?$/, "Enter operation cost")
          .notRequired(),
        manHourCost: yup
          .string()
          .matches(/^$|^\d+(\.\d+)?$/, "Enter man hour cost")
          .notRequired(),
        machine: yup
          .array()
        //   .min(1, "Please select machine !")
          .notRequired(),
        cycleTime: yup
          .string()
          .matches(/^$|^\d+(\.\d+)?$/, "Enter valid cycle time !")
          .notRequired(),
        settingTime: yup
          .string()
          .matches(/^$|^\d+(\.\d+)?$/, "Enter valid setting time !")
          .notRequired(),
      })
    ),
  })
  .required();

function Edit_Global_Routing(props) {
  const [visible, setVisible] = useState(false);

  const [machineList, setMachineList] = useState({ data: [], loading: false });
  const [operList, setOperList] = useState({ data: [], loading: false });

  const [lockBtn, setLockBtn] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  useEffect(() => {
    // resetting the form
    if (visible) {
      fetchOperations();
      fetchMachineList();
    }
  }, [visible]);

  useEffect(() => {
    var routingArr = [];
    if (props.data?.routeData?.length > 0) {
      for (let i = 0; i < props.data?.routeData?.length; i++) {
        routingArr.push({
          sequenceNo: props.data?.routeData?.[i]?.sequence_no,
          operation: [
            {
              uuid: props.data?.routeData?.[i]?.operation_id,
              operationName: props.data?.routeData?.[i]?.operationname,
              operationCategoryId: props.data?.routeData?.[i]?.operationCategoryId,
              operation_category_id: props.data?.routeData?.[i]?.operation_category_id,
              oprationCategoryName: props.data?.routeData?.[i]?.oprationCategoryName,
            },
          ],
          operationCost: props.data?.routeData?.[i]?.operation_cost ? props.data?.routeData?.[i]?.operation_cost : 0,
          manHourCost: props.data?.routeData?.[i]?.man_hour_cost ? props.data?.routeData?.[i]?.man_hour_cost : 0,
          machine: props.data?.routeData?.[i]?.machine_id_id ? [
            {
              uuid: props.data?.routeData?.[i]?.machine_id_id,
              machineName: props.data?.routeData?.[i]?.machine_name,
              serialNo: props.data?.routeData?.[i]?.serialNo,
              machineNameSerialNo: props.data?.routeData?.[i]?.machine_name + " - " + props.data?.routeData?.[i]?.serialNo,
            },
          ] : [],
          cycleTime: props.data?.routeData?.[i]?.cycle_time ? props.data?.routeData?.[i]?.cycle_time : 0,
          settingTime:  props.data?.routeData?.[i]?.setting_time ? props.data?.routeData?.[i]?.setting_time : 0,
          group_jobCards: props.data?.routeData?.[i]?.group_jobCards === true ? true : false,
        });
      }
      setValue("items", routingArr, { shouldValidate: true });
    //   reset({
    //     items: routingArr,
    //   });
    } else {
    //   reset({
    //     items: [{}],
    //   });
      setValue("items", []);
    }
  }, [props.data.routeData]);

  function fetchMachineList() {
    setMachineList({ ...machineList, data: [], loading: true });
    getMachineList().then(
      (res) => {
        if (res.data.data.length > 0) {
          const result = res.data.data.map((item) => ({
            ...item,
            machineNameSerialNo: item.machineName + " - " + item.serialNo,
          }));
          setMachineList({ ...machineList, data: result, loading: false });
        } else {
          setMachineList({ ...machineList, data: [], loading: false });
        }
      },
      (err) => {
        setMachineList({ ...machineList, data: [], loading: false });
      }
    );
  }

  function fetchOperations() {
    setOperList({ ...operList, data: [], loading: true });
    getOperation().then(
      (res) => {
        if (res.data.data.length > 0) {
          const result = res.data.data.filter((item) => item.operationName !== "");
          setOperList({ ...operList, data: result, loading: false });
        } else {
          toast.error("Operations not found !");
        }
      },
      (err) => {
        setOperList({ ...operList, data: [], loading: false });
      }
    );
  }

  const onSubmit = (data) => {
    // console.log("data", data); return;
    if (data !== "") {
        setLockBtn(true);
        let routeArr = [];
        if (data.items?.length > 0) {
          for (let i = 0; i < data.items?.length; i++) {
            const payload = {
              sequence_no: Number(data.items?.[i]?.sequenceNo),
              operation_id: data.items?.[i]?.operation?.[0]?.uuid,
              operationname: data.items?.[i]?.operation?.[0]?.operationName,
              operationCategoryId: data.items?.[i]?.operation?.[0]?.operationCategoryId,
              operation_category_id: data.items?.[i]?.operation?.[0]?.operation_category_id,
              oprationCategoryName: data.items?.[i]?.operation?.[0]?.oprationCategoryName,
              operation_cost: data.items?.[i]?.operationCost ? Number(data.items?.[i]?.operationCost) : 0,
              man_hour_cost: data.items?.[i]?.manHourCost ? Number(data.items?.[i]?.manHourCost) : 0,
              machine_id_id: data.items[i]?.machine ? data.items[i]?.machine?.[0]?.uuid : null ,
              machine_name: data.items[i]?.machine ? data.items[i]?.machine?.[0]?.machineName : null,
              serialNo: data.items[i]?.machine ? data.items[i]?.machine?.[0]?.serialNo : null,
              cycle_time: data.items[i]?.cycleTime ? data.items[i]?.cycleTime : null,
              setting_time: data.items[i]?.settingTime ? data.items[i]?.settingTime : null,
              group_jobCards: data.items[i]?.group_jobCards === true ? true : false,
            };
            routeArr.push(payload);
          }
        }
  
        const reqPayload = {
          uuid: props.data.uuid,
          routeName: data.routeName,
          routeData: routeArr,
        };
  
        console.log("reqPayload", reqPayload);
        // return;

      updateGlobalRouting(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Routing updated successfully !", {
              autoClose: 2000,
            });
            props.fetchGlobalRouting(props.page, props.entriesPerPage);
            setVisible(false);
            setLockBtn(false);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          }
          setLockBtn(false);
        }
      );
    } else {
      errors.showMessages();
      setLockBtn(false);
    }
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
        <Edit
            data-tooltip-id={"edit-tooltip"}
            data-tooltip-content={
              props.constLabel?.lbl_edit_routing
              ? props.constLabel?.lbl_edit_routing
              : "Edit Routing"
            }
          className="menu_icon_grey cursor_pointer me-2"
          size={20} 
          onClick={() => setVisible(true)}
        />
      <Modal show={visible} onHide={() => { setVisible(false); }} size={"xl"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_update_routing
              ? props.constLabel.lbl_update_routing
              : "Update Routing"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="row">
              <div className="col-12 col-md-3">
                <label
                  className="lbl-style roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {props.constLabel?.lbl_route_name
                    ? props.constLabel.lbl_route_name
                    : "Route Name"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  className={
                    errors.routeName
                      ? "form-control  is-invalid mt-2"
                      : "form-control mt-2 "
                  }
                  id="operationName"
                  name="operationName"
                  defaultValue={
                    props.data !== undefined || props.data.routeName !== ""
                      ? props.data.routeName
                      : ""
                  }
                  placeholder="Enter route name"
                  {...register("routeName")}
                />
                {errors.routeName && (
                  <span className="text-danger err-msg">
                    {errors.routeName.message}
                  </span>
                )}
              </div>
            </div>
            <hr className="mb-1" />
              <div className="d-flex justify-content-start align-items-center">
                <p className="roboto-bold mb-0" style={{ fontSize: "16px" }}>
                  {props.constLabel?.lbl_routing_detail
                    ? props.constLabel?.lbl_routing_detail
                    : "Routing Detail"}
                </p>

              </div>
              <hr className="mt-1" />
              <div className="col-12">
                {/* {fields.length > 0 ? ( */}
                <div className="">
                  <table className="table my-2 table-stirped table-bordered">
                    <thead>
                      <tr>
                        <th>
                          {props.constLabel?.lbl_sequence_no
                            ? props.constLabel?.lbl_sequence_no
                            : "Sequence No"}
                          <span className="text-danger"> *</span>
                        </th>
                        <th>
                          {props.constLabel?.lbl_operation
                            ? props.constLabel?.lbl_operation
                            : "Operation"}
                          <span className="text-danger"> *</span>
                        </th>
                        <th>
                          {props.constLabel?.lbl_operation_cost
                            ? props.constLabel?.lbl_operation_cost
                            : "Operation Cost"}
                        </th>
                        <th>
                          {props.constLabel?.lbl_grouped_operator
                            ? props.constLabel?.lbl_grouped_operator
                            : "Grouped Operator"}
                        </th>
                        <th>
                          {props.constLabel?.lbl_man_hour_cost
                            ? props.constLabel?.lbl_man_hour_cost
                            : "Man Hour Cost"}
                        </th>
                        <th>
                          {props.constLabel?.lbl_machine
                            ? props.constLabel?.lbl_machine
                            : "Machine"}
                        </th>
                        <th>
                          {props.constLabel?.lbl_cycle_time
                            ? props.constLabel?.lbl_cycle_time
                            : "Cycle Time"}
                        </th>
                        <th>
                          {props.constLabel?.lbl_setting_time
                            ? props.constLabel?.lbl_setting_time
                            : "Setting Time"}
                        </th>
                        <th>{"Action"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map(({ id }, index) => (
                        <tr key={id}>

                          <td style={{ verticalAlign: "top" }}>
                            <input
                              className={
                                errors.items?.[index]?.sequenceNo
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              name={`items[${index}].sequenceNo`}
                              {...register(`items[${index}].sequenceNo`)}
                              placeholder="Enter sequence no"
                            />

                            {errors.items?.[index]?.sequenceNo && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.sequenceNo.message}
                              </span>
                            )}
                            {index + 1 == fields.length ? (
                              <div>
                                <button
                                  style={{ fontSize: "16px" }}
                                  className="btn btn-sm button-primary px-2 mt-2"
                                  onClick={() => {
                                    append({
                                        sequenceNo: "",
                                        operation: [],
                                        operationCost: "0",
                                        manHourCost: "0",
                                        machine: [],
                                        cycleTime: "0",
                                        settingTime: "0",
                                        group_jobCards: false
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon
                                    style={{
                                      color: "#ffffff",
                                      marginRight: "5px",
                                    }}
                                    icon={faAdd}
                                    size="sm"
                                  />{" "}
                                  {props.constLabel?.lbl_add
                                    ? props.constLabel.lbl_add
                                    : "Add"}
                                </button>
                              </div>
                            ) : null}
                          </td>

                          <td style={{ verticalAlign: "top" }} width={"20%"}>
                            <Controller
                              name={`items[${index}].operation`}
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  size="sm"
                                  id="basic-typeahead"
                                  labelKey="operationName"
                                  multiple={false}
                                  options={operList.data}
                                  isLoading={operList.loading}
                                  placeholder="Select operation"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={
                                    errors.items?.[index]?.operation
                                      ? true
                                      : false
                                  }
                                />
                              )}
                            />
                            {errors.items?.[index]?.operation && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.operation.message}
                              </span>
                            )}

                            
                          </td>

                          <td style={{ verticalAlign: "top" }}>
                            <input
                              className={
                                errors.items?.[index]?.operationCost
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              name={`items[${index}].operationCost`}
                              {...register(`items[${index}].operationCost`)}
                              placeholder="Enter operation cost"
                            />

                            {errors.items?.[index]?.operationCost && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.operationCost.message}
                              </span>
                            )}
                          </td>

                          <td style={{ verticalAlign: "top" }}>
                            <input
                              className="form-check-input cursor_pointer"
                              type="checkbox"
                              defaultChecked={fields[index].group_jobCards}
                              // checked={fields[index].group_jobCards}
                              name={`items[${index}].group_jobCards`}
                              {...register(`items[${index}].group_jobCards`)}
                            />
                          </td>

                          <td style={{ verticalAlign: "top" }}>
                            <input
                              className={
                                errors.items?.[index]?.manHourCost
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              name={`items[${index}].manHourCost`}
                              {...register(`items[${index}].manHourCost`)}
                              placeholder="Enter man hour cost"
                            />

                            {errors.items?.[index]?.manHourCost && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.manHourCost.message}
                              </span>
                            )}
                          </td>

                          <td style={{ verticalAlign: "top" }} width={"20%"}>
                            <Controller
                              name={`items[${index}].machine`}
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  size="sm"
                                  id="basic-typeahead"
                                  labelKey="machineNameSerialNo"
                                  multiple={false}
                                  options={machineList.data}
                                  isLoading={machineList.loading}
                                  placeholder="Select machine"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                //   isInvalid={
                                //     errors.items?.[index]?.machine
                                //       ? true
                                //       : false
                                //   }
                                />
                              )}
                            />
                            {/* {errors.items?.[index]?.machine && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.machine.message}
                              </span>
                            )} */}

                          </td>

                          <td style={{ verticalAlign: "top" }}>
                            <input
                              className={
                                errors.items?.[index]?.cycleTime
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              name={`items[${index}].cycleTime`}
                              {...register(`items[${index}].cycleTime`)}
                              placeholder="Enter cycle time"
                            />

                            {errors.items?.[index]?.cycleTime && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.cycleTime.message}
                              </span>
                            )}
                          </td>

                          <td style={{ verticalAlign: "top" }}>
                            <input
                              className={
                                errors.items?.[index]?.settingTime
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              name={`items[${index}].settingTime`}
                              {...register(`items[${index}].settingTime`)}
                              placeholder="Enter setting time"
                            />

                            {errors.items?.[index]?.settingTime && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.settingTime.message}
                              </span>
                            )}
                          </td>

                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "top",
                            }}
                          >
                            <Tooltip
                              id={"delete-tooltip" + index}
                              place="top"
                            />
                            <Trash2
                              data-tooltip-id={"delete-tooltip" + index}
                              data-tooltip-content={"Delete"}
                              className="menu_icon_red cursor_pointer"
                              size={20}
                              onClick={
                                index > 0
                                  ? () => {
                                      remove(index);
                                    }
                                  : null
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* ) : (
                  <div
                    className="alert alert-danger mb-0 text-center"
                    role="alert"
                  >
                    {"No machine data found !"}
                  </div>
                )} */}
              </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel?.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default Edit_Global_Routing;