import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getProdList,
  get_tool_sub_family,
  get_tool_type_family,
  saveProduct,
  updateProduct,
  getMarket_Group,
  getProdCategory,
  getProduct_Family,
  getUOM,
  gethsn_master,
  createMarketGroup,
  createProduct_Family,
  createProdCategory
  
} from "../../../../../api";
import {
  convertDateYYMMDD,
  getLabels,
} from "../../../../../common/Functions/CommonFunctions";

import DatePicker from "react-datepicker";
import AddProductCategory from "../../../AddMasters/AddProductCategory";
import AddProductFamily from "../../../AddMasters/AddProductFamily";
import info from '../../../../../assets/images/info.png';
import { Tooltip } from "react-tooltip";
import AddTargetSegment from "../../../AddMasters/AddTargetSegment";
import { APPAREL, FASHION } from "../../../../../common/Constants/constants";

const schema = yup
  .object()
  .shape({
    tooltype: yup
      .array()
      .required("Please select guage type !")
      .min(1, "Please select guage type !"),
    toolsubtype: yup
      .array()
      .required("Please select gauge sub-type !")
      .min(1, "Please select gauge sub-type !"),
    productName: yup.string().required("Gauge name is required !"),
    toolnumber: yup.string().required("Gauge number is required !"),
    cal_freq: yup
      .string()
      .required("Calibration frequency is required !")
      .matches(/^[0-9]+$/, "Enter valid frequency !"),
    cal_validity: yup
      .string()
      .required("Calibration validity is required !")
      .matches(/^[0-9]+$/, "Enter valid validity !"),
    date: yup
      .date()
      .typeError("Please select calibration date !")
      .required("Please select a calibration date !"),
    duedate: yup
      .date()
      .typeError("Please select calibration due date !")
      .required("Please select a calibration due date !"),
    openingStock: yup
      .string()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      )
      .matches(/^[0-9]+$/, "Enter valid opening stock")
      .nullable(),
    OEMnumber: yup
      .string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      ),
    machineCost: yup
      .string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      )
      .matches(/^\d+(\.\d+)?$/, "Enter valid cost of manufacturing"),
    // salesPrice: yup
    //   .string()
    //   .required("Selling price is required !")
    //   .matches(/^\d+(\.\d+)?$/, "Enter valid Selling price"),
    // purchase_price: yup
    //   .string()
    //   .required("Procurement price is required !")
    //   .matches(/^\d+(\.\d+)?$/, "Enter valid procurement price"),
    reorder_qty: yup
    .string()
    .transform((value, originalValue) => originalValue.trim() === '' ? null : value)
    .matches(/^[0-9]+$/, 'Enter valid reorder qty')
    .nullable(),
  })
  .required();

function Gauges(props) {
  let navigate = useNavigate();
  let location = useLocation();

  const [tooltype, settooltype] = useState({
    data: [],
    loading: false,
  });
  const [toolsubtype, settoolsubtype] = useState({
    data: [],
    loading: false,
  });

  const [constLabel, setConstLabel] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);
  const [calibrationDate, setcalibrationDate] = useState();
  const [calibrationDueDate, setcalibrationDueDate] = useState();
  const [tool_type, settool_type] = useState([]);
  const [tool_subtype, settool_subtype] = useState([]);
  const [toolsubdata, settoolsubdata] = useState({ data: [], loading: false });
  const [validity, setValidity] = useState();
  const [prodCategory, setProdCategory] = useState({data: [], loading: false});
  const [marketGroup, setMarketGroup] = useState({ data: [], loading: false });
  const [prodFamily, setProdFamily] = useState({ data: [], loading: false });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [hsnList, setHSNList] = useState({ data: [], loading: false });
  const [filterConfig, setFilterConfig] = useState({ hsn_sac_code: "" });
  const { register, handleSubmit, formState, control, reset, watch, setValue, setError, clearErrors } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;
  let selUnit = watch("unit");
  let selCategory = watch("category");
  const localData = JSON.parse(localStorage.getItem("localData"));

  useEffect(() => {
    setConstLabel(getLabels("Master", "Product"));
    fetchToolType_Family();
    fetchToolSubType();
    fetchProductCategory();
    fetchMarketGroup();
    fetchProductFamily();
    fetchUOM();
    // console.log("main", props.main_obj);
    if (props?.main_obj?.prod_overview?.uuid) {
      let val =
        props.main_obj.prod_overview.toolfamily !== undefined &&
        props.main_obj.prod_overview.toolfamily !== null &&
        props.main_obj.prod_overview?.tool_family_id !== null
          ? [
              {
                uuid: props.main_obj.prod_overview.tool_family_id.uuid,
                name: props.main_obj.prod_overview?.tool_family_id.name,
                tool_family_code:
                  props.main_obj.prod_overview?.tool_family_id.tool_family_code,
              },
            ]
          : [];
      let val2 =
        props.main_obj.prod_overview.toolsubfamily !== undefined &&
        props.main_obj.prod_overview.toolsubfamily !== null &&
        props.main_obj.prod_overview?.tool_sub_family_id !== null
          ? [
              {
                uuid: props.main_obj.prod_overview.tool_sub_family_id.uuid,
                name: props.main_obj.prod_overview?.tool_sub_family_id.name,
                tool_sub_family_code:
                  props.main_obj.prod_overview?.tool_sub_family_id
                    .tool_sub_family_code,
              },
            ]
          : [];
      let val3 =
        props.main_obj.prod_overview.tool_grade_id !== undefined &&
        props.main_obj.prod_overview.tool_grade_id !== null &&
        props.main_obj.prod_overview?.tool_grade_id !== null
          ? [
              {
                uuid: props.main_obj.prod_overview.tool_grade_id.uuid,
                gradename:
                  props.main_obj.prod_overview?.tool_grade_id.gradename,
              },
            ]
          : [];
      setValue("tooltype", val);
      setValue("toolsubtype", val2);
      settool_type(val);
      settool_subtype(val2);
      setcalibrationDate(
        new Date(props.main_obj.prod_overview.calibration_date)
      );
      setcalibrationDueDate(
        new Date(props.main_obj.prod_overview.calibration_duedate)
      );
      setValue(
        "date",
        new Date(props.main_obj.prod_overview.calibration_date),
        { shouldValidate: true }
      );
      setValue(
        "duedate",
        new Date(props.main_obj.prod_overview.calibration_duedate),
        { shouldValidate: true }
      );
      setValidity(props.main_obj.prod_overview.calibration_validity);
    }
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      fetchHSN();
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function fetchHSN() {
    setHSNList({ ...hsnList, data: [], loading: true });
    let payload = {
      pageNo: 1,
      entriesPerPage: 1000,
    };
    if (
      filterConfig.hsn_sac_code !== "" &&
      filterConfig.hsn_sac_code.length >= 4
    ) {
      payload.hsn_sac_code = filterConfig.hsn_sac_code.trim(); // Search by hsn sac code
      gethsn_master(payload).then(
        (res) => {
          setHSNList({ ...hsnList, data: res.data.data, loading: false });
        },
        (err) => {
          setHSNList({ ...hsnList, data: [], loading: false });
        }
      );
    } else {
      // delete payload.hsn_sac_code;
      setHSNList({ ...hsnList, data: [], loading: false });
    }
  }

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM().then(
      (res) => {
        if(res && res.data.data?.length > 0){
          setUOMList({ ...uomList, data: res.data.data, loading: false });
          
          if(location && location.pathname !== "/pcms/editProductConfigurator"){
            setValue("unit", [res.data.data?.[0]]);
          }
        } else {
          setUOMList({ ...uomList, data: [], loading: false });
        } 
      },
      (err) => {
        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  function fetchProductCategory() {
    setProdCategory({ ...prodCategory, data: [], loading: true });
    getProdCategory().then(
      (res) => {
        if(res && res.data.data?.length > 0){
          setProdCategory({
            ...prodCategory,
            data: res.data.data,
            loading: false,
          });

          // if(location && location.pathname !== "/pcms/editProductConfigurator"){
          //   setValue("category", [res.data.data?.[0]]);
          // }
        } else {
          setProdCategory({ ...prodCategory, data: [], loading: false });
        }
      },
      (err) => {
        setProdCategory({ ...prodCategory, data: [], loading: false });
      }
    );
  }

  function fetchMarketGroup() {
    setMarketGroup({ ...marketGroup, data: [], loading: true });
    getMarket_Group().then(
      (res) => {
        if(res && res.data.data?.length > 0){
          setMarketGroup({
            ...marketGroup,
            data: res.data.data,
            loading: false,
          });
          // if(location && location.pathname !== "/pcms/editProductConfigurator"){
          //   setValue("marketGroup", [res.data.data?.[0]]);
          // }
        } else {
          setMarketGroup({ ...marketGroup, data: [], loading: false });
        }
      },
      (err) => {
        setMarketGroup({ ...marketGroup, data: [], loading: false });
      }
    );
  }

  function fetchProductFamily() {
    setProdFamily({ ...prodFamily, data: [], loading: true });
    getProduct_Family().then(
      (res) => {
        if(res && res.data.data?.length > 0){
          setProdFamily({
            ...prodFamily,
            data: res.data.data,
            loading: false,
          });

          // if(location && location.pathname !== "/pcms/editProductConfigurator"){
          //   setValue("productFamily", [res.data.data?.[0]]);
          // }

        } else {
          setProdFamily({ ...prodFamily, data: [], loading: false });
        }
      },
      (err) => {
        setProdFamily({ ...prodFamily, data: [], loading: false });
      }
    );
  }


  function fetchProdList(product_uuid) {
    getProdList({ uuid: product_uuid }).then(
      (res) => {
        props.setMain_obj({
          ...props.main_obj,
          prod_overview: res.data.data[0],
        });
        props.nextStep();
      },
      (err) => {}
    );
  }

  function filter_tool_sub_type(uuid) {
    settoolsubdata({ data: [], loading: true });
    let arr = toolsubtype.data.filter((item) => item.tool_family_id == uuid);
    settoolsubdata({ data: arr, loading: false });
  }

  const onSubmit = (data) => {
    // Check if the industry type is FASHION or APPAREL
    if ([FASHION, APPAREL].includes(localData?.industry_type)) {
      // If the category is empty, set an error for the category field
      if (!data.category?.length) {
        setError("category", {
          type: "custom",
          message: "Please select the category!",
        });
        return; // Stop further execution if validation fails
      }
    }

    if (data.can_be_sold === true) {
      if (data.salesPrice === "" || !/^\d+(\.\d+)?$/.test(data.salesPrice)) {
        setError("salesPrice", {
          type: "custom",
          message: data.salesPrice === ""
            ? "Please enter selling price!"
            : "Enter a valid value!",
        });
        return; // Stop further execution if validation fails
      }
    }

    if (data.can_be_purchased === true) {
      if (data.purchase_price === "" || !/^\d+(\.\d+)?$/.test(data.purchase_price)) {
        setError("purchase_price", {
          type: "custom",
          message: data.purchase_price === ""
            ? "Please enter procurement price!"
            : "Enter a valid value!",
        });
        return; // Stop further execution if validation fails
      }
    }
    
    setBtnDisable(true);

    if (data !== "") {
      const reqPayload = {
        productType: props.product_type?.prodTypeId,
        productTypename: props.product_type?.productType,
        product_type_id: props.product_type?.uuid,
        productName: data.productName,
        toolnumber: data?.toolnumber,
        lowersize: data.lowersize,
        uppersize: data.uppersize,
        tool_family_id: data?.tooltype[0]?.uuid,
        toolfamily: data?.tooltype[0]?.name,
        tool_sub_family_id: data?.toolsubtype[0]?.uuid,
        toolsubfamily: data?.toolsubtype[0]?.name,
        calibration_frequency: data?.cal_freq,
        calibration_validity: data?.cal_validity,
        calibration_date: convertDateYYMMDD(calibrationDate, "-"),
        calibration_duedate: convertDateYYMMDD(calibrationDueDate, "-"),
       
        category: data.category?.length
          ? data.category?.[0]?.categoryname
          : null,
        category_id: data.category?.length ? data.category?.[0]?.uuid : null,
        marketgrp: data.marketGroup?.[0]?.grpId,
        market_grp_id: data.marketGroup?.[0]?.uuid,
        prodfamily: data.productFamily?.[0]?.familyId,
        prod_family_id: data.productFamily?.[0]?.uuid,
        openingstock: data.openingStock,
        hsn: data.hsn?.length > 0 ? data.hsn?.[0]?.hsn_sac_code : null,
        hsn_uuid: data.hsn?.length > 0 ? data.hsn?.[0]?.uuid : null,
        machineCost: data.machineCost !== null ? data.machineCost : "0.00",
        salesPrice: data.can_be_sold == true ? data.salesPrice : "0.00",
        can_be_sold: data.can_be_sold,
        can_be_purchased: data.can_be_purchased,
        OEMnumber: data.OEMnumber,
        partNo: data.OEMnumber,
        purchase_price: data.can_be_purchased == true ? data.purchase_price : "0.00",
        unit_id: data.unit ? data.unit?.[0]?.uuid : null,
        remark: data.remark,
        batch_tracking: data.batch_tracking,
      };

      // console.log("reqpayload", reqPayload);
      // console.log("validity",validity)

      // return

      // props.nextStep();
      if (props.main_obj.prod_overview?.uuid) {
        reqPayload.uuid = props.main_obj.prod_overview.uuid;

        // update_tool_fixture(reqPayload).then(
        updateProduct(reqPayload).then(
          (res) => {
            setBtnDisable(false);
            if (res.status === 200 || res.status === 201) {
              toast.success("Gauges updated successfully !", {
                autoClose: 2000,
              });
              setTimeout(() => {
                // fetchProdList(res.data.uuid);
                fetchProdList(res.data.updatedRows?.[0]?.uuid);
              }, [2300]);
            }
          },
          (err) => {
            setBtnDisable(false);

            if (err.response.status === 400) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
            }
          }
        );
      } else {
        // save_tool_fixture(reqPayload).then(
        saveProduct(reqPayload).then(
          (res) => {
            // console.log("res", res);
            setBtnDisable(false);

            if (res.status === 200 || res.status === 201) {
              toast.success("Gauges created successfully !", {
                autoClose: 2000,
              });

              setTimeout(() => {
                // fetchProdList(res.data.uuid);
                fetchProdList(res.data.result.uuid);
              }, [2300]);
            }
          },
          (err) => {
            setBtnDisable(false);

            if (err.response.status === 400) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
            }
          }
        );
      }
    } else {
      errors.showMessages();
    }
  };

  function fetchToolType_Family() {
    settooltype({ loading: true, data: [] });

    get_tool_type_family().then(
      (res) => {
        settooltype({
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        settooltype({ loading: false, data: [] });
      }
    );
  }

  function fetchToolSubType() {
    settoolsubtype({ loading: true, data: [] });

    get_tool_sub_family().then(
      (res) => {
        settoolsubtype({
          loading: false,
          data: res.data.data,
        });
        if (props?.main_obj?.prod_overview?.uuid) {
          let uuid = props.main_obj.prod_overview.tool_family_id.uuid;
          let arr = res.data.data.filter((item) => item.tool_family_id == uuid);
          settoolsubdata({ data: arr, loading: false });
        }
      },
      (err) => {
        settoolsubtype({ loading: false, data: [] });
      }
    );
  }

  function handleCheckBoxChange(e, stringName){

    const isChecked = e.target.checked;

    if (isChecked) {
      // Make the field required with regex validation
      setError(stringName, {
        type: "manual",
        message: `${stringName == "salesPrice" ? "Selling price" : "Procurement price"} is required.`,
      });
    } else {
      // Only regex validation
      setValue(stringName, "", {shouldValidate: true});
      clearErrors(stringName);
    }
  }

  const handleInputChange = (e, fieldName, regex) => {
    const value = e.target.value;
  
    if (regex.test(value)) {
      // Valid input
      clearErrors(fieldName);
    } else {
      // Invalid input
      setError(fieldName, {
        type: "manual",
        message: "Enter a valid value.",
      });
    }
  };

    function addProductCategory(data){
        if (data !== "") {
          const reqPayload = {
            product_type: Object.keys(props.product_type).length > 0 ? props.product_type?.uuid : null,
            categoryname: data,
          };
          createProdCategory(reqPayload).then(
            (res) => {
              // console.log("res", res);
              if (res.status === 200 || res.status === 201) {
                toast.success("Product category created successfully !", {
                  autoClose: 1000,
                });
                setTimeout(() => {
                  fetchProductCategory();
                }, 1300);
                
                setValue("category", [res.data], { shouldValidate: true });
              }
            },
            (err) => {
              if (err.response.status === 400) {
                toast.error(err.response.data.message, {
                  autoClose: 2000,
                });
                reset();
              }
            }
          );
        } else {
          errors.showMessages();
        }
    }
  
    function addProductFamily(data){
      if (data !== "") {
        const reqPayload = {
          product_type: Object.keys(props.product_type).length > 0 ? props.product_type?.uuid : null,
          product_category_id: selCategory?.length > 0 ? selCategory?.[0]?.uuid : null,
          familyname: data,
        };
        createProduct_Family(reqPayload).then(
          (res) => {
            // console.log("res", res);
            if (res.status === 200 || res.status === 201) {
              toast.success("Product family created successfully !", {
                autoClose: 1000,
              });
              setTimeout(() => {
                fetchProductFamily();
              }, 1300);
              
              setValue("familyname", [res.data], { shouldValidate: true });
            }
          },
          (err) => {
            if (err.response.status === 400) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
              reset();
            }
          }
        );
      } else {
        errors.showMessages();
      }
    }
  
    function addTargetSegment(data){
      if (data !== "") {
        const reqPayload = {
          groupname: data,
        };
        createMarketGroup(reqPayload).then(
          (res) => {
            // console.log("res", res);
            if (res.status === 200 || res.status === 201) {
              toast.success("Target segment created successfully !", {
                autoClose: 1000,
              });
              setTimeout(() => {
                fetchProductFamily();
              }, 1300);
              
              setValue("groupname", [res.data], { shouldValidate: true });
            }
          },
          (err) => {
            if (err.response.status === 400) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
              reset();
            }
          }
        );
      } else {
        errors.showMessages();
      }
    }

  

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="card shadow-sm rounded-0 p-2">
        <div className="card-body">
          <p className="pc_card_title roboto-bold">
            {constLabel?.lbl_gauges_summary
              ? constLabel?.lbl_gauges_summary
              : "Gauges Summary"}
          </p>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_gauge_type
                    ? constLabel?.lbl_gauge_type
                    : "Guage Type"}
                  <span className="text-danger"> *</span>
                </label>
                <Controller
                  name="tooltype"
                  control={control}
                  // defaultValue={
                  //   props.main_obj.prod_overview.toolfamily !== undefined &&
                  //     props.main_obj.prod_overview.toolfamily !== null &&
                  //     props.main_obj.prod_overview?.tool_family_id !== null
                  //     ? [{uuid:props.main_obj.prod_overview.tool_family_id.uuid, name:props.main_obj.prod_overview?.tool_family_id.name }]
                  //     : []
                  // }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      className="mt-2"
                      multiple={false}
                      options={tooltype.data}
                      isLoading={tooltype.loading}
                      placeholder="Select gaues type"
                      onChange={(selected) => {
                        onChange(selected);

                        settool_type(selected);
                        //  console.log("selec",selected)
                        setValue("toolsubtype", []);
                        settool_subtype([]);
                        filter_tool_sub_type(selected[0]?.uuid);
                      }}
                      // defaultSelected={
                      //   props.main_obj.prod_overview.toolfamily !== undefined &&
                      //     props.main_obj.prod_overview.toolfamily !== null &&
                      //     props.main_obj.prod_overview?.tool_family_id !== null
                      //     ? [{uuid:props.main_obj.prod_overview.tool_family_id.uuid, name:props.main_obj.prod_overview?.tool_family_id.name }]
                      //     : []
                      // }
                      selected={value}
                      isInvalid={errors.tooltype ? true : false}
                      onBlur={onBlur}
                    />
                  )}
                />
                {!tooltype.loading && !errors.tooltype && <span className="arrow-icon"></span>}
                {errors.tooltype && (
                  <span className="text-danger err-msg">
                    {errors.tooltype.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_gauge_type_code
                    ? constLabel?.lbl_gauge_type_code
                    : "Gauge Type Code"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  placeholder="Enter gauge type code"
                  defaultValue={
                    tool_type.length > 0 && tool_type[0]?.tool_family_code
                      ? tool_type[0]?.tool_family_code
                      : ""
                  }
                  disabled={true}
                  name="tooltypecode"
                />
              </div>
            </div>
            <div className="col-md-3 ">
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_gauge_sub_type
                    ? constLabel?.lbl_gauge_sub_type
                    : "Gauge Sub Type"}
                  <span className="text-danger"> *</span>
                </label>
                <Controller
                  name="toolsubtype"
                  control={control}
                  // defaultValue={
                  //   props.main_obj.prod_overview.toolfamily !== undefined &&
                  //     props.main_obj.prod_overview.toolfamily !== null &&
                  //     props.main_obj.prod_overview.tool_family_uuid !== null
                  //     ? [props.main_obj.prod_overview?.tool_family_uuid]
                  //     : []
                  // }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      className="mt-2"
                      multiple={false}
                      options={toolsubdata.data}
                      isLoading={toolsubdata.loading}
                      placeholder="Select gauge sub-type"
                      onChange={(selected) => {
                        onChange(selected);
                        settool_subtype(selected);
                      }}
                      // defaultValue={
                      //   props.main_obj.prod_overview.category !== undefined &&
                      //     props.main_obj.prod_overview.category !== null &&
                      //     props.main_obj.prod_overview.category_id !== null
                      //     ? [props.main_obj.prod_overview?.category_id]
                      //     : []
                      // }
                      selected={value}
                      onBlur={onBlur}
                       isInvalid={errors.toolsubtype ? true : false}
                    />
                  )}
                />
                {!toolsubdata.loading && !errors.toolsubtype && <span className="arrow-icon"></span>}
                {errors.toolsubtype && (
                  <span className="text-danger err-msg">
                    {errors.toolsubtype.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3 ">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_gauge_sub_type_code	
                    ? constLabel?.lbl_gauge_sub_type_code	
                    : "Gauge Sub Type Code"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  placeholder="Enter gauge sub-type code"
                  defaultValue={
                    tool_subtype.length > 0 &&
                    tool_subtype[0]?.tool_sub_family_code
                      ? tool_subtype[0]?.tool_sub_family_code
                      : ""
                  }
                  disabled={true}
                  name="toolsubtypecode"
                />
              </div>
            </div>
          
            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_gauge_name	
                    ? constLabel?.lbl_gauge_name	
                    : "Gauge Name"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={
                    errors.productName
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter gauge name"
                  defaultValue={
                    props.main_obj.prod_overview.productName !== null &&
                    props.main_obj.prod_overview.productName !== ""
                      ? props.main_obj.prod_overview.productName
                      : ""
                  }
                  name="productName"
                  {...register("productName")}
                />
                {errors.productName && (
                  <span className="text-danger err-msg">
                    {errors.productName.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_gauge_number
                    ? constLabel?.lbl_gauge_number
                    : "Gauge Number"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={
                    errors.toolnumber
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter gauge number"
                  defaultValue={
                    props.main_obj.prod_overview.toolnumber !== null &&
                    props.main_obj.prod_overview.toolnumber !== ""
                      ? props.main_obj.prod_overview.toolnumber
                      : ""
                  }
                  name="toolnumber"
                  {...register("toolnumber")}
                />
                {errors.toolnumber && (
                  <span className="text-danger err-msg">
                    {errors.toolnumber.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_lower_size	
                    ? constLabel?.lbl_lower_size	
                    : "Lower Size"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={
                    errors.lowersize
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter lower size"
                  defaultValue={
                    props.main_obj.prod_overview.lowersize !== null &&
                    props.main_obj.prod_overview.lowersize !== ""
                      ? props.main_obj.prod_overview.lowersize
                      : ""
                  }
                  name="lowersize"
                  {...register("lowersize")}
                />
                {/* {errors.lowersize && (
                  <span className="text-danger err-msg">
                    {errors.lowersize.message}
                  </span>
                )} */}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_upper_size	
                    ? constLabel?.lbl_upper_size	
                    : "Upper Size"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={
                    errors.uppersize
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter upper size"
                  defaultValue={
                    props.main_obj.prod_overview.uppersize !== null &&
                    props.main_obj.prod_overview.uppersize !== ""
                      ? props.main_obj.prod_overview.uppersize
                      : ""
                  }
                  name="uppersize"
                  {...register("uppersize")}
                />
                {/* {errors.uppersize && (
                  <span className="text-danger err-msg">
                    {errors.uppersize.message}
                  </span>
                )} */}
              </div>
            </div>
            

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_calibration_frequency
                    ? constLabel?.lbl_calibration_frequency
                    : "Calibration Frequency"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={
                    errors.cal_freq
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter calibration frequency"
                  defaultValue={
                    props.main_obj.prod_overview.calibration_frequency !==
                      null &&
                    props.main_obj.prod_overview.calibration_frequency !== ""
                      ? props.main_obj.prod_overview.calibration_frequency
                      : ""
                  }
                  name="cal_freq"
                  {...register("cal_freq")}
                />
                {errors.cal_freq && (
                  <span className="text-danger err-msg">
                    {errors.cal_freq.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_calibration_validity
                    ? constLabel?.lbl_calibration_validity
                    : "	Calibration Validity"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={
                    errors.cal_validity
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter calibration validity"
                  value={validity}
                  defaultValue={
                    props.main_obj.prod_overview.calibration_validity !==
                      null &&
                    props.main_obj.prod_overview.calibration_validity !== ""
                      ? props.main_obj.prod_overview.calibration_validity
                      : ""
                  }
                  name="cal_validity"
                  {...register("cal_validity", {
                    onChange: (e) => {
                      // console.log("e", e.target.value);
                      const inputValue = Number(e.target.value); // Convert the string to an integer
                      setValidity(e.target.value);
                      // console.log("isNaN(inputValue)", isNaN(inputValue));

                      if (isNaN(inputValue) == false && calibrationDate) {
                        const dueDate = new Date(calibrationDate);
                        dueDate.setDate(
                          dueDate.getDate() + Number(e.target.value)
                        );
                        //  console.log("dueDate",dueDate);
                        setcalibrationDueDate(dueDate);
                        setValue("duedate", dueDate, { shouldValidate: true });
                      } else {
                        setcalibrationDueDate();
                      }
                    },
                  })}
                />
                {errors.cal_validity && (
                  <span className="text-danger err-msg">
                    {errors.cal_validity.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_calibration_date
                    ? constLabel?.lbl_calibration_date
                    : "Calibration Date"}
                  <span className="text-danger"> *</span>
                </label>

                <Controller
                  control={control}
                  name="date"
                  render={({ field }) => (
                    <DatePicker
                      className={
                        errors.date
                          ? "form-control  is-invalid mt-2"
                          : "form-control mt-2 "
                      }
                      placeholderText="Select calibration date"
                      selected={calibrationDate}
                      onChange={(date) => {
                        field.onChange(date);

                        //  console.log("validity",validity);

                        if (date) {
                          setcalibrationDate(date);
                        } else {
                          setcalibrationDate();
                        }
                        if (validity && validity.length > 0 && date) {
                          const dueDate = new Date(date);
                          dueDate.setDate(dueDate.getDate() + Number(validity));
                          //  console.log("dueDate",dueDate);
                          setcalibrationDueDate(dueDate);
                          // setcalibrationDate(date);
                          setValue("duedate", dueDate, {
                            shouldValidate: true,
                          });
                        } else {
                          // setcalibrationDate()
                          setcalibrationDueDate();
                        }
                      }}
                      dateFormat="dd/MM/yyyy"
                      autoComplete="false"
                    />
                  )}
                />

                {errors.date && (
                  <span className="text-danger err-msg ">
                    {errors.date.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_calibration_due_date
                    ? constLabel?.lbl_calibration_due_date
                    : "Calibration Due Date"}
                  <span className="text-danger"> *</span>
                </label>

                <Controller
                  control={control}
                  name="duedate"
                  render={({ field }) => (
                    <DatePicker
                      className={
                        errors.duedate
                          ? "form-control  is-invalid mt-2"
                          : "form-control mt-2  "
                      }
                      placeholderText="Select calibration due date"
                      selected={calibrationDueDate}
                      onChange={(date) => {
                        field.onChange(date);
                        setcalibrationDueDate(date);
                      }}
                      disabled={true}
                      // minDate={calibrationDate}
                      dateFormat="dd/MM/yyyy"
                      autoComplete="false"
                    />
                  )}
                />

                {errors.duedate && (
                  <span className="text-danger err-msg ">
                    {errors.duedate.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold d-flex align-items-center">
                  {constLabel?.lbl_product_category
                    ? constLabel?.lbl_product_category
                    : "Product Category"}
                  {/* <span className="text-danger">*</span> */}
                  <Tooltip id={"open-tooltip1"} place="top" style={{ zIndex: 9999 }}/>
                  <img src={info} alt='info' className='cursor_pointer'
                    data-tooltip-id={"open-tooltip1"}
                    // data-tooltip-content={`Classifies items based on their role in the manufacturing process,
                    //   including categories such as raw materials, components, finished goods, packaging materials, tools, and gauges. 
                    //   This field helps in organizing and tracking inventory for streamlined production, procurement, and management.`}
                    data-tooltip-html="To classify products into distinct categories for easier<br />
                       management, searchability, and reporting within the system."
                    height={20} width={20} />
                <AddProductCategory fetchProductCategory={fetchProductCategory} type={props.product_type}/>
                </label>
                <Controller
                  name="category"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.category !== undefined &&
                    props.main_obj.prod_overview.category !== null &&
                    props.main_obj.prod_overview.category_id !== null
                      ? [props.main_obj.prod_overview?.category_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="categoryname"
                      className="mt-2"
                      multiple={false}
                      options={prodCategory.data}
                      isLoading={prodCategory.loading}
                      placeholder="Select product category"
                      // onChange={onChange}
                      onChange={(selected) => {
                        // If the new option is added
                        const isNewOption = selected && selected[0]?.customOption;
                        if (isNewOption) {
                          const newItem = selected[0]?.categoryname;
                          // Fire API to add the new item
                          addProductCategory(newItem);
                        }
                        onChange(selected);
                      }}
                      newSelectionPrefix="Add New: "
                      allowNew
                      defaultValue={
                        props.main_obj.prod_overview.category !== undefined &&
                        props.main_obj.prod_overview.category !== null &&
                        props.main_obj.prod_overview.category_id !== null
                          ? [props.main_obj.prod_overview?.category_id]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                      // isInvalid={errors.category ? true : false}
                    />
                  )}
                />
                {!prodCategory.loading && <span className="arrow-icon"></span>}
                {/* {errors.category && (
                  <span className="text-danger err-msg">
                    {errors.category.message}
                  </span>
                )} */}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold d-flex align-items-center">
                {constLabel?.lbl_target_segment
                    ? constLabel?.lbl_target_segment
                    : "Target Segment"}
                  {/* <span className="text-danger">*</span> */}
                <AddTargetSegment fetchMarketGroup={fetchMarketGroup} />
                </label>
                <Controller
                  name="marketGroup"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.market_grp_id !== undefined &&
                    props.main_obj.prod_overview.market_grp_id !== null
                      ? [props.main_obj.prod_overview?.market_grp_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="groupname"
                      className="mt-2"
                      multiple={false}
                      options={marketGroup.data}
                      isLoading={marketGroup.loading}
                      placeholder="Select target segment"
                      // onChange={onChange}
                      onChange={(selected) => {
                        // If the new option is added
                        const isNewOption = selected && selected[0]?.customOption;
                        if (isNewOption) {
                          const newItem = selected[0]?.groupname;
                          // Fire API to add the new item
                          addTargetSegment(newItem);
                        }
                        onChange(selected);
                      }}
                      newSelectionPrefix="Add New: "
                      allowNew
                      defaultSelected={
                        props.main_obj.prod_overview.market_grp_id !==
                          undefined &&
                        props.main_obj.prod_overview.market_grp_id !== null
                          ? [props.main_obj.prod_overview?.market_grp_id]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                      // isInvalid={errors.marketGroup ? true : false}
                    />
                  )}
                />
                {!marketGroup.loading && <span className="arrow-icon"></span>}
                {/* {errors.marketGroup && (
                  <span className="text-danger err-msg">
                    {errors.marketGroup.message}
                  </span>
                )} */}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold d-flex align-items-center">
                  {constLabel?.lbl_product_family
                    ? constLabel?.lbl_product_family
                    : "Product Family"}
                  {/* <span className="text-danger">*</span> */}
                  <Tooltip id={"open-tooltip2"} place="top" style={{ zIndex: 9999 }}/>
                  <img src={info} alt='info' className='cursor_pointer'
                    data-tooltip-id={"open-tooltip2"}
                    data-tooltip-html=" Groups products that share common characteristics, functionalities, or manufacturing processes.<br /> 
                       This field helps categorize products into broader families, such as similar types of finished goods,<br /> 
                       components, or raw materials, enabling more efficient inventory management, reporting, and product<br />
                       lifecycle tracking."
                    height={20} width={20} />
                <AddProductFamily fetchProductFamily={fetchProductFamily} type={props.product_type} category={selCategory}/>
                </label>
                <Controller
                  name="productFamily"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.prod_family_id !== undefined &&
                    props.main_obj.prod_overview.prod_family_id !== null
                      ? [props.main_obj.prod_overview?.prod_family_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="familyname"
                      className="mt-2"
                      multiple={false}
                      options={prodFamily.data}
                      isLoading={prodFamily.loading}
                      placeholder="Select product family"
                      // onChange={onChange}
                      onChange={(selected) => {
                        // If the new option is added
                        const isNewOption = selected && selected[0]?.customOption;
                        if (isNewOption) {
                          const newItem = selected[0]?.familyname;
                          // Fire API to add the new item
                          addProductFamily(newItem);
                        }
                        onChange(selected);
                      }}
                      newSelectionPrefix="Add New: "
                      allowNew
                      defaultSelected={
                        props.main_obj.prod_overview.prod_family_id !==
                          undefined &&
                        props.main_obj.prod_overview.prod_family_id !== null
                          ? [props.main_obj.prod_overview?.prod_family_id]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                      // isInvalid={errors.productFamily ? true : false}
                    />
                  )}
                />
                {!prodFamily.loading && <span className="arrow-icon"></span>}
                {/* {errors.productFamily && (
                  <span className="text-danger err-msg">
                    {errors.productFamily.message}
                  </span>
                )} */}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_unit_of_measurement ? constLabel?.lbl_unit_of_measurement : "Unit Of Measurement"}
                  {/* <span className="text-danger"> *</span> */}
                </label>
                <Controller
                  name="unit"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.unit_id !== undefined &&
                    props.main_obj.prod_overview.unit_id !== null
                      ? [props.main_obj.prod_overview?.unit_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="uom"
                      className="mt-2"
                      multiple={false}
                      options={uomList.data}
                      isLoading={uomList.loading}
                      placeholder="Select unit"
                      onChange={onChange}
                      defaultSelected={
                        props.main_obj.prod_overview.unit_id !== undefined &&
                        props.main_obj.prod_overview.unit_id !== null
                          ? [props.main_obj.prod_overview?.unit_id]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                      // isInvalid={errors.unit ? true : false}
                    />
                  )}
                />
                {!uomList.loading && <span className="arrow-icon"></span>}
                {/* {errors.unit && (
                  <span className="text-danger err-msg">
                    {errors.unit.message}
                  </span>
                )} */}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_opening_stock
                    ? constLabel?.lbl_opening_stock
                    : "Opening Stock"}{" / "}{selUnit && selUnit[0]?.uom}
                </label>
                <input
                  className={
                    errors.openingStock
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  defaultValue={
                    props.main_obj.prod_overview.openingstock !== null &&
                    props.main_obj.prod_overview.openingstock !== ""
                      ? props.main_obj.prod_overview.openingstock
                      : 0
                  }
                  placeholder="Enter opening stock"
                  name="openingStock"
                  {...register("openingStock")}
                />
                {errors.openingStock && (
                  <span className="text-danger err-msg">
                    {errors.openingStock.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_reorder_qty
                    ? constLabel?.lbl_reorder_qty
                    : "Reorder Qty"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  defaultValue={
                    props.main_obj.prod_overview.reorder_qty !== null &&
                      props.main_obj.prod_overview.reorder_qty !== ""
                      ? props.main_obj.prod_overview.reorder_qty
                      : 0
                  }
                  placeholder="Enter reorder qty"
                  name="reorder_qty"
                  {...register("reorder_qty")}
                />
                {errors.reorder_qty && (
                  <span className="text-danger err-msg">
                    {errors.reorder_qty.message}
                  </span>
                )}
              </div>
            </div>

            {/* HSN select box */}
            <div className="col-md-3 mt-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_hsn ? constLabel?.lbl_hsn : "HSN"}
                  {/* <span className="text-danger"> *</span> */}
                  <Tooltip id={"open-tooltip2"} place="top" style={{ zIndex: 9999 }}/>
                  <img src={info} alt='info' className='cursor_pointer'
                    data-tooltip-id={"open-tooltip2"}
                    data-tooltip-html="Enter four digits to search hsn for selection."
                    height={20} width={20} />
                </label>
                <Controller
                  name="hsn"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.hsn_uuid !== undefined &&
                    props.main_obj.prod_overview.hsn_uuid !== null
                      ? [props.main_obj.prod_overview?.hsn_uuid]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="hsn_sac_code"
                      className="mt-2"
                      multiple={false}
                      options={hsnList.data}
                      isLoading={hsnList.loading}
                      placeholder="Select hsn"
                      onChange={onChange}
                      defaultSelected={
                        props.main_obj.prod_overview.hsn_uuid !== undefined &&
                        props.main_obj.prod_overview.hsn_uuid !== null
                          ? [props.main_obj.prod_overview?.hsn_uuid]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                      // isInvalid={errors.hsn ? true : false}
                      onInputChange={(text) =>
                        setFilterConfig({ ...filterConfig, hsn_sac_code: text })
                      }
                    />
                  )}
                />
                {!hsnList.loading && <span className="arrow-icon"></span>}
              </div>
            </div>
            {/* HSN select box */}

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_oem_part_no
                    ? constLabel?.lbl_oem_part_no
                    : "OEM Part No"}
                    <Tooltip id={"open-tooltip2"} place="top" style={{ zIndex: 9999 }}/>
                    <img src={info} alt='info' className='cursor_pointer'
                      data-tooltip-id={"open-tooltip2"}
                      data-tooltip-html="This field referred to enter part no, product code, OEM part no."
                      height={20} width={20} />
                </label>
                <input
                  className={
                    errors.OEMnumber
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter part no / product code"
                  name="OEMnumber"
                  defaultValue={
                    props.main_obj.prod_overview.OEMnumber !== null &&
                    props.main_obj.prod_overview.OEMnumber !== ""
                      ? props.main_obj.prod_overview.OEMnumber
                      : ""
                  }
                  {...register("OEMnumber")}
                />
                {errors.OEMnumber && (
                  <span className="text-danger err-msg">
                    {errors.OEMnumber.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">
          {constLabel?.lbl_product_information
              ? constLabel?.lbl_product_information
              : "Product Information"}
          </p>
          <div className="row col-12">
            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {constLabel?.lbl_can_be_sold
                  ? constLabel?.lbl_can_be_sold
                  : "Can be Sold"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={
                    props.main_obj.prod_overview.can_be_sold
                      ? props.main_obj.prod_overview.can_be_sold
                      : false
                  }
                  name="can_be_sold"
                  // onChange={(e) => handleCheckBoxChange(e, "salesPrice")}
                  {...register("can_be_sold", {
                    onChange: (e) => handleCheckBoxChange(e, "salesPrice"),
                  })}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.lbl_can_be_sold
                    ? constLabel?.lbl_can_be_sold
                    : "Can be Sold"}
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {constLabel?.lbl_can_be_pruchase
                  ? constLabel?.lbl_can_be_pruchase
                  : "Can be Purchase"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={
                    props.main_obj.prod_overview.can_be_purchased
                      ? props.main_obj.prod_overview.can_be_purchased
                      : false
                  }
                  name="can_be_purchased"
                  // {...register("can_be_purchased")}
                  {...register("can_be_purchased", {
                    onChange: (e) => handleCheckBoxChange(e, "purchase_price")
                  })}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.lbl_can_be_pruchase
                    ? constLabel?.lbl_can_be_pruchase
                    : "Can be Purchase"}
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {constLabel?.lbl_batch_tracking
                  ? constLabel?.lbl_batch_tracking
                  : "Batch Tracking"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={
                    props.main_obj.prod_overview.batch_tracking
                      ? props.main_obj.prod_overview.batch_tracking
                      : false
                  }
                  name="batch_tracking"
                  {...register("batch_tracking")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.lbl_batch_tracking
                    ? constLabel?.lbl_batch_tracking
                    : "Batch Tracking"}
                </label>
              </div>
            </div>
          </div>

          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">
          {constLabel?.lbl_pricing_details
              ? constLabel?.lbl_pricing_details
              : "Pricing Details"}
          </p>
          <div className="row col-12">
            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_cost_of_mfg ? constLabel?.lbl_cost_of_mfg : "Cost of Manufacturing"}
                  (<span> &#8377;</span>){" / "}{selUnit && selUnit[0]?.uom}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={
                    errors.machineCost
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter cost of manufacturing"
                  name="machineCost"
                  defaultValue={
                    props.main_obj.prod_overview.machineCost !== null &&
                    props.main_obj.prod_overview.machineCost !== ""
                      ? props.main_obj.prod_overview.machineCost
                      : "0.00"
                  }
                  {...register("machineCost")}
                />
                {errors.machineCost && (
                  <span className="text-danger err-msg">
                    {errors.machineCost.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                {constLabel?.lbl_selling_price
                    ? constLabel?.lbl_selling_price
                    : "Selling Price "}
                  {watch("can_be_sold") ? <span className="text-danger"> *</span> : null }
                  (<span>&#8377;</span>){" / "}{selUnit && selUnit[0]?.uom}
                </label>
                <input
                  className={
                    errors.salesPrice
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter selling price"
                  name="salesPrice"
                  defaultValue={
                    props.main_obj.prod_overview.salesPrice !== null &&
                    props.main_obj.prod_overview.salesPrice !== ""
                      ? props.main_obj.prod_overview.salesPrice
                      : "0.00"
                  }
                  {...register("salesPrice", {
                    required: watch("can_be_sold") ? "Selling price is required." : false,
                    pattern: {
                      value: /^\d+(\.\d+)?$/,
                      message: "Enter a valid selling price.",
                    },
                  })}
                  onChange={(e) => {
                    handleInputChange(e, "salesPrice", /^\d+(\.\d+)?$/);
                  }}
                />
                {errors.salesPrice && (
                  <span className="text-danger err-msg">
                    {errors.salesPrice.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                {constLabel?.lbl_procurement_price
                    ? constLabel?.lbl_procurement_price
                    : "Procurement Price "}
                  {watch("can_be_purchased") ? <span className="text-danger"> *</span> : null }
                  (<span>&#8377;</span>){" / "}{selUnit && selUnit[0]?.uom}
                </label>
                <input
                  className={
                    errors.purchase_price
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter procurement price"
                  name="purchase_price"
                  defaultValue={
                    props.main_obj.prod_overview.purchase_price !== null &&
                    props.main_obj.prod_overview.purchase_price !== ""
                      ? props.main_obj.prod_overview.purchase_price
                      : "0.00"
                  }
                  {...register("purchase_price", {
                    required: watch("can_be_purchased") ? "Procurement price is required." : false,
                    pattern: {
                      value: /^\d+(\.\d+)?$/,
                      message: "Enter a valid procurement price.",
                    },
                  })}
                  onChange={(e) => {
                    handleInputChange(e, "purchase_price", /^\d+(\.\d+)?$/);
                  }}
                />
                {errors.purchase_price && (
                  <span className="text-danger err-msg">
                    {errors.purchase_price.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_remark ? constLabel?.lbl_remark : "Remark"}
                </label>
                <textarea
                  className="form-control mt-2"
                  id="remark"
                  type="text"
                  defaultValue={
                    props.main_obj.prod_overview.remark !== null &&
                    props.main_obj.prod_overview.remark !== ""
                      ? props.main_obj.prod_overview.remark
                      : ""
                  }
                  placeholder="Enter remark"
                  name="remark"
                  {...register("remark")}
                ></textarea>
              </div>
            </div>
          </div>
          <hr className="my-4" />

          <div className="d-flex justify-content-end">
            <button
              className="btn btn-grey px-4 me-2"
              onClick={() => navigate("/pcms/product")}
            >
              {constLabel?.lbl_cancel ? constLabel?.lbl_cancel : "Cancel"}
            </button>
            <button
              className="btn button-primary px-4 me-2"
              onClick={handleSubmit(onSubmit)}
              disabled={btnDisable}
              // onClick={() => props.setCurrentStep(props.currentStep + 1)}
            >
              {constLabel?.lbl_save_continue
                ? constLabel?.lbl_save_continue
                : "Save & Continue"}
            </button>
            <button
              className="btn btn-grey px-4 me-2"
              disabled={
                Object.keys(props.main_obj.prod_overview).length > 0
                  ? false
                  : true
              }
              onClick={() => props.nextStep()}
            >
              {constLabel?.lbl_skip ? constLabel?.lbl_skip : "Skip"}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Gauges;
