import { PcmsSignIn } from "../../constant/index";
import { loginSuccess, loginFailure } from './authSlice';
import axios from "axios";

// = () => (dispatch) => {
export const loginUser = (credentials) => (dispatch) => {
    let apiUrl
    let congnito=localStorage.getItem("congnito");
    // console.log("congnito",typeof congnito)
    // let normal=localStorage.getItem("normal");
     apiUrl = `${process.env.REACT_APP_COGNITO_AUTH_URL}/login/user`;
    
     if(congnito === "true"){
        apiUrl = `${process.env.REACT_APP_COGNITO_AUTH_URL}/login/user`;
     }
     else{
        apiUrl=`${process.env.REACT_APP_API_URL + PcmsSignIn}`;
     }
    // console.log("apiurl",apiUrl)
    // console.log("credentials.username", credentials.username);
    localStorage.setItem("user_name", credentials.username);
    axios
        .post(apiUrl, credentials)
        .then((response) => {
            // console.log("res_sss", response);
            dispatch(loginSuccess(response.data));
        })
        .catch((error) => {
            console.log(error);
            dispatch(loginFailure(error.response));
        });

};