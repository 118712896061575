import React, { useState, useEffect } from "react";
import { Modal, Pagination } from "react-bootstrap";
import { getPO } from "../../../api";
import { Eye } from "react-feather";
import { Tooltip } from "react-tooltip";
import { convertDate, convertDateYYMMDD } from "../../../common/Functions/CommonFunctions";

function SupplierPOList(props) {
  const [dataList, setDataList] = useState({
    data: [],
    loading: false,
    totalRecords: "",
  });
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [show2, setShow2] = useState(false);
  const [selData, setSelData] = useState({});
  const [total, setTotal] = useState("");

  useEffect(() => {
    if (
      props.selectedSupp.uuid !== undefined &&
      props.selectedSupp.uuid !== null
    ) {
      fetchPOList(1, entriesPerPage);
    }
  }, [props.selectedSupp.uuid]);

  function fetchPOList(pg, epp) {
    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
      startDate: convertDateYYMMDD(props.startDate, "-"),
      endDate: convertDateYYMMDD(props.endDate, "-"),
      suppliername: `["${props.selectedSupp.name}"]`,
    };
    setDataList({ ...dataList, data: [], loading: true });

    if (payload.partyId == [] && payload.partyId == null) {
      return;
    }

    getPO(payload).then(
      (res) => {
        setDataList({ ...dataList, data: res.data.data, loading: false });

        const tot = res.data.data[0]?.PO_line_item.reduce(
          (accumulator, currentValue) => {
            // Extract the 'number' property from each object and add it to the accumulator
            return parseFloat(accumulator) + parseFloat(currentValue.amount);
          },
          0
        );

        setTotal(tot);
      },
      (err) => {
        setDataList({ ...dataList, data: [], loading: false });
      }
    );
  }

  return (
    <React.Fragment>
      {dataList.loading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : dataList.data.length > 0 ? (
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="table-grey roboto-bold">
              <tr className="">
                <th scope="col">{"Sr.No"}</th>
                <th scope="col">
                  {props.constLabel?.lbl_date
                    ? props.constLabel?.lbl_date
                    : "Date"}
                </th>
                <th scope="col">
                  {props.constLabel?.lbl_invoice_to
                    ? props.constLabel?.lbl_invoice_to
                    : "Invoice To"}
                </th>
                <th scope="col">
                  {props.constLabel?.lbl_dispatch_to
                    ? props.constLabel?.lbl_dispatch_to
                    : "Dispatch To"}
                </th>
                <th scope="col">
                  {props.constLabel?.lbl_quote_ref
                    ? props.constLabel?.lbl_quote_ref
                    : "Quote Ref"}
                </th>
                <th scope="col">
                  {props.constLabel?.lbl_credit_period
                    ? props.constLabel?.lbl_credit_period
                    : "Credit Period"}
                </th>
                <th scope="col">
                  {props.constLabel?.lbl_status
                    ? props.constLabel?.lbl_status
                    : "Status"}
                </th>
                <th scope="col" width="15%">
                  {"Action"}
                </th>
              </tr>
            </thead>
            <tbody className=" roboto">
              {dataList.data.map((val, ind) => (
                <tr key={ind}>
                  <td>{parseInt(ind) + 1 + parseInt(srNo)}</td>
                  <td>{val.po_date}</td>
                  <td>{val.invoice_toname}</td>
                  <td>{val.despatch_toname}</td>
                  <td>{val.suppliername}</td>
                  <td>{val.credit_period}</td>
                  <td>{val.po_status}</td>
                  <td>
                    <Tooltip id={"view-tooltip" + ind} place="top" />
                    <Eye
                      data-tooltip-id={"view-tooltip" + ind}
                      data-tooltip-content={
                        props.constLabel?.lbl_view_po
                          ? props.constLabel?.lbl_view_po
                          : "View PO"
                      }
                      className="menu_icon_grey cursor_pointer"
                      size={18}
                      onClick={() => {
                        setShow2(true);
                        setSelData(val);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="alert alert-danger mb-0 text-center my-4" role="alert">
          {"No Purchase Orders Found !"}
        </div>
      )}

      {/* {dataList.loading ? null : (
            <div className="mt-4 d-flex justify-content-between">
              <p className="mb-0">{`Showing ${parseInt(srNo) + 1} to ${10 + parseInt(srNo)
                } of ${dataList.totalRecords} entries`}</p>
              <Pagination className="my-2">
                <Pagination.Prev
                  disabled={page === 1 ? true : false}
                  onClick={() => {
                    fetchPOList(page - 1);
                    setSrNo((prevC) => (page - 1 == 1 ? 0 : prevC - 10));
                    setPage(page - 1);
                  }}
                >
                  {"Prev"}
                </Pagination.Prev>

                <Pagination.Item active>{page}</Pagination.Item>

                <Pagination.Next
                  disabled={page === maxPage || maxPage === 0 ? true : false}
                  onClick={() => {
                    // console.log("parseInt(srNo) + parseInt(entriesPerPage)", parseInt(srNo) + parseInt(entriesPerPage));
                    fetchPOList(page + 1);
                    setSrNo((prevC) => prevC + 10);
                    setPage(page + 1);
                  }}
                >
                  {"Next"}
                </Pagination.Next>
              </Pagination>
            </div>
          )} */}

      <Modal
        show={show2}
        onHide={() => setShow2(false)}
        size="xl"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_purchase_order_detail
              ? props.constLabel?.lbl_purchase_order_detail
              : "Purchase Order Detail"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="bg-lightgrey" style={{ padding: "12px", borderRadius: "12px" }}>
                <div className="row my-2">
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {props.constLabel?.lbl_po_id ? props.constLabel.lbl_id : "PO No"}
                    </p>
                    <p className="mb-2 roboto-bold">{selData?.poid}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {props.constLabel?.lbl_date ? props.constLabel.lbl_date : "Date"}
                    </p>
                    <p className="mb-2 roboto-bold">{convertDate(selData?.po_date, '-')}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {props.constLabel?.lbl_invoice_to ? props.constLabel.lbl_invoice_to : "Invoice To"}
                    </p>
                    <p className="mb-2 roboto-bold">{selData?.invoice_to_id?.comName}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {props.constLabel?.lbl_dispatch_to ? props.constLabel.lbl_dispatch_to : "Dispatch To"}
                    </p>
                    <p className="mb-2 roboto-bold">{selData?.despatch_to_id?.comName}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {props.constLabel?.lbl_supplier_name ? props.constLabel.lbl_supplier_name : "Supplier Name"}
                    </p>
                    <p className="mb-2 roboto-bold">{selData?.supplier_id?.name}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {props.constLabel?.lbl_quote_ref ? props.constLabel.lbl_quote_ref : "Quote Ref"}
                    </p>
                    <p className="mb-2 roboto-bold">{selData?.quote_ref == null
                        ? "NA"
                        : selData?.quote_ref}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {props.constLabel?.lbl_credit_period ? props.constLabel.lbl_credit_period : "Credit Period"}
                    </p>
                    <p className="mb-2 roboto-bold">{selData?.credit_period}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {props.constLabel?.lbl_status ? props.constLabel.lbl_status : "Status"}
                    </p>
                    <p className="mb-2 roboto-bold">{selData?.po_status}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {props.constLabel?.lbl_remark ? props.constLabel.lbl_remark : "Remark"}
                    </p>
                    <p className="mb-2 roboto-bold">{selData?.remark}</p>
                  </div>
                </div>
            </div>

            <hr className="my-2" />
              <h4 className="roboto-bold mb-0">
                {props.constLabel?.lbl_product_detail
                  ? props.constLabel.lbl_product_detail
                  : "Product Detail"}
              </h4>
            <hr className="my-2" />

            <div className="my-3">
              <div
                className="table-responsive"
                style={{ maxHeight: "300px", overflowY: "auto" }}
              >
                <table className="table table-bordered">
                  <thead className="table-grey">
                    <tr>
                      <th scope="col">
                        {props.constLabel?.lbl_product_type
                          ? props.constLabel.lbl_product_type
                          : "Product Type"}
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_product_name
                          ? props.constLabel.lbl_product_name
                          : "Product Name"}
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_quantity
                          ? props.constLabel.lbl_quantity
                          : "Quantity"}
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_unit
                          ? props.constLabel.lbl_unit
                          : "Unit"}
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_rate
                          ? props.constLabel.lbl_rate
                          : "Rate"}{" "}
                        <span>&#8377;</span>
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_gst
                          ? props.constLabel.lbl_gst
                          : "GST "}(%)
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_discount
                          ? props.constLabel.lbl_discount
                          : "Discount "}(%)
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_amount
                          ? props.constLabel.lbl_amount
                          : "Amount"}{" "}
                        <span>&#8377;</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selData?.PO_line_item?.map((val, ind) => (
                      <tr key={ind}>
                        <td>{val.producttype}</td>
                        <td style={{ verticalAlign: "top" }}>{val.productname}</td>
                        <td style={{ verticalAlign: "top" }}>{val.quantity}</td>
                        <td style={{ verticalAlign: "top" }}>{val.unit}</td>
                        <td style={{ verticalAlign: "top" }}>{val.rate}</td>
                        <td style={{ verticalAlign: "top" }}>{val.gst_rate}</td>
                        <td style={{ verticalAlign: "top" }}>{val.discount}</td>
                        <td style={{ verticalAlign: "top" }}>{val.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div
                className="p-3 ms-auto col-12 col-md-4"
                style={{
                  border: "1px solid #c6c6c6",
                  borderRadius: "2px",
                  fontSize: "14px",
                }}
              >
                <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label className="col-12 col-md-6 roboto-bold">
                      {"Sub Total"} <span>&#8377;</span>
                    </label>

                    <div className="col-12 col-md-6">
                      {parseFloat(total).toFixed(2)}
                    </div>
                  </div>
                </div>

                <hr />
                {selData && selData?.cgst_cost !== 0 ? (
                  <>
                    <div className="row mb-2">
                      <div className="col-12 row ms-auto">
                        <label className="col-12 col-md-6 roboto-bold">
                          {"CGST "} &#8377;
                        </label>

                        {/* <div className="col-12 col-md-4">
                          {poList.data[indexx]?.cgst}
                        </div> */}
                        <div className="col-12 col-md-6 d-flex align-items-center">
                         
                          {parseFloat(selData?.cgst_cost).toFixed(
                            2
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-12 row ms-auto">
                        <label className="col-12 col-md-6 roboto-bold">
                          {"SGST "} &#8377;
                        </label>

                        {/* <div className="col-12 col-md-4">
                          {selData?.sgst}
                        </div> */}
                        <div className="col-12 col-md-6 d-flex align-items-center">
                          
                          {parseFloat(selData?.sgst_cost).toFixed(
                            2
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                {selData && selData?.igst_cost !== 0 ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label className="col-12 col-md-6 roboto-bold">
                        {"IGST "} &#8377;
                      </label>

                      {/* <div className="col-12 col-md-4">
                        {selData?.igst}
                      </div> */}
                      <div className="col-12 col-md-6 d-flex align-items-center">
                        
                        {parseFloat(selData?.igst_cost).toFixed(2)}
                      </div>
                    </div>
                  </div>
                ) : null}

                {selData && selData?.ugst_cost !== 0 ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label className="col-12 col-md-6 roboto-bold">
                        {"UGST "} &#8377;
                      </label>

                      {/* <div className="col-12 col-md-4">
                        {selData?.ugst}
                      </div> */}
                      <div className="col-12 col-md-6">
                        
                        {parseFloat(selData?.ugst_cost).toFixed(2)}
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label className="col-12 col-md-6 roboto-bold">
                      {"Total Amount "} &#8377;
                    </label>

                    <div className="col-12 col-md-6">
                      {parseFloat(selData?.net_amount).toFixed(2)}
                    </div>
                  </div>
                </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-grey px-4" onClick={() => setShow2(false)}>
            {props.constLabel?.lbl_close ? props.constLabel.lbl_close : "Close"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default SupplierPOList;
