// features/productSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const present_date = new Date();
const nextMonthDate = new Date(present_date);
nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);

const productSlice = createSlice({
    name: "products",
    initialState: {
        base_data: [],
        start_date: present_date,
        end_date: nextMonthDate,
        big_calender_date: present_date,
        view: "month",
        selectedProduct: null,
        status: "idle",
        error: null,
    },
    reducers: {
        reset_productSlice(state, action) {
            state.base_data = [];
            state.start_date = present_date;
            state.end_date = nextMonthDate;
            state.big_calender_date = present_date;
            state.view = "month";
            state.selectedProduct = null;
            state.status = "idle";
            state.error = null;
        },
        selectProduct(state, action) {
            state.selectedProduct = action.payload;
        },
        handle_date: (state, action) => {
            console.log("action", action);
            console.log("present_date", present_date);
            state.start_date = new Date(action.payload.start_date);
            state.end_date = new Date(action.payload.end_date);
            state.view = action.payload.view;

            let big_calender_date_copy = new Date(action.payload.big_calender_date);

            if (big_calender_date_copy.getMonth() < new Date(action.payload.start_date).getMonth()) {
                const start_date_month = new Date(action.payload.start_date).getMonth();
                big_calender_date_copy.setMonth(start_date_month);
            }

            state.big_calender_date = new Date(big_calender_date_copy)
        },
        saveStepTwo: (state, action) => {
            console.log("action.payload", action.payload);

            state.base_data = [...action.payload.base_data];
        },
        save_routes_operations(state, action) {
            for (let i = 0; i < state.base_data.length; i++) {
                if (state.base_data[i].uuid == action.payload.data.uuid) {
                    state.base_data[i].selected_route = JSON.parse(JSON.stringify(action.payload.route));
                    state.base_data[i].selected_route.route_operation_list = action.payload.operations
                }
            }
        }
    }
});

export const { selectProduct, handle_date, saveStepTwo, save_routes_operations, reset_productSlice } = productSlice.actions;
export default productSlice.reducer;
