// features/routeSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import client2 from "../../constant/NodeAPI_AxiosInstance";
import { wo_product_routes, global_routing } from "../../constant/index"

// Fetch routes for a selected product
export const fetchRoutes = createAsyncThunk("routes/fetchRoutes", async (payload) => {
    try {
        const response = await client2.get(wo_product_routes, { params: payload });
        console.log("response", response);

        if (response.data && response.data?.data.length === 0) {
            // If first API call returns an empty array, call the second API
            const response2 = await client2.get(global_routing);
            console.log("response2", response2);

            return {
                route_list: [],
                global_route_list: response2.data.data,
            };
        }
        return {
            route_list: response.data.data,
            global_route_list: [],
        };
    } catch (error) {
        console.log("error", error);
        return {};
    }
});

const routeSlice = createSlice({
    name: "routes",
    initialState: {
        list: {},
        status: "idle",
        error: null,
    },
    reducers: {
        reset_routes(state, action) {
            state.list = {};
            state.status = "idle";
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoutes.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchRoutes.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.list = action.payload;
            })
            .addCase(fetchRoutes.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});
export const { reset_routes } = routeSlice.actions;
export default routeSlice.reducer;
