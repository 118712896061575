import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import {
  convertDateYYMMDD,
  getLabels,
} from "../../../../common/Functions/CommonFunctions";
import { toast } from "react-toastify";

const schema = yup
  .object()
  .shape({
    inward_date: yup
      .date()
      .typeError("Please select inward date !")
      .required("Please select inward date !"),
    heat_no: yup
      .string()
      .required("Please enter heat no!"),
    weight_rcpl: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid rcpl weight",
      excludeEmptyString: true,
    }),
    weight_transport: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid transport weight",
      excludeEmptyString: true,
    }),
    forwarding: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid forwarding",
      excludeEmptyString: true,
    }),
    total: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid total",
      excludeEmptyString: true,
    }),
    gst: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid gst",
      excludeEmptyString: true,
    }),
    adj: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid adj",
      excludeEmptyString: true,
    }),
    net_amt: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid net amount",
      excludeEmptyString: true,
    }),
    grand_amt: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid grand amount",
      excludeEmptyString: true,
    }),
    freight: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid freight",
      excludeEmptyString: true,
    }),
    invoiceWt: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid invoice weight",
      excludeEmptyString: true,
    }),
    qty: yup
      .string()
      .matches(/^\d+(\.\d+)?$/, "Enter valid quantity")
      .required("Please enter accepted quantity!"),
    quantity: yup
      .string()
      .matches(/^\d+(\.\d+)?$/, "Enter valid quantity")
      .required("Please enter quantity!"),
  })
  .required();

function Procured(props) {
  const {
    rm_type,
    setClear,
    currIndex,
    rmi_data,
    setRMI_Data,
    setRMIModal,
  } = props;

  let existingIndex = rmi_data?.findIndex((item) => item.ind == currIndex);

  // const [mat_code, setMat_Code] = useState();
  const [nc_flag, setNC_Flag] = useState(
    rmi_data?.[existingIndex]?.qty < rmi_data?.[existingIndex]?.quantity
      ? true
      : false
  );

  const [constLabel, setConstLabel] = useState({});

  const loc_storage = JSON.parse(localStorage.getItem("form-data"));

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      items: [],
    },
  });

  let { errors } = formState;

  const {
    fields: fields,
    append: append,
    remove: remove,
  } = useFieldArray({
    control,
    name: "items",
  });

  useEffect(() => {
    setConstLabel(getLabels("Purchase", "RMI"));
    setValue(
      "quantity",
      loc_storage?.product?.[0]?.material_type_id?.material_type == "BAR"
        ? 0
        : loc_storage.received_qty
    );
    setValue("po_number", 0);
    setValue("invoice_value", loc_storage.amount);
    setValue(
      "uom",
      loc_storage?.product?.[0]?.material_type_id?.material_type == "BAR"
        ? "NOS"
        : loc_storage?.product?.[0]?.unit_id?.uom
    );
    setValue("invoice_number", loc_storage && loc_storage !== null ? loc_storage?.invoiceNo : "");
    if (loc_storage?.product?.[0]?.material_type_id?.material_type !== "BAR") {
      setValue(
        "qty",
        loc_storage && loc_storage?.received_qty !== null
          ? loc_storage?.received_qty
          : ""
      );
    } else {
      setValue("qty", 0);
    }

    // handleAppend({ target: { value: loc_storage.received_qty } });
  }, []);

  useEffect(() => {
      if (loc_storage?.material_code !== undefined && loc_storage?.material_code !== null) {
        if (loc_storage?.product?.[0]?.material_type_id?.material_type !== "BAR") {
          handleAppend({ target: { value: loc_storage.received_qty } });
        }
      }
    }, [loc_storage?.material_code]);

  useEffect(() => {
    if (rmi_data.length > 0 && existingIndex !== -1) {
      let abc = [];
      // let pqr = [];

      setValue("adj", rmi_data?.[existingIndex]?.adj);
      setValue("forwarding", rmi_data?.[existingIndex]?.forwarding);
      setValue("freight", rmi_data?.[existingIndex]?.freight);
      setValue("grand_amt", rmi_data?.[existingIndex]?.grand_amt);
      setValue("gst", rmi_data?.[existingIndex]?.gst);
      setValue("heat_no", rmi_data?.[existingIndex]?.heat_no);
      setValue("inspectionstatus", rmi_data?.[existingIndex]?.inspectionstatus);
      setValue("invoiceWt", rmi_data?.[existingIndex]?.invoiceWt);
      setValue(
        "inward_date",
        rmi_data?.[existingIndex]?.inward_date !== null
          ? new Date(rmi_data?.[existingIndex]?.inward_date)
          : null
      );
      setValue(
        "lengthDetailsflag",
        loc_storage?.product?.[0]?.material_type_id?.material_type === "BAR"
          ? true
          : false
      );
      setValue("net_amt", rmi_data?.[existingIndex]?.net_amt);
      setValue("qty", rmi_data?.[existingIndex]?.qty || loc_storage?.received_qty);
      setValue("srmt", [rmi_data?.[existingIndex]?.srmt]);
      setValue("total", rmi_data?.[existingIndex]?.total);
      setValue("weight_rcpl", rmi_data?.[existingIndex]?.weight_rcpl);
      setValue("weight_transport", rmi_data?.[existingIndex]?.weight_transport);
      setValue("uom", rmi_data?.[existingIndex]?.unit);

      setValue(
        "quantity",
        rmi_data?.[existingIndex]?.quantity || loc_storage?.received_qty
      );

      if (rmi_data?.[existingIndex]?.barLengthDetails?.length > 0) {
        for (
          let x = 0;
          x < rmi_data?.[existingIndex]?.barLengthDetails?.length;
          x++
        ) {
          abc.push({
            bar_no: rmi_data?.[existingIndex]?.barLengthDetails[x]?.bar_no,
            cutlength:
              rmi_data?.[existingIndex]?.barLengthDetails[x]?.cutlength,
          });
        }
      } else {
        abc = [];
      }
      setValue("items", abc);
    }
  }, [rmi_data, currIndex]);


  const onSubmit = (data) => {
    if (
      loc_storage?.product?.[0]?.material_type_id?.material_type == undefined &&
      loc_storage?.product?.[0]?.material_type_id?.material_type == null
    ) {
      toast.error("Please set material type for product!");
      return;
    }

    let reqLoad = [];
    // let reqLoad1 = [];
    if (data !== undefined && data !== "") {
      if (data.items?.length > 0) {
        for (let i = 0; i < data.items?.length; i++) {
          const len_detail = {
            bar_no: data.items[i].bar_no,
            cutlength: data.items[i].cutlength,
          };
          reqLoad.push(len_detail);
        }
      }

      const payload = {
        ind: currIndex,
        adj: data.adj,
        challanno: data.invoice_number,
        forwarding: data.forwarding,
        freight: data.freight,
        grand_amt: data.grand_amt,
        gst: data.gst,
        heat_no: data.heat_no,
        inspectionstatus: data.inspectionstatus,
        invoiceWt: data.invoiceWt,
        inward_date: convertDateYYMMDD(data.inward_date, "-"),
        lengthDetailsflag:
          loc_storage?.product?.[0]?.material_type_id?.material_type === "BAR"
            ? true
            : false,
        net_amt: data.net_amt,
        price: data.invoice_value.toString(),
        // quantity: data.quantity,
        qty: data.qty,
        srmt: rm_type?.[0]?.typename,
        total: data.total,
        weight_rcpl: data.weight_rcpl,
        weight_transport: data.weight_transport,
        unit: data.uom,
        material_code: loc_storage?.material_code,
        // NCDetails: reqLoad1,
        barLengthDetails: reqLoad,
      };

      if (loc_storage?.product?.[0]?.material_type_id?.material_type === "BAR") {
        payload.quantity = data.quantity;
      }

      let tempRMI_Data = [...rmi_data];

      const existingInd = tempRMI_Data.findIndex(
        (item) => item.ind === currIndex
      );
      if (existingInd !== -1) {
        // If the index is found, update the object at that index
        tempRMI_Data[existingInd] = payload;
      } else {
        // If the index is not found, push the new payload to the array
        tempRMI_Data.push(payload);
      }

      console.log('payload', payload);

      setRMI_Data(tempRMI_Data);
      localStorage.removeItem("form-data");
      setRMIModal(false);
      setClear("rmType", []);
    }
  };

  function handleAppend(e) {
    const ord_qty = getValues("quantity");
    let i;
    if (
      parseInt(e.target.value) !== undefined &&
      parseInt(e.target.value) !== ""
    ) {

        if (loc_storage?.product?.[0]?.material_type_id?.material_type !== "BAR") {
          if (parseInt(loc_storage.received_qty) > parseInt(e.target.value)) {
            toast.error("Received qty and accepted qty should be same", {
              autoClose: 2000,
            });
            return;
          }
        }
     

      if (
        parseInt(ord_qty) >= parseInt(e.target.value) && loc_storage?.product?.[0]?.material_type_id?.material_type == "BAR"
      ) {
        for (i = 0; i < parseInt(e.target.value); i++) {
          let arr = rmi_data?.[currIndex]?.barLengthDetails;
          let val = arr?.[i];
          if (val) {
            append({ bar_no: val.bar_no, cutlength: val.cutlength });
          } else {
            append({ bar_no: loc_storage?.material_code + "__" + (i + 1), cutlength: "0" });
          }
        }
      }

      if (ord_qty !== undefined && ord_qty !== "") {
        if (parseInt(ord_qty) == parseInt(e.target.value)) {
          setValue("inspectionstatus", "Ok");
          setNC_Flag(false);
        } else if (
          parseInt(ord_qty) > parseInt(e.target.value) &&
          parseInt(e.target.value) >= 1
        ) {
          setValue("inspectionstatus", "Partial Ok");
          setNC_Flag(true);
        } else if (
          parseInt(e.target.value) == 0 ||
          parseInt(e.target.value) == ""
        ) {
          setValue("inspectionstatus", "Reject");
        } else if (parseInt(e.target.value) > parseInt(ord_qty)) {
          // setNC_Flag(false);
          toast.error("Accepted quantity should be less than quantity", {
            autoClose: 2000,
          });
          setValue("inspectionstatus", "");
          return;
        } else {
          setValue("inspectionstatus", "");
        }
      }
    } else {
      setValue("items", []);
      // setValue("items1", []);
      // setNC_Flag(false);
    }

    if (parseInt(e.target.value) !== "") {
      if (fields.length >= 0) {
        remove(fields);
      }
    }
  }

  return (
    <div className="card border-0">
      <div className="card-body">
        <form action="">
          <div className="row">
            <div className="col-12 col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_po_no ? constLabel?.lbl_po_no : "PO Number"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  disabled
                  placeholder="Enter PO number"
                  name="po_number"
                  {...register("po_number")}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_inward_date
                    ? constLabel?.lbl_inward_date
                    : "Inward Date"}
                  <span className="text-danger"> *</span>
                </label>
                <Controller
                  name="inward_date"
                  control={control}
                  defaultValue={new Date()}
                  render={({ field }) => (
                    <DatePicker
                      className={
                        errors.inward_date
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      {...field}
                      showYearDropdown
                      scrollableMonthYearDropdown
                      dateFormat="dd/MM/yyyy"
                      defaultValue={new Date()}
                      selected={field.value || null}
                      maxDate={new Date()}
                      placeholderText="Select inward date"
                      autoComplete="off"
                      isInvalid={errors.inward_date ? true : false}
                    />
                  )}
                />
                {errors.inward_date && (
                  <span className="text-danger err-msg">
                    {errors.inward_date.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_supplier_name
                    ? constLabel?.lbl_supplier_name
                    : "Supplier Name"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  defaultValue={loc_storage?.supplier?.[0]?.name}
                  placeholder="Enter supplier name"
                  name="supplier_name"
                  {...register("supplier_name")}
                  disabled
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_supplier_invoice_no
                    ? constLabel?.lbl_supplier_invoice_no
                    : "Supplier Invoice No"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  placeholder="Enter supplier invoice number"
                  name="invoice_number"
                  {...register("invoice_number")}
                  disabled
                />
              </div>
            </div>
            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_heat_no
                    ? constLabel?.lbl_heat_no
                    : "Heat No"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={
                    errors.heat_no
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter heat number "
                  name="heat_no"
                  {...register("heat_no")}
                />
                {errors.heat_no && (
                  <span className="text-danger err-msg">
                    {errors.heat_no.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_material_code
                    ? constLabel?.lbl_material_code
                    : "Material code"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  disabled
                  placeholder="Enter material code"
                  value={loc_storage?.material_code}
                  name="material_code"
                  {...register("material_code")}
                />
              </div>
            </div>
            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_supplier_invoice_value
                    ? constLabel?.lbl_supplier_invoice_value
                    : "Supplier Invoice Value"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  placeholder="Enter supplier invoice value"
                  name="invoice_value"
                  {...register("invoice_value")}
                  disabled
                />
              </div>
            </div>

            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_supplier_invoice_date
                    ? constLabel?.lbl_supplier_invoice_date
                    : "Supplier Invoice Date"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <Controller
                  name="invoice_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      className={"form-control mt-2"}
                      {...field}
                      showYearDropdown
                      scrollableMonthYearDropdown
                      dateFormat="dd/MM/yyyy"
                      selected={
                        loc_storage && loc_storage?.invoiceDate !== null
                          ? new Date(loc_storage?.invoiceDate)
                          : null
                      }
                      placeholderText="Select invoice date"
                      autoComplete="off"
                      disabled
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.weight_rcpl
                    ? constLabel?.weight_rcpl
                    : "Weight at RCPL"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={
                    errors.weight_transport
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter weight at RCPL"
                  name="weight_rcpl"
                  {...register("weight_rcpl")}
                />
                {errors.weight_rcpl && (
                  <span className="text-danger err-msg">
                    {errors.weight_rcpl.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_weight_transport
                    ? constLabel?.lbl_weight_transport
                    : "Weight at Transport"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={
                    errors.weight_transport
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter weight at transport"
                  name="weight_transport"
                  {...register("weight_transport")}
                />
                {errors.weight_transport && (
                  <span className="text-danger err-msg">
                    {errors.weight_transport.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_forwarding
                    ? constLabel?.lbl_forwarding
                    : "Forwarding"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={
                    errors.forwarding
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter forwarding"
                  name="forwarding"
                  {...register("forwarding")}
                />
                {errors.forwarding && (
                  <span className="text-danger err-msg">
                    {errors.forwarding.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_total_amt_forwarding
                    ? constLabel?.lbl_total_amt_forwarding
                    : "Total (Amount + Forwading) "}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={
                    errors.total
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter Total (Amount + Forwading)"
                  name="total"
                  {...register("total")}
                />
                {errors.total && (
                  <span className="text-danger err-msg">
                    {errors.total.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_gst ? constLabel?.lbl_gst : "GST"} 18%
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={
                    errors.gst
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Select GST 18%"
                  name="gst"
                  {...register("gst")}
                />
                {errors.gst && (
                  <span className="text-danger err-msg">
                    {errors.gst.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_adj ? constLabel?.lbl_adj : "Adj"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={
                    errors.adj
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Select Adj"
                  name="adj"
                  {...register("adj")}
                />
                {errors.adj && (
                  <span className="text-danger err-msg">
                    {errors.adj.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_net_amt
                    ? constLabel?.lbl_net_amt
                    : "Net Amt"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={
                    errors.net_amt
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter net amount"
                  name="net_amt"
                  {...register("net_amt")}
                />
                {errors.net_amt && (
                  <span className="text-danger err-msg">
                    {errors.net_amt.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_freight
                    ? constLabel?.lbl_freight
                    : "Freight"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  placeholder="Enter freight"
                  name="freight"
                  {...register("freight")}
                />
                {errors.freight && (
                  <span className="text-danger err-msg">
                    {errors.freight.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_grand_amt
                    ? constLabel?.lbl_grand_amt
                    : "Grand Amt"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={
                    errors.grand_amt
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter grand amount"
                  name="grand_amt"
                  {...register("grand_amt")}
                />
                {errors.grand_amt && (
                  <span className="text-danger err-msg">
                    {errors.grand_amt.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">{"NC Details"}</label>
                <div className="form-check mt-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    style={{ width: "20px", height: "20px" }}
                    onChange={() => setNC_Flag(!nc_flag)}
                    checked={nc_flag}
                  />{" "}
                  <label>{"Has NC ?"}</label>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-4" />

          <h5 className="roboto-bold ">
            {constLabel?.lbl_product_detail
              ? constLabel?.lbl_product_detail
              : "Product Detail"}
          </h5>
          <div className="row mt-3">
            <div className="col-12 col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_product_name
                    ? constLabel?.lbl_product_name
                    : "Product Name"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  defaultValue={loc_storage?.product?.[0]?.productName}
                  placeholder="Enter product name"
                  name="product_name"
                  {...register("product_name")}
                  disabled
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_uom ? constLabel?.lbl_uom : "UOM"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  placeholder="Enter UOM"
                  name="uom"
                  {...register("uom")}
                  disabled
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_quantity
                    ? constLabel?.lbl_quantity
                    : "Quantity"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  placeholder="Enter quantity"
                  name="quantity"
                  {...register("quantity")}
                  disabled={loc_storage?.product?.[0]?.material_type_id?.material_type == "BAR" ? false : true }
                />
                {errors.quantity && (
                  <span className="text-danger err-msg">
                    {errors.quantity.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_accepted_qty
                    ? constLabel?.lbl_accepted_qty
                    : "Accepted Qty"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  placeholder="Enter accepted quantity"
                  name="qty"
                  {...register("qty", {
                    onChange: (e) => {
                      handleAppend(e);
                    },
                  })}
                />
                {errors.qty && (
                  <span className="text-danger err-msg">
                    {errors.qty.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_inspection_status
                    ? constLabel?.lbl_inspection_status
                    : "Inspection Status"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  disabled
                  placeholder="Enter inspection status"
                  name="inspectionstatus"
                  {...register("inspectionstatus")}
                />
              </div>
            </div>

            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_invoice_total_weight
                    ? constLabel?.lbl_invoice_total_weight
                    : "Invoice Total weight"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={
                    errors.invoiceWt
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter invoice total weight"
                  name="invoiceWt"
                  {...register("invoiceWt")}
                />
                {errors.invoiceWt && (
                  <span className="text-danger err-msg">
                    {errors.invoiceWt.message}
                  </span>
                )}
              </div>
            </div>
          </div>
            
            {loc_storage?.product?.[0]?.material_type_id?.material_type == "BAR" ? (
              <>
                <hr className="my-4" />
                <h5 className="roboto-bold ">
                  {constLabel?.lbl_length_detail
                    ? constLabel?.lbl_length_detail
                    : "Length Detail"}
                </h5>

                <div
                  className="table-responsive py-3"
                  style={{ overflowX: "scroll", maxHeight: "40vh" }}
                >
                  <table className="table table-bordered table-sm">
                    <thead className="thead-light text-center">
                      <tr>
                        <th scope="col">
                          {constLabel?.lbl_bar_no
                            ? constLabel?.lbl_bar_no
                            : "Bar No"}
                        </th>
                        <th scope="col">
                          {constLabel?.lbl_bar_length
                            ? constLabel?.lbl_bar_length
                            : "Bar Length"}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map(({ id }, index) => (
                        <tr key={id} className="f-18">
                          <td width={"30%"}>
                            <input
                              className={
                                errors.items?.[index]?.bar_no
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              disabled
                              name={`items[${index}].bar_no`}
                              // value={mat_code + "__" +(index + 1)}
                              {...register(`items[${index}].bar_no`)}
                              placeholder="Enter bar number"
                            />

                            {errors.items?.[index]?.bar_no && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.bar_no.message}
                              </span>
                            )}
                          </td>
                          <td style={{ verticalAlign: "top" }}>
                            <input
                              className={
                                errors.items?.[index]?.cutlength
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              defaultValue={0}
                              name={`items[${index}].cutlength`}
                              {...register(`items[${index}].cutlength`)}
                              placeholder="Enter cut length"
                            />
                            {errors.items?.[index]?.cutlength && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.cutlength.message}
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : null }
          <hr className="my-4" />

          <div className="d-flex justify-content-end">
            <button className="btn btn-yellow" onClick={handleSubmit(onSubmit)}>
              {constLabel?.lbl_save ? constLabel?.lbl_save : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Procured;
