import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert2";
import DatePicker from "react-datepicker";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  createMaterialRequest,
  deleteMaterialRequest,
  getMaterialRequest,
  getProdList,
  getProductType,
  getSupplier,
  updateMaterialRequest,
} from "../../../api/index";
import {
  calMaxPage_new,
  convertDate,
  convertDateYYMMDD,
  formatDateString,
  getLabels,
  isExcelFile,
  setAccessPrivilage,
} from "../../../common/Functions/CommonFunctions";
import { Modal, Pagination, Spinner } from "react-bootstrap";
import { Trash2, FilePlus, Image } from "react-feather";
import { useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { Material_Request_Col_name } from "../../../common/Labels/labelConstant";
import List from "../../../assets/images/icons/project_menu/checklist.png";
import Slide from "../../../assets/images/icons/project_menu/collapse.png";
import AccessDenied from "../../Common/AccessDenied";
import * as name_const from "../../../common/Constants/constants";
import { faAdd, faPlus } from "@fortawesome/free-solid-svg-icons";
import EditMaterialRequest from "./EditMaterialRequest";
import dummyImg from "../../../assets/images/dummyImg.png";
import MR_ImageGallary from "./MR_ImageGallary";

const schema = yup
  .object()
  .shape({
    req_date: yup
      .date()
      .required("Please select material request date!")
      .typeError("Please select material request date!"),
    required_date: yup
      .date()
      .nullable() // Allows the field to be optional
      .typeError("Please select a valid required date!")
      .when("req_date", (req_date, schema) =>
        req_date
          ? schema.min(
            req_date,
            "Required date must be later than request date!"
          )
          : schema
      ),
    req_status: yup
      .array()
      .min(1, "Please select status!")
      .required("Please select status!"),
    remarks: yup.string().max(1000).notRequired("Please enter remarks !"),
    items: yup.array().of(
      yup.object().shape({
        product: yup
          .array()
          .min(1, "Please select product!")
          .required("Please select product!"),
        quantity: yup
          .string()
          .required("Quantity is required !")
          .matches(/^\d+(\.\d+)?$/, "Enter valid quantity!"),
      })
    ),
  })
  .required();

function MaterialRequest() {
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });

  const [constLabel, setConstLabel] = useState({});
  const [startDate, setStartDate] = useState(getPrvMonthDateFromToday);
  const [endDate, setEndDate] = useState(new Date());

  const [showLabel, setShowLabel] = useState(Material_Request_Col_name);
  const [visible2, setVisible2] = useState(false);
  const [prodList, setProdList] = useState({ data: [], loading: false });
  const [prodType, setProdType] = useState({ data: [], loading: false });

  const [sea_val, setSea_val] = useState(false);
  const [filterConfig, setFilterConfig] = useState({
    status: [],
  });

  const [supplierList, setSupplierList] = useState({
    data: [],
    loading: false,
  });

  const [statusList, setStatusList] = useState({
    data: [
      { id: 1, name: name_const.DRAFT },
      { id: 2, name: name_const.APPROVED },
      { id: 3, name: name_const.CLOSED },
      { id: 4, name: name_const.PENDING_APPROVAL },
      { id: 5, name: name_const.REJECTED },
    ],
    loading: false,
  });

  const [lockBtn, setLockBtn] = useState(false);

  const [galVisible, setGalVisible] = useState(false);
  const [mrData, setMRData] = useState({});

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
    no_access: false,
  });

  const { register, handleSubmit, formState, control, reset, watch, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: {
        items: [
          {
            product: [],
            quantity: 0,
          },
        ],
      },
    }); // initialise the hook

  let { errors } = formState;
  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  let navigate = useNavigate();

  const localData = JSON.parse(localStorage.getItem("localData"));
  const def_org = localData?.organizations?.filter(
    (item) => item.default_org == true
  );

  useEffect(() => {
    setPrivilage(setAccessPrivilage(loginData, "Material Request"));
    setConstLabel(getLabels("Purchase", "Material Request"));
    setFilterConfig({ ...filterConfig, status: [statusList.data?.[0]] });
    fetchSuppliers();
  }, []);

  useEffect(() => {
    if (visible2) {
      fetchProductType();
      setValue("req_date", new Date());
      setValue("req_status", [{ name: statusList.data?.[0]?.name }], {
        shouldValidate: true,
      });
    }
  }, [visible2]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchMaterialRequestList(1, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function getPrvMonthDateFromToday() {
    const today = new Date();
    const endDate = new Date(today);
    endDate.setMonth(today.getMonth() - 1);
    return endDate;
  }

  function fetchProductType() {
    getProductType().then(
      (res) => {
        if (res && res.data.data.length > 0) {
          // Filter and extract uuid values
          let uuidArray = res.data.data
            .filter(
              (item) =>
                item.productType === name_const.GENERAL_ITEM ||
                item.productType === name_const.CONSUMABLE_ITEMS ||
                item.productType === name_const.RAW_MATERIAL
            )
            .map((item) => item.uuid); // Extract the uuid values

          if (uuidArray.length > 0) {
            fetchProdList(uuidArray); // Pass uuidArray to fetchProdList
          }
        }
      },
      (err) => {
        // console.log("err", err);
      }
    );
  }

  function fetchProdList(uuidArray) {
    setProdList({ ...prodList, data: [], loading: true });

    getProdList({ product_type_id_list: JSON.stringify(uuidArray) }).then(
      (res) => {
        let arr = res.data.data.filter(
          (item) => item.productName !== null || item.productName !== undefined
        );
        setProdList({ ...prodList, data: arr, loading: false });
      },
      (err) => {
        setProdList({ ...prodList, data: [], loading: false });
      }
    );
  }

  function fetchSuppliers() {
    setSupplierList({ ...supplierList, loading: true, data: [] });
    getSupplier({ partyType: "Supplier" }).then(
      (res) => {
        const result = res.data.data.filter((item) => item.name !== null);
        setSupplierList({
          ...supplierList,
          loading: false,
          data: result,
        });
      },
      (err) => {
        //
        setSupplierList({ ...supplierList, loading: false, data: [] });
        // toast.error("Something went wrong in suppliers!", {
        //   autoClose: 3000,
        // });
      }
    );
  }

  function fetchMaterialRequestList(pg, epp) {
    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
      // startDate: convertDateYYMMDD(new Date(startDate), "-"),
      // endDate: convertDateYYMMDD(new Date(endDate), "-"),
      organisation_id: def_org?.[0]?.uuid,
    };
    setDataList({ ...dataList, data: [], loading: true });

    if (filterConfig.status.length > 0) {
      payload.status = filterConfig.status[0]?.name;
      if (filterConfig.status?.length > 0 && page !== 1 && sea_val == false) {
        payload.pageNo = 1;
        payload.entriesPerPage = entriesPerPage;
        setPage(1);
        setSrNo(0);
      }
    } else {
      delete payload.status;
    }

    getMaterialRequest(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });

        if (filterConfig.status?.length > 0) {
          setSea_val(true);
        } else {
          setSea_val(false);
        }

        setMaxPage(calMaxPage_new(parseInt(res.data.total), epp));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        toast.error(err.response.data.message, {
          autoClose: 2000,
        });
        setDataList({ ...dataList, data: [], loading: false });
      }
    );
  }
  //console.log("max",maxPage);

  const delRecord = (val) => {
    SweetAlert.fire({
      title: "Are you sure to delete ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteMaterialRequest({ uuid: val.uuid }).then(
          (res) => {
            toast.success("Material request deleted successfully", {
              autoClose: 2000,
            });

            if (dataList.data.length == 1 && page > 1) {
              fetchMaterialRequestList(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchMaterialRequestList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  const onSubmit = (data) => {
    setLockBtn(true);
    if (data !== "") {
      let reqPayload = [];
      for (let i = 0; i < data.items.length; i++) {
        const lineItems = {
          product_uuid: data.items?.[i]?.product?.[0]?.uuid,
          quantity: data.items?.[i]?.quantity,
        };
        reqPayload.push(lineItems);
      }

      const payload = {
        material_req_date: convertDateYYMMDD(data.req_date, "-"),
        required_date:
          data?.required_date !== undefined && data?.required_date !== null
            ? convertDateYYMMDD(data.required_date, "-")
            : null,
        material_req_remarks: data.remark,
        material_req_status: data.req_status?.[0]?.name,
        material_req_line_items: reqPayload,
      };

      createMaterialRequest(payload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Material request created successfully !", {
              autoClose: 2000,
            });

            setTimeout(() => {
              setVisible2(false);
              setLockBtn(false);
              fetchMaterialRequestList(page, entriesPerPage);
            }, 2300);

          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
            setLockBtn(false);
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };



  function debounce(func, delay) {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const update_material_request = (data, updateString) => {
    console.log("data", data);
    console.log("updateString", updateString);
    if (!data) {
      errors.showMessages();
      return;
    }

    let reqPayload = [];

    // Build the line items payload if necessary
    for (let i = 0; i < data.material_req_lineitems?.length; i++) {
      const lineItems = {
        uuid: data.material_req_lineitems?.[i]?.uuid,
        product_uuid: data.material_req_lineitems?.[i]?.prod_data?.uuid,
        quantity: data.material_req_lineitems?.[i]?.qty,
      };
      reqPayload.push(lineItems);
    }

    const payload = {
      uuid: data?.uuid,
      material_req_date: convertDateYYMMDD(data.material_req_date, "-"),
      required_date:
        data?.required_date !== undefined && data?.required_date !== null
          ? convertDateYYMMDD(data.required_date, "-")
          : null,
      material_req_remarks: data?.remarks,
      material_req_status: data?.status,
      material_req_line_items: reqPayload.length > 0 ? reqPayload : undefined,
      supplier_id: data.supplier_id
    };

    if (data.supplier_id !== null && updateString == "vendor") {
      payload.material_req_status = name_const.PENDING_APPROVAL;
    }

    if (data?.status == name_const.APPROVED) {
      payload.organisation_id = def_org?.[0]?.uuid;
      payload.approved_by = localData?.employee_id !== null && localData.employee_id?.uuid !== null ? localData.employee_id?.uuid : localData?.user_id;
    }

    updateMaterialRequest(payload).then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success("Material request updated successfully!", {
            autoClose: 2000,
          });
          fetchMaterialRequestList(page, entriesPerPage);
        }
      },
      (err) => {
        if (err.response?.status === 400) {
          toast.error(err.response?.data?.message, {
            autoClose: 2000,
          });
        }
      }
    );
  };

  const debouncedUpdateItems = debounce(update_material_request, 1000);

  // For open thumbnail image in modal view
  const [showModal, setShowModal] = useState(false); // State to handle modal visibility
  const [modalImage, setModalImage] = useState(""); // State to store the selected image URL

  // Function to handle image click and open modal
  const handleImageClick = (imageUrl) => {
    setModalImage(imageUrl);
    setShowModal(true);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <MR_ImageGallary
        setGalVisible={setGalVisible}
        galVisible={galVisible}
        mrData={mrData}
      />
      <div id="mySidebar" className="customsidebar ">
        <a className="closebtn" onClick={() => toggleNav()}>
          ×
        </a>

        <div className=" content">
          <div className="form-group typeahead-container">
            <label
              className="filterLabel mb-1 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {constLabel?.lbl_status ? constLabel.lbl_status : "Status"}
            </label>
            <Controller
              name="status"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  multiple={false}
                  // size="sm"
                  options={statusList.data}
                  isLoading={statusList.loading}
                  onChange={(val) => {
                    if (val.length > 0) {
                      setFilterConfig({ ...filterConfig, status: val });
                    } else {
                      setFilterConfig({ ...filterConfig, status: [] });
                    }
                  }}
                  placeholder="Select status"
                  onBlur={onBlur}
                  selected={value}
                  isInvalid={errors.req_status ? true : false}
                  filterBy={() => true}
                />
              )}
            />
            {!statusList.loading && <span className="arrow-icon"></span>}

            {/* <Typeahead
              id="basic-typeahead-single"
              labelKey="name"
              name="status"
              multiple={false}
              // size="sm"
              options={statusList.data}
              isLoading={statusList.loading}
              selected={filterConfig.status}
              onChange={(val) => {
                if (val.length > 0) {
                  setFilterConfig({ ...filterConfig, status: val });
                } else {
                  setFilterConfig({ ...filterConfig, status: [] });
                }
              }}
              placeholder="Search by status"
            />
            {!statusList.loading && (
              <span className="arrow-icon"></span>
            )} */}
          </div>
        </div>
      </div>

      {!privilage.read ? (
        <AccessDenied />
      ) : (
        <div className="min-vh-100" id="root_div_main">
          <Modal
            show={visible2}
            onHide={() => {
              setVisible2(false);
              reset();
            }}
            size={"lg"}
          >
            <Modal.Header
              style={{ background: "#2A3643", color: "white" }}
              closeButton
            >
              <Modal.Title>
                {constLabel?.lbl_add_material_request
                  ? constLabel.lbl_add_material_request
                  : "Add Material Request"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form action="">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group mt-md-0 mt-3">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold"
                      >
                        {constLabel?.lbl_material_req_date
                          ? constLabel.lbl_material_req_date
                          : "Material Request Date"}
                        <span className="text-danger"> *</span>
                      </label>
                      <Controller
                        control={control}
                        name="req_date"
                        // defaultValue={new Date()}
                        render={({ field }) => (
                          <DatePicker
                            className={
                              errors.req_date
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            placeholderText="Select material request date"
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                            minDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            disabled
                          />
                        )}
                      />
                      {errors.req_date && (
                        <span className="text-danger err-msg">
                          {errors.req_date.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="form-group mt-md-0 mt-3">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold"
                      >
                        {constLabel?.lbl_required_date
                          ? constLabel.lbl_required_date
                          : "Required Date"}
                      </label>
                      <Controller
                        control={control}
                        name="required_date"
                        // defaultValue={new Date()}
                        render={({ field }) => (
                          <DatePicker
                            className={
                              errors.required_date
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            placeholderText="Select required date"
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                            minDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                      {errors.required_date && (
                        <span className="text-danger err-msg">
                          {errors.required_date.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="form-group mt-md-0 mt-3 typeahead-container">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold"
                      >
                        {constLabel?.lbl_status
                          ? constLabel.lbl_status
                          : "Status"}
                        <span className="text-danger"> *</span>
                      </label>

                      <Controller
                        name="req_status"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="name"
                            multiple={false}
                            // size="sm"
                            options={statusList.data}
                            isLoading={statusList.loading}
                            placeholder="Select status"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={errors.req_status ? true : false}
                            filterBy={() => true}
                            disabled
                          />
                        )}
                      />
                      {!statusList.loading && !errors.req_status && (
                        <span className="arrow-icon"></span>
                      )}
                      <span className="text-danger err-msg">
                        {errors.req_status?.message}
                      </span>
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="form-group mt-md-0 mt-3">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold"
                      >
                        {constLabel?.lbl_remark
                          ? constLabel.lbl_remark
                          : "Remark"}
                      </label>

                      <textarea
                        type="text"
                        className="form-control"
                        id="remark"
                        name="remark"
                        placeholder="Enter remark"
                        {...register("remark")}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <hr className="mb-1" />
                <div className="d-flex justify-content-between align-items-center">
                  <p className="roboto-bold mb-0" style={{ fontSize: "16px" }}>
                    {constLabel?.lbl_item_detail
                      ? constLabel?.lbl_item_detail
                      : "Item Detail"}
                  </p>
                  {/* <div>
                    <a
                      style={{ fontSize: "16px" }}
                      className="btn btn-sm button-primary px-2 mt-2"
                      onClick={() => {
                        append({
                          product: [],
                          quantity: 0,
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        style={{
                          color: "#ffffff",
                          marginRight: "5px",
                        }}
                        icon={faAdd}
                        size="sm"
                      />{" "}
                      {constLabel?.lbl_add ? constLabel?.lbl_add : "Add"}
                    </a>
                  </div> */}
                </div>
                <hr className="mt-1" />
                <div className="col-12">
                  {/* {fields.length > 0 ? ( */}
                  <div className="">
                    <table className="table my-2 table-stirped table-bordered">
                      <thead>
                        <tr>
                          <th>
                            {constLabel?.lbl_product
                              ? constLabel?.lbl_product
                              : "Product"}
                            <span className="text-danger"> *</span>
                          </th>
                          <th>
                            {constLabel?.lbl_quantity
                              ? constLabel?.lbl_quantity
                              : "Quantity"}
                            <span className="text-danger"> *</span>
                          </th>
                          <th>{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.map(({ id }, index) => (
                          <tr key={id}>
                            <td style={{ verticalAlign: "top" }} width={"70%"}>
                              <Controller
                                name={`items[${index}].product`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    size="sm"
                                    id="basic-typeahead"
                                    labelKey="productName"
                                    multiple={false}
                                    options={prodList.data}
                                    isLoading={prodList.loading}
                                    placeholder="Select product"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    isInvalid={
                                      errors.items?.[index]?.product
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                              />
                              {errors.items?.[index]?.product && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.product.message}
                                </span>
                              )}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <input
                                className={
                                  errors.items?.[index]?.quantity
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                name={`items[${index}].quantity`}
                                {...register(`items[${index}].quantity`)}
                                placeholder="Enter quantity"
                              />

                              {errors.items?.[index]?.quantity && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.quantity.message}
                                </span>
                              )}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "top",
                              }}
                            >
                              <Tooltip
                                id={"delete-tooltip" + index}
                                place="top"
                              />
                              <Trash2
                                data-tooltip-id={"delete-tooltip" + index}
                                data-tooltip-content={"Delete"}
                                className="menu_icon_red cursor_pointer"
                                size={20}
                                onClick={
                                  index > 0
                                    ? () => {
                                      remove(index);
                                    }
                                    : null
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={handleSubmit(onSubmit)}
                className="f-16 btn btn-yellow "
                disabled={lockBtn}
              >
                {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}{" "}
                {lockBtn ? <Spinner animation="border" size="sm" /> : null}
              </button>
            </Modal.Footer>
          </Modal>
          <div className="content-wrapper">
            <div className="card border-0">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="d-md-flex justify-content-start col-12 col-md-6">
                    <Tooltip id={"open-tooltip"} place="top" />
                    <img
                      src={Slide}
                      height="32"
                      width="32"
                      className="cursor_pointer my-1 me-2"
                      onClick={() => toggleNav()}
                      data-tooltip-id={"open-tooltip"}
                      data-tooltip-content={"Filters"}
                    />
                    <h1 className="bold">
                      {constLabel?.lbl_material_request
                        ? constLabel.lbl_material_request
                        : "Material Request"}
                    </h1>
                  </div>

                  <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">

                    {privilage.write && (
                      <button
                        onClick={() => {
                          navigate("/pcms/save/production_planning", {
                            state: {
                              from_location: "material_request"
                            }
                          });
                        }}
                        className="btn btn-secondary me-0 me-md-2">
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}{constLabel?.lbl_create_material_plan
                          ? constLabel.lbl_create_material_plan
                          : "Create Material Plan"}</button>
                    )}

                    {privilage.write && (
                      <button
                        className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                        onClick={() => {
                          setVisible2(true);
                          reset();
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_add_material_request
                          ? constLabel.lbl_add_material_request
                          : "Add Material Request"}
                      </button>

                    )}

                    <Tooltip id={"show-tooltip"} place="top" />
                    <div
                      className="dropdown"
                      data-tooltip-id={"show-tooltip"}
                      data-tooltip-content={"Show / Hide Columns"}
                    >
                      <div
                        className="d-md-flex justify-content-start align-items-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <FontAwesomeIcon icon={faListCheck} size="lg" /> */}
                        {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                        <img
                          src={List}
                          height="32"
                          width="32"
                          className="cursor_pointer"
                        />
                      </div>

                      <ul className="dropdown-menu px-2">
                        {showLabel !== undefined && showLabel.length > 0
                          ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  {dataList.loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : dataList.data.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead className="table-grey roboto-bold">
                            <tr className="">
                              <th scope="col">{"Sr.No"}</th>

                              {showLabel.map((v, i) =>
                                v.label === "Thumbnail" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_thumbnail
                                      ? constLabel.lbl_thumbnail
                                      : "Thumbnail"}
                                  </th>
                                ) : v.label === "MR Number" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_po_id
                                      ? constLabel.lbl_po_id
                                      : "MR Number"}
                                  </th>
                                ) : v.label === "Material Request Date" &&
                                  v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_date
                                      ? constLabel.lbl_date
                                      : "Material Request Date"}
                                  </th>
                                ) : v.label === "Product Code" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_product_code
                                      ? constLabel.lbl_product_code
                                      : "Product Code"}
                                  </th>
                                ) : v.label === "Product Name" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_product_name
                                      ? constLabel.lbl_product_name
                                      : "Product Name"}
                                  </th>
                                ) : v.label === "Required Date" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_required_date
                                      ? constLabel.lbl_required_date
                                      : "Required Date"}
                                  </th>
                                ) : v.label === "Required Qty" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_required_qty
                                      ? constLabel.lbl_required_qty
                                      : "Required Qty"}
                                  </th>
                                ) : v.label === "UOM" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_uom
                                      ? constLabel.lbl_uom
                                      : "UOM"}
                                  </th>
                                ) : v.label === "Available Qty" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_available_qty
                                      ? constLabel.lbl_available_qty
                                      : "Available Qty"}
                                  </th>
                                ) : v.label === "Status" && v.flag ? (
                                  <th key={i} scope="col" width="12%">
                                    {constLabel?.lbl_status
                                      ? constLabel.lbl_status
                                      : "Status"}
                                  </th>
                                ) : v.label === "Previous Vendor" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_previous_vendor
                                      ? constLabel.lbl_previous_vendor
                                      : "Previous Vendor"}
                                  </th>
                                ) : v.label === "Vendor" && v.flag ? (
                                  <th key={i} scope="col" width="12%">
                                    {constLabel?.lbl_vendor
                                      ? constLabel.lbl_vendor
                                      : "Vendor"}
                                  </th>
                                ) : v.label === "Remark" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_remark
                                      ? constLabel.lbl_remark
                                      : "Remark"}
                                  </th>
                                ) : v.label === "Previous PO No" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_previous_po_no
                                      ? constLabel.lbl_previous_po_no
                                      : "Previous PO No"}
                                  </th>
                                ) : v.label === "Previous Rate" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_previous_rate
                                      ? constLabel.lbl_previous_rate
                                      : "Previous Rate"}
                                  </th>
                                ) : v.label === "Previous Qty" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_previous_qty
                                      ? constLabel.lbl_previous_qty
                                      : "Previous Qty"}
                                  </th>
                                ) : v.label === "Previous PO Date" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_previous_po_date
                                      ? constLabel.lbl_previous_po_date
                                      : "Previous PO Date"}
                                  </th>
                                ) : v.label === "Inward Date" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_inward_date
                                      ? constLabel.lbl_inward_date
                                      : "Inward Date"}
                                  </th>
                                ) : null
                              )}

                              <th scope="col" width="12%">
                                {"Action"}
                              </th>
                            </tr>
                          </thead>
                          <tbody className=" roboto">
                            {dataList.data.map((val, ind) => (
                              <tr key={val.uuid}>
                                <td>{parseInt(ind) + 1 + parseInt(srNo)}</td>
                                {showLabel.map((item, idx) =>
                                  item.label === "Thumbnail" && item.flag ? (
                                    <td key={idx}>
                                      {val.material_req_lineitems?.length > 0 &&
                                        val.material_req_lineitems !== null ? (
                                        val.material_req_lineitems?.[0]
                                          ?.prod_data?.productattachments
                                          ?.length > 0 ? (
                                          // Render clickable thumbnail
                                          <a
                                            href="#"
                                            onClick={(e) => {
                                              e.preventDefault(); // Prevent the default anchor behavior
                                              handleImageClick(
                                                `${process.env.REACT_APP_IMAGE_URL}${val.material_req_lineitems?.[0]?.prod_data?.productattachments?.[0]?.attachment}`
                                              );
                                            }}
                                          >
                                            <img
                                              src={`${process.env.REACT_APP_IMAGE_URL}${val.material_req_lineitems?.[0]?.prod_data?.productattachments?.[0]?.attachment}`}
                                              alt="Thumbnail"
                                              className="img-thumbnail"
                                              style={{ maxWidth: "40px" }}
                                            />
                                          </a>
                                        ) : (
                                          // Render non-clickable dummy image
                                          <img
                                            src={dummyImg} // Dummy image URL
                                            alt="Dummy Thumbnail"
                                            className="img-thumbnail"
                                            style={{ maxWidth: "40px" }}
                                          />
                                        )
                                      ) : (
                                        // Render non-clickable dummy image
                                        <img
                                          src={dummyImg} // Dummy image URL
                                          alt="Dummy Thumbnail"
                                          className="img-thumbnail"
                                          style={{ maxWidth: "40px" }}
                                        />
                                      )}
                                    </td>
                                  ) : item.label === "MR Number" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {" "}
                                      {val.material_req_id != "" &&
                                        val.material_req_id !== null
                                        ? val.material_req_id
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Material Request Date" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.material_req_date != "" &&
                                        val.material_req_date !== null
                                        ? formatDateString(
                                          val.material_req_date,
                                          "-"
                                        )
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Product Code" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.material_req_lineitems?.length > 0 &&
                                        val.material_req_lineitems !== null
                                        ? (val.material_req_lineitems?.[0]
                                          ?.prod_data?.OEMnumber &&
                                          val.material_req_lineitems?.[0]
                                            ?.prod_data?.OEMnumber) ||
                                        "NA"
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Product Name" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.material_req_lineitems?.length > 0 &&
                                        val.material_req_lineitems !== null
                                        ? val.material_req_lineitems?.[0]
                                          ?.prod_data?.productName
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Required Date" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.required_date != "" &&
                                        val.required_date !== null
                                        ? formatDateString(
                                          val.required_date,
                                          "-"
                                        )
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Required Qty" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {/* {val.material_req_lineitems?.length > 0 &&
                                            val.material_req_lineitems !== null
                                            ? val.material_req_lineitems?.[0]?.qty
                                            : "NA"} */}
                                      <input
                                        type="text"
                                        className={
                                          "form-control form-control-sm"
                                        }
                                        id="qty"
                                        defaultValue={parseFloat(
                                          val.material_req_lineitems?.[0]?.qty
                                        ).toFixed(2)}
                                        onChange={(e) => {
                                          let newQty = e.target.value;
                                          // Create an updated copy of `val` immutably
                                          const updatedVal = {
                                            ...val,
                                            material_req_lineitems: [
                                              {
                                                ...val
                                                  .material_req_lineitems[0],
                                                qty: newQty,
                                              },
                                            ],
                                          };
                                          // update_material_request(
                                          //   updatedVal,
                                          //   "qty"
                                          // );
                                          debouncedUpdateItems(updatedVal, "qty");

                                        }}
                                        disabled={
                                          val.status == name_const.FINALIZED ||
                                            val.status == name_const.APPROVED
                                            ? true
                                            : false
                                        }
                                      />
                                    </td>
                                  ) : item.label === "UOM" && item.flag ? (
                                    <td key={idx}>
                                      {val.material_req_lineitems?.length > 0 &&
                                        val.material_req_lineitems !== null
                                        ? val.material_req_lineitems?.[0]
                                          ?.prod_data?.uomproduct?.uom
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Available Qty" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.material_req_lineitems?.length > 0 &&
                                        val.material_req_lineitems !== null
                                        ? val.material_req_lineitems?.[0]
                                          ?.avail_qty
                                        : 0}
                                    </td>
                                  ) : item.label === "Status" && item.flag ? (
                                    <td key={idx}>
                                      {/* {val.status ? val.status : "NA"} */}
                                      <div className="form-group">
                                        <select
                                          id={"status-select_" + idx}
                                          className="form-control form-control-sm"
                                          value={val.status || ""}
                                          onChange={(event) => {
                                            const selectedStatus =
                                              event.target.value;
                                            // console.log("selected", selectedStatus);
                                            const updatedVal = {
                                              ...val,
                                              status: selectedStatus,
                                            };
                                            update_material_request(
                                              updatedVal,
                                              "status"
                                            );
                                          }}
                                          disabled={
                                            val.status ==
                                              name_const.FINALIZED ||
                                              val.status == name_const.APPROVED
                                              ? true
                                              : false
                                          }
                                        >
                                          {/* <option value="" disabled>Select status</option> */}
                                          {!statusList.loading &&
                                            statusList.data.map(
                                              (status, index) => (
                                                <option
                                                  key={index}
                                                  value={status.name}
                                                >
                                                  {status.name}
                                                </option>
                                              )
                                            )}
                                        </select>
                                      </div>
                                    </td>
                                  ) : item.label === "Previous Vendor" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {
                                        val.material_req_lineitems?.[0]
                                          ?.prod_data?.po_info?.supplier_id
                                          ? val.material_req_lineitems[0]
                                            .prod_data.po_info
                                            .supplier_details?.name
                                          : "NA"
                                      }
                                    </td>
                                  ) : item.label === "Vendor" && item.flag ? (
                                    <td key={idx}>
                                      <div className="form-group typeahead-container">
                                        <Controller
                                          name={`supplier` + val.uuid}
                                          control={control}
                                          defaultValue={
                                            val.vender_details !== null
                                              ? [val.vender_details]
                                              : []
                                          }
                                          render={({
                                            field: {
                                              onChange,
                                              onBlur,
                                              value,
                                              ref,
                                            },
                                          }) => (
                                            <Typeahead
                                              className="mt-2"
                                              id="basic-typeahead-single"
                                              labelKey="name"
                                              size="sm"
                                              defaultSelected={
                                                val.vender_details !== null
                                                  ? [val.vender_details]
                                                  : []
                                              }
                                              multiple={false}
                                              options={supplierList.data}
                                              isLoading={supplierList.loading}
                                              placeholder="Select vendor"
                                              onChange={(selected) => {
                                                onChange(selected);

                                                if (selected.length > 0) {
                                                  const updatedVal = {
                                                    ...val,
                                                    supplier_id:
                                                      selected?.[0]?.uuid,
                                                  };
                                                  update_material_request(
                                                    updatedVal,
                                                    "vendor"
                                                  );
                                                }
                                              }}
                                              onBlur={onBlur}
                                              selected={value}
                                              disabled={
                                                val.status ==
                                                  name_const.FINALIZED ||
                                                  val.status ==
                                                  name_const.APPROVED
                                                  ? true
                                                  : false
                                              }
                                            />
                                          )}
                                        />
                                        {!supplierList.loading && (
                                          <span
                                            className="arrow-icon"
                                            style={{ top: "60%" }}
                                          ></span>
                                        )}
                                      </div>
                                    </td>
                                  ) : item.label === "Remark" && item.flag ? (
                                    <td key={idx}>
                                      {val.remarks ? val.remarks : "NA"}
                                    </td>
                                  ) : item.label === "Previous PO No" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.material_req_lineitems?.length > 0 &&
                                        val.material_req_lineitems !== null
                                        ? val.material_req_lineitems?.[0]
                                          ?.prod_data?.po_info !== null
                                          ? val.material_req_lineitems?.[0]
                                            ?.prod_data?.po_info.poid
                                          : "NA"
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Previous Rate" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.material_req_lineitems?.length > 0 &&
                                        val.material_req_lineitems !== null
                                        ? val.material_req_lineitems?.[0]
                                          ?.prod_data?.po_info !== null
                                          ? val.material_req_lineitems?.[0]
                                            ?.prod_data?.po_info?.PO_line_item
                                            .length > 0
                                            ? val.material_req_lineitems?.[0]
                                              ?.prod_data?.po_info
                                              ?.PO_line_item?.[0]?.price_per
                                            : 0
                                          : 0
                                        : 0}
                                    </td>
                                  ) : item.label === "Previous Qty" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.material_req_lineitems?.length > 0 &&
                                        val.material_req_lineitems !== null
                                        ? val.material_req_lineitems?.[0]
                                          ?.prod_data?.po_info !== null
                                          ? val.material_req_lineitems?.[0]
                                            ?.prod_data?.po_info?.PO_line_item
                                            .length > 0
                                            ? val.material_req_lineitems?.[0]
                                              ?.prod_data?.po_info
                                              ?.PO_line_item?.[0]?.quantity
                                            : 0
                                          : 0
                                        : 0}
                                    </td>
                                  ) : item.label === "Previous PO Date" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.material_req_lineitems?.length > 0 &&
                                        val.material_req_lineitems !== null
                                        ? val.material_req_lineitems?.[0]
                                          ?.prod_data?.po_info !== null
                                          ? convertDate(
                                            val.material_req_lineitems?.[0]
                                              ?.prod_data?.po_info?.po_date,
                                            "-"
                                          )
                                          : "NA"
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Inward Date" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.material_req_lineitems?.length > 0 &&
                                        val.material_req_lineitems !== null
                                        ? val.material_req_lineitems?.[0]
                                          ?.prod_data?.po_info !== null
                                          ? val.material_req_lineitems?.[0]
                                            ?.prod_data?.po_info?.piDetails
                                            .length > 0
                                            ? convertDate(
                                              val.material_req_lineitems?.[0]
                                                ?.prod_data?.po_info
                                                ?.piDetails?.[0]
                                                ?.invoice_date,
                                              "-"
                                            )
                                            : "NA"
                                          : "NA"
                                        : "NA"}
                                    </td>
                                  ) : null
                                )}

                                <td>
                                  {privilage.write && (
                                    <EditMaterialRequest
                                      data={val}
                                      constLabel={constLabel}
                                      fetchMaterialRequestList={
                                        fetchMaterialRequestList
                                      }
                                      page={page}
                                      entriesPerPage={entriesPerPage}
                                    />
                                  )}

                                  {privilage.delete && (
                                    <>
                                      <Tooltip
                                        id={"delete-tooltip" + ind}
                                        place="top"
                                      />
                                      <Trash2
                                        data-tooltip-id={"delete-tooltip" + ind}
                                        data-tooltip-content={
                                          constLabel?.lbl_delete_material_request
                                            ? constLabel.lbl_delete_material_request
                                            : "Delete Material Request"
                                        }
                                        className="menu_icon_red cursor_pointer me-2"
                                        size={20}
                                        onClick={() => {
                                          delRecord(val);
                                          console.log("asdflk");
                                        }}
                                      />
                                    </>
                                  )}

                                  {privilage.write && (
                                    <>
                                      <Tooltip
                                        id={"image-tooltip" + ind}
                                        place="top"
                                      />
                                      <Image
                                        data-tooltip-id={"image-tooltip" + ind}
                                        data-tooltip-content={
                                          constLabel?.lbl_image_gallary
                                            ? constLabel.lbl_image_gallary
                                            : "Image Gallary"
                                        }
                                        className="menu_icon_grey cursor_pointer me-2"
                                        size={20}
                                        onClick={() => {
                                          setGalVisible(true);
                                          setMRData(val);
                                        }}
                                      />
                                    </>
                                  )}

                                  {privilage.write &&
                                    val.status === name_const.APPROVED && (
                                      <>
                                        <Tooltip
                                          id={"po-tooltip" + ind}
                                          place="top"
                                        />
                                        <FilePlus
                                          data-tooltip-id={
                                            "delete-tooltip" + ind
                                          }
                                          data-tooltip-content={
                                            constLabel?.lbl_create_po
                                              ? constLabel.lbl_create_po
                                              : "Create Purchase Order"
                                          }
                                          className="menu_icon_grey cursor_pointer"
                                          size={20}
                                          onClick={() => {
                                            navigate(
                                              `/pcms/create/purchase-order/${btoa(
                                                "null"
                                              )}/${btoa("null")}`,
                                              { state: val }
                                            );
                                          }}
                                        />
                                      </>
                                    )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-2">
                        <p className="mb-0">
                          Show
                          <select
                            className="mx-1"
                            defaultValue={entriesPerPage}
                            onChange={(e) => {
                              setEntriesPerPage(e.target.value);
                              fetchMaterialRequestList(1, e.target.value);
                            }}
                          >
                            <option value={"10"}>10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          Entries
                        </p>
                        <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                          } to ${Math.min(
                            parseInt(entriesPerPage) + parseInt(srNo),
                            dataList.totalRecords
                          )} of ${dataList.totalRecords} entries`}</p>
                        <Pagination className="my-2">
                          <Pagination.Prev
                            disabled={page === 1 ? true : false}
                            onClick={() => {
                              fetchMaterialRequestList(
                                page - 1,
                                entriesPerPage
                              );
                              setSrNo((prevC) =>
                                page - 1 == 1
                                  ? 0
                                  : prevC - parseInt(entriesPerPage)
                              );
                              setPage(page - 1);
                            }}
                          >
                            {"Prev"}
                          </Pagination.Prev>

                          <Pagination.Item active>{page}</Pagination.Item>

                          <Pagination.Next
                            disabled={
                              page === maxPage || maxPage === 0 ? true : false
                            }
                            onClick={() => {
                              fetchMaterialRequestList(
                                page + 1,
                                entriesPerPage
                              );
                              setSrNo(
                                (prevC) => prevC + parseInt(entriesPerPage)
                              );
                              setPage(page + 1);
                            }}
                          >
                            {"Next"}
                          </Pagination.Next>
                        </Pagination>
                      </div>
                    </>
                  ) : (
                    <div
                      className="alert alert-danger mb-0 text-center my-4"
                      role="alert"
                    >
                      {"No Material Request Found"}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal to display full image */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_product_image
              ? constLabel?.lbl_product_image
              : "Product Image"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={modalImage}
            alt="Full-size"
            className="img-fluid"
            style={{ maxHeight: "80vh", width: "100%" }}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default MaterialRequest;
