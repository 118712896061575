import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import {
  getInternalCompany,
  get_company_type,
  get_gst_treatment,
  get_org_type,
  master_multi_file_upload,
  save_internal_company,
} from "../../../api";
import Gallary from "./Gallary";
import { getLabels } from "../../../common/Functions/CommonFunctions";
import { Edit, Trash2 } from "react-feather";
import AddConfiguration from "./AddConfiguration";

const schema = yup
  .object()
  .shape({
    orgnisation_type: yup
      .array()
      .min(1, "Please select orgnisation type !")
      .required("Please select orgnisation type !"),
    company_type: yup
      .array()
      .min(1, "Please select company type !")
      .required("Please select company type !"),
    gst_type: yup
      .array()
      .min(1, "Please select GST type !")
      .required("Please select GST type !"),
    // parent_id: yup
    //     .array()
    //     .min(1, "Please select parent company !")
    //     .required("Please select parent company !"),
    comName: yup.string().required("Please enter company name !"),
    comaddress: yup.string().required("Please enter company address !"),
    comGSTnumber: yup.string().when(["gst_type"], (gst_type, schema) => {
      // Check if 'machine_' exists in any of the selected categories

      if (gst_type !== undefined && gst_type.length > 0) {
        const gst_typeSelected = gst_type[0].name == "Register";

        // If 'machine_' is in the category, make 'supplier' mandatory
        return gst_typeSelected
          ? schema
              .required("Please enter GST number !")
              .max(15, "GST number must be 15 characters only !")
              .matches(/^[A-Z0-9]*$/, "Enter valid GST number !")
          : schema;
      } else {
        return schema;
      }
    }),
    // comGSTnumber: yup
    //     .string()
    //     .required("Please enter GST number !")
    //     .max(15, 'GST number must be 15 characters only !')
    //     .matches(/^[A-Z0-9]*$/, 'Enter valid GST number !'),
    first_name: yup.string().required("Please enter first name !"),
    middle_name: yup.string().required("Please enter middle name !"),
    last_name: yup.string().required("Please enter last name !"),
    mail: yup
      .string()
      .email("Invalid email address !")
      .required("Please enter mail !"),
    phone: yup
      .string()
      .required("Please enter phone number !")
      .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid phone number !"),
    landline: yup
      .string()
      .max(10, "Landline number must be 10 characters only")
      .matches(/^[0-9]*$/, "Please enter a valid landline number")
      .notRequired(),
    uan_no: yup
      .string()
      .max(12, "Udyog Aadhaar number must be 12 characters only !")
      .matches(/^[A-Z0-9]*$/, "Please enter valid Udyog Aadhaar number !")
      .notRequired(),
    cin_no: yup
      .string()
      .max(21, "CIN number must be 21 characters only")
      .matches(/^[A-Z0-9]*$/, "Please enter a valid CIN number")
      .notRequired(),
  })
  .required();

const ViewOrgization = () => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);

  const [constLabel, setConstLabel] = useState({});

  useEffect(() => {
    setConstLabel(getLabels("Master", "Organization"));

    let arr = flattenNestedObject(location.state);

    setData(arr);
  }, []);

  let navigate = useNavigate();

  function flattenNestedObject(obj) {
    const result = [];

    function recursiveFlatten(item) {
      let obj = { ...item, internal_company_item: [] };
      result.push(obj); // Add the current item to the result

      if (item.internal_company_item && item.internal_company_item.length > 0) {
        item.internal_company_item.forEach((childItem) => {
          recursiveFlatten(childItem); // Recursively process child items
        });
      }
    }

    recursiveFlatten(obj); // Start the recursive flattening

    return result;
  }

  const { register, handleSubmit, formState, control, reset, watch, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;

  const [visible, setVisible] = useState(false);
  const [name_gal, setName_gal] = useState({ name: "", type: "" });
  const [files, setFiles] = useState({
    gst_certificate: {
      name: "",
      file: {},
    },
    uydog_certificate: {
      name: "",
      file: {},
    },
    cin_certificate: {
      name: "",
      file: {},
    },
  });

  const [parentlist, setParentlist] = useState({
    data: [],
    loading: false,
  });
  const [companytype, setCompanytype] = useState({
    data: [],
    loading: false,
  });
  const [orgtype, setOrgtype] = useState({
    data: [],
    loading: false,
  });
  const [gstType, setGstType] = useState({
    data: [],
    loading: false,
  });

  const [visible2, setVisible2] = useState(false);
  const [table_label, setTable_lables] = useState([]);
  const [editedKey2, setEditedKey2] = useState("");
  const [editedValue2, setEditedValue2] = useState({});

  const localData = JSON.parse(localStorage.getItem("localData"));

  useEffect(() => {
    fetchInternal_company();
    fetchCompanyType();
    fetchOrgType();
    fetchGstType();

    if(localData?.configuration && Object.keys(localData?.configuration).length > 0){
      // Transform configuration into the desired format
    const transformedData = Object.entries(localData?.configuration).map(([key, value]) => {
      const isBoolType = typeof value == "boolean";
      return {
        key: "label",
        value: key,
        flag: isBoolType ? value == true ? true : false : null, // Replace `false` with any `isChecked` logic if needed
        text_value: isBoolType ? null : value,
      };
    });
      setTable_lables(transformedData);
    } else {
      setTable_lables([]);
    }
  }, []);

  const fetchInternal_company = () => {
    setParentlist({
      data: [],
      loading: true,
    });

    getInternalCompany({})
      .then((res) => {
        setParentlist({
          data: res.data.data,
          loading: false,
        });
      })
      .catch((err) => {
        setParentlist({
          data: [],
          loading: false,
        });
      });
  };

  function fetchCompanyType() {
    setCompanytype({ loading: true, data: [] });

    get_company_type().then(
      (res) => {
        setCompanytype({
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        //
        setCompanytype({ loading: false, data: [] });
      }
    );
  }

  function fetchOrgType() {
    setOrgtype({ loading: true, data: [] });

    get_org_type().then(
      (res) => {
        setOrgtype({
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        //
        setOrgtype({ loading: false, data: [] });
      }
    );
  }

  function fetchGstType() {
    setGstType({ loading: true, data: [] });

    get_gst_treatment().then(
      (res) => {
        setGstType({
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        //
        setGstType({ loading: false, data: [] });
      }
    );
  }

  const onSubmit = (data) => {
    if (
      data.comGSTnumber.length > 0 &&
      files.gst_certificate.name.length == 0
    ) {
      toast.error("Please Upload GST Certificate !", {
        autoClose: 2000,
      });
      return;
    }

    if (data.uan_no.length > 0 && files.uydog_certificate.name.length == 0) {
      toast.error("Please Upload Udyog Aadhar Certificate !", {
        autoClose: 2000,
      });
      return;
    }

    if (data.cin_no.length > 0 && files.cin_certificate.name.length == 0) {
      toast.error(" Upload CIN Certificate !", {
        autoClose: 2000,
      });
      return;
    }

    let config_obj = {}
    table_label.forEach((item)=>{
      let ob = {[`${item.value}`]: item.flag==null?item.text_value:item.flag};
      config_obj = {...config_obj, ...ob}   
    });

    const payload = {
      ...data,
      parent_id:
        data.parent_id !== undefined && data.parent_id.length !== 0
          ? data.parent_id[0].uuid
          : null,
      parent_name:
        data.parent_id !== undefined && data.parent_id.length !== 0
          ? data.parent_id[0].comName
          : null,
      company_type: data.company_type[0].name,
      orgnisation_type: data.orgnisation_type[0].name,
      gst_type: data.gst_type[0].name,
      org_configuration: config_obj,
    };

    let arr = [];

    for (const x in files) {
      if (files[x].name.length > 0) {
        arr.push(files[x]);
      }
    }

    save_internal_company(payload)
      .then((res) => {
        let barr = [];

        for (let i = 0; i < arr.length; i++) {
          let obj = {};
          obj.ref_model_id = res.data.comId;
          obj.ref_model_uuid = res.data?.uuid;
          obj.model_name = "Organization";
          obj.name = arr[i].name;
          obj.description = arr[i].type;
          if (arr[i].file.type === "image/png") {
            obj.document_type = "image";
          }
          if (arr[i].file.type === "application/pdf") {
            obj.document_type = "document";
          }

          barr.push(obj);
        }

        let imgArr = {};

        imgArr["img_count"] = barr.length;

        for (let a = 0; a < barr.length; a++) {
          imgArr["attachment" + (a + 1)] = arr[a].file;
          imgArr["obj" + (a + 1)] = JSON.stringify(barr[a]);
        }

        const formDataToSend = new FormData();
        for (const [key, value] of Object.entries(imgArr)) {
          formDataToSend.append(key, value);
        }

        if (barr.length > 0) {
          master_multi_file_upload(formDataToSend).then(
            (res) => {
              toast.success("Organization created successfully !", {
                autoClose: 2000,
              });
              setTimeout(() => {
                navigate(`/pcms/orgnization`);
              }, 2200);
            },
            (err) => {
              if (err.response.status == 413) {
                toast.error("Image upload failed !", {
                  autoClose: 3000,
                });
              }
            }
          );
        } else {
          toast.success("Organization created successfully !", {
            autoClose: 2000,
          });
          setTimeout(() => {
            navigate(`/pcms/orgnization`);
          }, 2200);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
          });

          setTimeout(() => {
            navigate(`/pcms/orgnization`);
          }, 2200);
        }
      });
  };

  const handleUpdate2 = (ind) => {
    let arr = [...table_label];
    arr[ind] = editedValue2;
 
    setTable_lables(arr);
    setEditedKey2("");
    setEditedValue2([]);
  };

  const handleEdit2 = (ind) => {
    setEditedKey2(ind);
    setEditedValue2(table_label[ind]);
  };

  const handleDelete2 = (ind) => {
    table_label.splice(ind, 1);
    setTable_lables([...table_label]);
  };

  const handleInputChange2 = (event) => {
    if (event.target.type == "text") {
      let obj = { ...editedValue2, text_value: event.target.value };
      setEditedValue2(obj);
    } else {
      let obj = { ...editedValue2, flag: event.target.checked };
      setEditedValue2(obj);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <Gallary
        visible={visible}
        setVisible={setVisible}
        files={files}
        setFiles={setFiles}
        name_gal={name_gal}
      />

      <AddConfiguration
        visible={visible2}
        setVisible={setVisible2}
        table_label={table_label}
        setTable_lables={setTable_lables}
      />

      <div style={{ maxHeight: "650px", overflowY: "scroll" }}>
        {data.map((compnay, ind) => (
          <div className="view organization p-4">
            <div className="row">
              <div className="d-flex justify-content-between mb-2">
                <h1 className="page-heading roboto-bold">
                  {constLabel?.lbl_organization_profile
                    ? constLabel?.lbl_organization_profile
                    : "Organization Profile"}
                </h1>
                <div>
                  <button
                    className="btn btn-darkblue px-3"
                    //navigate to the create page
                    onClick={() => {
                      setValue("parent_id", [
                        { comName: compnay.comName, uuid: compnay.uuid },
                      ]);
                      setShow(!show);
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ color: "#fff", marginRight: "6px" }}
                      icon={faPlus}
                      size="sm"
                    />
                    {constLabel?.lbl_add_company
                      ? constLabel?.lbl_add_company
                      : "Add Company"}
                  </button>
                </div>
              </div>

              <div className="card-body">
                <div
                  className="bg-white"
                  style={{ padding: "50px", borderRadius: "12px" }}
                >
                  <div className="row">
                    <div className="col-12 col-md-3">
                      <p
                        className="m-0  roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_parent
                          ? constLabel?.lbl_parent
                          : "Parent"}
                      </p>
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {Object.keys(compnay).length > 0 &&
                        compnay.parent_name !== null
                          ? compnay.parent_name
                          : "NA"}
                      </p>
                    </div>
                    <div className="col-12 col-md-3">
                      <p
                        className="m-0  roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_company_name
                          ? constLabel?.lbl_company_name
                          : "Company Name"}
                      </p>
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {Object.keys(compnay).length > 0 &&
                        compnay.comName !== null
                          ? compnay.comName
                          : "NA"}
                      </p>
                    </div>

                    <div className="col-12 col-md-3">
                      <p
                        className="m-0  roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_company_type
                          ? constLabel?.lbl_company_type
                          : "Company Type"}
                      </p>
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {Object.keys(compnay).length > 0 &&
                        compnay.company_type !== null
                          ? compnay.company_type
                          : "NA"}
                      </p>
                    </div>

                    <div className="col-12 col-md-3">
                      <p
                        className="m-0  roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_gst_number
                          ? constLabel.lbl_gst_number
                          : "GST Number"}
                      </p>
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {Object.keys(compnay).length > 0 &&
                        compnay.comGSTnumber !== null &&
                        compnay.comGSTnumber.length > 0
                          ? compnay.comGSTnumber
                          : "NA"}
                      </p>
                    </div>

                    <div className="col-12 col-md-3 mt-3">
                      <p
                        className="m-0  roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_udyog_aadhar
                          ? constLabel.lbl_udyog_aadhar
                          : "Udyog Aadhar"}
                      </p>
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {Object.keys(compnay).length > 0 &&
                        compnay.uan_no !== null &&
                        compnay.uan_no.length > 0
                          ? compnay.uan_no
                          : "NA"}
                      </p>
                    </div>

                    <div className="col-12 col-md-3 mt-3">
                      <p
                        className="m-0  roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_cin_no
                          ? constLabel.lbl_cin_no
                          : "CIN No"}
                      </p>
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {Object.keys(compnay).length > 0 &&
                        compnay.cin_no !== null &&
                        compnay.cin_no.length > 0
                          ? compnay.cin_no
                          : "NA"}
                      </p>
                    </div>
                    <div className="col-12 col-md-3 mt-3">
                      <p
                        className="m-0  roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_first_name
                          ? constLabel?.lbl_first_name
                          : "First Name"}
                      </p>
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {Object.keys(compnay).length > 0 &&
                        compnay.first_name !== null
                          ? compnay.first_name
                          : "NA"}
                      </p>
                    </div>
                    <div className="col-12 col-md-3 mt-3">
                      <p
                        className="m-0  roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_last_name
                          ? constLabel?.lbl_last_name
                          : "Last Name"}
                      </p>
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {Object.keys(compnay).length > 0 &&
                        compnay.last_name !== null
                          ? compnay.last_name
                          : "NA"}
                      </p>
                    </div>
                    <div className="col-12 col-md-3 mt-3">
                      <p
                        className="m-0  roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_phone_number
                          ? constLabel?.lbl_phone_number
                          : "Phone Number"}
                      </p>
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {Object.keys(compnay).length > 0 &&
                        compnay.phone !== null
                          ? compnay.phone
                          : "NA"}
                      </p>
                    </div>

                    <div className="col-12 col-md-3 mt-3">
                      <p
                        className="m-0  roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_landline_number
                          ? constLabel?.lbl_landline_number
                          : "Landline Number"}
                      </p>
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {Object.keys(compnay).length > 0 &&
                        compnay.landline !== null &&
                        compnay.landline.length > 0
                          ? compnay.landline
                          : "NA"}
                      </p>
                    </div>

                    <div className="col-12 col-md-3 mt-3">
                      <p
                        className="m-0  roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_organization_type
                          ? constLabel?.lbl_organization_type
                          : "Organization Type"}
                      </p>
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {Object.keys(compnay).length > 0 &&
                        compnay.orgnisation_type !== null
                          ? compnay.orgnisation_type
                          : "NA"}
                      </p>
                    </div>
                    <div className="col-12 col-md-3 mt-3">
                      <p
                        className="m-0  roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_gst_type
                          ? constLabel?.lbl_gst_type
                          : "GST Type"}
                      </p>
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {Object.keys(compnay).length > 0 &&
                        compnay.gst_type !== null
                          ? compnay.gst_type
                          : "NA"}
                      </p>
                    </div>

                    <div className="col-12 col-md-3 mt-3">
                      <p
                        className="m-0  roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_location_name
                          ? constLabel?.lbl_location_name
                          : "Location Name"}
                      </p>
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {Object.keys(compnay).length > 0 &&
                        compnay.location_name !== null &&
                        compnay.location_name.length > 0
                          ? compnay.location_name
                          : "NA"}
                      </p>
                    </div>

                    <div className="col-12 col-md-3 mt-3">
                      <p
                        className="m-0  roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_company_address
                          ? constLabel?.lbl_company_address
                          : "Company Address"}
                      </p>
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {Object.keys(compnay).length > 0 &&
                        compnay.comaddress !== null
                          ? compnay.comaddress
                          : "NA"}
                      </p>
                    </div>

                    <div className="col-12 col-md-3 mt-3">
                      <p
                        className="m-0  roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_industry_name
                          ? constLabel?.lbl_industry_name
                          : "Industry Name"}
                      </p>
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {Object.keys(compnay).length > 0 &&
                        compnay.industry !== null &&
                        compnay.industry.length > 0
                          ? compnay.industry
                          : "NA"}
                      </p>
                    </div>
                    <div className="col-12 col-md-3 mt-3">
                      <p
                        className="m-0  roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_middle_name
                          ? constLabel?.lbl_middle_name
                          : "Middle Name"}
                      </p>
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {Object.keys(compnay).length > 0 &&
                        compnay.middle_name !== null
                          ? compnay.middle_name
                          : "NA"}
                      </p>
                    </div>
                    <div className="col-12 col-md-3 mt-3">
                      <p
                        className="m-0  roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_mail ? constLabel?.lbl_mail : "Mail"}
                      </p>
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {Object.keys(compnay).length > 0 &&
                        compnay.mail !== null
                          ? compnay.mail
                          : "NA"}
                      </p>
                    </div>
                    <div className="col-12 col-md-3 mt-3">
                      <p
                        className="m-0  roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_linkedIn
                          ? constLabel?.lbl_linkedIn
                          : "Linkedln"}
                      </p>
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {Object.keys(compnay).length > 0 &&
                        compnay.linkedin !== null &&
                        compnay.linkedin.length > 0
                          ? compnay.website
                          : "NA"}
                      </p>
                    </div>
                    <div className="col-12 col-md-3 mt-3">
                      <p
                        className="m-0  roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_website
                          ? constLabel?.lbl_website
                          : "Website"}
                      </p>
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {Object.keys(compnay).length > 0 &&
                        compnay.website !== null &&
                        compnay.website.length > 0
                          ? compnay.website
                          : "NA"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {show && (
          <div className="card mt-4">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center px-2 mb-3">
                <p className="roboto-bold m-0" style={{ fontSize: "24px" }}>
                  {constLabel?.lbl_create_organization
                    ? constLabel?.lbl_create_organization
                    : "Create Organization"}
                </p>
              </div>

              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_parent
                          ? constLabel?.lbl_parent
                          : "Parent"}
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <Controller
                        name="parent_id"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            className={"mt-2"}
                            id="basic-typeahead-single"
                            labelKey="comName"
                            multiple={false}
                            options={parentlist.data}
                            isLoading={parentlist.loading}
                            placeholder="Select Parent "
                            disabled={true}
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={errors.parent_id ? true : false}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_company_name
                          ? constLabel?.lbl_company_name
                          : "Company Name"}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className={
                          errors.comName
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        type="text"
                        id="comName"
                        name="comName"
                        placeholder="Enter company name"
                        {...register("comName")}
                      />
                      <span className="text-danger err-msg">
                        {errors.comName?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_organization_type
                          ? constLabel?.lbl_organization_type
                          : "Organization Type"}
                        <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name={`orgnisation_type`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            className={"mt-2"}
                            id="basic-typeahead-single"
                            labelKey="name"
                            multiple={false}
                            options={orgtype.data}
                            isLoading={orgtype.loading}
                            placeholder="Select organization type"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={errors.orgnisation_type ? true : false}
                          />
                        )}
                      />
                      {errors.orgnisation_type && (
                        <span className="text-danger err-msg">
                          {errors.orgnisation_type.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_company_type
                          ? constLabel?.lbl_company_type
                          : "Company Type"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>
                      <Controller
                        name="company_type"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            className={"mt-2"}
                            id="basic-typeahead-single"
                            labelKey="name"
                            multiple={false}
                            options={companytype.data}
                            isLoading={companytype.loading}
                            placeholder="Select company type "
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={errors.company_type ? true : false}
                          />
                        )}
                      />
                      <span className="text-danger err-msg">
                        {errors.company_type?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_gst_type
                          ? constLabel?.lbl_gst_type
                          : "GST Type"}
                        <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name={`gst_type`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            className={"mt-2"}
                            id="basic-typeahead-single"
                            labelKey="name"
                            multiple={false}
                            options={gstType.data}
                            isLoading={gstType.loading}
                            placeholder="Select GST type"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={errors.gst_type ? true : false}
                          />
                        )}
                      />
                      {errors.gst_type && (
                        <span className="text-danger err-msg">
                          {errors.gst_type.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_company_address
                          ? constLabel?.lbl_company_address
                          : "Company Address"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <input
                        className={
                          errors.comaddress
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        type="text"
                        id="comaddress"
                        name="comaddress"
                        placeholder="Enter company address"
                        {...register("comaddress")}
                      />
                      <span className="text-danger err-msg">
                        {errors.comaddress?.message}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_location_name
                          ? constLabel?.lbl_location_name
                          : "Location Name"}
                      </label>

                      <input
                        className={"form-control mt-2"}
                        type="text"
                        id="location_name"
                        name="location_name"
                        placeholder="Enter location"
                        {...register("location_name")}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_industry_name
                          ? constLabel?.lbl_industry_name
                          : "Industry Name"}
                        {/* <span>
                        <i style={{ color: "red" }}>*</i>
                    </span> */}
                      </label>

                      <input
                        className={"form-control mt-2"}
                        type="text"
                        id="industry"
                        name="industry"
                        placeholder="Enter company name"
                        {...register("industry")}
                      />
                      {/* <span className="text-danger err-msg">
                    {errors.industry?.message}
                </span> */}
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_first_name
                          ? constLabel?.lbl_first_name
                          : "First Name"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <input
                        className={
                          errors.first_name
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        type="text"
                        id="first_name"
                        name="first_name"
                        placeholder="Enter first name"
                        {...register("first_name")}
                      />
                      <span className="text-danger err-msg">
                        {errors.first_name?.message}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_middle_name
                          ? constLabel?.lbl_middle_name
                          : "Middle Name"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <input
                        className={
                          errors.middle_name
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        type="text"
                        id="middle_name"
                        name="middle_name"
                        placeholder="Enter middle name"
                        {...register("middle_name")}
                      />
                      <span className="text-danger err-msg">
                        {errors.middle_name?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_last_name
                          ? constLabel?.lbl_last_name
                          : "Last Name"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <input
                        className={
                          errors.last_name
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        type="text"
                        id="last_name"
                        name="last_name"
                        placeholder="Enter last name"
                        {...register("last_name")}
                      />
                      <span className="text-danger err-msg">
                        {errors.last_name?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_phone_number
                          ? constLabel?.lbl_phone_number
                          : "Phone Number"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <input
                        className={
                          errors.phone
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        type="text"
                        id="phone"
                        name="phone"
                        maxLength={10}
                        placeholder="Enter phone number"
                        {...register("phone")}
                      />
                      <span className="text-danger err-msg">
                        {errors.phone?.message}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_landline_number
                          ? constLabel?.lbl_landline_number
                          : "Landline Number"}
                        {/* <span>
                        <i style={{ color: "red" }}>*</i>
                    </span> */}
                      </label>

                      <input
                        className={"form-control mt-2"}
                        type="text"
                        id="landline"
                        name="landline"
                        maxLength={10}
                        placeholder="Enter landline number"
                        {...register("landline")}
                      />
                      <span className="text-danger err-msg">
                        {errors.landline?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_mail ? constLabel?.lbl_mail : "Mail"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <input
                        className={
                          errors.mail
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        type="text"
                        id="mail"
                        name="mail"
                        placeholder="Enter mail"
                        {...register("mail")}
                      />
                      <span className="text-danger err-msg">
                        {errors.mail?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_website
                          ? constLabel?.lbl_website
                          : "Website"}
                        {/* <span>
                        <i style={{ color: "red" }}>*</i>
                    </span> */}
                      </label>

                      <input
                        className={"form-control mt-2"}
                        type="text"
                        id="website"
                        name="website"
                        placeholder="Enter website"
                        {...register("website")}
                      />
                      {/* <span className="text-danger err-msg">
                    {errors.website?.message}
                </span> */}
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_linkedIn
                          ? constLabel?.lbl_linkedIn
                          : "Linkedln"}
                        {/* <span>
                        <i style={{ color: "red" }}>*</i>
                    </span> */}
                      </label>

                      <input
                        className={"form-control mt-2"}
                        type="text"
                        id="linkedin"
                        name="linkedin"
                        placeholder="Enter linkedln url "
                        {...register("linkedin")}
                      />
                      {/* <span className="text-danger err-msg">
                    {errors.website?.message}
                </span> */}
                    </div>
                  </div>
                </div>
              </div>
              <hr className="m-3" />
              <div className="d-flex justify-content-between align-items-center px-2 mt-4">
                <p className="roboto-bold m-0" style={{ fontSize: "24px" }}>
                  {constLabel?.lbl_taxAndCertificationInfo
                    ? constLabel.lbl_taxAndCertificationInfo
                    : "Tax and Certification Info"}
                </p>
              </div>
              <div className="container-fluid">
                <div className="row mt-3">
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_gst_number
                          ? constLabel.lbl_gst_number
                          : "GST Number"}
                        {/* <span>
                        <i style={{ color: "red" }}>*</i>
                    </span> */}
                      </label>

                      <input
                        className={
                          errors.comGSTnumber
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        type="text"
                        id="comGSTnumber"
                        name="comGSTnumber"
                        maxLength={15}
                        placeholder="Enter GST number"
                        {...register("comGSTnumber")}
                      />
                      <span className="text-danger err-msg">
                        {errors.comGSTnumber?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_udyog_aadhar
                          ? constLabel.lbl_udyog_aadhar
                          : "Udyog Aadhar"}
                        {/* <span>
                        <i style={{ color: "red" }}>*</i>
                    </span> */}
                      </label>

                      <input
                        className={"form-control mt-2"}
                        type="text"
                        id="uan_no"
                        name="uan_no"
                        maxLength={12}
                        placeholder="Enter udyog aadhar number"
                        {...register("uan_no")}
                      />
                      <span className="text-danger err-msg">
                        {errors.uan_no?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_cin_no
                          ? constLabel.lbl_cin_no
                          : "CIN No"}
                        {/* <span>
                        <i style={{ color: "red" }}>*</i>
                    </span> */}
                      </label>

                      <input
                        className={"form-control mt-2"}
                        type="text"
                        id="cin_no"
                        name="cin_no"
                        maxLength={21}
                        placeholder="Enter CIN number"
                        {...register("cin_no")}
                      />
                      <span className="text-danger err-msg">
                        {errors.cin_no?.message}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-12 col-sm-4">
                    <div
                      className="form-group"
                      onClick={() => {
                        setName_gal({
                          name: "Upload GST Certificate",
                          type: "gst_certificate",
                        });
                        setVisible(true);
                      }}
                    >
                      <label style={{ color: "#4E7DAE", fontSize: "15px" }}>
                        {constLabel?.lbl_uploadGSTCertificate
                          ? constLabel.lbl_uploadGSTCertificate
                          : "Upload GST Certificate"}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div
                      className="form-group"
                      onClick={() => {
                        setName_gal({
                          name: "Upload Udyog Aadhar Certificate",
                          type: "uydog_certificate",
                        });
                        setVisible(true);
                      }}
                    >
                      <label style={{ color: "#4E7DAE", fontSize: "15px" }}>
                        {constLabel?.lbl_uploadUdyogAadharCertificate
                          ? constLabel.lbl_uploadUdyogAadharCertificate
                          : "Upload Udyog Aadhar Certificate"}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div
                      className="form-group"
                      onClick={() => {
                        setName_gal({
                          name: "Upload CIN Certificate",
                          type: "cin_certificate",
                        });
                        setVisible(true);
                      }}
                    >
                      <label style={{ color: "#4E7DAE", fontSize: "15px" }}>
                        {constLabel?.lbl_uploadCINCertificate
                          ? constLabel.lbl_uploadCINCertificate
                          : "Upload CIN Certificate"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="m-3" />
              <div className="d-flex justify-content-between align-items-center px-2 mt-4">
                <p className="roboto-bold m-0" style={{ fontSize: "24px" }}>
                  {constLabel?.lbl_configuration
                    ? constLabel.lbl_configuration
                    : "Configuration"}
                </p>
                <button 
                  className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                  onClick={() => setVisible2(true)}
                
                >
                  <FontAwesomeIcon
                    style={{ color: "#fff" }}
                    icon={faPlus}
                    size="sm"
                  />{" "}
                  {constLabel?.lbl_add_configuration
                    ? constLabel.lbl_add_configuration
                    : "Add Configuration"}
                </button>
              </div>
              <div className="table-responsive mt-3">
                <table className="table table-bordered">
                  <thead className="table-grey roboto-bold">
                    <tr>
                      <th>{"Name"}</th>
                      <th>{"Value"}</th>
                      <th>{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {table_label.map((item, ind) => {
                      return (
                        <tr>
                          <td>{item.value}</td>
                          {item.flag !== null && (
                            <td>
                              {editedKey2 === ind ? (
                                <div className="form-check form-switch ">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={editedValue2.flag}
                                    onChange={(e) => handleInputChange2(e)}
                                  />
                                </div>
                              ) : item.flag ? (
                                "True"
                              ) : (
                                "False"
                              )}
                            </td>
                          )}
                          {item.flag == null && (
                            <td>
                              {editedKey2 === ind ? (
                                <input
                                  value={editedValue2.text_value}
                                  onChange={(e) => handleInputChange2(e)}
                                />
                              ) : (
                                item.text_value
                              )}
                            </td>
                          )}
                          <td>
                            {editedKey2 === ind ? (
                              <button
                                className="btn btn-yellow px-3 btn-sm"
                                onClick={() => handleUpdate2(ind)}
                              >
                                Update
                              </button>
                            ) : (
                              <React.Fragment>
                                <Edit
                                  className="menu_icon_grey cursor_pointer me-2"
                                  size={20}
                                  onClick={() => handleEdit2(ind)}
                                />
  
                                <Trash2
                                  className="menu_icon_red cursor_pointer"
                                  size={20}
                                  onClick={() => handleDelete2(ind)}
                                />
                              </React.Fragment>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end mt-4">
                <div>
                  <button
                    className="btn btn-grey px-3 mx-3"
                    onClick={handleSubmit(onSubmit)}
                  >
                    {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ViewOrgization;
