import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { Edit, Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {updateDesignationApi} from "../../../api";
import * as name_const from "../../../common/Constants/constants"

const schema = yup
  .object()
  .shape({
    desi_name: yup.string().trim().required("Name is required!"),
  })
  .required();

function EditDesignation(props) {
  // console.log("props", props);
  const [visible, setVisible] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;  

  const onSubmit = (data) => {
    if (data !== "") {
      const reqPayload = {
        uuid: props.data.uuid,
        desi_name: data.desi_name?.trim(),
        desi_descript: data.desi_descript !== "" ? data.desi_descript : null,
      };

      updateDesignationApi(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success(`${name_const.DESIGNATION} updated successfully !`, {
              autoClose: 2000,
            });
            setVisible(false);
            props.fetchDesignation(props.page, props.entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <Edit
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_edit_designation
            ? props.constLabel?.lbl_edit_designation
            : `Edit ${name_const.DESIGNATION}`
        }
        className="menu_icon_grey cursor_pointer me-2"
        size={20}
        onClick={() => {
          setVisible(true);
          reset();
        }}
      />
      <Modal
        show={visible}
        onHide={() => {
          setVisible(false);
          reset();
        }}
        size={"md"}
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_update_designation
              ? props.constLabel?.lbl_update_designation
              : `Update ${name_const.DESIGNATION}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
          <div className="form-group row mt-2">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-md-12 roboto-bold"
                  >
                    {props.constLabel?.lbl_name ? props.constLabel?.lbl_name : "Name"}
                    <span className="text-danger"> *</span>
                  </label>
                  <div className="col-md-12">
                    <input
                      type="text"
                      className={
                        errors.desi_name
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      id="desi_name"
                      name="desi_name"
                      defaultValue={props.data?.desi_name}
                      placeholder="Enter name"
                      {...register("desi_name")}
                    />
                    <span className="text-danger err-msg">
                      {errors.desi_name?.message}
                    </span>
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-md-12 roboto-bold"
                  >
                    {props.constLabel?.lbl_description ? props.constLabel?.lbl_description : "Description"}
                  </label>
                  <div className="col-md-12">
                    <textarea
                      type="text"
                      className="form-control"
                      id="desi_descript"
                      name="desi_descript"
                      defaultValue={props.data?.desi_descript}
                      placeholder="Enter description"
                      {...register("desi_descript")}
                    ></textarea>
                  </div>
                </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel?.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditDesignation;