import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { data } from "../SampleData";
import { Accordion, Pagination } from "react-bootstrap";
import {
  screen_list,
  module_with_screen,
} from "../../../common/Labels/screenList";
import { useLocation } from "react-router-dom";
import {
  fetch_privilages,
  create_privilages,
  update_privilages,
} from "../../../api/index";
import { ToastContainer, toast } from "react-toastify";

function ScreenAccess() {
  const [constLabel, setConstLabel] = useState({});
  const [entriesPerPage, setEntriesPerPage] = useState(25); // Default items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [privilage_uuid, set_privilage_uuid] = useState(null);

  const [screen_module, setScreen_Module] = useState([]);
  const [curr_data, setCurr_Data] = useState([]);
  const [totalEntries, setTotalEntries] = useState();
  const [totalPages, setTotalPages] = useState();
  const [showingFrom, setShowingForm] = useState();
  const [showingTo, setShowingTo] = useState();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Reset page when data changes
    setCurrentPage(1);
    get_privilages();
  }, [location.state.user_id]); // Only depend on user_id

  useEffect(() => {
    setTotalEntries(filteredData.length);
    setTotalPages(Math.ceil(filteredData.length / entriesPerPage));
    const indexOfLastItem = currentPage * entriesPerPage;
    const indexOfFirstItem = indexOfLastItem - entriesPerPage;

    // Update the data for the current page
    setCurr_Data(filteredData.slice(indexOfFirstItem, indexOfLastItem));

    setShowingForm(indexOfFirstItem + 1);
    setShowingTo(Math.min(indexOfLastItem, filteredData.length));
  }, [filteredData, currentPage, entriesPerPage]); // Dependencies

  // Function to merge permissions while maintaining the original structure
  const mergePermissionsWithStructure = (originalModules, permissionModules) => {
    return originalModules
      .map((moduleItem) => {
        // Find the corresponding module in the permissionModules
        const permissionModule = permissionModules.find(
          (permModule) => permModule.module === moduleItem.module
        );
  
        if (!permissionModule) return null; // Skip if no matching module
  
        const screens = moduleItem.screens
          .map((screen) => {
            const permission = permissionModule.screens.find(
              (permScreen) => permScreen.screen_name === screen.name
            );
  
            if (!permission) return null; // Skip if no matching screen
  
            return {
              ...screen,
              ...permission, // Merge permissions if found
            };
          })
          .filter(Boolean); // Remove null values (unmatched screens)
  
        if (screens.length === 0) return null; // Skip if no screens matched
  
        return {
          ...moduleItem,
          screens, // Include only matched screens
        };
      })
      .filter(Boolean); // Remove null values (unmatched modules)
  };

  function get_privilages() {
    const payload = {
      user_id: location.state.user_id,
    };

  //   module_with_screen.forEach(module => {
  //     module.screens = module.screens.map(screen => ({
  //         ...screen,
  //         no_access: screen.no_access === null ? true : screen.no_access,
  //         read: screen.read === null ? false : screen.read,
  //         write: screen.write === null ? false : screen.write,
  //     }));
  //   });

  //   const updatedModuleWithScreen = module_with_screen.map(module => ({
  //     ...module,
  //     screens: module.screens.map(screen => ({
  //         ...screen,
  //         no_access: screen.no_access === null ? false : screen.no_access,
  //         read: screen.read === null ? false : screen.read,
  //         write: screen.write === null ? false : screen.write,
  //     }))
  // }));

    // console.log("module_with_screen", module_with_screen);

    // Start loading before API call
    fetch_privilages(payload)
      .then((res) => {
        if (res.data.data.length > 0) {
          if (res.data.data?.[0]?.module_list?.length > 0) {
            setScreen_Module(res.data.data?.[0]?.module_list);
            set_privilage_uuid({
              uuid: res.data.data[0].uuid,
            });

            const updatedScreenModules = mergePermissionsWithStructure(
              module_with_screen,
              res.data.data?.[0]?.module_list
            );

            const updatedModuleWithScreen = updatedScreenModules.map(module => ({
              ...module,
              screens: module.screens.map(screen => ({
                  ...screen,
                  no_access: (screen.read === null && screen.write === null && screen.no_access === null) 
                      ? true 
                      : screen.no_access
              }))
          }));

            // console.log("updatedScreenModules", updatedScreenModules);

            // setCurr_Data(updatedScreenModules);
            setFilteredData(updatedModuleWithScreen);
          } else {
            setFilteredData(module_with_screen);
          }
        } else {
          set_privilage_uuid(null);
          setFilteredData(module_with_screen);
        }
      })
      .catch((err) => {
        // console.log("err", err);
        if (err.response.status == 404) {
          setFilteredData(module_with_screen);
        }
      })
      .finally(() => {
        setLoading(false);
        // Stop loading after API call is done
      });
  }

  // Case-insensitive search function
  const handleSearch = () => {
    setLoading(true);
    if (searchValue.length > 0 && searchValue.trim()) {
      const newFilteredData = screen_module.filter((item) =>
        item.module.toLowerCase().includes(searchValue.toLowerCase())
      );

      setCurr_Data(newFilteredData);
      setFilteredData(newFilteredData);
      setLoading(false);
    } else {
      get_privilages();
      setLoading(false); // Reset to original data when search is cleared
    }
    setCurrentPage(1); // Reset to first page after search
    setEntriesPerPage(5);
  };

  // Handle page change (Previous and Next)
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // Handle entries per page change
  const handleEntriesPerPageChange = (e) => {
    setActiveModule(null);
    setEntriesPerPage(Number(e.target.value)); // Update items per page
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const handleCheckboxChange = (ind, type, modName, scrName) => {
    // console.log(`ind - ${ind} ------ type - ${type} --------- modName - ${modName}`);
    const updatedData = filteredData.map((item) => {
      if (item.module === modName) {
        const updatedScreens = item.screens.map((itm, idx) => {
          if (itm.name === scrName) {
            // console.log(`itm.name - ${itm.name} scrName - ${scrName}`);
            let updatedItem = { ...itm };
            // console.log("updatedItem", updatedItem);

            if (type === "read") {
              updatedItem.read = !updatedItem.read;
              updatedItem.no_access = false;
              updatedItem.write = false;

              // Reset all to null if none are selected
              if (
                !updatedItem.read &&
                !updatedItem.write &&
                !updatedItem.no_access
              ) {
                updatedItem.read =
                  updatedItem.write =
                  updatedItem.no_access =
                    null;
              }
            } else if (type === "write") {
              updatedItem.write = !updatedItem.write;
              if (updatedItem.write) {
                updatedItem.read = true;
                updatedItem.no_access = false;
              }

              // Reset all to null if none are selected
              if (
                !updatedItem.read &&
                !updatedItem.write &&
                !updatedItem.no_access
              ) {
                updatedItem.read =
                  updatedItem.write =
                  updatedItem.no_access =
                    null;
              }
            } else if (type === "no_access") {
              updatedItem.no_access = !updatedItem.no_access;
              if (updatedItem.no_access) {
                updatedItem.read = false;
                updatedItem.write = false;
              }

              // Reset all to null if none are selected
              if (
                !updatedItem.read &&
                !updatedItem.write &&
                !updatedItem.no_access
              ) {
                updatedItem.read =
                  updatedItem.write =
                  updatedItem.no_access =
                    null;
              }
            }
            return updatedItem;
          }
          return itm;
        });

        // Return updated item with modified screens
        return { ...item, screens: updatedScreens };
      }
      // Return item as is if it doesn't match the module
      return item;
    });
    setFilteredData(updatedData);
    setLoading(false);
  };

  function onSubmit() {
    let screen_get_list = [];
    let uniqueNames = new Set();

    for (let i = 0; i < filteredData.length; i++) {
      let ele = filteredData[i];

      // Filter, remove unwanted fields, and check for duplicates
      let modifiedScreens = ele.screens
        .map((screen) => {
          let { display_name, screen_name, ...rest } = screen; // Exclude unwanted fields
          return rest;
        })
        .filter((screen) => {
          if (uniqueNames.has(screen.name)) {
            return false; // Skip duplicates
          }
          uniqueNames.add(screen.name); // Track unique names
          return true; // Keep unique screens
        });

      screen_get_list.push(...modifiedScreens); // Push the unique screens
    }

    const payload = {
      user_id: location.state.user_id,
      screen_list: screen_get_list,
    };

    if (privilage_uuid && privilage_uuid?.uuid !== undefined) {
      payload.uuid = privilage_uuid.uuid;

      update_privilages(payload).then(
        (res) => {
          // console.log("res", res);
          toast.success("Permission updated successfully", {
            autoClose: 2000,
          });
          // get_privilages();
          setTimeout(() => {
            navigate(-1);
          }, [2000]);
        },
        (err) => {
          console.log("err", err);
          toast.error(err.response.data.message, {
            autoClose: 2000,
          });
        }
      );
    } else {
      create_privilages(payload).then(
        (res) => {
          toast.success("Permission created successfully", {
            autoClose: 2000,
          });
          setTimeout(() => {
            navigate(-1);
          }, [2000]);
          // get_privilages();
        },
        (err) => {
          console.log("err", err);
          toast.error(err.response.data.message, {
            autoClose: 2000,
          });
        }
      );
    }
  }

  const [activeModule, setActiveModule] = useState(null);

  useEffect(() => {
    if (curr_data && curr_data.length > 0) {
      if(activeModule == null){
        setActiveModule(curr_data?.[0]?.module); // Set the first module as the default active module
      } else {
        const latest_selected = curr_data.filter((item) => item.module == activeModule);
        setActiveModule(latest_selected?.[0]?.module);
      }
    }
  }, [curr_data]);

  const handleModuleClick = (module) => {
    setActiveModule(module); // Update the active module
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="ScreenAccess mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_revise_supplier_quotation
                ? constLabel.lbl_revise_supplier_quotation
                : "Screen Access"}
            </p>
          </div>
          <div className="card-body">
            <div className="container-fluid px-4 py-2">
              <div className="row">
                <div className="col-12 col-md-5">
                  <input
                    id="search_input"
                    className="form-control"
                    placeholder="Search module"
                    onChange={(e) => {
                      // console.log("e", e);
                        setSearchValue(e.target.value);
                        setCurrentPage(1);
                    }}
                    value={searchValue}
                  />
                </div>

                <div className="col-12 col-md-3">
                  <button
                    onClick={() => handleSearch()}
                    className="btn button-primary px-4"
                  >
                    Search
                  </button>
                </div>

                {loading ? (
                  <div className="text-center mt-4">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : curr_data?.length > 0 ? (
                  <>
                    <div className="row mt-4">
                      <div className="col-3">
                        <div className="list-group" id="list-tab" role="tablist">
                          {curr_data.map((mod, modIndex) => (
                            <a
                              key={modIndex}
                              className={`list-group-item list-group-item-action cursor_pointer ${
                                activeModule === mod.module ? "button-primary" : ""
                              }`}
                              onClick={() => handleModuleClick(mod.module)}
                              role="tab"
                            >
                              {mod.module}
                            </a>
                          ))}
                        </div>
                      </div>
                      <div className="col-9">
                        <div className="tab-content" id="nav-tabContent">
                          {curr_data.map(
                            (mod) =>
                              activeModule === mod.module && (
                                <div
                                  key={mod.module}
                                  className="tab-pane fade show active"
                                  role="tabpanel"
                                >
                                  <div className="table-responsive">
                                    <table className="table table-bordered">
                                      <thead className="table-grey">
                                        <tr>
                                          <th>{"Sr. No"}</th>
                                          <th>{"Screen Name"}</th>
                                          <th>{"Read"}</th>
                                          <th>{"Write"}</th>
                                          <th>{"No Access"}</th>
                                        </tr>
                                      </thead>
                                      <tbody className="roboto">
                                        {mod.screens.map((screen, screenIndex) => (
                                          <tr key={screenIndex}>
                                            <td>{screenIndex + 1}</td>
                                            <td>{screen.screen_name}</td>
                                            <td>
                                              <input
                                                className="form-check-input me-2"
                                                type="checkbox"
                                                checked={screen.read ?? false}
                                                onChange={() =>
                                                  handleCheckboxChange(
                                                    screenIndex,
                                                    "read",
                                                    mod.module,
                                                    screen.screen_name
                                                  )
                                                }
                                              />
                                            </td>
                                            <td>
                                              <input
                                                className="form-check-input me-2"
                                                type="checkbox"
                                                checked={screen.write ?? false}
                                                onChange={() =>
                                                  handleCheckboxChange(
                                                    screenIndex,
                                                    "write",
                                                    mod.module,
                                                    screen.screen_name
                                                  )
                                                }
                                              />
                                            </td>
                                            <td>
                                              <input
                                                className="form-check-input me-2"
                                                type="checkbox"
                                                checked={screen.no_access ?? false}
                                                onChange={() =>
                                                  handleCheckboxChange(
                                                    screenIndex,
                                                    "no_access",
                                                    mod.module,
                                                    screen.screen_name
                                                  )
                                                }
                                              />
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="d-flex justify-content-end">
                                    <button
                                      className="btn btn-grey px-4 ms-0 ms-md-2"
                                      onClick={() => onSubmit()}
                                    >
                                      {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
                                    </button>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                    <hr className="my-2"/>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="mb-0">
                        Show
                        <select
                          className="mx-1"
                          value={entriesPerPage}
                          onChange={handleEntriesPerPageChange}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="75">75</option>
                          <option value="100">100</option>
                        </select>
                        Entries
                      </p>
                      <p className="mb-0">{`Showing ${showingFrom} to ${showingTo} of ${totalEntries} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={currentPage === 1}
                          onClick={() => {
                            {
                              setActiveModule(null);
                              handlePageChange(currentPage - 1);
                              setSrNo(
                                (prevC) => prevC - parseInt(entriesPerPage)
                              );
                            }
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{currentPage}</Pagination.Item>

                        <Pagination.Next
                          disabled={currentPage === totalPages}
                          onClick={() => {
                            setActiveModule(null);
                            handlePageChange(currentPage + 1);
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  </>
                ) : (
                  <div className="alert alert-danger mb-0 text-center my-4" role="alert">
                    {"No Screens Found!"}
                  </div>
                )}

                {/* {loading == true ? (
                  <div className="text-center  mt-4">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : curr_data?.length > 0 ? (
                  <>
                    <Accordion defaultActiveKey="0" className="mt-3">
                      {curr_data.map((mod, modIndex) => (
                        <Accordion.Item key={modIndex} eventKey={modIndex}>
                          <Accordion.Header className="roboto-bold">
                            {mod.module}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="table-responsive mt-2">
                              <table className="table table-bordered">
                                <thead className="table-grey">
                                  <tr className="">
                                    <th>{"Sr. No"}</th>
                                    <th>{"Screen Name"}</th>
                                    <th>{"Read"}</th>
                                    <th>{"Write"}</th>
                                    <th>{"No Access"}</th>
                                  </tr>
                                </thead>
                                <tbody className=" roboto">
                                  {mod.screens.map((screen, screenIndex) => (
                                    <tr key={screenIndex}>
                                      <td>{screenIndex + 1}</td>
                                      <td>{screen.screen_name}</td>
                                      <td>
                                        <input
                                          className="form-check-input me-2 "
                                          type="checkbox"
                                          checked={screen.read ?? false}
                                          onChange={() =>
                                            handleCheckboxChange(
                                              screenIndex,
                                              "read",
                                              mod.module,
                                              screen.screen_name
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="form-check-input me-2 "
                                          type="checkbox"
                                          checked={screen.write ?? false}
                                          onChange={() =>
                                            handleCheckboxChange(
                                              screenIndex,
                                              "write",
                                              mod.module,
                                              screen.screen_name
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="form-check-input me-2 "
                                          type="checkbox"
                                          checked={
                                            screen.no_access ?? false
                                          }
                                          onChange={() =>
                                            handleCheckboxChange(
                                              screenIndex,
                                              "no_access",
                                              mod.module,
                                              screen.screen_name
                                            )
                                          }
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                    <div className="d-flex justify-content-between align-itmes-center mt-4">
                      <p className="mb-0">
                        Show
                        <select
                          className="mx-1"
                          value={entriesPerPage}
                          onChange={handleEntriesPerPageChange}
                        >
                          <option value="5">5</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        Entries
                      </p>
                      <p className="mb-0">{`Showing ${showingFrom} to ${showingTo} of ${totalEntries} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={currentPage === 1}
                          onClick={() => {
                            {
                              handlePageChange(currentPage - 1);
                              setSrNo(
                                (prevC) => prevC - parseInt(entriesPerPage)
                              );
                            }
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{currentPage}</Pagination.Item>

                        <Pagination.Next
                          disabled={currentPage === totalPages}
                          onClick={() => {
                            handlePageChange(currentPage + 1);
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  </>
                ) : (
                  <div className="alert alert-danger mb-0 text-center my-4" role="alert">
                    {"No Screens Found!"}
                  </div>
                )} */}
              </div>
            </div>
          </div>

          {/* <div className="card-footer d-flex justify-content-end py-3">
            <button
              className="btn btn-grey px-4 ms-0 ms-md-2"
              onClick={() => onSubmit()}
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
            </button>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ScreenAccess;
