import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert2";
import {
  faBan,
  faPlus,
  faUpload,
  faFilter,
  faCog,
  faBars,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Pagination, Spinner, Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Eye, Trash2, Copy, FileText, Edit2, Edit } from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import DatePicker from "react-datepicker";
import {
  calMaxPage,
  convertDate,
  getLabels,
  setAccessPrivilage,
  adjustDateTime,
  formatDate5,
  convertDateYYMMDD,
  getShortReadableId,
  getFinancialYearStartDate,
  formatDateString5
} from "../../../common/Functions/CommonFunctions";
import {
  deleteJobCard,
  delete_job_card,
  fetch_production_plan,
  getJobCard,
  getOperation,
  getOperatorList,
  get_job_card,
  get_job_log_summary,
  getjobcardDataExcel,
} from "../../../api";
import { Job_Col_name } from "../../../common/Labels/labelConstant";
import { useSelector } from "react-redux";
import List from "../../../assets/images/icons/project_menu/checklist.png"
import Slide from "../../../assets/images/icons/project_menu/collapse.png"
import { useLocation } from "react-router-dom";
import AccessDenied from "../../Common/AccessDenied";
import Box from "../../../assets/images/icons/project_menu/carton-box.png";

export function JobCard() {
  let navigate = useNavigate();
  let location = useLocation();

  const [showLabel, setShowLabel] = useState(Job_Col_name);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [sea_val, setSea_val] = useState(false);
  const [opList, setOpList] = useState({ data: [], loading: false });
  const [oprtList, setOprtList] = useState({ data: [], loading: false });
  const [prodPlan, setProdPlan] = useState({ data: [], loading: false });
  const [filterConfig, setFilterConfig] = useState({
    work_order_no: "",
    job_card_no: "",
    operation_id: [],
    operator_id: [],
    prod_plan_id: [],
    efficiency: "",
  });
  const [constLabel, setConstLabel] = useState({});
  const [startDate, setStartDate] = useState(getFinancialYearStartDate(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const [sel, setSelectedRadio] = useState("");
  const [lockBtn, setLockBtn] = useState(false);
  const threeMonthsLater = new Date(); // Clone the current date
  threeMonthsLater.setMonth(threeMonthsLater.getMonth() + 3);

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
    no_access: false
  });

  const [eyeBtn, setEyeBtn] = useState(null);
  const [show, setShow] = useState(false);
  const [quickViewData, setQuickViewData] = useState({
    data: [],
    loading: false,
  });
  const [activity_detail, setActivity_Detail] = useState({total_time: null, productive_time: null, break_time: null })

  // useEffect(() => {

  //   if (location?.state) {
  //     setFilterConfig({
  //       ...filterConfig,
  //       work_order_no: location.state.work_order_no,
  //       operation_id: [location.state.operation]
  //     });

  //     setTimeout(() => {
  //       toggleNav();
  //     }, [500]);
  //   }


  // }, []);

  useEffect(() => {
    setPrivilage(setAccessPrivilage(loginData, "Job Card"));
  }, []);

  useEffect(() => {
    setConstLabel(getLabels("Production", "Job Card"));
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchJobCardList(page, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig, startDate, endDate]);

  function fetchJobCardList(pg, epp) {
    setDataList({ ...dataList, data: [], loading: true });

    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
      startDate: convertDateYYMMDD(new Date(startDate), "-"),
      endDate: convertDateYYMMDD(new Date(endDate), "-"),
    };

    // if (filterConfig.work_order_no != "") {
    //   payload.work_order_no = filterConfig.work_order_no.trim(); //Search by work card no)
    // } else {
    //   delete payload.work_order_no;
    // }

    // if (filterConfig.job_card_no != "") {
    //   payload.job_card_no = filterConfig.job_card_no.trim(); //Search by job card no)
    // } else {
    //   delete payload.job_card_no;
    // }

    // if (filterConfig.operation_id.length > 0) {
    //   payload.operation_id = filterConfig.operation_id?.[0]?.uuid;
    // } else {
    //   delete payload.operation_id;
    // }

    // if (filterConfig.operator_id.length > 0) {
    //   payload.operator_id = filterConfig.operator_id?.[0]?.uuid;
    // } else {
    //   delete payload.operator_id;
    // }

    // if (filterConfig.efficiency !== "") {
    //   payload.efficiency = filterConfig.efficiency;
    // } else {
    //   delete payload.efficiency;
    // }

    if (
      filterConfig.work_order_no !== "" ||
      filterConfig.job_card_no !== "" ||
      filterConfig.operation_id.length > 0 ||
      filterConfig.operator_id.length > 0 ||
      filterConfig.prod_plan_id.length > 0 ||
      filterConfig.efficiency !== ""
    ) {
      payload.work_order_no = filterConfig.work_order_no.trim();
      payload.job_card_no = filterConfig.job_card_no.trim();
      payload.operation_id = filterConfig.operation_id[0]?.uuid;
      payload.operator_id = filterConfig.operator_id[0]?.uuid;
      payload.prod_plan_id = filterConfig.prod_plan_id[0]?.uuid;
      payload.efficiency = filterConfig.efficiency;
      if (
        (filterConfig.work_order_no?.length > 0 ||
          filterConfig.job_card_no?.length > 0 ||
          filterConfig.operation_id?.length > 0 ||
          filterConfig.operator_id?.length > 0 ||
          filterConfig.operator_id?.length > 0 ||
          filterConfig.efficiency?.length > 0) &&
        page !== 1 &&
        sea_val == false
      ) {
        payload.pageNo = 1;
        payload.entriesPerPage = entriesPerPage;
        setPage(1);
        setSrNo(0);
      }
    } else {
      delete payload.work_order_no;
      delete payload.job_card_no;
      delete payload.operation_id;
      delete payload.operator_id;
      delete payload.prod_plan_id;
      delete payload.efficiency;
    }

    // getJobCard(payload).then(
    get_job_card(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });
        // setMaxPage(calMaxPage_new(parseInt(res.data.total), epp));

        if (
          (filterConfig.work_order_no !== "" && filterConfig.work_order_no?.length > 0) ||
          (filterConfig.job_card_no !== "" && filterConfig.job_card_no?.length > 0) ||
          (filterConfig.operation_id?.length > 0) ||
          (filterConfig.operator_id?.length > 0) ||
          (filterConfig.prod_plan_id?.length > 0) ||
          (filterConfig.efficiency !== "" && filterConfig.efficiency?.length > 0)
        ) {
          setSea_val(true);
        } else {
          setSea_val(false);
        }

        setMaxPage(calMaxPage(res.data.total));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, data: [], loading: false });

        // if(err.response.status == 400){
        //   toast.error(err.response.data.message, {
        //     autoClose: 2000,
        //   });
        //   setPriceRange({from: 0, to: 100});
        // }
      }
    );
  }

  function fetchOperatorList() {
    setOprtList({ ...oprtList, data: [], loading: true });
    getOperatorList({ designation: "Operator" }).then(
      (res) => {
        const result = res.data.data.map((item) => ({
          ...item,
          fullName:
            item.MiddleName !== null && item.MiddleName !== ""
              ? item.FirstName + " " + item.MiddleName + " " + item.LastName
              : item.FirstName + " " + item.LastName,
        }));
        setOprtList({ ...oprtList, data: result, loading: false });
      },
      (err) => {
        setOprtList({ ...oprtList, data: [], loading: false });
      }
    );
  }

  function fetchOperationList() {
    setOpList({ ...opList, data: [], loading: true });
    getOperation().then(
      (res) => {
        setOpList({ ...opList, data: res.data.data, loading: false });
      },
      (err) => {
        setOpList({ ...opList, data: [], loading: false });
      }
    );
  }

  function fetchOperatorList() {
    setOprtList({ ...oprtList, data: [], loading: true });
    getOperatorList({ designation: "Operator" }).then(
      (res) => {
        const result = res.data.data.map((item) => ({
          ...item,
          fullName:
            item.MiddleName !== null && item.MiddleName !== ""
              ? item.FirstName + " " + item.MiddleName + " " + item.LastName
              : item.FirstName + " " + item.LastName,
        }));
        setOprtList({ ...oprtList, data: result, loading: false });
      },
      (err) => {
        setOprtList({ ...oprtList, data: [], loading: false });
      }
    );
  }

  function fetchProductionPlanning() {
    setProdPlan({ ...prodPlan, data: [], loading: true });
    fetch_production_plan().then(
      (res) => {
        if(res && res.data?.data?.length > 0){
          setProdPlan({ ...prodPlan, data: res.data.data, loading: false });
        }
      },
      (err) => {
        setProdPlan({ ...prodPlan, data: [], loading: false });
      }
    );
  }

  const delJobCard = (val) => {
    // console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure to delete ? \n" + val.job_card_no,
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // deleteJobCard({ uuid: val.uuid }).then(
        delete_job_card({ uuid: val.uuid }).then(
          (res) => {
            toast.success("Job card deleted successfully", {
              autoClose: 2000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchJobCardList(page - 1, entriesPerPage);
            } else {
              fetchJobCardList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  function clearFilter() {
    let obj = {
      work_order_no: "",
      job_card_no: "",
      operation_id: [],
      operator_id: [],
      prod_plan_id: [],
      efficiency: "",
    };
    setFilterConfig({ ...obj });
    setPage(1);
    fetchJobCardList(page, entriesPerPage);
    setStartDate(getFinancialYearStartDate(new Date()));
    setEndDate(new Date());
  }

  async function goToDownload() {
    setLockBtn(true);
    // console.log("inside download excel", filterConfig);
    const payload = {
      startDate: convertDateYYMMDD(new Date(startDate), "-"),
      endDate: convertDateYYMMDD(new Date(endDate), "-"),
    };

    if (filterConfig.work_order_no != "") {
      payload.work_order_no = filterConfig.work_order_no.trim(); //Search by work card no)
    } else {
      delete payload.work_order_no;
    }

    if (filterConfig.job_card_no != "") {
      payload.job_card_no = filterConfig.job_card_no.trim(); //Search by job card no)
    } else {
      delete payload.job_card_no;
    }

    if (filterConfig.operation_id.length > 0) {
      payload.operation_id = filterConfig.operation_id?.[0]?.uuid;
    } else {
      delete payload.operation_id;
    }

    if (filterConfig.operator_id.length > 0) {
      payload.operator_id = filterConfig.operator_id?.[0]?.uuid;
    } else {
      delete payload.operator_id;
    }

    if (filterConfig.prod_plan_id.length > 0) {
      payload.prod_plan_id = filterConfig.prod_plan_id?.[0]?.uuid;
    } else {
      delete payload.prod_plan_id;
    }

    if (filterConfig.efficiency !== "") {
      payload.efficiency = filterConfig.efficiency;
    } else {
      delete payload.efficiency;
    }

    // await getProductionData(payload).then(
    await getjobcardDataExcel(payload).then(
      (res) => {
        // console.log("res", res);
        if (res) {
          // excel_download(res.data.data);
          base64ToExcel(res.data);
        }
      },
      (err) => {
        // console.log("err", err);
        toast.error(err.message, {
          autoClose: 2000,
        });
        setLockBtn(false);
      }
    );
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
    fetchOperatorList();
    fetchOperationList();
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function getPrvMonthDateFromToday() {
    const today = new Date();
    const endDate = new Date(today);
    endDate.setMonth(today.getMonth() - 1);
    return endDate;
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  const handleFromDateChange = (date) => {
    if (endDate && date > endDate) {
      return;
    }
    setStartDate(date);
  };

  const handleToDateChange = (date) => {
    if (startDate && date < startDate) {
      return;
    }
    setEndDate(date);
  };

  function handleEfficiency(e) {
    setFilterConfig({
      ...filterConfig,
      efficiency: e.target.value,
    });
    // Apply filter logic based on selected userType value
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);

      fetchOperatorList();
      fetchOperationList();
      fetchProductionPlanning();
    }
  }

  function base64ToExcel(response) {
    const { statusCode, headers, body, isBase64Encoded } = response;

    // Assume base64String contains the base64 representation of the Excel file
    const base64String = body; // Replace this with your base64 string

    // Convert the base64 string into a Uint8Array
    const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));

    // Create a Blob object from the Uint8Array
    const blob = new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a link element with download attribute
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toDateString().split(" ").join("_");
    link.download = `JobCardReport_${currentDate}.xlsx`;

    // Programmatically click on the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Cleanup: remove the link and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    setLockBtn(false);
  }

  function getShortWorkOrderNo(readableId) {
    if (!readableId) return 'None';
    const workOrderNoParts = readableId.split('-');
    return workOrderNoParts.length > 2 ? `${workOrderNoParts[0]}-${workOrderNoParts[1]}` : readableId;
  }

  async function quickViewModal(jc_uuid) {
    setQuickViewData({ ...quickViewData, data: [], loading: true });
    await get_job_log_summary({ job_card_id: jc_uuid }).then(
      (res) => {
        // console.log("quick view res", res);
        if(res && res.data.data.length > 0){
          setQuickViewData({
            ...quickViewData,
            data: res.data.data,
            loading: false,
          });
          setShow(true);
          setEyeBtn(null);
          processResponseData(res.data.data);

        } else {
          setQuickViewData({ ...quickViewData, data: [], loading: false });
        }
      },
      (err) => {
        // console.log("err", err);
        toast.error(err.response.data.message, {autoClose: 2000});
        setQuickViewData({ ...quickViewData, data: [], loading: false });
        setEyeBtn(null);
      }
    );
  }

  // Utility function to calculate time difference in minutes
  const calculateTimeDifference = (start, end) => {
    if (!start || !end) return 0;
    return (new Date(end) - new Date(start)) / (1000 * 60); // Convert milliseconds to minutes
  };

  // Utility function to format time in hh:mm format with specific rounding logic
  const formatTime = (decimalMinutes) => {
    // Convert decimal minutes to hours and minutes
    const hours = Math.floor(decimalMinutes / 60); // Calculate hours
    const minutes = Math.round(decimalMinutes % 60); // Calculate remaining minutes
  
    // Format and return the time in hh:mm
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  // Function to process activity data and update state
  const processResponseData = (response) => {
    if (!response?.length) {
      // console.error("No data found in the response");
      return;
    }

    // Initialize variables
    let startTime = null;
    let stopTime = null;
    let totalBreakTime = 0;

    response.forEach((entry, index, arr) => {
      if (entry.activity === "start" && !startTime) {
        startTime = entry.timestamp;
      }
      if (entry.activity === "stop") {
        stopTime = entry.timestamp;
      }
      if (entry.activity === "pause") {
        const resumeEntry = arr.find(
          (e, i) => i > index && e.activity === "resume" && e.jobnumber === entry.jobnumber
        );
        if (resumeEntry) {
          totalBreakTime += calculateTimeDifference(entry.timestamp, resumeEntry.timestamp);
        }
      }
    });

    // Validate startTime and stopTime
    if (!startTime || !stopTime) {
      // console.error("Missing start or stop time in the data");
      setActivity_Detail({
        total_time: null,
        productive_time: null,
        break_time: null,
      });
      return;
    }

    // Calculate times
    const totalWorkTime = calculateTimeDifference(startTime, stopTime);
    // console.log("totalWorkTime", totalWorkTime);

    const totalProductiveTime = totalWorkTime - totalBreakTime;
    // console.log("totalProductiveTime", totalProductiveTime);

    // console.log("totalBreakTime", totalBreakTime);

    // Update state with formatted times
    setActivity_Detail({
      total_time: formatTime(totalWorkTime),
      productive_time: formatTime(totalProductiveTime),
      break_time: formatTime(totalBreakTime),
    });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      {privilage.read && (
        <div id="mySidebar" className="customsidebar ">
          <a className="closebtn" onClick={() => toggleNav()}>
            ×
          </a>

          <div className=" content">
            <div className="filter row">
              <label
                className="filterLabel mb-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selectDateRange
                  ? constLabel.lbl_selectDateRange
                  : "Select Date Range"}
              </label>
              <div className="fromDate col-12 col-md-6">
                <DatePicker
                  id="fromDate"
                  className="form-control form-control-sm"
                  selected={startDate}
                  showYearDropdown
                  scrollableMonthYearDropdown
                  dateFormat="dd/MM/yyyy"
                  placeholderText="From Date"
                  onChange={(date) => handleFromDateChange(date)}
                  maxDate={threeMonthsLater}
                />
              </div>

              <div className="toDate col-12 col-md-6">
                <DatePicker
                  id="toDate"
                  className="form-control form-control-sm"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  selected={endDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="To Date"
                  onChange={(date) => handleToDateChange(date)}
                  maxDate={threeMonthsLater}
                />
              </div>
            </div>

            <div className="mt-2">
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_work_order_no
                  ? constLabel?.lbl_work_order_no
                  : "Work Order No"}
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by work order no"
                value={filterConfig.work_order_no}
                onChange={(val) => {
                  const inputValue = val.target.value;
                  if (!inputValue.trim()) {
                    // Input contains only whitespace, don't trigger the search.
                    if (filterConfig.work_order_no.length == 1) {
                      setFilterConfig({ ...filterConfig, work_order_no: "" });
                    }

                    if (inputValue.length == 0) {
                      setFilterConfig({ ...filterConfig, work_order_no: "" });
                    }
                  } else {
                    setFilterConfig({
                      ...filterConfig,
                      work_order_no: inputValue,
                    });
                  }
                }}
              />
            </div>

            <div className="mt-2">
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_job_card_no
                  ? constLabel?.lbl_job_card_no
                  : "Job Card No"}
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by job card no"
                // value={filterConfig.job_card_no != ""  ? filterConfig.job_card_no : ""}
                value={filterConfig.job_card_no}
                onChange={(val) => {
                  const inputValue = val.target.value;
                  if (!inputValue.trim()) {
                    // Input contains only whitespace, don't trigger the search.
                    if (filterConfig.job_card_no.length == 1) {
                      setFilterConfig({ ...filterConfig, job_card_no: "" });
                    }

                    if (inputValue.length == 0) {
                      setFilterConfig({ ...filterConfig, job_card_no: "" });
                    }
                  } else {
                    setFilterConfig({
                      ...filterConfig,
                      job_card_no: inputValue,
                    });
                  }
                }}
              />
            </div>

            <div className="mt-2">
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_operation
                  ? constLabel.lbl_operation
                  : "Operation"}
              </label>

              <Typeahead
                id="basic-typeahead-single"
                labelKey="operationName"
                name="operation"
                multiple={false}
                options={opList.data}
                isLoading={opList.loading}
                selected={filterConfig.operation_id}
                onChange={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, operation_id: val });
                  } else {
                    setFilterConfig({ ...filterConfig, operation_id: [] });
                  }
                }}
                placeholder="Search by operation"
              />
            </div>

            <div className="mt-2">
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_operator
                  ? constLabel.lbl_operator
                  : "Operator"}
              </label>

              <Typeahead
                id="basic-typeahead-single"
                labelKey="fullName"
                name="operator"
                multiple={false}
                options={oprtList.data}
                isLoading={oprtList.loading}
                selected={filterConfig.operator_id}
                onChange={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, operator_id: val });
                  } else {
                    setFilterConfig({ ...filterConfig, operator_id: [] });
                  }
                }}
                placeholder="Search by operator"
              />
            </div>

            <div className="mt-2">
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_production_plan
                  ? constLabel.lbl_production_plan
                  : "Production Plan"}
              </label>

              <Typeahead
                id="basic-typeahead-single"
                labelKey="prod_plan_id"
                name="prod_plan_id"
                multiple={false}
                options={prodPlan.data}
                isLoading={prodPlan.loading}
                selected={filterConfig.prod_plan_id}
                onChange={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, prod_plan_id: val });
                  } else {
                    setFilterConfig({ ...filterConfig, prod_plan_id: [] });
                  }
                }}
                placeholder="Search by production plan"
              />
            </div>

            <div className="mt-2">
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_efficiency
                  ? constLabel.lbl_efficiency
                  : "Efficiency"}
              </label>
              <div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="efficiency"
                    id="inlineRadio1"
                    value="91-100"
                    checked={filterConfig.efficiency === "91-100"}
                    onChange={handleEfficiency}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    {"91-100"}
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="efficiency"
                    id="inlineRadio2"
                    value="81-90"
                    checked={filterConfig.efficiency === "81-90"}
                    onChange={handleEfficiency}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    {"81-90"}
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="userType"
                    id="inlineRadio3"
                    value="0-80"
                    checked={filterConfig.efficiency === "0-80"}
                    onChange={handleEfficiency}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio3">
                    {"0-80"}
                  </label>
                </div>
              </div>
            </div>


            <div className="my-3 d-flex justify-content-end">
              <button
                onClick={() => clearFilter()}
                className="btn btn-sm btn-yellow "
              >
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {constLabel?.lbl_clear_filter
                  ? constLabel.lbl_clear_filter
                  : "Clear Filter"}
              </button>
            </div>
          </div>
        </div>
      )}

      {!privilage.read ?
        <AccessDenied />
        :
        <div className="min-vh-100" id="root_div_main">
          <div className="content-wrapper">
            <div className="card border-0">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="d-md-flex justify-content-start col-12 col-md-6">
                    {/* <FontAwesomeIcon
                    onClick={() => toggleNav()}
                    className="cursor_pointer mb-2"
                    style={{
                      color: "Black",
                      marginRight: "10px",
                    }}
                    icon={faBars}
                    size="lg"
                  /> */}
                    <Tooltip id={"open-tooltip"} place="top" />
                    <img
                      src={Slide}
                      height="32"
                      width="32"
                      className="cursor_pointer my-1 me-2"
                      onClick={() => toggleNav()}
                      data-tooltip-id={"open-tooltip"}
                      data-tooltip-content={"Filters"}
                    />
                    <h1 className="bold">
                      {constLabel?.lbl_job_card
                        ? constLabel.lbl_job_card
                        : "Job Card"}
                    </h1>
                  </div>
                  <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                    {loginData.loginData?.data?.response.role_name !== "client_admin" &&
                      loginData.loginData?.data?.response.role_name !== "admin" ? (
                      privilage.report == true ? (
                        <button
                          className="btn btn-light-grey me-0 me-2 ms-0 ms-md-2"
                          // onClick={() => downloadExcel()}
                          disabled={lockBtn}
                          onClick={() => goToDownload()}
                        >
                          <FontAwesomeIcon
                            style={{ color: "#fff" }}
                            icon={faDownload}
                            size="sm"
                          />{" "}
                          {constLabel?.lbl_work_order_report
                            ? constLabel.lbl_work_order_report
                            : "Job Card Report"}{" "}
                          {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
                        </button>
                      ) : null
                    ) : (
                      <button
                        className="btn btn-light-grey me-0 me-2 ms-0 ms-md-2"
                        // onClick={() => downloadExcel()}
                        disabled={lockBtn}
                        onClick={() => goToDownload()}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faDownload}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_job_card_report
                          ? constLabel.lbl_job_card_report
                          : "Job Card Report"}{" "}
                        {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
                      </button>
                    )}
                    {privilage.write && (
                      <button
                        className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                        onClick={() => navigate(`/pcms/create-job-card`)}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff", marginRight: "6px" }}
                          icon={faPlus}
                          size="sm"
                        />
                        {constLabel?.lbl_create_job_card
                          ? constLabel.lbl_create_job_card
                          : "Create Job Card"}
                      </button>
                    )}

                    <Tooltip id={"show-tooltip"} place="top" />
                    <div
                      className="dropdown"
                      data-tooltip-id={"show-tooltip"}
                      data-tooltip-content={"Show / Hide Columns"}
                    >
                      <div
                        className="d-md-flex justify-content-start align-items-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <FontAwesomeIcon icon={faCog} size="lg" /> */}
                        {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                        <img
                          src={List}
                          height="32"
                          width="32"
                          className="cursor_pointer"
                        />
                      </div>

                      <ul className="dropdown-menu px-2">
                        {showLabel !== undefined && showLabel.length > 0
                          ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>

                {dataList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : dataList.data.length > 0 ? (
                  <>
                    <div>
                      <table className="table mt-2 table-responsive table-bordered">
                        <thead className="table-grey roboto-bold">
                          <tr className="">
                            <th scope="col">{"Sr.No"}</th>
                            {showLabel.map((v, i) =>
                              v.label === "Plan Id" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_plan_id
                                    ? constLabel.lbl_plan_id
                                    : "Plan Id"}
                                </th>
                              ) : v.label === "Work Order No" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_workorder_no
                                    ? constLabel.lbl_workorder_no
                                    : "Work Order No"}
                                </th>
                              ) : v.label === "Work Order Qty" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_workorder_qty
                                    ? constLabel.lbl_workorder_qty
                                    : "Work Order Qty"}
                                </th>
                              ) : v.label === "Job Card No" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_job_card_no
                                    ? constLabel.lbl_job_card_no
                                    : "Job Card No"}
                                </th>
                              ) : v.label === "Lot No" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_lot_no
                                    ? constLabel?.lbl_lot_no
                                    : "Lot No"}
                                </th>
                              ) : v.label === "Product Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_product_name
                                    ? constLabel.lbl_product_name
                                    : "Product Name"}
                                </th>
                              ) : v.label === "Product Operation" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_product_operation
                                    ? constLabel.lbl_product_operation
                                    : "Product Operation"}
                                </th>
                              ) : v.label === "Machine" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_machine
                                    ? constLabel.lbl_machine
                                    : "Machine"}
                                </th>
                              ) : v.label === "Operator" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_operator
                                    ? constLabel.lbl_operator
                                    : "Operator"}
                                </th>
                              ) : v.label === "Start Date" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_start_date
                                    ? constLabel.lbl_start_date
                                    : "Start Date"}
                                </th>
                              ) : v.label === "End Date" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_end_date
                                    ? constLabel.lbl_end_date
                                    : "End Date"}
                                </th>
                              ) : v.label === "Planned Quantity" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_planned_quantity
                                    ? constLabel.lbl_planned_quantity
                                    : "Planned Quantity"}
                                </th>
                              ) : v.label === "Actual Quantity" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_actual_quantity
                                    ? constLabel.lbl_actual_quantity
                                    : "Actual Quantity"}
                                </th>
                              ) : v.label === "Production Efficiency" &&
                                v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_production_efficiency
                                    ? constLabel.lbl_production_efficiency
                                    : "Production Efficiency"}{" "}
                                  {"%"}
                                </th>
                              ) : v.label === "NC Qty" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_nc_qty
                                    ? constLabel.lbl_nc_qty
                                    : "NC Qty"}
                                </th>
                              ) : v.label === "Quality Efficiency" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_quality_efficiency
                                    ? constLabel.lbl_quality_efficiency
                                    : "Quality Efficiency"}{" "}
                                  {"%"}
                                </th>
                              ) : v.label === "Start Time" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_start_time
                                    ? constLabel.lbl_start_time
                                    : "Start Time"}
                                </th>
                              ) : v.label === "End Time" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_end_time
                                    ? constLabel.lbl_end_time
                                    : "End Time"}
                                </th>
                              ) : v.label === "Total Time" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_total_time
                                    ? constLabel.lbl_total_time
                                    : "Total Time"}
                                </th>
                              ) : v.label === "Remark" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_remark
                                    ? constLabel.lbl_remark
                                    : "Remark"}
                                </th>
                              ) : null
                            )}
                            <th scope="col" width="10%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="roboto">
                          {dataList.data.map((val, ind) => (
                            <tr key={ind}>
                              <td>{parseInt(ind) + 1 + parseInt(srNo)}</td>
                              {showLabel.map((item, idx) =>
                                item.label === "Plan Id" && item.flag ? (
                                  <td key={idx}>
                                    {val.production_plan_details !== null ? (
                                      val.production_plan_details?.prod_plan_id != "" &&
                                        val.production_plan_details?.prod_plan_id !==
                                        null ? (
                                        <a
                                          href="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            navigate(
                                              "/pcms/update/production_planning",
                                              { state: { prev_data: val.production_plan_details } }
                                            );
                                          }}
                                        >
                                          {val.production_plan_details?.prod_plan_id}
                                        </a>
                                      ) : (
                                        "NA"
                                      )
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                ) : item.label === "Work Order No" && item.flag ? (
                                  <td key={idx}>
                                    {val.workorderDetails !== null ? (
                                      val.workorderDetails?.work_order_no != "" &&
                                        val.workorderDetails?.work_order_no !==
                                        null ? (
                                        <a
                                          href="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            navigate(`/pcms/job-card-board`, {
                                              state: val,
                                            });
                                          }}
                                        >
                                          {getShortReadableId(val.workorderDetails?.work_order_no)}
                                        </a>
                                      ) : (
                                        "NA"
                                      )
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                ) : item.label === "Work Order Qty" && item.flag ? (
                                  <td key={idx}>
                                    {val.workorderDetails.quantity != "" &&
                                      val.workorderDetails.quantity !== null
                                      ? val.workorderDetails.quantity
                                      : "NA"}
                                  </td>
                                ) : item.label === "Job Card No" && item.flag ? (
                                  <td key={idx}>
                                    {val.job_card_no != "" &&
                                      val.job_card_no !== null
                                      ? getShortReadableId(val.job_card_no)
                                      : "NA"}
                                  </td>
                                ) : item.label === "Lot No" && item.flag ? (
                                  <td key={idx}>
                                    {val.lotDetails !== null
                                      ? val.lotDetails?.lotId != "" &&
                                        val.lotDetails?.lotId !== null
                                        ? val.lotDetails?.lotId
                                        : "NA"
                                      : "N/A"}
                                  </td>
                                ) : item.label === "Product Name" && item.flag ? (
                                  <td key={idx}>
                                    {val.productDetail?.productName != "" &&
                                      val.productDetail?.productName !== null
                                      ? val.productDetail?.productName
                                      : "NA"}
                                  </td>
                                ) : item.label === "Product Operation" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.productOperDetails !== null && val.productOperDetails?.operationname !== null
                                      ? val.productOperDetails?.operationname
                                      : val.operationDetails?.operationName !== null
                                        ? val.operationDetails?.operationName
                                        : "NA"}
                                  </td>
                                ) : item.label === "Machine" && item.flag ? (
                                  <td key={idx}>
                                    {val.machineDetails?.machineName != "" &&
                                      val.machineDetails?.machineName !== null
                                      ? val.machineDetails?.serialNo != "" &&
                                        val.machineDetails?.serialNo !== null
                                        ? `${val.machineDetails.machineName} - ${val.machineDetails.serialNo} `
                                        : `${val.machineDetails.machineName}`
                                      : "NA"}
                                  </td>
                                ) : item.label === "Operator" && item.flag ? (
                                  <td key={idx}>
                                    {val.operatorDetails?.FirstName !== "" &&
                                      val.operatorDetails?.FirstName !== null
                                      ? val.operatorDetails?.FirstName +
                                      " " +
                                      val.operatorDetails?.LastName
                                      : "NA"}
                                  </td>
                                ) : item.label === "Start Date" && item.flag ? (
                                  <td key={idx}>
                                    {val.start_time != "" &&
                                      val.start_time != null
                                      ? formatDate5(val.start_time.replace(".000Z", ""))
                                      : "NA"}
                                  </td>
                                ) : item.label === "End Date" && item.flag ? (
                                  <td key={idx}>
                                    {val.end_time != "" && val.end_time != null
                                      ? formatDate5(
                                        val.end_time.replace(".000Z", "")
                                      )
                                      : "NA"}
                                  </td>
                                ) : item.label === "Planned Quantity" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.planned_qty !== "" &&
                                      val.planned_qty !== null
                                      ? val.planned_qty
                                      : "NA"}
                                  </td>
                                ) : item.label === "Actual Quantity" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.actual_qty !== "" &&
                                      val.actual_qty !== null
                                      ? val.actual_qty
                                      : "NA"}
                                  </td>
                                ) : item.label === "Production Efficiency" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.efficiency !== "" &&
                                      val.efficiency !== null
                                      ? val.efficiency
                                      : "NA"}
                                  </td>
                                ) : item.label === "NC Qty" && item.flag ? (
                                  <td key={idx}>
                                    {val.nc_qty !== "" && val.nc_qty !== null
                                      ? val.nc_qty
                                      : "NA"}
                                  </td>
                                ) : item.label === "Quality Efficiency" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.quality_efficiency !== "" &&
                                      val.quality_efficiency !== null
                                      ? val.quality_efficiency
                                      : "NA"}</td>
                                ) : item.label === "Start Time" && item.flag ? (
                                  <td key={idx}>
                                    {val.start_time != "" &&
                                      val.start_time != null
                                      ? new Date(
                                        adjustDateTime(val.start_time)
                                      ).toLocaleTimeString("en-US", {
                                        hour: "numeric",
                                        minute: "numeric",
                                      })
                                      : "NA"}
                                  </td>
                                ) : item.label === "End Time" && item.flag ? (
                                  <td key={idx}>
                                    {val.end_time != "" && val.end_time != null
                                      ? new Date(
                                        adjustDateTime(val.end_time)
                                      ).toLocaleTimeString("en-US", {
                                        hour: "numeric",
                                        minute: "numeric",
                                      })
                                      : "NA"}
                                  </td>
                                ) : item.label === "Total Time" && item.flag ? (
                                  <td key={idx}>
                                    {val.total_time_hrs != "" &&
                                      val.total_time_hrs != null
                                      ? val.total_time_hrs
                                      : "NA"}
                                  </td>
                                ) : item.label === "Remark" && item.flag ? (
                                  <td key={idx}>
                                    {val.remarks != "" && val.remarks != null
                                      ? val.remarks
                                      : "NA"}
                                  </td>
                                ) : null
                              )}
                              <td>
                                {privilage.write && (
                                  <>
                                    <Tooltip
                                      id={"edit-tooltip" + ind}
                                      place="top"
                                    />
                                    <Edit
                                      data-tooltip-id={"edit-tooltip" + ind}
                                      data-tooltip-content={
                                        constLabel?.lbl_edit_job_card
                                          ? constLabel.lbl_edit_job_card
                                          : "Edit Job Card"
                                      }
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20}
                                      onClick={() =>
                                        navigate("/pcms/update-job-card", {
                                          state: val,
                                        })
                                      }
                                    />
                                  </>
                                )}

                                {privilage.delete && (
                                  <>
                                    <Tooltip id={"delete-tooltip" + ind} place="top" />
                                    <Trash2
                                      data-tooltip-id={"delete-tooltip" + ind}
                                      data-tooltip-content={
                                        constLabel?.lbl_delete_job_card
                                          ? constLabel.lbl_delete_job_card
                                          : "Delete Job Card"
                                      }
                                      className="menu_icon_red cursor_pointer me-2"
                                      size={20}
                                      onClick={() => delJobCard(val)}
                                    />
                                  </>
                                )}

                                {privilage.read && (
                                      <>
                                        <Tooltip
                                          id={"view-tooltip" + ind}
                                          place="top"
                                        />
                                        {eyeBtn == ind ? (
                                          <Spinner
                                            animation="border"
                                            size="sm"
                                            className="me-2"
                                          />
                                        ) : (
                                          <Eye
                                            data-tooltip-id={"view-tooltip" + ind}
                                            data-tooltip-content={
                                              constLabel?.lbl_job_log_view
                                                ? constLabel.lbl_job_log_view
                                                : "Job Log View"
                                            }
                                            className="menu_icon_grey cursor_pointer"
                                            size={20}
                                            onClick={() => {
                                              quickViewModal(val.uuid);
                                              setEyeBtn(ind);
                                            }}
                                          />
                                        )}
                                      </>
                                    )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <p className="mb-0">
                        Show
                        <select
                          className="mx-1"
                          defaultValue={entriesPerPage}
                          onChange={(e) => {
                            setEntriesPerPage(e.target.value);
                            fetchJobCardList(1, e.target.value);
                          }}
                        >
                          <option value={"10"}>10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        Entries
                      </p>
                      <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                        } to ${Math.min(
                          parseInt(entriesPerPage) + parseInt(srNo),
                          dataList.totalRecords
                        )} of ${dataList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchJobCardList(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                              maxPage === 0 ||
                              entriesPerPage > dataList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            fetchJobCardList(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  </>
                ) : (
                  <div
                    className="alert alert-danger mb-0 text-center my-4"
                    role="alert"
                  >
                    {"No Job Card Found !"}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }

      <Modal show={show} onHide={() => setShow(false)} size="xl">
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel.lbl_job_log_view ? constLabel.lbl_job_log_view : "Job Log View"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center flex-wrap my-1 py-1">
            {/* Cards Section */}
            <div className="d-flex flex-row flex-wrap">
              {/* Dispatch Qty Card */}
              <div className="card border-1 p-0 mx-2 my-1 py-1" style={{ width: "10rem" }}>
                <div className="d-flex align-items-center">
                  <div className="px-1">
                    <img
                      src={Box}
                      className="img-fluid svg-img"
                      alt="Card"
                      width={40}
                      height={40}
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-center ms-2 py-1">
                    <h6 className="card-subtitle text_muted mb-1">
                      {constLabel?.lbl_total_time || `Total Time`}
                    </h6>
                    <h6 className="roboto-bold">
                      {activity_detail?.total_time}
                    </h6>
                  </div>
                </div>
              </div>

              {/* Work Order Qty Card */}
              <div className="card border-1 p-0 mx-2 my-1 py-1" style={{ width: "12rem" }}>
                <div className="d-flex align-items-center">
                  <div className="px-1">
                    <img
                      src={Box}
                      className="img-fluid svg-img"
                      alt="Card"
                      width={40}
                      height={40}
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-center ms-2 py-1">
                    <h6 className="card-subtitle text_muted mb-1">
                      {constLabel?.lbl_productive_time || `Productive Time`}
                    </h6>
                    <h6 className="roboto-bold">
                      {activity_detail?.productive_time}
                    </h6>
                  </div>
                </div>
              </div>

              {/* NC Qty Card */}
              <div className="card border-1 p-0 mx-2 my-1 py-1" style={{ width: "10rem" }}>
                <div className="d-flex align-items-center">
                  <div className="px-1">
                    <img
                      src={Box}
                      className="img-fluid svg-img"
                      alt="Card"
                      width={40}
                      height={40}
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-center ms-2 py-1">
                    <h6 className="card-subtitle text_muted mb-1">
                      {constLabel?.lbl_break_time || `Break Time`}
                    </h6>
                    <h6 className="roboto-bold">
                      {activity_detail?.break_time}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {quickViewData.loading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : quickViewData.data?.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className="table-grey roboto-bold">
                  <tr className="">
                    <th scope="col">{"Action"}</th>
                    <th scope="col">{"Reason"}</th>
                    <th scope="col">{"Timestamp"}</th>
                  </tr>
                </thead>
                <tbody className="roboto">
                  {quickViewData.data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.activity.charAt(0).toUpperCase() + item.activity.slice(1)}</td>
                      <td>{item.reason || "N/A"}</td>
                      <td>{formatDateString5(item.timestamp)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-center text-danger mb-0">{"No data found !"}</p>
          )}
        </Modal.Body>
      </Modal>

    </React.Fragment>
  );
}
