import { parse, format } from 'date-fns'
// import nameConstant from "../Labels/nameConstant.json"
import routes from "../../Routes/routes";
import moment from 'moment';
import SweetAlert from "sweetalert2";

export const convertDateYYMMDD = (d, divider) => {


  const isYYYYMMDDFormat = /^\d{2}-\d{2}-\d{4}$/.test(d) ||
    /^\d{1}-\d{2}-\d{4}$/.test(d) ||
    /^\d{2}-\d{1}-\d{4}$/.test(d) ||
    /^\d{1}-\d{1}-\d{4}$/.test(d);

  if (isYYYYMMDDFormat) {


    const [day, month, year] = d.split('-');
    const originalDate = new Date(`${year}-${month}-${day}`);
    return originalDate.toISOString().split('T')[0];
  }

  var weekDate = new Date(d)
  let date = weekDate.getDate()
  let month = weekDate.getMonth() + 1
  const year = weekDate.getFullYear()

  if (month < 10) {
    month = '0' + month
  }
  if (date < 10) {
    date = '0' + date
  }

  return year + divider + month + divider + date
}

export function newDateTimeForm(date, time) {
  // console.log("date", date);
  // console.log("time", time);
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  // Extracting time components
  let hours = time.getHours().toString().padStart(2, "0");
  let minutes = time.getMinutes().toString().padStart(2, "0");
  let seconds = time.getSeconds().toString().padStart(2, "0");

  // Creating start_time in the desired format (assuming UTC time for the example)
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

export const convertDateYYMMDDUsingLib = (d) => {
  // Parse the date string into a Date object
  const parsedDate = parse(d, 'dd-MM-yyyy', new Date())

  // Format the Date object into the desired format
  const formattedDate = format(parsedDate, 'yyyy-MM-dd')


  return formattedDate
}

export const convertDate = (d, divider) => {

  const isYYYYMMDDFormat = /^\d{4}-\d{2}-\d{2}$/.test(d);
  if (!isYYYYMMDDFormat) {
    return d;
  }
  var weekDate = new Date(d);
  const date = weekDate.getDate();
  const month = weekDate.getMonth() + 1;
  const year = weekDate.getFullYear();

  const formattedDate = (date < 10 ? '0' : '') + date;
  const formattedMonth = (month < 10 ? '0' : '') + month;

  return formattedDate + divider + formattedMonth + divider + year;
}

export function convertDateFormat(inputDate) {
  if (inputDate !== undefined) {
    const parts = inputDate.split('-'); // Split the input date
    const year = parts[2];
    const month = parts[1].length === 1 ? '0' + parts[1] : parts[1]; // Add leading zero if needed
    const day = parts[0].length === 1 ? '0' + parts[0] : parts[0]; // Add leading zero if needed

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
}

export function convertDateYYMMDDHHMM(date) {
  if (date !== undefined) {
    // Extract the year, month, day, hour, and minute
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');

    // Create the formatted date string
    const formattedDate = `${year}-${month}-${day} ${hour}:${minute}`;
    return formattedDate;
  }
}

export const calMaxPage = (counts) => {
  const maxPage = Math.floor(counts / 10)
  if (counts % 10 === 0) {
    return maxPage
  } else {
    return maxPage + 1
  }
}

export const calMaxPage_new = (counts, entriesPerPage) => {

  const maxPage = Math.ceil(counts / entriesPerPage)

  return maxPage;
}

export function timeDuration(startTime, endTime) {
  const start = startTime.split(':').map(Number)
  const end = endTime.split(':').map(Number)
  const startInMinutes = start[0] * 60 + start[1]
  const endInMinutes = end[0] * 60 + end[1]
  const duration = endInMinutes - startInMinutes
  const hours = Math.floor(duration / 60)
  const minutes = duration % 60
  return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`
}

export function timeDuration2(start, end) {
  const date1 = start
  const date2 = end

  const durationInMs = date2 - date1
  const durationInMinutes = durationInMs / (1000 * 60)
  const hours = Math.floor(durationInMinutes / 60)
  const minutes = durationInMinutes % 60
  const durationFormatted = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`


  return durationFormatted
}

export async function downloadAsJSON(val) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))


  val.customerId = userInfo.user_id

  if (Object.keys(val).length > 0) {
    const json = await JSON.stringify(val)

    const blob = new Blob([json], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const fileName = val.name + '_' + val.vesselname + '.json'
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.click()
    URL.revokeObjectURL(url)
  } else {
    return null
  }
}


export function splitStringFromQuestionMark(inputString) {
  const questionMarkIndex = inputString.indexOf('?');
  if (questionMarkIndex !== -1) {
    const substringAfterQuestionMark = inputString.substring(questionMarkIndex);
    return substringAfterQuestionMark;
  } else {
    return inputString;
  }
}


export const generateAndDownloadCSV = (jsonData) => {
  if (!Array.isArray(jsonData) || jsonData.length === 0) return;

  const csvRows = jsonData.map((data) =>
    Object.values(data)
      .map((value) => `"${value}"`)
      .join(",")
  );

  const csvContent = csvRows.join("\n");

  const blob = new Blob([csvContent], { type: "text/csv" });

  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = "Operation_category.csv";

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
};


export function convertImageTobase64(file) {
  const reader = new FileReader();

  reader.onload = function (e) {
    const base64String = e.target.result;

    return base64String
  };

}


export function generateRandomString(length) {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    randomString += charset.charAt(randomIndex);
  }

  return randomString;
}



export function convert_date_and_time(date, time) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const hours = time.getHours();
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();
  const milliseconds = time.getMilliseconds();

  return new Date(year, month, day, hours, minutes, seconds, milliseconds)
}




export var settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "60px",
  slidesToShow: 4,
  slidesToScroll: 1,
  speed: 500,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
};


export function convertUrl(url) {

  var result;

  if (url !== null || url !== undefined) {
    result = url.split("?");
  }

  return result[0];
}


export function amountInWords(amount) {
  const units = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
  const teens = ["", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
  const tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
  const thousands = ["", "Thousand", "Million", "Billion"];

  function toWords(num) {
    if (num === 0) return "Zero";
    if (num < 10) return units[num];
    if (num < 20) return teens[num - 10];
    if (num < 100) return tens[Math.floor(num / 10)] + " " + toWords(num % 10);
    return units[Math.floor(num / 100)] + " Hundred " + toWords(num % 100);
  }

  function groupToWords(num, idx) {
    if (num === 0) return "";
    if (num < 1000) return toWords(num) + " " + thousands[idx];
    return toWords(Math.floor(num / 1000)) + " " + thousands[idx] + " " + groupToWords(num % 1000, idx + 1);
  }

  return groupToWords(amount, 0);
}


export function isExcelFile(fileName) {
  // Define an array of valid Excel extensions
  const excelExtensions = ['.xlsx', '.xls'];

  // Get the file extension from the file name
  const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

  // Check if the file extension is in the array of valid Excel extensions
  return excelExtensions.includes(`.${fileExtension.toLowerCase()}`);
}

/* Function is used for the filter module wise screens then screen wise const label list */
export function getLabels(moduleName, screenName) {
  if (moduleName !== "" && screenName !== "") {
    /*
    # Niranjan 04-12-2023
    # Get Module Name and Screen List by compare Module Name
    # Get Screen Name and Label List by compare Screen Name
 */
    const nameConstant = JSON.parse(localStorage.getItem("lablelist"))
    if (nameConstant?.length > 0) {
      const result = nameConstant.find(
        (module) => module.module_name === moduleName
      );

      if (result !== undefined && Object.keys(result).length > 0) {
        const res_list = result.screen_list.find(
          (item) => item.screen_name === screenName
        );

        return res_list !== undefined ? res_list.labelList : {};
      } else {
        return {};
      }
    } else {
      return {};
    }

  } else {
    return {};
  }
}



function handle_screen_access(permissions, screen_access) {
  console.log("permissions", permissions);
  console.log("screen_access", screen_access);

  if (screen_access.no_access && !screen_access.write && !screen_access.read) {
    permissions.read = false
    permissions.write = false
    permissions.view = false
    permissions.delete = false
    permissions.report = false
    permissions.no_access = screen_access.no_access

  } else if (screen_access.read && screen_access.write && !screen_access.no_access) {
    permissions.read = screen_access.read
    permissions.write = screen_access.write
    permissions.view = true
    permissions.delete = true
    permissions.report = true
    permissions.no_access = screen_access.no_access

  } else if (screen_access.read && !screen_access.write) {
    permissions.read = screen_access.read
    permissions.write = screen_access.write
    permissions.view = true
    permissions.delete = false
    permissions.report = true
    permissions.no_access = screen_access.no_access

  }
  return permissions;
}


// para2 here is actually the name of module or screen from where this function is getting called.
export function setAccessPrivilage(loginData, para2) {
  // console.log("loginData", loginData);
  // console.log("para2", para2);
  const permissions = {
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
    no_access: false
  }

  if (loginData.role_name.trim() === "admin" ||
    loginData.role_name.trim() === "client_admin") {
    return permissions;
  } else {
    for (let i = 0; i < loginData.module_list.length; i++) {
      console.log(`${loginData.module_list[i].module} === ${para2}`);
      console.log(loginData.module_list[i].module === para2);
      if (loginData.module_list[i].module === para2) {

        const foundedScreen = loginData.module_list[i].screens.find(
          (val) => val.screen_name.trim() == para2.trim());
        // console.log("foundedScreen", foundedScreen);

        if (foundedScreen && foundedScreen.read !== null && foundedScreen.write !== null
          && foundedScreen.no_access !== null) {
          const per = handle_screen_access(permissions, foundedScreen);
          // console.log("per", per);
          return per
        }
        // console.log("loginData.module_list[i]", loginData.module_list[i]);
        return loginData.module_list[i];


      } else {
        if (loginData.module_list[i].screens !== undefined) {
          for (let j = 0; j < loginData.module_list[i].screens.length; j++) {
            if (loginData.module_list[i].screens[j].screen_name.trim().toUpperCase()
              == para2.trim().toUpperCase()) {

              const foundedScreen = loginData.module_list[i].screens[j];
              // console.log("foundedScreen", foundedScreen);
              if (foundedScreen.read !== null && foundedScreen.write !== null
                && foundedScreen.no_access !== null) {
                const per = handle_screen_access(permissions, foundedScreen);
                // console.log("per", per);
                return per
              }

              // console.log("loginData.module_list[i]", loginData.module_list[i]);
              return loginData.module_list[i];


            }
            // else {
            //   permissions.read = false
            //   permissions.write = false
            //   permissions.view = false
            //   permissions.delete = false
            //   permissions.report = false
            //   permissions.no_access = false
            //   console.log("permissions", permissions);
            // return permissions;
            // }
          }

        } else {
          permissions.read = false
          permissions.write = false
          permissions.view = false
          permissions.delete = false
          permissions.report = false
          permissions.no_access = false
          // console.log("permissions", permissions);
          return permissions;
        }


      }
    }

    return permissions
  }
}





export function findRoute(scrval) {


  let result = [];
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].screenForMaster !== undefined) {

      if (scrval.screen_name.toLowerCase() === "nc") {
        if (routes[i].screenForMaster?.toLowerCase() === scrval.screen_name.toLowerCase()
          || routes[i].screenForMaster?.toLowerCase() === "quality") {
          result.push(routes[i]);
        }
      } else if (scrval.screen_name.toLowerCase() === "nc dashboard" || scrval.screen_name.toLowerCase() === "quality dashboard") {

        if (routes[i].screenForMaster?.toLowerCase() === "quality dashboard") {
          result.push(routes[i]);
        }

      } else {
        if (routes[i].screenForMaster?.toLowerCase() === scrval.screen_name.toLowerCase()) {
          result.push(routes[i]);
        }
      }
    }
  }



  // if the we dont find the route required to navigate then give slash
  if (result.length > 0) {

    return result[0].path
  } else {
    return "/"
  }
}

// date convert into yyyy-mm-dd h:mm aa 2024-01-22 5:02 PM
export function formatHrs_Min(date) {
  if (date !== undefined) {
    // Extract the year, month, day, hour, and minute
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    let hour = date.getHours();
    const ampm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12 || 12; // Convert 0 to 12 for midnight

    const formattedDate = `${year}-${month}-${day} ${hour.toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} ${ampm}`;
    return formattedDate;
  }
}

export function formatUTCDate(dateString) {
  const date = new Date(dateString);
  if (!date) {
    throw new Error("A valid date is required.");
  }

  const padZero = (num) => String(num).padStart(2, "0");

  const day = padZero(date?.getDate());
  const month = padZero(date?.getMonth() + 1); // Months are zero-based
  const year = date?.getFullYear();

  let hours = date?.getHours();
  const minutes = padZero(date?.getMinutes());

  const amPm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert to 12-hour format

  return `${day}-${month}-${year} ${padZero(hours)}:${minutes} ${amPm}`;
}

export function formatDateString(inputDate) {
  // Create a Date object from the input string
  const date = new Date(inputDate);

  // Check if the input is a valid date
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  // Format the date components
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  // const hours = date.getHours().toString().padStart(2, '0');
  // const minutes = date.getMinutes().toString().padStart(2, '0');
  // const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

  // Construct the formatted date string
  // const formattedDate = `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
}



export function formatDateString2(input) {
  // Parse the input date string

  let ourDate = input.trim();

  const pad = (num) => String(num).padStart(2, '0');

  const dateFormatRegex = /^\d{1,2}-\d{1,2}-\d{4}$/;

  if (dateFormatRegex.test(ourDate)) {
    // Split the date components
    const [day, month, year] = ourDate.split('-');

    // Pad the day and month with leading zeros if needed
    const paddedDay = String(day).padStart(2, '0');
    const paddedMonth = String(month).padStart(2, '0');

    // Return the formatted date
    ourDate = `${paddedDay}-${paddedMonth}-${year}`;
  }

  // Function to format date as "dd-mm-yyyy"
  const formatDate = (date) => {
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Try parsing the input date in different formats
  const dateFormats = [
    'YYYY-MM-DD hh:mm A',
    'YYYY-MM-DD',
    'DD-MM-YYYY',
    'D-MM-YYYY',
    'DD-M-YYYY',
    'D-M-YYYY'
  ];

  let formattedDate = 'Invalid Date';

  for (const format of dateFormats) {
    const parsedDate = moment(ourDate, format, true);
    if (parsedDate.isValid()) {
      formattedDate = formatDate(parsedDate.toDate());
      break;
    }
  }



  return formattedDate;
}

export function formatDate5(inputDate) {
  // Check if inputDate is not present or empty
  if (!inputDate) {
    return "NA";
  }

  // Create a new Date object from the given string
  const date = new Date(inputDate);

  // Get the day, month, and year components
  const day = date.getDate().toString().padStart(2, '0'); // Add leading zero if needed
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  // Construct the formatted date string in "dd mm yyyy" format
  const formattedDate = `${day}-${month}-${year}`;

  // Return the formatted date
  return formattedDate;
}


export function formatDateString3(input) {
  // Function to pad single digits with leading zeros
  const pad = (num) => String(num).padStart(2, '0');

  // Function to format date as "dd-mm-yyyy"
  const formatDate = (date) => {
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are zero-based
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  // Try parsing the input date in different formats
  const dateFormats = [
    'YYYY-MM-DD hh:mm A',
    'YYYY-MM-DD',
    'DD-MM-YYYY'
  ];

  let formattedDate = 'Invalid Date';

  for (const format of dateFormats) {
    const parsedDate = moment(input, format, true);
    if (parsedDate.isValid()) {
      // Check if the parsed format is 'YYYY-MM-DD hh:mm A'
      if (format === 'YYYY-MM-DD hh:mm A') {
        return input; // Return as it is
      } else {
        formattedDate = formatDate(parsedDate.toDate());
        break;
      }
    }
  }

  return formattedDate + " 00:00:00";
}




export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}


export function formatDateString4(input) {
  // Function to pad single digits with leading zeros

  let ourDate = input.trim();

  const pad = (num) => String(num).padStart(2, '0');

  const dateFormatRegex = /^\d{1,2}-\d{1,2}-\d{4}$/;

  if (dateFormatRegex.test(ourDate)) {
    // Split the date components
    const [day, month, year] = ourDate.split('-');

    // Pad the day and month with leading zeros if needed
    const paddedDay = String(day).padStart(2, '0');
    const paddedMonth = String(month).padStart(2, '0');

    // Return the formatted date
    ourDate = `${paddedDay}-${paddedMonth}-${year}`;
  }

  // Function to format date as "dd-mm-yyyy"
  const formatDate = (date) => {
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are zero-based
    const year = date.getFullYear();
    const hours = pad(date.getHours() % 12 || 12); // Convert to 12-hour format
    const minutes = pad(date.getMinutes());
    const ampm = date.getHours() < 12 ? 'AM' : 'PM';
    return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
  };

  // Try parsing the input date in different formats
  const dateFormats = [
    'YYYY-MM-DD hh:mm A',
    'YYYY-MM-DD',
    'DD-MM-YYYY',
    'D-MM-YYYY',
    'DD-M-YYYY',
    'D-M-YYYY'
  ];

  let formattedDate = 'Invalid Date';

  for (const format of dateFormats) {
    const parsedDate = moment(ourDate, format, true);
    if (parsedDate.isValid()) {
      formattedDate = formatDate(parsedDate.toDate());
      break;
    }
  }



  return formattedDate;
}


// RMI material code increment logic is here
export function incrementMaterialCode(materialCode, currIndex) {

  let [prefix, suffixStr, fix] = materialCode.split('-');
  // let [suffix, fix] = suffixStr.split('-');
  let suffix = parseInt(suffixStr, 10);

  // Increment the suffix and handle overflow
  if (suffix < 9999) {
    suffix += currIndex;
  } else {
    suffix = currIndex;
    // Increment the prefix (handle transition from single-letter to double-letter)
    if (!prefix) {
      prefix = 'A';
    } else {
      const lastChar = prefix.slice(-1);
      if (lastChar === 'Z') {
        prefix += 'A';
      } else {
        const prefixChars = prefix.split('');
        const lastIndex = prefixChars.length - 1;
        const newChar = String.fromCharCode(prefixChars[lastIndex].charCodeAt(0) + 1);
        prefixChars[lastIndex] = newChar;
        prefix = prefixChars.join('');
      }
    }
  }

  // Format the new material code
  const newMaterialCode = `${prefix}-${String(suffix).padStart(4, '0')}-${fix}`;
  return newMaterialCode;
}


export function removePrefix(url) {
  const prefix = process.env.REACT_APP_IMAGE_URL;
  console.log(url.replace(prefix, ''));
  return url.replace(prefix, '');
}

export function splitString(inputString) {
  let arr = inputString.split("-"); // split client id from the string Ex. RMI-1234-001
  let str = `${arr[0]}-${arr[1]}`; // RMI-1234
  return str;
}

export function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    if (file) {
      reader.readAsDataURL(file);
    } else {
      reject(new Error("File is empty"));
    }
  });
}

// For inventory date conversion date format function
export function formatDateString5(dateStr) {
  // Create a new Date object from the input string
  const date = new Date(dateStr);

  // Define a function to pad single digit numbers with leading zeros
  const pad = (num) => num.toString().padStart(2, '0');

  // Extract date components
  const day = pad(date.getUTCDate());
  const month = pad(date.getUTCMonth() + 1); // Months are zero-indexed
  const year = date.getUTCFullYear();

  const hours = date.getUTCHours();
  const minutes = pad(date.getUTCMinutes());
  const seconds = pad(date.getUTCSeconds());

  // Determine AM/PM
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours from 24-hour to 12-hour format
  const hours12 = pad(hours % 12 || 12); // Convert 0 (midnight) to 12

  // Format the date string
  return `${day}-${month}-${year} ${hours12}:${minutes}:${seconds} ${ampm}`;
}


export function adjustDateTime(database_time) {
  // Parse the input time string to a Date object
  let date = new Date(database_time);
  // Subtract 5 hours and 30 minutes (330 minutes) from the date
  date.setMinutes(date.getMinutes() - 330);
  // Format the date without milliseconds and the "Z" indicating UTC
  return date;
}

export function adjustDateTime2(database_time) {
  let date = new Date(database_time);
  date.setMinutes(date.getMinutes());
  return date;
}


// Split readable id's (Ex. "WO-123-2024-001" => "WO-123")
export function getShortReadableId(readableId) {
  if (!readableId) return 'None';
  const workOrderNoParts = readableId.split('-');
  return workOrderNoParts.length > 2 ? `${workOrderNoParts[0]}-${workOrderNoParts[1]}` : readableId;
}

export function get_dispatch_quantity(arr) {

  if (arr.length == 0) {
    return 0
  }
  let sum = 0;
  sum = arr.reduce((ac, dc) => {
    return ac + Number(dc.dispatch_qty)
  }, 0);
  return sum;
}

export function calculateWorkHours(startDateTime, endDateTime, officeStartMillis, officeEndMillis) {
  const start = new Date(startDateTime);
  const end = new Date(endDateTime);

  // Edge case: if startDateTime is after endDateTime
  if (start >= end) return 0;

  // Constants for full working day in hours
  const fullDayHours = (officeEndMillis - officeStartMillis) / 1000 / 60 / 60;

  let totalHours = 0;

  // Case 1: If start and end date are on the same day
  if (start.toDateString() === end.toDateString()) {
    // Calculate the working hours within office hours for the same day
    const startWorkMillis = Math.max(
      start.getHours() * 60 * 60 * 1000 + start.getMinutes() * 60 * 1000,
      officeStartMillis
    );
    const endWorkMillis = Math.min(
      end.getHours() * 60 * 60 * 1000 + end.getMinutes() * 60 * 1000,
      officeEndMillis
    );
    totalHours = Math.max(0, (endWorkMillis - startWorkMillis) / 1000 / 60 / 60); // Convert ms to hours
    return totalHours;
  }

  // Case 2: If start and end dates are different
  let currentDate = new Date(start);

  // First day: calculate hours worked after the start time or from office start time
  const startWorkMillis = Math.max(
    start.getHours() * 60 * 60 * 1000 + start.getMinutes() * 60 * 1000,
    officeStartMillis
  );
  totalHours += (officeEndMillis - startWorkMillis) / 1000 / 60 / 60; // Convert ms to hours

  // Loop through full days between start and end dates
  currentDate.setDate(currentDate.getDate() + 1);
  currentDate.setHours(0, 0, 0, 0);
  while (currentDate.toDateString() !== end.toDateString()) {
    // Add full working hours for full days between
    totalHours += fullDayHours;
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Last day: calculate hours worked up to the end time or until office end time
  const endWorkMillis = Math.min(
    end.getHours() * 60 * 60 * 1000 + end.getMinutes() * 60 * 1000,
    officeEndMillis
  );
  const officeStartTimeOnEndDay = officeStartMillis;
  totalHours += Math.max(0, (endWorkMillis - officeStartTimeOnEndDay) / 1000 / 60 / 60); // Convert ms to hours

  return totalHours;
}

export function excelReportDate(date) {
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  const suffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // covers 11th to 19th
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return `${day}${suffix(day)} ${month} ${year}`;
}

export function getFinancialYearStartDate(today) {
  const year =
    today.getMonth() >= 3 ? today.getFullYear() : today.getFullYear() - 1;
  const financialYearStartDate = new Date(year, 3, 1); // April is month 3 in JavaScript Date
  return financialYearStartDate;
}

export function formatDateToAMPM(dateString) {
  // Remove 'Z' from the ISO string if present
  const cleanDateString = dateString.replace('Z', '');

  const date = new Date(cleanDateString);
  let hours = date.getHours();
  const minutes = date.getMinutes();

  const period = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;

  return `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
}

export function convertMinutesToHHMM(minutes) {
  // If input is 0, directly return "00:00"
  if (minutes === 0) return "00:00";

  // Calculate hours and remaining minutes
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  // Format as hh:mm with zero-padding for minutes
  return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;
}


export function getWeekRange(date) {
  // Ensure we are working with a Date object
  const givenDate = new Date(date);

  // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const dayOfWeek = givenDate.getDay();

  // Calculate the first and last dates of the week
  const firstDate = new Date(givenDate);
  firstDate.setDate(givenDate.getDate() - dayOfWeek); // Go back to Sunday

  const lastDate = new Date(givenDate);
  lastDate.setDate(givenDate.getDate() + (6 - dayOfWeek)); // Go forward to Saturday

  return { firstDate, lastDate };
}


export function create_job_obj(data) {
  console.log("data", data);
  return {
    "resourceId": data.resourceId ?? null,
    "uuid": null,
    "job_card_no": data.operator[0].FullName + "_" + data.prod_operation[0].operationname,
    "cycle_time": data.cycle_time,
    "start_time": data.startTime,
    "end_time": data.endTime,
    "total_time_hrs": data.total_time_hrs,
    "total_time_mins": data.total_time_mins,
    "total_time_sec": data.total_time_sec,
    "planned_qty": data.target_qty,
    "actual_qty": data.actual_qty,
    "remarks": data.remarks,
    "machine_id": data.machine[0].uuid,
    "operator_id": data.operator[0].uuid,
    "product_id": data.product[0].uuid,
    "product_operation_id": data.prod_operation[0].uuid,
    "work_order_id": null,
    "lot_no_id": null,
    "actual_cycle_time": null,
    "down_time": null,
    "setting_time": null,
    "status": "Open",
    productDetail: data.product[0],
    productOperDetails: data.prod_operation[0],
    operatorDetails: data.operator[0],
    operationDetails: data.prod_operation[0].operation_details,
    production_data: null,
    workorderDetails: null,
  }
}

export function groupRecordsBySequence(array) {
  const result = [];
  const sequenceMap = new Map();

  array.forEach(record => {
    // Assign '1' if grouping_sequence is null
    const grouping_sequence = record.grouping_sequence === null ? 1 : record.grouping_sequence;

    // Check if the grouping_sequence already exists in the result
    if (!sequenceMap.has(grouping_sequence)) {
      const newEntry = { grouping_sequence, group: [] };
      result.push(newEntry);
      sequenceMap.set(grouping_sequence, newEntry.group);
    }

    // Add the record to the appropriate group
    sequenceMap.get(grouping_sequence).push(record);
  });

  return result;
}



export function assignDatesToOperations(operationList, startDate, endDate, already_saved_opr_list) {
  if (!operationList || operationList.length === 0) {
    throw new Error("Operation list cannot be empty.");
  }

  const totalOperations = operationList.length;
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (totalOperations === 1) {
    // Assign the same start and end dates for a single operation
    return operationList.map((operation) => {

      if (already_saved_opr_list && already_saved_opr_list.length > 0) {
        const founded_prev_saved_opr = already_saved_opr_list.find(val => val.uuid);
        console.log("founded_prev_saved_opr", founded_prev_saved_opr);
        if (founded_prev_saved_opr) {
          operation.operation_details.startDate = founded_prev_saved_opr.operation_details.startDate;
          operation.operation_details.endDate = founded_prev_saved_opr.operation_details.endDate;
          if (founded_prev_saved_opr.create_job_card) {
            operation.create_job_card = founded_prev_saved_opr.create_job_card;
          }
          // operation.operation_details.selected = false;
          return { ...operation };
        }
      }

      operation.operation_details.startDate = start;
      operation.operation_details.endDate = start;
      // operation.operation_details.selected = false;
      return { ...operation };
    });
  }

  // Calculate the interval in days between operations
  const interval = Math.floor((end - start) / (totalOperations - 1));

  // Assign dates dynamically
  return operationList.map((operation, index) => {
    const operationStartDate = new Date(start.getTime() + index * interval);
    const operationEndDate = new Date(operationStartDate); // Same day for both start and end

    operation.operation_details.startDate = operationStartDate;
    operation.operation_details.endDate = operationEndDate;
    operation.operation_details.selected = false;

    return { ...operation };
  });
}


// export function assignDatesToOperationsReverse(operationList, startDate, endDate, already_saved_opr_list) {
//   if (!operationList || operationList.length === 0) {
//     throw new Error("Operation list cannot be empty.");
//   }

//   const totalOperations = operationList.length;
//   const start = new Date(startDate);
//   const end = new Date(endDate);

//   // Handle the case where there is only one operation
//   if (totalOperations === 1) {
//     return operationList.map((operation) => {
//       console.log("already_saved_opr_list", already_saved_opr_list);

//       if (already_saved_opr_list && already_saved_opr_list.length > 0) {
//         const founded_prev_saved_opr = already_saved_opr_list.find(val => {
//           if (val.uuid) {
//             return val.uuid === operation.uuid;
//           } else {
//             return val.operation_id === operation.operation_id;
//           }
//         });
//         console.log("founded_prev_saved_opr", founded_prev_saved_opr);
//         if (founded_prev_saved_opr) {
//           operation.operation_details.startDate = founded_prev_saved_opr.operation_details.startDate;
//           operation.operation_details.endDate = founded_prev_saved_opr.operation_details.endDate;
//           if (founded_prev_saved_opr.create_job_card) {
//             operation.create_job_card = founded_prev_saved_opr.create_job_card;
//           }
//           // operation.operation_details.selected = false;
//           return { ...operation };
//         }
//       }
//       const sd = end;
//       const ed = end;
//       operation.operation_details.startDate = sd.setHours(10, 0, 0, 0); // Assign end date as the only date
//       operation.operation_details.endDate = ed.setHours(12, 0, 0, 0);
//       operation.operation_details.selected = false;

//       return { ...operation };
//     });
//   }

//   // Calculate the interval in days between operations
//   const interval = Math.floor((end - start) / (totalOperations - 1));

//   // Assign dates dynamically, starting from the endDate
//   return operationList.map((operation, index) => {
//     const operationStartDate = new Date(end.getTime() - index * interval).setHours(10, 0, 0, 0);
//     const operationEndDate = new Date(operationStartDate).setHours(12, 0, 0, 0); // Same day for both start and end

//     if (already_saved_opr_list && already_saved_opr_list.length > 0) {
//       const founded_prev_saved_opr = already_saved_opr_list.find(val => {
//         if (val.uuid) {
//           return val.uuid === operation.uuid;
//         } else {
//           return val.operation_id === operation.operation_id;
//         }
//       });
//       console.log("founded_prev_saved_opr", founded_prev_saved_opr);
//       if (founded_prev_saved_opr) {
//         operation.operation_details.startDate = founded_prev_saved_opr.operation_details.startDate;
//         operation.operation_details.endDate = founded_prev_saved_opr.operation_details.endDate;
//         if (founded_prev_saved_opr.create_job_card) {
//           operation.create_job_card = founded_prev_saved_opr.create_job_card;
//         }
//         // operation.operation_details.selected = false;
//         return { ...operation };
//       }
//     }
//     operation.operation_details.startDate = operationStartDate;
//     operation.operation_details.endDate = operationEndDate;
//     operation.operation_details.selected = false;

//     return { ...operation };
//   });
// }


export function assignDatesToOperationsReverse(operationList, startDate, endDate, already_saved_opr_list, StepOneSavedData) {
  if (!operationList || operationList.length === 0) {
    throw new Error("Operation list cannot be empty.");
  }

  console.log("operationList", operationList);
  console.log("already_saved_opr_list", already_saved_opr_list);

  const { formData } = StepOneSavedData;

  const totalOperations = operationList.length;
  let start = new Date(startDate);
  let end = new Date(endDate);

  // Helper function to adjust dates for holidays
  const adjustDateForHolidays = (date, called_for) => {
    while (true) {
      const dayName = date.toLocaleString("en-US", { weekday: "long" }).toLowerCase();
      if (formData[dayName]) {
        if (called_for == "sd") {
          date.setDate(date.getDate() + 1); // Move to the previous day

        } else if (called_for == "ed") {

          date.setDate(date.getDate() - 1); // Move to the previous day
        }
      } else {
        return date;
      }
    }
  };

  // Adjust start and end dates for holidays
  start = adjustDateForHolidays(new Date(start), "sd");
  end = adjustDateForHolidays(new Date(end), "ed");

  // Handle the case where there is only one operation
  if (totalOperations === 1) {
    return operationList.map((operation) => {
      if (already_saved_opr_list && already_saved_opr_list.length > 0) {
        const founded_prev_saved_opr = already_saved_opr_list.find((val) => {
          if (val.uuid) {
            return val.uuid === operation.uuid;
          } else {
            return val.operation_id === operation.operation_id;
          }
        });
        if (founded_prev_saved_opr) {
          operation.operation_details.startDate = founded_prev_saved_opr.operation_details.startDate;
          operation.operation_details.endDate = founded_prev_saved_opr.operation_details.endDate;
          if (founded_prev_saved_opr.create_job_card) {
            operation.create_job_card = founded_prev_saved_opr.create_job_card;
          }
          return { ...operation };
        }
      }
      let sd = adjustDateForHolidays(new Date(start), "sd");
      let ed = adjustDateForHolidays(new Date(end), "ed");
      sd.setHours(10, 0, 0, 0);
      ed.setHours(12, 0, 0, 0);
      operation.operation_details.startDate = sd.getTime();
      operation.operation_details.endDate = ed.getTime();
      operation.operation_details.selected = false;
      operation.create_job_card = [];
      return { ...operation };
    });
  }

  // Calculate the interval in days between operations
  const interval = Math.floor((end - start) / (totalOperations - 1));

  // Assign dates dynamically, starting from the endDate
  return operationList.map((operation, index) => {
    const rawStartDate = new Date(end.getTime() - index * interval);
    const operationStartDate = adjustDateForHolidays(new Date(rawStartDate), "sd");
    const operationEndDate = adjustDateForHolidays(new Date(operationStartDate), "ed");

    operationStartDate.setHours(10, 0, 0, 0);
    operationEndDate.setHours(12, 0, 0, 0);

    if (already_saved_opr_list && already_saved_opr_list.length > 0) {
      const founded_prev_saved_opr = already_saved_opr_list.find((val) => {
        if (val.uuid) {
          return val.uuid === operation.uuid;
        } else {
          return val.operation_id === operation.operation_id;
        }
      });

      console.log("founded_prev_saved_opr", founded_prev_saved_opr);

      if (founded_prev_saved_opr) {
        operation.operation_details.startDate = founded_prev_saved_opr.operation_details.startDate;
        operation.operation_details.endDate = founded_prev_saved_opr.operation_details.endDate;
        if (founded_prev_saved_opr.create_job_card) {
          operation.create_job_card = founded_prev_saved_opr.create_job_card;
        }
        return { ...operation };
      }
    }
    operation.operation_details.startDate = operationStartDate.getTime();
    operation.operation_details.endDate = operationEndDate.getTime();
    operation.operation_details.selected = false;
    operation.create_job_card = [];
    console.log("operation", operation);
    return { ...operation };
  });
}




export function validateJobCardTiming(event_list, newJobCard, visible) {
  if (!newJobCard || !newJobCard.startTime || !newJobCard.endTime) {
    throw new Error("New job card must have valid start_time and end_time.");
  }

  const newStartTime = new Date(newJobCard.startTime).getTime();
  const newEndTime = new Date(newJobCard.endTime).getTime();

  console.log("event_list", event_list);
  console.log("newJobCard", newJobCard);
  console.log("newStartTime", newStartTime);
  console.log("newEndTime", newEndTime);


  for (const jc of event_list) {
    if (jc) {
      const existingStartTime = new Date(jc.start_time).getTime();
      const existingEndTime = new Date(jc.end_time).getTime();
      console.log("existingStartTime", existingStartTime);
      console.log("existingEndTime", existingEndTime);


      for (let a = 0; a < newJobCard.operator.length; a++) {
        // Check if the new job card's start time or end time falls within an existing job card's range
        if (newJobCard.operator[a].uuid === jc.resourceId) {
          // if (newJobCard.operator[a].uuid === jc.resourceId && jc.uuid) {
          if (
            (newStartTime >= existingStartTime && newStartTime <= existingEndTime) || // Overlaps start
            (newEndTime >= existingStartTime && newEndTime <= existingEndTime) || // Overlaps end
            (newStartTime <= existingStartTime && newEndTime >= existingEndTime) // Completely overlaps existing
          ) {
            console.log("adjustDateTime(jc.start_time)", jc.start_time);
            console.log("jc.end_time", jc.end_time);

            // visible.displayData presents means user is updating a locally created jc again
            if (!jc.uuid && visible.displayData) {
              return {
                valid: true,
                message: "No conflicts found. Job card can be assigned.",
              };
            }

            console.log(`${newJobCard.operator[a].FullName} has already job card on 
              ${formatUTCDate(jc.start_time)} to ${formatUTCDate(jc.end_time)}`);
            return {
              valid: false,
              message: `${newJobCard.operator[a].FullName} has already job card on 
              ${formatUTCDate(jc.start_time)} to ${formatUTCDate(jc.end_time)}`,
            };
          }
        }
      }
    }
  }

  return {
    valid: true,
    message: "No conflicts found. Job card can be assigned.",
  };
}


export function call_sweet_alert(title, msg) {
  SweetAlert.fire({
    title: title,
    text: msg,
    icon: "error",
    // showCancelButton: true,
    confirmButtonText: "Ok",
    // cancelButtonText: "Cancel",
    reverseButtons: true,
    customClass: {
      container: "my-sweetalert", // Add a custom class to SweetAlert
    },
  })
}


export function getTrueDaysDates(data) {
  console.log("data", data);
  const { formData } = data;
  const resultDates = [];
  const start = new Date(formData.plan_date);
  const end = new Date(formData.delivery_date);
  const days = {
    monday: formData.monday,
    tuesday: formData.tuesday,
    wednesday: formData.wednesday,
    thursday: formData.thursday,
    friday: formData.friday,
    saturday: formData.saturday,
    sunday: formData.sunday,
  }

  // Validate input
  if (!days || typeof days !== "object") {
    throw new Error("Invalid days object provided.");
  }
  if (!(start instanceof Date) || isNaN(start) || !(end instanceof Date) || isNaN(end)) {
    throw new Error("Invalid start or end date.");
  }

  // Iterate from startDate to endDate
  for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
    const dayName = date.toLocaleString("en-US", { weekday: "long" }).toLowerCase();

    if (days[dayName]) {
      resultDates.push(new Date(date)); // Add the date if the day is marked as true
    }
  }


  // if plan delivery date falls under holiday then disable that day in calender 
  const endDayName = end.toLocaleString("en-US", { weekday: "long" }).toLowerCase();
  if (days[endDayName]) {
    resultDates.push(new Date(end));
  }
  // if plan date falls under holiday then disable that day in calender
  const startDayName = start.toLocaleString("en-US", { weekday: "long" }).toLowerCase();
  if (days[startDayName]) {
    resultDates.push(new Date(start));
  }

  console.log("resultDates", resultDates);
  return resultDates;
}


export function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
}