import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert2";
import {
  faBan,
  faCog,
  faFilter,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  get_purchaseInward,
  delete_purchase_inward,
  import_po,
  get_purchaseInward_node,
  delete_purchase_inward_node,
  getSupplier,
} from "../../../api/index";
import {
  calMaxPage,
  convertDate,
  getLabels,
  setAccessPrivilage,
} from "../../../common/Functions/CommonFunctions";
import { Typeahead } from "react-bootstrap-typeahead";
import { Dropdown, Pagination } from "react-bootstrap";
import { Eye, Trash2, Edit2, Image, Edit } from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { Purchase_Inward_Col_Name } from "../../../common/Labels/labelConstant";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import List from "../../../assets/images/icons/project_menu/checklist.png";
import Slide from "../../../assets/images/icons/project_menu/collapse.png";
import AccessDenied from "../../Common/AccessDenied";

function PurchaseInv() {
  const [showLabel, setShowLabel] = useState(Purchase_Inward_Col_Name);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [supplierList, setSupplierList] = useState({
    data: [],
    loading: false,
  });

  const [filterConfig, setFilterConfig] = useState({
    supplier: [],
    voucher_no: "",
    invoice_no: "",
  });
  const [sea_val, setSea_val] = useState(false);
  const [constLabel, setConstLabel] = useState({});

  const { control } = useForm({ mode: "onChange" }); // initialise the hook

  let navigate = useNavigate();

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
   no_access: false
  });

  useEffect(() => {
    setConstLabel(getLabels("Purchase", "Purchase Inward"));
    const debounceTimeout = setTimeout(() => {
      fetchPOInvList(page, entriesPerPage);
    }, 1000);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [filterConfig]);

  useEffect(() => {
    setPrivilage(setAccessPrivilage(loginData, "Purchase Inward"));
    fetchSuppliers();
  }, []);
  function fetchPOInvList(pg, epp) {
    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
    };
    setDataList({ ...dataList, data: [], loading: true });

    // if (filterConfig?.supplier?.length > 0) {
    //   payload.supplier_id_id = filterConfig.supplier[0].uuid;
    // } else {
    //   delete payload.supplier_id_id;
    // }
    // if (filterConfig.voucher_no !== "") {
    //   // payload.productName_list = filterConfig.productName;
    //   payload.voucher_no = filterConfig.voucher_no.trim();
    // } else {
    //   delete payload.voucher_no;
    // }

    // if (filterConfig.invoice_no !== "") {
    //   // payload.productName_list = filterConfig.productName;
    //   payload.invoice_no = filterConfig.invoice_no.trim();
    // } else {
    //   delete payload.invoice_no;
    // }

    if (
      filterConfig.supplier.length > 0 ||
      filterConfig.voucher_no !== "" ||
      filterConfig.invoice_no !== ""
    ) {
      payload.supplier_id_id = filterConfig.supplier[0]?.uuid;
      payload.voucher_no = filterConfig.voucher_no;
      payload.invoice_no = filterConfig.invoice_no;
      if (
        (filterConfig.supplier?.length > 0 ||
          filterConfig.voucher_no?.length > 0 ||
          filterConfig.invoice_no?.length > 0) &&
        page !== 1 &&
        sea_val == false
      ) {
        payload.pageNo = 1;
        payload.entriesPerPage = entriesPerPage;
        setPage(1);
        setSrNo(0);
      }
    } else {
      delete payload.supplier_id_id;
      delete payload.voucher_no;
      delete payload.invoice_no;
    }

    get_purchaseInward_node(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });

        if (
          (filterConfig.supplier?.length > 0) ||
          (filterConfig.voucher_no !== "" && filterConfig.voucher_no?.length > 0) ||
          (filterConfig.invoice_no !== "" && filterConfig.invoice_no?.length > 0)
        ) {
          setSea_val(true);
        } else {
          setSea_val(false);
        }

        setMaxPage(calMaxPage(res.data.total));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, data: [], loading: false });
      }
    );
  }

  const delPOInv = (val) => {
    SweetAlert.fire({
      title: "Are you sure to delete ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        delete_purchase_inward_node({ uuid: val.uuid }).then(
          (res) => {
            toast.success("Material inward deleted successfully", {
              autoClose: 2000,
            });

            if (dataList.data.length == 1 && page > 1) {
              fetchPOInvList(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchPOInvList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  function handleClick(ind) {
    //

    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function clearFilter() {
    let obj = {
      supplier: [],
      voucher_no: "",
      invoice_no: "",
    };
    setFilterConfig(obj);

    fetchSuppliers();
  }

  function fetchSuppliers() {
    setSupplierList({ ...supplierList, loading: true, data: [] });

    getSupplier({ partyType: "Supplier" }).then(
      (res) => {
        // console.log("res", res);
        if(res && res.data?.data?.length > 0){
          const result = res.data.data.filter((item) => item.name !== "" && item.name !== null);
          setSupplierList({
            ...supplierList,
            loading: false,
            data: result,
          });
        } else {
          setSupplierList({ ...supplierList, loading: false, data: [] });
        }
      },
      (err) => {
        //
        setSupplierList({ ...supplierList, loading: false, data: [] });

        // toast.error("Something went wrong in suppliers!", {
        //   autoClose: 3000,
        // });
      }
    );
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />

      {privilage &&
        <div id="mySidebar" className="customsidebar ">
          <a className="closebtn" onClick={() => toggleNav()}>
            ×
          </a>

          <div className=" content">
            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {"Select Supplier "}
              </label>

              <Typeahead
                id="basic-typeahead-single"
                labelKey="name"
                name="suppliername"
                multiple={false}
                // size="sm"
                options={supplierList.data}
                isLoading={supplierList.loading}
                selected={filterConfig.supplier}
                onChange={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, supplier: val });
                  } else {
                    setFilterConfig({ ...filterConfig, supplier: [] });
                  }
                }}
                placeholder="Search supplier name "
              />
            </div>

            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.voucher_no
                  ? constLabel.voucher_no
                  : "Voucher Number"}
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by voucher number"
                value={filterConfig.voucher_no}
                onChange={(val) => {
                  const inputValue = val.target.value;
                  if (!inputValue.trim()) {
                    // Input contains only whitespace, don't trigger the search.
                    if (filterConfig.voucher_no.length == 1) {
                      setFilterConfig({ ...filterConfig, voucher_no: "" });
                    }

                    if (inputValue.length == 0) {
                      setFilterConfig({ ...filterConfig, voucher_no: "" });
                    }
                  } else {
                    setFilterConfig({ ...filterConfig, voucher_no: inputValue });
                  }
                }}
              />
            </div>

            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_invoice_no
                  ? constLabel.lbl_invoice_no
                  : "Supplier Invoice No"}
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by supplier invoice no"
                value={filterConfig.invoice_no}
                onChange={(val) => {
                  const inputValue = val.target.value;
                  if (!inputValue.trim()) {
                    // Input contains only whitespace, don't trigger the search.
                    if (filterConfig.invoice_no.length == 1) {
                      setFilterConfig({ ...filterConfig, invoice_no: "" });
                    }

                    if (inputValue.length == 0) {
                      setFilterConfig({ ...filterConfig, invoice_no: "" });
                    }
                  } else {
                    setFilterConfig({ ...filterConfig, invoice_no: inputValue });
                  }
                }}
              />
            </div>

            <div className="my-3 d-flex justify-content-end">
              <button
                onClick={() => clearFilter()}
                className="btn btn-sm btn-yellow "
              >
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {constLabel?.lbl_clear_filter
                  ? constLabel.lbl_clear_filter
                  : "Clear Filter"}
              </button>
            </div>
          </div>
        </div>
      }

      {!privilage.read ?
        <AccessDenied />
        :
        <div className="min-vh-100" id="root_div_main">
          <div className="content-wrapper">
            <div className="card border-0">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="d-md-flex justify-content-start col-12 col-md-6">
                    <Tooltip id={"open-tooltip"} place="top" />
                    <img
                      src={Slide}
                      height="32"
                      width="32"
                      className="cursor_pointer my-1 me-2"
                      onClick={() => toggleNav()}
                      data-tooltip-id={"open-tooltip"}
                      data-tooltip-content={"Filters"}
                    />
                    <h1 className="bold">
                      {constLabel?.lbl_purchase_inward
                        ? constLabel.lbl_purchase_inward
                        : "Purchase Inward"}
                    </h1>
                  </div>

                  <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                    {privilage.write && (
                      <button
                        className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                        onClick={() => navigate(`/pcms/createPurchaseInward`)}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_create_purchase_inward
                          ? constLabel.lbl_create_purchase_inward
                          : "Create Purchase Inward"}
                      </button>
                    )}

                    <Tooltip id={"show-tooltip"} place="top" />
                    <div
                      className="dropdown"
                      data-tooltip-id={"show-tooltip"}
                      data-tooltip-content={"Show / Hide Columns"}
                    >
                      <div
                        className="d-md-flex justify-content-start align-items-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <FontAwesomeIcon icon={faListCheck} size="lg" /> */}
                        {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                        <img
                          src={List}
                          height="32"
                          width="32"
                          className="cursor_pointer"
                        />
                      </div>

                      <ul className="dropdown-menu px-2">
                        {showLabel !== undefined && showLabel.length > 0
                          ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="mt-3">
                  {dataList.loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : dataList.data.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead className="table-grey  roboto-bold">
                          <tr className="">
                            <th scope="col">{"Sr.No"}</th>
                            {showLabel.map((v, i) =>
                              v.label === "PO No" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_po_no
                                    ? constLabel?.lbl_po_no
                                    : "PO No"}
                                </th>
                              ) : v.label === "Voucher No" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_voucher_no
                                    ? constLabel?.lbl_voucher_no
                                    : "Voucher No"}
                                </th>
                                // ) : v.label === "Client PO No" && v.flag ? (
                                //   <th key={i} scope="col">
                                //     {constLabel?.lbl_client_po_no
                                //       ? constLabel.lbl_client_po_no
                                //       : "Client PO No"}
                                //   </th>
                              ) : v.label === "Invoice Date" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_invoice_date
                                    ? constLabel.lbl_invoice_date
                                    : "Invoice Date"}
                                </th>
                              ) : v.label === "Invoice To" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_invoice_to
                                    ? constLabel.lbl_invoice_to
                                    : "Invoice To"}
                                </th>
                              ) : v.label === "Supplier Invoice No" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_invoice_no
                                    ? constLabel.lbl_invoice_no
                                    : "Supplier Invoice No"}
                                </th>
                              ) : v.label === "Dispatch To" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_dispatch_to
                                    ? constLabel.lbl_dispatch_to
                                    : "Dispatch To"}
                                </th>
                              ) : v.label === "Supplier Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_supplier_name
                                    ? constLabel.lbl_supplier_name
                                    : "Supplier Name"}
                                </th>
                              ) : v.label === "Store Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_store_name
                                    ? constLabel.lbl_store_name
                                    : "Store Name"}
                                </th>
                              ) : v.label === "Location Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_location_name
                                    ? constLabel.lbl_location_name
                                    : "Location Name"}
                                </th>
                              ) : v.label === "Tally Voucher Date" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_tally_voucher_date
                                    ? constLabel.lbl_tally_voucher_date
                                    : "Tally Voucher Date"}
                                </th>
                              ) : v.label === "Tally Voucher No" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_tally_voucher_no
                                    ? constLabel.lbl_tally_voucher_no
                                    : "Tally Voucher No"}
                                </th>
                              ) : // v.label === "Remark" && v.flag ? (<th key={i} scope="col">{constLabel?.lbl_remark ? constLabel.lbl_remark : "Remark"}</th>) :
                                null
                            )}
                            <th scope="col" width="15%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className=" roboto">
                          {dataList.data.map((val, ind) => (
                            <tr key={ind}>
                              <td>{parseInt(ind) + 1 + parseInt(srNo)}</td>
                              {showLabel.map((item, idx) =>
                                item.label === "PO No" && item.flag ? (
                                  <td key={idx}>
                                    {val.po_details?.poid
                                      ? val.po_details?.poid
                                      : "NA"}
                                  </td>
                                ) : item.label === "Voucher No" && item.flag ? (
                                  <td key={idx}>
                                    {val.po_details?.voucher_no
                                      ? val.po_details?.voucher_no
                                      : "NA"}
                                  </td>
                                  // ) : item.label === "Client PO No" &&
                                  //   item.flag ? (
                                  //   <td key={idx}>
                                  //     {val.client_poid ? val.client_poid : "NA"}
                                  //   </td>
                                ) : item.label === "Invoice Date" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.invoice_date !== undefined &&
                                      val.invoice_date !== null
                                      ? convertDate(val.invoice_date, "-")
                                      : "NA"}
                                  </td>
                                ) : item.label === "Invoice To" && item.flag ? (
                                  <td key={idx}>
                                    {val.invoice_to_name
                                      ? val.invoice_to_name
                                      : "NA"}
                                  </td>
                                ) : item.label === "Supplier Invoice No" && item.flag ? (
                                  <td key={idx}>
                                    {val.invoice_no ? val.invoice_no : "NA"}
                                  </td>
                                ) : item.label === "Dispatch To" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.dispatch_to_name
                                      ? val.dispatch_to_name
                                      : "NA"}
                                  </td>
                                ) : item.label === "Supplier Name" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.supplier_name
                                      ? val.supplier_name
                                      : "NA"}
                                  </td>
                                ) : item.label === "Store Name" && item.flag ? (
                                  <td key={idx}>
                                    {val.store_name ? val.store_name : "NA"}
                                  </td>
                                ) : item.label === "Location Name" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.location_name
                                      ? val.location_name
                                      : "NA"}
                                  </td>
                                ) : item.label === "Tally Voucher Date" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.tally_voucher_date
                                      ? val.tally_voucher_date
                                      : "NA"}
                                  </td>
                                ) : item.label === "Tally Voucher No" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.tally_voucher_no
                                      ? val.tally_voucher_no
                                      : "NA"}
                                  </td>
                                ) : // item.label === "Remark" && item.flag ? (<td key={idx}>{val.location_name ? val.location_name : "NA"}</td>) :
                                  null
                              )}
                              <td>


                                {privilage.write && (
                                  <>
                                    <Tooltip id={"edit-tooltip" + ind} place="top" />
                                    <Edit
                                      data-tooltip-id={"edit-tooltip" + ind}
                                      data-tooltip-content={
                                        constLabel?.lbl_edit_po_inward
                                          ? constLabel.lbl_edit_po_inward
                                          : "Edit PO Inward"
                                      }
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20}
                                      onClick={() =>
                                        navigate(`/pcms/editPurchaseInward/`, {
                                          state: val,
                                        })
                                      }
                                    />
                                  </>
                                )}

                                {privilage.write && (
                                  <>
                                    <Tooltip id={"view-tooltip" + ind} place="top" />
                                    <Eye
                                      data-tooltip-id={"view-tooltip" + ind}
                                      data-tooltip-content={
                                        constLabel?.lbl_view_inward_items
                                          ? constLabel.lbl_view_inward_items
                                          : "View Inward Items"
                                      }
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        navigate(
                                          `/pcms/poInwardItems/${val.uuid}`,
                                          { state: val }
                                        )
                                      }
                                      }
                                    />
                                  </>
                                )}

                                {val.piattachments?.length == 0
                                  ? null
                                  : privilage.read && (
                                    <>
                                      <Tooltip id={"image-tooltip" + ind} place="top" />
                                      <Image
                                        data-tooltip-id={
                                          "image-tooltip" + ind
                                        }
                                        data-tooltip-content={"View Image"}
                                        className="menu_icon_grey cursor_pointer me-2"
                                        size={20}
                                        onClick={() =>
                                          window.open(
                                            `${process.env.REACT_APP_IMAGE_URL}${val.piattachments?.[0]?.attachment}`,
                                            "_blank"
                                          )
                                        }
                                      // onClick={() => delPOInv(val)}
                                      />
                                    </>
                                  )}

                                {privilage.delete && (
                                  <>
                                    <Tooltip id={"delete-tooltip" + ind} place="top" />
                                    <Trash2
                                      data-tooltip-id={"delete-tooltip" + ind}
                                      data-tooltip-content={
                                        constLabel?.lbl_delete_po_inward
                                          ? constLabel.lbl_delete_po_inward
                                          : "Delete PO Inward"
                                      }
                                      className="menu_icon_red cursor_pointer"
                                      size={20}
                                      onClick={() => delPOInv(val)}
                                    />
                                  </>
                                )}

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div
                      className="alert alert-danger mb-0 text-center my-4"
                      role="alert"
                    >
                      {"No Purchase Inward Found !"}
                    </div>
                  )}

                  {dataList.loading ? null : (
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <p className="mb-0">
                        Show
                        <select
                          className="mx-1"
                          defaultValue={entriesPerPage}
                          onChange={(e) => {
                            setEntriesPerPage(e.target.value);
                            fetchPOInvList(1, e.target.value);
                          }}
                        >
                          <option value={"10"}>10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        Entries
                      </p>
                      <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                        } to ${Math.min(
                          parseInt(entriesPerPage) + parseInt(srNo),
                          dataList.totalRecords
                        )} of ${dataList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchPOInvList(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1
                                ? 0
                                : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                              maxPage === 0 ||
                              entriesPerPage > dataList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            fetchPOInvList(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default PurchaseInv;
