// features/routeSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import client2 from "../../constant/NodeAPI_AxiosInstance";
import { production_plan } from "../../constant/index"

// Fetch routes for a selected product
export const fetchPPByid = createAsyncThunk("routes/fetchPPByid", async (payload) => {
    try {
        const response = await client2.get(production_plan, { params: payload });
        console.log("response", response);

        if (response.data.data.length > 0) {
            return response.data.data[0];
        } else {
            return null
        }

    } catch (error) {
        console.log("error", error);
        return {};
    }
});

const prodPlanByIdSlice = createSlice({
    name: "prod_plan_by_id",
    initialState: {
        data: null,
        status: "idle",
        error: null,
    },
    reducers: {
        reset_production_plan(state, action) {
            state.data = null;
            state.status = "idle";
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPPByid.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchPPByid.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.data = action.payload;
            })
            .addCase(fetchPPByid.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export const { reset_production_plan } = prodPlanByIdSlice.actions;
export default prodPlanByIdSlice.reducer;
