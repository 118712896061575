import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faAdd,
  faEye,
  faArrowLeft,
  faCircle,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert2";
import { Trash2 } from "react-feather";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import {
  getSupplier,
  getUOM,
  getProdList,
  getProductType,
  getQuote_Products,
  updateQuotation,
  getStatusMaster,
  getGST,
  getInternalCompany,
  get_master_files,
  master_multi_file_upload,
  deleteQuoteProduct,
} from "../../../api/index";
import {
  convertDateYYMMDD,
  convertDateFormat,
  getLabels,
  convertBase64,
} from "../../../common/Functions/CommonFunctions";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import { Await, useLocation, useNavigate } from "react-router-dom";
import { Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";
import AddProduct from "../AddProducts/AddProduct";
import EditQuoteImageGallary from "./EditQuoteImageGallary";

const schema = yup
  .object()
  .shape({
    // ncPrefix: yup.string().required("Prefix is required !").matches(/^[A-Z0-9]*$/, "Only alphabets and space Allowed !"),,
    supplier: yup
      .array()
      .min(1, "Please select supplier !")
      .required("Please select supplier !"),
    quotation_for: yup
      .array()
      .min(1, "Please select company !")
      .required("Please select company !"),
    quote_date: yup.date().required("Please select date !").typeError("Please select date !"),
    status: yup
      .array()
      .min(1, "Please select status")
      .required("Please select status !"),
    remark: yup.string().when("status", {
      is: (status) => status && status[0]?.status_name == "Rejected",
      then: yup.string().required("Please enter remarks"),
      otherwise: yup.string(),
    }),
    items: yup.array().of(
      yup.object().shape({
        prod_type: yup
          .array()
          .min(1, "Please select product type !")
          .required("Please select product type !"),
        product: yup
          .array()
          .min(1, "Please select product !")
          .required("Please select product !"),
        quantity: yup
          .string()
          .required("Quantity is required")
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid quantity")
          .typeError("Enter valid quantity"),
        unit: yup
          .array()
          .min(1, "Please select unit !")
          .required("Please select unit !"),
        unit_price: yup
          .string()
          .required("Unit price is required !")
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid unit price !")
          .typeError("Enter valid unit price !"),
        gst_rate: yup
          .string()
          .required("gst rate  is required !")
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter gst rate !")
          .typeError("Enter gst rate !"),
        gst_amount: yup
          .string()
          .notRequired()
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter gst amount !")
          .typeError("gst amount is invalid !"),
        discount: yup
          .string()
          .required("Discount is required !")
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter discount !")
          .typeError("Enter discount !"),
        hsn_code: yup.string().notRequired(),
        amount: yup
          .string()
          .required("Amount is required !")
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid amount !"),
      })
    ),
    discount: yup
      .string()
      .matches(/^\d+(\.\d+)?$/, "Enter valid discount")
      .required("Discount is required"),
    // selfile: yup
    //     .mixed()
    //     .test('fileSize', 'Image can be uploaded upto 10 MB',
    //         (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024)),
  })
  .required();

function EditQuotation() {
  let navigate = useNavigate();
  let location = useLocation();
  const [supplierList, setSupplierList] = useState({
    data: [],
    loading: false,
  });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [prod_List, setProd_List] = useState({ data: [], loading: false });
  const [prodType, setProdType] = useState({ data: [], loading: false });
  const [quote_status, setQuote_Status] = useState({
    data: [],
    loading: false,
  });
  const [internalCompList, setInternalComp] = useState({
    data: [],
    loading: false,
  });
  const [gstErr, setGstErr] = useState(false);

  const [galVisible, setGalVisible] = useState(false);

  const [disableLineItems, setDisableLineItems] = useState(true);

  const [drawingFiles, setDrawingFiles] = useState({
    loading: false,
    data: [],
  });
  const [taskImgs, setTaskImgs] = useState({ loading: false, data: [] });

  const [constLabel, setConstLabel] = useState({});

  const {
    register,
    handleSubmit,
    formState,
    control,
    setValue,
    getValues,
    watch,
    reset,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      items: [
        {
          prod_type: [],
          product: [],
          quantity: 0,
          unit_price: 0,
          unit: [],
          amount: 0,
          gst_rate: 0,
          gst_amount: 0,
          discount: 0,
        },
      ],
    },
  });
  let { errors } = formState;
  let lineItems = watch("items");
  const supp = watch("supplier");
  const qfor = watch("quotation_for");

  const [subTotalForRef, setSubtotalForRef] = useState(0);
  const [taxDetails, setTaxDetails] = useState({});

  let subTotalForRef2 = "";

  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  const userData = JSON.parse(localStorage.getItem("localData"));

  useEffect(() => {
    // window.history.pushState(null, "", window.location.href);
    // window.onpopstate = function () {
    //   window.history.pushState(null, "", window.location.href);
    // };
    setConstLabel(getLabels("Master", "Supplier Quotation"));
    fetchUOM();
    fetchSuppliers();
    // fetchProdList();
    fetchStatus();
    fetchProductType();
    fetchInternalComp();
  }, []);

  useEffect(() => {
    if (location.state !== undefined) {
      fetchImage();
    }
  }, [location.state]);

  useEffect(() => {
    console.log("supp", supp);
    console.log("qfor", qfor);

    if (supp !== undefined && qfor !== undefined) {
      if (supp.length > 0 && supp[0].gst !== undefined && qfor.length > 0) {
        if (
          qfor[0].comGSTnumber !== null &&
          qfor[0].comGSTnumber !== undefined
        ) {
          fetchGST({
            suplier_gst_number: supp[0].gst,
            // invoice_gst_number: invTo[0].comGSTnumber
            invoice_gst_number: qfor[0].comGSTnumber,
          });
        }
        console.log("false");
        setDisableLineItems(false);
      } else {
        console.log("true");
        setDisableLineItems(true);
      }
    }
  }, [supp, qfor]);

  function fetchImage() {
    setTaskImgs({ ...taskImgs, loading: true, data: [] });
    get_master_files({
      // ref_model_id: "",
      ref_model_uuid: location.state?.uuid,
      // model_name: "PO",
      // document_type: "image"
    }).then(
      (res) => {
        setTaskImgs({ ...taskImgs, loading: false, data: res.data.data });
      },
      (err) => {
        setTaskImgs({ ...taskImgs, loading: false, data: [] });
      }
    );
  }

  function fetchInternalComp() {
    setInternalComp({ ...internalCompList, data: [], loading: true });

    getInternalCompany().then(
      (res) => {
        setInternalComp({
          ...internalCompList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        setInternalComp({ ...internalCompList, data: [], loading: false });
      }
    );
  }

  function fetchGST(payload) {
    getGST(payload).then(
      (res) => {
        setTaxDetails(res.data);

        const subTot = parseFloat(getValues("sub_total"));

        // setValue("SGST_amount", ((subTot * res.data.sgst) / 100).toFixed(2));
        // setValue("CGST_amount", ((subTot * res.data.cgst) / 100).toFixed(2));
        // setValue("IGST_amount", ((subTot * res.data.igst) / 100).toFixed(2));
        // setValue("UGST_amount", ((subTot * res.data.ugst) / 100).toFixed(2));

        // setValue("CGST", res.data.cgst);
        // setValue("SGST", res.data.sgst);
        // setValue("IGST", res.data.igst);
        // setValue("UGST", res.data.ugst);

        setGstErr(false);
      },
      (err) => {
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
        setGstErr(true);
      }
    );
  }

  useEffect(() => {
    if (location.state.sgst_cost != 0) {
      taxDetails.type = "sgst";
      taxDetails.sgst = location.state.sgst;
      taxDetails.cgst = location.state.cgst;
    } else if (location.state.igst_cost != 0) {
      taxDetails.type = "igst";
      taxDetails.igst = location.state.igst;
    } else if (location.state.ugst_cost != 0) {
      taxDetails.type = "ugst";
      taxDetails.ugst = location.state.ugst;
    }

    let dis = getValues("discount");

    if (lineItems !== undefined) {
      if (lineItems.length > 0) {
        calSubAmt();
      }
    }

    if (dis !== null && dis !== undefined && dis.toString().length > 0) {
      cal_final_total_with_tax_details();
    }
  }, [lineItems]);

  function calSubAmt() {
    const totalAmount = lineItems.reduce(
      (accumulator, item) => parseFloat(accumulator) + parseFloat(item.amount),
      0
    );

    setValue("sub_total", totalAmount.toFixed(2));
    setSubtotalForRef(parseFloat(totalAmount).toFixed(2));
    subTotalForRef2 = parseFloat(totalAmount).toFixed(2);
  }

  // useEffect(() => {
  //   if (location.state?.uuid) {
  //     const payload = {
  //       quote_id: location.state?.uuid,
  //     };
  //     getQuote_Products(payload).then(
  //       (res) => {
  //         var quoteProdArr = [];
  //         if (res.data.data?.length > 0) {
  //           for (let i = 0; i < res.data.data.length; i++) {
  //             quoteProdArr.push({
  //               uuid: res.data.data[i].uuid,
  //               quantity: res.data.data[i].quantity,
  //               amount: res.data.data[i].amount,
  //               unit_price: res.data.data[i].rate,
  //               unit: [
  //                 {
  //                   uom: res.data.data[i].unit,
  //                   uuid: res.data.data[i].unit_id,
  //                 },
  //               ],
  //               product: [
  //                 {
  //                   productName: res.data.data[i].product_name ?? "",
  //                   // productName: res.data.data[i].product_name !== null ? res.data.data[i].product_name : "",
  //                   uuid: res.data.data[i].product_id ?? "",
  //                   productId: res.data.data[i].productId ?? "",
  //                 },
  //                 ,
  //               ],
  //               prod_type: [
  //                 {
  //                   productType: res.data.data[i].producttype,
  //                   uuid: res.data.data[i].product_type_id,
  //                 },
  //               ],
  //             });
  //           }

  //           setValue("items", quoteProdArr);
  //           setValue("supplier", [
  //             {
  //               name: location.state?.supplier_name,
  //               uuid: location.state?.supplierid_id,
  //               partyCode: location.state?.supplier,
  //             },
  //           ]);
  //           setValue("quotation_for", [location.state?.quotationForDetails]);
  //           setValue("discount", location.state?.discount);

  //           setValue("CGST_amount", location.state?.cgst_cost);
  //           setValue("CGST", location.state?.cgst);
  //           setValue("SGST_amount", location.state?.sgst_cost);
  //           setValue("SGST", location.state?.sgst);

  //           setValue("IGST", location.state?.igst);
  //           setValue("IGST_amount", location.state?.igst_cost);

  //           setValue("UGST", location.state?.ugst);
  //           setValue("UGST_amount", location.state?.ugst_cost);

  //           // setValue("final_total", location.state?.final_total);
  //         }
  //       },
  //       (err) => {
  //         toast.error(err.response.data.message, {
  //           autoClose: 3000,
  //         });
  //       }
  //     );
  //   }
  // }, []);

  useEffect(() => {
    console.log("location.state", location.state);

    var quoteProdArr = [];
    if (location.state?.quote_products?.length > 0) {
      for (let i = 0; i < location.state?.quote_products.length; i++) {
        quoteProdArr.push({
          uuid: location.state?.quote_products[i].uuid,
          quantity: location.state?.quote_products[i].quantity,
          amount: location.state?.quote_products[i].amount,
          unit_price: location.state?.quote_products[i].rate,
          gst_rate: location.state?.quote_products[i].gst_rate,
          gst_amount: location.state?.quote_products[i].gst_amount,
          discount: location.state?.quote_products[i].discount,
          hsn_code:
            location.state?.quote_products[i].prod_details?.hsn_model
              ?.hsn_sac_code ?? "",
          unit: [
            {
              uom: location.state?.quote_products[i].unit,
              uuid: location.state?.quote_products[i].unit_id,
            },
          ],
          product: [
            {
              productName: location.state?.quote_products[i].product_name ?? "",
              // productName: location.state?.quote_products[i].product_name !== null ? location.state?.quote_products[i].product_name : "",
              uuid: location.state?.quote_products[i].product_id ?? "",
              productId: location.state?.quote_products[i].productId ?? "",
            },
            ,
          ],
          prod_type: [
            {
              productType: location.state?.quote_products[i].producttype,
              uuid: location.state?.quote_products[i].product_type_id,
            },
          ],
        });
      }

      setValue("items", quoteProdArr);
      setValue("supplier", [
        {
          name: location.state?.supplier_name,
          uuid: location.state?.supplierid_id,
          partyCode: location.state?.supplier,
          gst: location.state?.supplierDetails?.gst,
        },
      ]);
      setValue("quotation_for", [location.state?.quotationForDetails]);
      setValue("discount", location.state?.discount ?? 0);

      setValue("CGST_amount", location.state?.cgst_cost);
      setValue("CGST", location.state?.cgst);
      setValue("SGST_amount", location.state?.sgst_cost);
      setValue("SGST", location.state?.sgst);

      setValue("IGST", location.state?.igst);
      setValue("IGST_amount", location.state?.igst_cost);

      setValue("UGST", location.state?.ugst);
      setValue("UGST_amount", location.state?.ugst_cost);

      setValue("final_total", location.state?.final_total);
    }
  }, []);

  function fetchStatus() {
    setQuote_Status({ ...quote_status, data: [], loading: true });
    getStatusMaster().then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.model_name == "quote"
        );
        setQuote_Status({ ...quote_status, data: result, loading: false });

        // setValue('status', result)
      },
      (err) => {
        setQuote_Status({ ...quote_status, data: [], loading: false });
      }
    );
  }

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM().then(
      (res) => {
        setUOMList({ ...uomList, data: res.data.data, loading: false });
      },
      (err) => {
        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  function fetchProdList(selVal) {
    if (selVal.length > 0) {
      setProd_List({ ...prod_List, loading: true, data: [] });

      getProdList({ product_type_id: selVal[0].uuid }).then(
        (res) => {
          setProd_List({ ...prod_List, loading: false, data: res.data.data });
        },
        (err) => {}
      );
    } else {
    }
  }

  function handleProdType(selVal, index) {
    setValue(`items[${index}].prod_type`, selVal);
    setValue(`items[${index}].product`, []);
    if (selVal.length > 0) {
      fetchProdList(selVal);
    } else {
      setProd_List({ ...prod_List, loading: false, data: [] });
    }
  }

  function fetchProductType() {
    setProdType({ ...prodType, data: [], loading: true });
    getProductType().then(
      (res) => {
        setProdType({ ...prodType, data: res.data.data, loading: false });
      },
      (err) => {
        setProdType({ ...prodType, data: [], loading: false });
      }
    );
  }

  function fetchSuppliers() {
    setSupplierList({ ...supplierList, state: true, data: [] });
    getSupplier({ partyType: "Supplier" }).then(
      (res) => {
        // const result = res.data.data.filter((item) => item.name !== null);
        setSupplierList({
          ...supplierList,
          state: false,
          data: res.data.data,
        });
      },
      (err) => {
        //
        setSupplierList({ ...supplierList, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }

  const onSubmit = (data) => {
    let reqPayload = [];
    if (data !== "") {
      for (let i = 0; i < data.items.length; i++) {
        const products = {
          product_name: data.items[i]?.product[0]?.productName,
          product_id: data.items[i]?.product[0]?.uuid,
          productId: data.items[i]?.product[0]?.productId,
          producttype: data.items[i]?.prod_type[0]?.productType,
          product_type_id: data.items[i]?.prod_type[0]?.uuid,
          quantity: data.items[i]?.quantity,
          unit: data.items[i]?.unit[0]?.uom,
          rate: parseFloat(data.items[i]?.unit_price),
          unit_id: data.items[i]?.unit[0]?.uuid,
          amount: parseFloat(data.items[i]?.amount),
          gst_rate: parseFloat(data.items[i]?.gst_rate),
          gst_amount: parseFloat(data.items[i]?.gst_amount),
          discount: parseFloat(data.items[i]?.discount),
          uuid: data.items[i]?.uuid,
        };

        reqPayload.push(products);
      }

      const payload = {
        uuid: location.state?.uuid,
        supplier: data.supplier[0]?.partyCode,
        quotation_for_id: data.quotation_for[0].uuid,
        supplier_name: data.supplier[0]?.name,
        supplierid_id: data.supplier[0]?.uuid,
        quote_date: convertDateYYMMDD(data.quote_date, "-"),
        remark: data.remark !== "" ? data.remark : null,
        // quantity: data.quantity,
        sub_total: data.sub_total,
        // cgst: data.cgst,
        // cgst_cost: data.cgst_cost,
        // sgst: data.sgst,
        // sgst_cost: data.sgst_cost,
        final_total: data.final_total.toString(),
        // client_id: userData.client_id,
        status_name: data.status[0].status_name,
        status: data.status[0].uuid,
        quote_products: reqPayload,
        discount: parseFloat(data.discount),
        // internal_status: "",
      };

      if (taxDetails.type == "sgst") {
        payload.cgst = 0;
        payload.cgst_cost = parseFloat(data.CGST_amount);
        payload.sgst = 0;
        payload.sgst_cost = parseFloat(data.SGST_amount);
      } else if (taxDetails.type == "igst") {
        payload.igst = 0;
        payload.igst_cost = parseFloat(data.IGST_amount);
      } else if (taxDetails.type == "ugst") {
        payload.ugst = 0;
        payload.ugst_cost = parseFloat(data.UGST_amount);
        payload.cgst = 0;
        payload.cgst_cost = parseFloat(data.CGST_amount);
      }

      // if (taskImgs.data.length > 0) {
      //   const fileUuids = drawingFiles.data
      //     .filter((item) => item.uuid !== null && item.uuid !== undefined)
      //     .map((item) => item.uuid);

      //   if (fileUuids.length > 0) {
      //     payload.file_uuids = JSON.stringify(fileUuids);
      //   }
      // }

      // let newImg = drawingFiles.data.filter((item) => !item.uuid);

      updateQuotation(payload).then(
        async (res) => {
          if (res.status === 200 || res.status === 201) {
            // toast.success("Quotation revised successfully !", {
            //   autoClose: 3000,
            // });
            // setTimeout(() => {
            //   navigate("/pcms/quotation/");
            // }, 3000); // Wait for 3 seconds before navigating

            // for (let i = 0; i < newImg.length; i++) {
            //   newImg[i].ref_model_id = "";
            //   newImg[i].ref_model_uuid = res.data?.qoute?.uuid;
            //   newImg[i].model_name = "Quotation";
            //   newImg[i].name = newImg[i].filename;
            //   newImg[i].document_type = "image";
            // }

            // let imgArr = {};
            // let formDataToSend = new FormData();
            // for (let a = 0; a < newImg.length; a++) {
            //   imgArr["attachment" + (a + 1)] = newImg[a].file;
            //   imgArr["obj" + (a + 1)] = JSON.stringify(newImg[a]);
            //   imgArr["img_count"] = newImg.length;

            //   for (const [key, value] of Object.entries(imgArr)) {
            //     formDataToSend.append(key, value);
            //   }
            // }

            toast.success("Supplier quotation updated successfully !", {
              autoClose: 2000,
            });
            setTimeout(() => {
              navigate("/pcms/quotation");
              reset();
            }, [2000]);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  // async function calRate(para1, para2, index) {
  //   let rate;

  //   if (para1.length == 0) {
  //     para1 = 0.0;
  //   }

  //   switch (para2) {
  //     case "quantity":
  //       // items[${index}].price
  //       const price = parseFloat(
  //         getValues(`items[${index}].unit_price`)
  //       ).toFixed(2);

  //       rate = para1 * price;

  //       setValue(`items[${index}].amount`, rate.toFixed(2));

  //       // setValue("final_total", rate.toFixed(2));
  //       await calSubAmt();
  //       cal_final_total_with_tax_details();

  //       break;

  //     case "unit_price":
  //       const quantity = parseFloat(getValues(`items[${index}].quantity`));
  //       rate = para1 * quantity;
  //       setValue(`items[${index}].amount`, rate.toFixed(2));
  //       // setValue("final_total", rate.toFixed(2));
  //       await calSubAmt();
  //       cal_final_total_with_tax_details();

  //       break;
  //   }
  // }
  async function calRate(para1, para2, index) {
    let rate;
    let gst_rate;
    let gst_amount;
    let discount;
    let discounted_amt;
    let amount;
    let quantity;
    let unit_price;
    para1 = Number(para1);

    if (para1.length == 0) {
      para1 = parseFloat(0).toFixed(2);
    }

    switch (para2) {
      case "quantity":
        // items[${index}].price
        const price = parseFloat(
          getValues(`items[${index}].unit_price`)
        ).toFixed(2);

        gst_rate = Number(getValues(`items[${index}].gst_rate`));
        discount = parseFloat(getValues(`items[${index}].discount`));

        rate = parseFloat((para1 * Number(price)).toFixed(2));

        discounted_amt = parseFloat((rate * discount) / 100).toFixed(2);

        gst_amount = parseFloat(
          (((rate - discounted_amt) * gst_rate) / 100).toFixed(2)
        );

        console.log(rate);
        console.log(gst_amount);
        amount = rate - discounted_amt;

        setValue(`items[${index}].amount`, amount.toFixed(2));
        setValue(`items[${index}].gst_amount`, gst_amount);

        await calSubAmt();
        cal_final_total_with_tax_details();

        break;

      case "unit_price":
        quantity = Number(getValues(`items[${index}].quantity`));

        rate = parseFloat((para1 * quantity).toFixed(2));

        gst_rate = Number(getValues(`items[${index}].gst_rate`));
        discount = parseFloat(getValues(`items[${index}].discount`));

        discounted_amt = parseFloat((rate * discount) / 100).toFixed(2);

        gst_amount = parseFloat(
          (((Number(rate) - discounted_amt) * Number(gst_rate)) / 100).toFixed(
            2
          )
        );

        amount = rate - discounted_amt;

        setValue(`items[${index}].amount`, amount.toFixed(2));
        setValue(`items[${index}].gst_amount`, gst_amount);

        await calSubAmt();
        cal_final_total_with_tax_details();
        break;

      case "gst_rate":
        quantity = Number(getValues(`items[${index}].quantity`));
        unit_price = Number(getValues(`items[${index}].unit_price`));
        discount = parseFloat(getValues(`items[${index}].discount`));

        discounted_amt = parseFloat(
          ((quantity * unit_price * discount) / 100).toFixed(2)
        );
        const result = parseFloat(
          (
            ((Number(quantity) * Number(unit_price) - discounted_amt) * para1) /
            100
          ).toFixed(2)
        );

        console.log("result", result);
        setValue(`items[${index}].gst_amount`, result);

        amount = quantity * unit_price - discounted_amt;

        setValue(`items[${index}].amount`, amount.toFixed(2));

        await calSubAmt();
        cal_final_total_with_tax_details();
        break;

      case "discount":
        quantity = Number(getValues(`items[${index}].quantity`));
        unit_price = Number(getValues(`items[${index}].unit_price`));
        gst_rate = Number(getValues(`items[${index}].gst_rate`));
        discounted_amt = parseFloat(
          ((quantity * unit_price * para1) / 100).toFixed(2)
        );

        const result2 = parseFloat(
          (
            ((Number(quantity) * Number(unit_price) - discounted_amt) *
              gst_rate) /
            100
          ).toFixed(2)
        );
        setValue(`items[${index}].gst_amount`, result2);

        amount = quantity * unit_price - discounted_amt;

        setValue(`items[${index}].amount`, amount.toFixed(2));

        await calSubAmt();
        cal_final_total_with_tax_details();
        break;
    }
  }

  async function cal_final_total_with_tax_details() {
    await calSubAmt();

    // let SGST = parseFloat(getValues("SGST"));
    // let CGST = parseFloat(getValues("CGST"));

    // Discount Calcultaion logic
    // console.log("taxDetails.type", taxDetails.type);
    const line_items = getValues("items");

    // console.log("line_items", line_items);

    if (!taxDetails.type || line_items?.length == 0) {
      setValue("final_total", "0.00");
    }

    let total_of_gst_amount = 0;
    let total_of_amt_after_discount = 0;

    if (line_items.length > 0) {
      line_items.forEach((val) => {
        total_of_gst_amount += val.gst_amount ? val.gst_amount : 0;
        total_of_amt_after_discount += Number(val.amount);
      });
    }

    // console.log("line_items", line_items);

    // console.log("total_of_gst_amount", total_of_gst_amount);
    // console.log("total_of_amt_after_discount", total_of_amt_after_discount);

    if (taxDetails.type && line_items.length > 0) {
      setValue(
        "final_total",
        (
          Number(total_of_gst_amount) + Number(total_of_amt_after_discount)
        ).toFixed(2)
      );
    }

    if (taxDetails.type == "sgst") {
      setValue("SGST_amount", (Number(total_of_gst_amount) / 2).toFixed(2));
      setValue("CGST_amount", (Number(total_of_gst_amount) / 2).toFixed(2));
    } else if (taxDetails.type == "igst") {
      setValue("IGST_amount", Number(total_of_gst_amount).toFixed(2));
    } else if (taxDetails.type == "ugst") {
      setValue("CGST_amount", (Number(total_of_gst_amount) / 2).toFixed(2));
      setValue("UGST_amount", (Number(total_of_gst_amount) / 2).toFixed(2));
    }
  }

  function delQuoteProduct(index) {
    SweetAlert.fire({
      title: "Are you sure to delete",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (fields[index].uuid !== null && fields[index].uuid !== undefined) {
          deleteQuoteProduct({ uuid: fields[index].uuid }).then(
            (res) => {
              remove(index);
              toast.success("Product deleted successfully !", {
                autoClose: 1000,
              });
            },
            (err) => {
              toast.error("Something went wrong ");
            }
          );
        } else {
          remove(index);
        }
      }
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <EditQuoteImageGallary
        setGalVisible={setGalVisible}
        galVisible={galVisible}
        taskImgs={taskImgs}
        setDrawingFiles={setDrawingFiles}
        drawingFiles={drawingFiles}
        fetchImage={fetchImage}
        locData={location.state}
        constLabel={constLabel}
      />

      <div className="manufac_goods_form mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                navigate("/pcms/quotation/");
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_revise_supplier_quotation
                ? constLabel.lbl_revise_supplier_quotation
                : "Revise Supplier Quotation"}
            </p>
          </div>
          <div className="card-body">
            <div className="createNc container-fluid">
              <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_quotation_detail
                  ? constLabel.lbl_quotation_detail
                  : "Quotation Detail"}
              </p>
              <hr className="my-2" />

              <div className="row my-4">
                <div className="col-12 col-md-4">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_quotation_for
                      ? constLabel.lbl_quotation_for
                      : "Quotation For"}
                    <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      name={`quotation_for`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="comName"
                          multiple={false}
                          options={internalCompList.data}
                          isLoading={internalCompList.loading}
                          placeholder="Select company"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.quotation_for ? true : false}
                        />
                      )}
                    />
                    {!internalCompList.loading && !errors.quotation_for && <span className="arrow-icon"></span>}
                    {errors.quotation_for && (
                      <span className="text-danger err-msg">
                        {errors.quotation_for.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_supplier_name
                      ? constLabel.lbl_supplier_name
                      : "Supplier Name"}
                    <span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>
                    <Controller
                      name={`supplier`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="name"
                          multiple={false}
                          options={supplierList.data}
                          isLoading={supplierList.loading}
                          placeholder="Select supplier"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.supplier ? true : false}
                        />
                      )}
                    />
                    {!supplierList.loading && !errors.supplier && <span className="arrow-icon"></span>}
                    {errors.supplier && (
                      <span className="text-danger err-msg">
                        {errors.supplier.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_date ? constLabel.lbl_date : "Date"}
                    <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      name="quote_date"
                      control={control}
                      defaultValue={new Date(location.state?.quote_date)}
                      render={({ field }) => (
                        <DatePicker
                          className={
                            errors.quote_date
                              ? "form-control mt-2 is-invalid"
                              : "form-control mt-2"
                          }
                          {...field}
                          defaultSelected={new Date(location.state?.quote_date)}
                          showYearDropdown
                          scrollableMonthYearDropdown
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                          maxDate={new Date()}
                          placeholderText="Select a date"
                          autoComplete="off"
                        />
                      )}
                    />
                    {errors.quote_date && (
                      <span className="text-danger err-msg">
                        {errors.quote_date.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_status ? constLabel.lbl_status : "Status"}
                    <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      name={`status`}
                      control={control}
                      defaultValue={[
                        {
                          status_name: location.state?.status_name,
                          uuid: location.state?.status,
                        },
                      ]}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="status_name"
                          defaultSelected={[
                            {
                              status_name: location.state?.status_name,
                              uuid: location.state?.status,
                            },
                          ]}
                          multiple={false}
                          options={quote_status.data}
                          isLoading={quote_status.loading}
                          placeholder="Select status"
                          onChange={(e) => {
                            setValue("status", e);
                            trigger("remark");
                          }}
                          onBlur={onBlur}
                          // selected={value}
                          isInvalid={errors.status ? true : false}
                          filterBy={() => true}
                        />
                      )}
                    />
                    {!quote_status.loading && !errors.status && <span className="arrow-icon"></span>}
                    {errors.status && (
                      <span className="text-danger err-msg">
                        {errors.status.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_remark ? constLabel.lbl_remark : "Remark"}
                  </label>
                    <textarea
                      className={
                        errors.remark
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      id="remark"
                      name="remark"
                      defaultValue={location.state?.remark}
                      {...register("remark")}
                      placeholder="Enter remarks"
                    ></textarea>

                    {errors.remark && (
                      <span className="text-danger err-msg">
                        {errors.remark.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <p className="mt-2 mb-4">
                <i>
                  {" "}
                  <span className="text-danger roboto-bold">Note: </span>
                  <FontAwesomeIcon
                    style={{
                      color: "#c7c7c7",
                    }}
                    icon={faCircle}
                    size="2xs"
                    className="mx-2"
                  />
                  Product type must be selected first then product
                </i>
                <i className=" d-block">
                  {" "}
                  <span className="text-danger roboto-bold invisible">
                    Note:{" "}
                  </span>
                  <FontAwesomeIcon
                    style={{
                      color: "#c7c7c7",
                    }}
                    icon={faCircle}
                    size="2xs"
                    className="mx-2"
                  />
                  Remarks are mandatory for rejected status
                </i>
              </p>

              <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_product_detail
                  ? constLabel.lbl_product_detail
                  : "Product Detail"}
              </p>

              <div className="py-3" style={{ maxHeight: "80vh" }}>
                <table className="table table-responsive table-bordered">
                  <thead className="thead-light text-center">
                    <tr>
                      <th scope="col">
                        {constLabel?.lbl_product_type
                          ? constLabel.lbl_product_type
                          : "Product Type"}
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_product_name
                          ? constLabel.lbl_product_name
                          : "Product Name"}
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_quantity
                          ? constLabel.lbl_quantity
                          : "Quantity"}
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_unit_price
                          ? constLabel.lbl_unit_price
                          : "Unit Price "}
                        <span>&#8377;</span>
                      </th>
                      <th scope="col">
                        {constLabel?.gst_rate ? constLabel.gst_rate : "GST %"}
                      </th>
                      <th scope="col">
                        {constLabel?.hsn_code
                          ? constLabel.hsn_code
                          : "HSN Code"}
                      </th>
                      {/* <th scope="col">
                        {constLabel?.gst_amount
                          ? constLabel.gst_amount
                          : "GST Amount "}
                        <span>&#8377;</span>
                      </th> */}
                      <th scope="col">
                        {constLabel?.discount
                          ? constLabel.discount
                          : "Discount "}
                        <span>%</span>
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_unit ? constLabel.lbl_unit : "Unit"}
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_amount
                          ? constLabel.lbl_amount
                          : "Amount (After Discount)"}
                        <span>&#8377;</span>
                      </th>
                      <th scope="col">{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map(({ id }, index) => (
                      <tr key={id} className="f-18">
                        <td style={{ verticalAlign: "top", width: "15%" }}>
                          <Controller
                            name={`items[${index}].prod_type`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="productType"
                                size="sm"
                                multiple={false}
                                options={prodType.data}
                                isLoading={prodType.loading}
                                placeholder="Select type"
                                // onChange={onChange}
                                onChange={(selected) => {
                                  handleProdType(selected, index);
                                }}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.prod_type
                                    ? true
                                    : false
                                }
                                disabled={disableLineItems}
                              />
                            )}
                          />

                          {errors.items?.[index]?.prod_type && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.prod_type.message}
                            </span>
                          )}

                          {index + 1 == fields.length ? (
                            <div>
                              <button
                                style={{ fontSize: "16px" }}
                                className="btn btn-sm button-primary px-2 mt-2"
                                onClick={() => {
                                  append({
                                    product_type: [],
                                    product: [],
                                    quantity: 0,
                                    unit_price: 0,
                                    unit: [],
                                    amount: 0,
                                    discount: 0,
                                  });
                                }}
                              >
                                <FontAwesomeIcon
                                  style={{
                                    color: "#ffffff",
                                    marginRight: "5px",
                                  }}
                                  icon={faAdd}
                                  size="sm"
                                />{" "}
                                {constLabel?.lbl_add
                                  ? constLabel.lbl_add
                                  : "Add"}
                              </button>
                            </div>
                          ) : null}
                        </td>

                        <td style={{ verticalAlign: "top", width: "22%" }}>
                          <Controller
                            name={`items[${index}].product`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="productName"
                                size="sm"
                                multiple={false}
                                options={prod_List.data}
                                isLoading={prod_List.loading}
                                placeholder="Select product"
                                onChange={(selected) => {
                                  console.log("selected", selected);
                                  if (selected.length > 0) {
                                    setValue(
                                      `items[${index}].product`,
                                      selected,
                                      { shouldValidate: true }
                                    );
                                    setValue(
                                      `items[${index}].gst_rate`,
                                      selected[0].hsn_uuid?.gst_rate ?? 0,
                                      { shouldValidate: true }
                                    );
                                    setValue(
                                      `items[${index}].hsn_code`,
                                      selected[0].hsn_uuid?.hsn_sac_code ?? "",
                                      { shouldValidate: true }
                                    );

                                    setValue(
                                      `items[${index}].unit`,
                                      selected[0].unit_id ?? "",
                                      { shouldValidate: true }
                                    );

                                    calRate(
                                      selected[0].hsn_uuid?.gst_rate ?? 0,
                                      "gst_rate",
                                      index
                                    );
                                  } else {
                                    setValue(`items[${index}].product`, [], {
                                      shouldValidate: true,
                                    });
                                    setValue(`items[${index}].gst_rate`, [], {
                                      shouldValidate: true,
                                    });
                                    setValue(`items[${index}].unit`, [], {
                                      shouldValidate: true,
                                    });
                                  }
                                }}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.product ? true : false
                                }
                                disabled={disableLineItems}
                              />
                            )}
                          />

                          {errors.items?.[index]?.product && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.product.message}
                            </span>
                          )}

                          {index + 1 == fields.length ? (
                            <AddProduct fetchProdList={fetchProdList} />
                          ) : null}
                        </td>

                        <td style={{ verticalAlign: "top", width: "9%" }}>
                          <input
                            className={
                              errors.items?.[index]?.quantity
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            // defaultValue={0}
                            name={`items[${index}].quantity`}
                            {...register(`items[${index}].quantity`, {
                              onChange: (e) =>
                                calRate(e.target.value, "quantity", index),
                            })}
                            disabled={disableLineItems}
                          />

                          {errors.items?.[index]?.quantity && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.quantity.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top", width: "14%" }}>
                          <input
                            className={
                              errors.items?.[index]?.unit_price
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            // defaultValue={0}
                            name={`items[${index}].unit_price`}
                            {...register(`items[${index}].unit_price`, {
                              onChange: (e) =>
                                calRate(e.target.value, "unit_price", index),
                            })}
                            disabled={disableLineItems}
                          />

                          {errors.items?.[index]?.unit_price && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.unit_price.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top", width: "6%" }}>
                          <input
                            className={
                              errors.items?.[index]?.gst_rate
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            // defaultValue={0}
                            name={`items[${index}].gst_rate`}
                            {...register(`items[${index}].gst_rate`, {
                              onChange: (e) => {
                                console.log("e", e);
                                calRate(e.target.value, "gst_rate", index);
                              },
                            })}
                            disabled={disableLineItems}
                          />

                          {errors.items?.[index]?.gst_rate && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.gst_rate.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top", width: "9%" }}>
                          <input
                            className={
                              errors.items?.[index]?.hsn_code
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            // defaultValue={0}
                            name={`items[${index}].hsn_code`}
                            {...register(`items[${index}].hsn_code`)}
                            disabled
                          />

                          {errors.items?.[index]?.hsn_code && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.hsn_code.message}
                            </span>
                          )}
                        </td>

                        {/* <td style={{ verticalAlign: "top", }}>
                          <input
                            className={
                              errors.items?.[index]?.gst_amount
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            // defaultValue={0}
                            name={`items[${index}].gst_amount`}
                            {...register(`items[${index}].gst_amount`)}
                            disabled
                          />

                          {errors.items?.[index]?.gst_amount && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.gst_amount.message}
                            </span>
                          )}
                        </td> */}

                        <td style={{ verticalAlign: "top", width: "6%" }}>
                          <input
                            className={
                              errors.items?.[index]?.discount
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            // defaultValue={0}
                            name={`items[${index}].discount`}
                            {...register(`items[${index}].discount`, {
                              onChange: (e) =>
                                calRate(e.target.value, "discount", index),
                            })}
                            disabled={disableLineItems}
                          />

                          {errors.items?.[index]?.discount && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.discount.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top", width: "10%" }}>
                          <Controller
                            name={`items[${index}].unit`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="uom"
                                size="sm"
                                multiple={false}
                                options={uomList.data}
                                isLoading={uomList.loading}
                                placeholder="Select unit"
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.unit ? true : false
                                }
                                disabled={disableLineItems}
                              />
                            )}
                          />

                          {errors.items?.[index]?.unit && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.unit.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top", width: "10%" }}>
                          <input
                            className={
                              errors.items?.[index]?.amount
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            // value={
                            //   (parseFloat(watch(`items[${index}].quantity`)) *
                            //     parseFloat(watch(`items[${index}].unit_price`))).toFixed(2)
                            // }
                            name={`items[${index}].amount`}
                            {...register(`items[${index}].amount`)}
                            disabled
                          />

                          {errors.items?.[index]?.amount && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.amount.message}
                            </span>
                          )}
                        </td>

                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "top",
                            width: "5%",
                          }}
                        >
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="tooltip">
                                {constLabel?.lbl_delete_product
                                  ? constLabel.lbl_delete_product
                                  : "Delete Product"}
                              </Tooltip>
                            }
                          >
                            <div
                              className={
                                location.state?.status_name == "Approved"
                                  ? "button-blur"
                                  : "deleteIcon cursor_pointer"
                              }
                              disabled={
                                location.state?.status_name == "Approved"
                                  ? true
                                  : false
                              }
                              onClick={
                                index > 0
                                  ? () => {
                                      if (
                                        location.state?.status_name !==
                                        "Approved"
                                      ) {
                                        delQuoteProduct(index);
                                      }
                                    }
                                  : null
                              }
                            >
                              <Trash2 color="#FFFFFF" size={18} />
                            </div>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <hr className="my-2" />

              <div
                className="p-3 ms-auto col-12 col-md-6"
                style={{ border: "1px solid #c6c6c6", borderRadius: "2px" }}
              >
                <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-3"
                    >
                      {constLabel?.lbl_sub_total
                        ? constLabel.lbl_sub_total
                        : "Sub Total"}{" "}
                      <span>
                        <i style={{ color: "red" }}>*</i>
                      </span>
                    </label>

                    <div className="col-12 col-md-4  d-flex align-items-center">
                      <span>&#8377;</span>
                      <input
                        className="form-control form-control-sm ms-2"
                        type="number"
                        disabled
                        value={subTotalForRef}
                        defaultValue={location.state?.sub_total}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-3"
                    >
                      {constLabel?.lbl_discount
                        ? constLabel.lbl_discount
                        : "Discount"}{" "}
                      (%)
                    </label>

                    <div className="col-12 col-md-4">
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        {...register("discount", {
                          onChange: (e) => cal_final_total_with_tax_details(),
                        })}
                        max={100}
                        min={0}
                        placeholder="Enter discount"
                      />
                      {errors.discount && (
                        <span className="text-danger err-msg">
                          {errors.discount.message}
                        </span>
                      )}
                    </div>

                    <div className="col-12 col-md-4 d-flex align-items-center">
                      <span>&#8377;</span>
                      <input
                        className="form-control form-control-sm ms-2"
                        type="text"
                        disabled
                        {...register("discount_amount")}
                        defaultValue={0}
                        placeholder="Discounted Amount"
                      />
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-3"
                    >
                      {constLabel?.lbl_amountAfter_discount
                        ? constLabel.lbl_amountAfter_discount
                        : "Amount after discount"}{" "}
                      <span>&#8377;</span>
                    </label>

                    <div className="col-12 col-md-4 ">
                      <input
                        className="form-control form-control-sm"
                        type="number"
                        {...register("sub_total")}
                        disabled
                      />
                    </div>
                  </div>
                </div> */}

                <hr />

                {taxDetails.type == "sgst" ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-3"
                      >
                        {constLabel?.lbl_cgst ? constLabel.lbl_cgst : "CGST"}{" "}
                        (%)
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      {/* <div className="col-12 col-md-4">
                        <input
                          className="form-control form-control-sm"
                          type="number"
                          placeholder="Enter cgst"
                          {...register("CGST")}
                          value={taxDetails.cgst}
                          disabled
                        />
                        {errors.cgst && (
                          <span className="text-danger err-msg">
                            {errors.cgst.message}
                          </span>
                        )}
                      </div> */}
                      <div className="col-12 col-md-4 d-flex align-items-center">
                        <span>&#8377;</span>
                        <input
                          className="form-control form-control-sm ms-2"
                          type="number"
                          disabled
                          {...register("CGST_amount")}
                          placeholder="CGST amount"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {taxDetails.type == "sgst" ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-3"
                      >
                        {constLabel?.lbl_sgst ? constLabel.lbl_sgst : "SGST"}{" "}
                        (%)
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      {/* <div className="col-12 col-md-4">
                        <input
                          className="form-control form-control-sm"
                          type="number"
                          placeholder="Enter sgst"
                          {...register("SGST")}
                          value={taxDetails.sgst}
                          disabled
                        />
                        {errors.sgst && (
                          <span className="text-danger err-msg">
                            {errors.sgst.message}
                          </span>
                        )}
                      </div> */}
                      <div className="col-12 col-md-4 d-flex align-items-center">
                        <span>&#8377;</span>
                        <input
                          className="form-control form-control-sm ms-2"
                          type="number"
                          disabled
                          {...register("SGST_amount")}
                          placeholder="SGST amount"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {taxDetails.type == "igst" ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-3"
                      >
                        {constLabel?.lbl_igst ? constLabel.lbl_igst : "IGST"}{" "}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      {/* <div className="col-12 col-md-4">
                        <input
                          className="form-control form-control-sm"
                          type="number"
                          placeholder="Enter igst"
                          {...register("IGST")}
                          disabled
                          value={taxDetails.igst}
                        />
                        {errors.igst && (
                          <span className="text-danger err-msg">
                            {errors.igst.message}
                          </span>
                        )}
                      </div> */}
                      <div className="col-12 col-md-4 d-flex align-items-center">
                        <span>&#8377;</span>
                        <input
                          className="form-control form-control-sm ms-2"
                          type="number"
                          disabled
                          {...register("IGST_amount")}
                          placeholder="igst cost"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {taxDetails.type == "ugst" ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-3"
                      >
                        {constLabel?.lbl_ugst ? constLabel.lbl_ugst : "UGST"}{" "}
                        (%)
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      {/* <div className="col-12 col-md-4">
                        <input
                          className="form-control form-control-sm"
                          type="number"
                          placeholder="Enter ugst"
                          {...register("UGST")}
                          value={taxDetails.ugst}
                          disabled
                        />
                        {errors.ugst && (
                          <span className="text-danger err-msg">
                            {errors.ugst.message}
                          </span>
                        )}
                      </div> */}
                      <div className="col-12 col-md-4 d-flex align-items-center">
                        <span>&#8377;</span>
                        <input
                          className="form-control form-control-sm ms-2"
                          type="number"
                          disabled
                          {...register("UGST_amount")}
                          placeholder="ugst cost"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {taxDetails.type == "ugst" ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-3"
                      >
                        {constLabel?.lbl_cgst ? constLabel.lbl_cgst : "CGST"}{" "}
                        (%)
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <div className="col-12 col-md-4 d-flex align-items-center">
                        <span>&#8377;</span>
                        <input
                          className="form-control form-control-sm ms-2"
                          type="number"
                          disabled
                          {...register("CGST_amount")}
                          placeholder="CGST amount"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-3"
                    >
                      {constLabel?.lbl_total_amount
                        ? constLabel.lbl_total_amount
                        : "Total Amount"}{" "}
                    </label>

                    <div className="col-12 col-md-4  d-flex align-items-center">
                      <span>&#8377;</span>
                      <input
                        className="form-control form-control-sm ms-2"
                        type="text"
                        {...register("final_total")}
                        placeholder="Total Amount"
                        // defaultValue={location.state?.final_total}
                        disabled
                      />
                      {errors.final_total && (
                        <span className="text-danger err-msg">
                          {errors.final_total.message}
                        </span>
                      )}
                    </div>
                  </div>

                  {gstErr ? (
                    <div className="col-12 mt-3">
                      <div
                        className="alert alert-danger mb-0 text-center my-4"
                        role="alert"
                      >
                        GST Number is not valid of supplier or quotation for's
                        company, quotation will be saved without gst.
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end py-3">
            <button
              onClick={() => setGalVisible(true)}
              className="btn btn-yellow px-3"
            >
              {constLabel?.lbl_image_gallary
                ? constLabel.lbl_image_gallary
                : "Image Gallery"}
              <FontAwesomeIcon
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginLeft: "5px",
                }}
                icon={faImage}
              />
            </button>

            <button
              className="btn btn-grey px-4 ms-0 ms-md-2"
              onClick={handleSubmit(onSubmit)}
              disabled={
                location.state?.status_name == "Open" ||
                location.state?.status_name == "Pending"
                  ? false
                  : true
              }
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditQuotation;
