import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faFileAlt, faMusic } from "@fortawesome/free-solid-svg-icons";
import { Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { del_master_file, get_master_files } from "../../../api";
import { getLabels, truncateText } from "../../../common/Functions/CommonFunctions";
import { s3_image_upload } from "../../../constant";
import axios from "axios";
import { screenList } from "../../../common/Labels/screenList";
import audio from "../../../assets/images/audio.png"

const imageFileTypes = ["image/jpeg", "image/jpg", "image/png"];

const schema = yup
  .object()
  .shape({
    image: yup
      .mixed()
      .test(
        "fileSize",
        "File can be uploaded up to 10 MB",
        (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024)
      )
      .test("fileType", "Only image files are allowed", (value) => {
        if (!value) return true; // If no file is selected, it's considered valid.
        const fileType = value[0]?.type;
        return imageFileTypes.includes(fileType);
      })
      .required("File is required"),
  })
  .required();

function MR_ImageGallary(props) {
  const { mrData, galVisible, setGalVisible } = props;
  const [lockBtn, setLockBtn] = useState(false);

  const [constLabel, setConstLabel] = useState({});
  const [taskImgs, setTaskImgs] = useState({ data: [], loading: false});

  const localData = JSON.parse(localStorage.getItem("localData"));

  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  useEffect(() => {
    if(galVisible){
        setConstLabel(getLabels("Purchase", "Material Request"));
        fetchImage(mrData?.uuid);
    }
  }, [galVisible]);

  async function fetchImage(mr_uuid) {
    setTaskImgs({ ...taskImgs, loading: true, data: [] });
    await get_master_files({
        ref_model_uuid: mr_uuid,
    }).then(
    (res) => {
        setTaskImgs({ ...taskImgs, loading: false, data: res.data.data });
    },
    (err) => {
        setTaskImgs({ ...taskImgs, loading: false, data: [] });
    }
    );
}

  function deleteFile(val) {
    SweetAlert.fire({
      title: "Are you sure to delete file ? \n",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        del_master_file({ uuid: val.uuid }).then(
          (res) => {
            toast.success("File deleted successfully", {
              autoClose: 4000,
            });
            setTimeout(() => {
                fetchImage(mrData?.uuid);
            }, [4500]);
          },
          (err) => {
            toast.error(err.response?.data?.message, {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  const onsubmit = (data) => {
    setLockBtn(true);
    if (data.image?.length > 0) {
      const url = process.env.REACT_APP_COGNITO_URL + s3_image_upload;
      const idToken = localStorage.getItem("id");

      const imgObj = {
        fileName: data.image[0]?.name,
        contentType: data.image[0]?.type,
        model: {
          ref_model_uuid: mrData?.uuid,
          model_name: screenList.material_request_screen.replace(/\s+/g, ""),
          name: data.image[0]?.name,
          document_type: data.image[0]?.type.startsWith("image/") ? "image" : "image",
          created_by_user: localData?.user_id,
          client_id: localData?.client_id,
        },
      };

      const payload = {
        files: [imgObj],
      };

      axios
        .post(url, payload, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        })
        .then(
          (res) => {
            // console.log("res", res);
            for (let x = 0; x < res.data.data?.body?.length; x++) {
              const url2 = res.data.data?.body?.[x]?.url;

              axios
                .put(url2, data.image[0], {
                  headers: {
                    "Content-Type": data.image[0]?.type,
                  },
                })
                .then(
                  (ress) => {
                    toast.success("File uploaded successfully !", {
                      autoClose: 4000,
                    });
                    setTimeout(() => {
                      setValue("image", {});
                      fetchImage(mrData?.uuid);
                    }, [4500]);
                    setLockBtn(false);
                  },
                  (errr) => {
                    console.log("errr", errr);
                    if (errr.response.status == 413) {
                      toast.error("File upload failed !", {
                        autoClose: 5000,
                      });
                    }
                    setLockBtn(true);
                  }
                );
            }
          },
          (err) => {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            setLockBtn(true);
          }
        );
    }
  };

  // function removeFile(i) {
  //   let tempImg = [...props.drawingFiles.data];

  //   tempImg.splice(i, 1);
  //   props.setDrawingFiles({ ...props.drawingFiles, data: tempImg });
  // }

  const renderAttachment = (type, attachment, name) => {
    const fileURL = `${process.env.REACT_APP_IMAGE_URL}${attachment}`;
  
    switch (type) {
      case "image":
        return (
          <a href={fileURL} target="_blank" rel="noopener noreferrer">
          <img src={fileURL} className="img-fluid" alt={name || "Image"} />
          </a>
        );
      default:
        return (
          <div>
            <FontAwesomeIcon
              className="icon-file-default"
              style={{ color: "#fff" }}
              icon={faFileAlt} // Icon for default file type
              size="3x"
            />
            <span className="ms-2 text-secondary">{name || "Unsupported File"}</span>
          </div>
        );
    }
  };
  
  return (
    <React.Fragment>
      <ToastContainer />
      <Modal
        show={galVisible}
        onHide={() => {
          setGalVisible(false);
        }}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_image_gallary
              ? constLabel.lbl_image_gallary
              : "Image Gallery"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-4">
                <label style={{ fontSize: "14px" }} className="col-form-label">
                  {constLabel?.lbl_file ? constLabel.lbl_file : "File"}
                </label>
                <input
                  className={
                    errors.image
                      ? "form-control form-control-sm is-invalid"
                      : "form-control form-control-sm"
                  }
                  type="file"
                  {...register("image")}
                  accept="image/*"
                />
                {errors.image && (
                  <span className="text-danger err-msg">
                    {errors.image.message}
                  </span>
                )}
              </div>
              

              <div className="col-12 col-md-3">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label invisible d-block"
                >
                  {constLabel?.lbl_file_name
                    ? constLabel.lbl_file_name
                    : "Filename"}
                </label>

                <button
                  className="btn btn-sm btn-yellow px-3"
                  onClick={handleSubmit(onsubmit)}
                  disabled={lockBtn}
                >
                  {constLabel?.lbl_submit ? constLabel.lbl_submit : "Submit"}{" "}
                  {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
                </button>
              </div>
            </div>

          {taskImgs?.loading ? (
            <div className="text-center mt-4">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="row mt-4 drawing_gallery row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
              {taskImgs?.data.map((val, i) =>
                val.attachment !== null ? (
                  <div className="col text-center" key={i}>
                    <div className="image-container">
                      {renderAttachment(val.document_type, val.attachment, val.name)}

                      <FontAwesomeIcon
                        onClick={() => deleteFile(val)}
                        // onClick={() => removeFile(i)}
                        className="icon-close"
                        style={{ color: "#000" }}
                        icon={faClose}
                        size="lg"
                      />
                    </div>

                    <span
                      // onClick={() =>
                      //   setFilenameUpdate({
                      //     ...filenameUpdate,
                      //     status: true,
                      //     value: val,
                      //   })
                      // }
                      className="image-name cursor_pointer"
                    >
                      {truncateText((val.name).toUpperCase(), 15)}
                    </span>
                  </div>
                ) : null
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-grey px-4"
            onClick={() => {
              setGalVisible(false);
              // setFilenameUpdate({ ...filenameUpdate, status: false });
            }}
          >
            {constLabel?.lbl_close ? constLabel.lbl_close : "Close"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default MR_ImageGallary;