export const MANUFACTURED_GOODS = "Manufactured Goods";
export const FINISHED_GOODS = "Finished Goods";
export const RAW_MATERIAL = "Raw material";
export const SEMI_FINISH_GOODS = "Semi Finish Goods";
export const CONSUMABLE_ITEMS = "Consumable Items";
export const FIXTURES = "Fixtures";
export const GAUGES = "Gauges";
export const GENERAL_ITEM = "General Item";
export const TOOLS = "Tools";

export const OPEN = "Open";
export const DRAFT = "Draft";
export const START = "Start";
export const PENDING = "Pending";
export const IN_PROGRESS = "In Progress";
export const CLOSED = "Closed";
export const CANCELLED = "Cancelled";
export const COMPLETED = "Completed";
export const RELEASED = "Released";
export const APPROVED = "Approved";
export const BOMCREATED = "BOM Created";
export const DELIVERED = "Delivered";
export const FINALIZED = "Finalized";
export const PENDING_APPROVAL = "Pending Approval";
export const REJECTED = "Rejected";

export const REWORK = "Rework";
export const REWORK_S = "Rework S";
export const SALVAGE = "Salvage";
export const SCRAP = "Scrap";

export const PURCHASE = "Purchase";

export const OPERATOR = "Operator";
export const JUNIOR_OPERATOR = "Junior Operator";
export const SENIOR_OPERATOR = "Senior Operator";

export const PER_HOUR_WAGE = "Per Day Wage";
export const PER_DAY_WAGE = "Per Hour Wage";

export const FASHION = "Fashion";
export const APPAREL = "Apparel";
export const MANUFACTURING = "Manufacturing";

export const UPDATE = "Update";

export const CUSTOMER = "Customer"
export const SUPPLIER = "Supplier"

export const FORGING = "FORGING";
export const BAR = "BAR";
export const CASTING = "CASTING";


export const RESOURCE_COLORS =
    ['#8da9c4', '#94d2bd', '#dfe7fd', '#F1F3C2', '#55AD9B', '#EFBC9B',
        '#D37676', '#FEECE2', '#C7C8CC', '#FFDCA9', '#D0B8A8', '#FAF4B7',
        '#f5b041', '#bb8fce', '#ec7063', '#d7dbdd', '#f4d03f', '#1abc9c',
        '#884ea0', '#909497', '#6495ED', '#CCCCFF', '#9FE2BF', '#DFFF00',
        '#FFBF00', '#FF7F50', '#DE3163', '#d6ce93', '#9d8189', '#415a77',]


export const TEMPLATE = "Template";
export const REPORT = "Report";

export const MASTER = "Master";
export const DESIGNATION = "Designation";
export const SALES_ORDER_STATUS = "Sales Order Status";
export const STOCK_AVAILABILITY = "Stock Availability"
export const STOCK_LEDGER = "Stock Ledger";
export const PARTY_MASTER = "Party Master";
export const INVENTORY = "Inventory";
export const PRODUCTION = "Production";

