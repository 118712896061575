import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faBan,
  faFilter,
  faCog,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Pagination, Spinner } from "react-bootstrap";
import { Edit, Edit2, Eye, FileText, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";

import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import { Goods_Challan_COL_Name } from "../../common/Labels/labelConstant";
import {
  calMaxPage_new,
  convertDateYYMMDD,
  formatDate5,
  getFinancialYearStartDate,
  getLabels,
  setAccessPrivilage,
} from "../../common/Functions/CommonFunctions";
import {
  Challan_Excel,
  Challan_itc_report,
  delete_challan,
  getLocation,
  getProdList,
  getSupplier,
  get_challan,
  get_challan_type,
} from "../../api";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import List from "../../assets/images/icons/project_menu/checklist.png"
import Slide from "../../assets/images/icons/project_menu/collapse.png"
import AccessDenied from "../Common/AccessDenied";

function GoodsChallan() {
  const [startDate, setStartDate] = useState(getFinancialYearStartDate(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const navigate = useNavigate();
  const [showLabel, setShowLabel] = useState(Goods_Challan_COL_Name);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [sea_val, setSea_val] = useState(false);
  const [prodList, setProdList] = useState({ data: [], loading: true });
  const [locList, setLocList] = useState({ data: [], loading: true });
  const [supplierList, setSupplierList] = useState({ data: [], loading: true });
  const [lockBtn, setLockBtn] = useState(false);
  const [lockBtnforITC4, setLockBtnforITC4] = useState(false);

  const [challanTypeList, setChallanTypeList] = useState({
    data: [],
    loading: true,
  });

  const [modalShow, setModalShow] = useState(false);
  const [item_list, setItem_List] = useState({data: [], loading: false});

  const [filterConfig, setFilterConfig] = useState({
    searchVal: "",
    work_order_no: "",
    product: [],
    from_location: [],
    supplier: [],
    to_location: [],
    challantype: [],
  });

  const [constLabel, setConstLabel] = useState({});

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
   no_access: false
  });

  useEffect(() => {
    setPrivilage(setAccessPrivilage(loginData, "Goods Challan"));
  }, []);

  useEffect(() => {
    setConstLabel(getLabels("Inventory", "Goods Challan"));
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchchallan(page, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig, startDate, endDate]);

  function clearFilter() {
    setFilterConfig({
      ...filterConfig,
      searchVal: "",
      work_order_no: "",
      product: [],
      from_location: [],
      supplier: [],
      to_location: [],
      challantype: [],
    });
    setSrNo(0);
    setPage(1);
    setStartDate(getFinancialYearStartDate(new Date()));
    setEndDate(new Date());
  }

  function fetchchallan(pg, epp) {
    setDataList({ ...dataList, loading: true, data: [] });

    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
      startDate: convertDateYYMMDD(new Date(startDate), "-"),
      endDate: convertDateYYMMDD(new Date(endDate), "-"),
    };

    if (filterConfig.searchVal !== "") {
      // payload.productName_list = filterConfig.productName;
      payload.searchVal = filterConfig.searchVal.trim();

    } else {
      delete payload.searchVal;
    }
    if (filterConfig.work_order_no !== "") {
      // payload.productName_list = filterConfig.productName;
      payload.work_order_no = filterConfig.work_order_no.trim();

    } else {
      delete payload.work_order_no;
    }

    if (filterConfig.product.length > 0) {
      payload.product_id = filterConfig.product?.[0]?.uuid;
    } else {
      delete payload.product_id;
    }

    if (filterConfig.from_location.length > 0) {
      payload.from_location_id = filterConfig.from_location?.[0]?.uuid;
    } else {
      delete payload.from_location_id;
    }

    if (filterConfig.supplier.length > 0) {
      payload.supplier_id = filterConfig.supplier?.[0]?.uuid;
    } else {
      delete payload.supplier_id;
    }

    if (filterConfig.to_location.length > 0) {
      payload.to_location_id = filterConfig.to_location?.[0]?.uuid;
    } else {
      delete payload.to_location_id;
    }

    if (filterConfig.challantype.length > 0) {
      payload.challantype = filterConfig.challantype?.[0]?.typename;
    } else {
      delete payload.challantype;
    }

    get_challan(payload).then(
      (res) => {
        // console.log("res", res.data.data);
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });
        // if (
        //   filterConfig.searchVal.length > 0 &&
        //   filterConfig.searchVal.length > 0
        // ) {
        //   setSea_val(true);
        // } else {
        //   setSea_val(false);
        // }
        setMaxPage(calMaxPage_new(res.data.total, epp));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, loading: false, data: [] });
      }
    );
  }

  // Deleting GST challan
  const del_challan = (val) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          uuid: val.uuid,
        };
        delete_challan(payload).then(
          (res) => {
            toast.success("Challan deleted successfully !", {
              autoClose: 3000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchchallan(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchchallan(page, entriesPerPage);
            }
          },
          (err) => {
            if (err.response.status === 400) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
              // reset();
            } else {
              toast.error("Something went wrong ", {
                autoClose: 2000,
              });
            }
          }
        );
      }
    });
  };

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);

    setTimeout(() => {
      
    }, [2000]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function toggleDiv(ind) {
    const selRow = document.getElementById("collapse_row" + ind);

    selRow.classList.toggle("expanded");
  }

  function getPrvMonthDateFromToday() {
    const today = new Date();
    const endDate = new Date(today);
    endDate.setMonth(today.getMonth() - 1);
    return endDate;
  }

  const handleFromDateChange = (date) => {
    if (endDate && date > endDate) {
      return;
    }
    setStartDate(date);
  };

  const handleToDateChange = (date) => {
    if (startDate && date < startDate) {
      return;
    }
    setEndDate(date);
  };

  function fetchProdList() {
    setProdList({ ...prodList, loading: true, data: [] });
    getProdList().then(
      (res) => {
        // console.log("res", res);
        const filteredData = res.data.data.filter(
          (item) => item.id !== "" && item.productName !== null
        );
        setProdList({ ...prodList, loading: false, data: filteredData });
      },
      (err) => {
        setProdList({ ...prodList, loading: false, data: [] });
      }
    );
  }

  function fetchLocation() {
    setLocList({ ...locList, loading: true, data: [] });
    getLocation().then(
      (res) => {
        // console.log("res", res);
        setLocList({ ...locList, loading: false, data: res.data.data });
      },
      (err) => {
        setLocList({ ...locList, loading: false, data: [] });
      }
    );
  }

  function fetchChallantype() {
    setChallanTypeList({ ...challanTypeList, loading: true, data: [] });
    get_challan_type().then(
      (res) => {
        // console.log("res", res);
        setChallanTypeList({
          ...challanTypeList,
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        setChallanTypeList({ ...challanTypeList, loading: false, data: [] });
      }
    );
  }


  function fetchSuppliers() {
    setSupplierList({ loading: true, data: [] });

    let payload = {

      partyType: "Supplier",
    };


    getSupplier(payload).then(
      (res) => {
        setSupplierList({
          loading: false,
          data: res.data.data,
        });


      },
      (err) => {
        setSupplierList({
          loading: false,
          data: []
        });
        if (err.response.status === 500) {
          toast.error("Something went wrong !", { autoClose: 5000 });
        }
      }
    );
  }


  function base64ToExcel(response,itc_four) {
    const { statusCode, headers, body, isBase64Encoded } = response;

    // Assume base64String contains the base64 representation of the Excel file
    const base64String = body; // Replace this with your base64 string

    // Convert the base64 string into a Uint8Array
    const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));

    // Create a Blob object from the Uint8Array
    const blob = new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a link element with download attribute
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toDateString().split(" ").join("_");
    link.download = itc_four?`Challan_ITC4_Report_${currentDate} `:`ChallanReport_${currentDate}.xlsx`;

    // Programmatically click on the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Cleanup: remove the link and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    if(itc_four){
      setLockBtnforITC4(false)
    }else{

      setLockBtn(false);
    }
  }

  async function goToDownload() {
    setLockBtn(true);

    const payload = {};

    payload.startDate = convertDateYYMMDD(new Date(startDate), "-");
    payload.endDate = convertDateYYMMDD(new Date(endDate), "-");

    if (filterConfig.supplier.length > 0) {
      payload.Party_id = filterConfig.supplier?.[0]?.uuid;
    } else {
      delete payload.Party_id;
    }

    if (filterConfig.work_order_no !== "") {
      payload.work_order_no = filterConfig.work_order_no.trim();

    } else {
      delete payload.work_order_no;
    }

    if (filterConfig.product.length > 0) {
      payload.product_id = filterConfig.product?.[0]?.uuid;
    } else {
      delete payload.product_id;
    }

    if (filterConfig.from_location.length > 0) {
      payload.from_location = filterConfig.from_location?.[0]?.uuid;
    } else {
      delete payload.from_location;
    }



    if (filterConfig.to_location.length > 0) {
      payload.to_location = filterConfig.to_location?.[0]?.uuid;
    } else {
      delete payload.to_location;
    }

    if (filterConfig.challantype.length > 0) {
      payload.challan_type = filterConfig.challantype?.[0]?.typename;
    } else {
      delete payload.challan_type;
    }

    await Challan_Excel(payload).then(
      (res) => {
        //
        if (res) {
          // excel_download(res.data.data);
          base64ToExcel(res.data);

        }
      },
      (err) => {
        toast.error(err.response.data.message, {
          autoClose: 2000,
        });
        setLockBtn(false);
      }
    );
  }

  async function goToDownload2() {
    setLockBtnforITC4(true);

    const payload = {};

    payload.startDate = convertDateYYMMDD(new Date(startDate), "-");
    payload.endDate = convertDateYYMMDD(new Date(endDate), "-");

   
    await Challan_itc_report(payload).then(
      (res) => {
        //
        if (res) {
          // excel_download(res.data.data);
          base64ToExcel(res.data, true);

        }
      },
      (err) => {
        toast.error(err.response.data.message, {
          autoClose: 2000,
        });
        setLockBtnforITC4(false);
      }
    );
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
      fetchProdList();
      fetchLocation();
      fetchChallantype();
      fetchSuppliers();
    }
  }

  function showProducts(){
    setModalShow(true);
  }

  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}


      {!privilage.read ?
        <AccessDenied />
        :

        <div className="min-vh-100 " id="root_div_main">
          {privilage.read && (
            <div id="mySidebar" className="customsidebar ">
              <a className="closebtn" onClick={() => toggleNav()}>
                ×
              </a>

              <div className=" content">
                <div className="filter row">
                  <label
                    className="filterLabel mb-2 roboto-bold"
                    style={{ fontSize: "14px" }}
                  >
                    {constLabel?.lbl_date_range
                      ? constLabel.lbl_date_range
                      : "Date Range"}
                  </label>
                  <div className="fromDate col-12 col-md-6">
                    <DatePicker
                      id="fromDate"
                      className="form-control"
                      selected={startDate}
                      showYearDropdown
                      scrollableMonthYearDropdown
                      dateFormat="dd/MM/yyyy"
                      placeholderText="From Date"
                      // onChange={(date) => setStartDate(date)}
                      onChange={(date) => handleFromDateChange(date)}
                      maxDate={new Date()}
                    />
                  </div>

                  <div className="toDate col-12 col-md-6">
                    <DatePicker
                      id="toDate"
                      className="form-control"
                      showYearDropdown
                      scrollableMonthYearDropdown
                      selected={endDate}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="To Date"
                      // onChange={(date) => setEndDate(date)}
                      onChange={(date) => handleToDateChange(date)}
                      maxDate={new Date()}
                    />
                  </div>
                </div>

                <div>
                  <label
                    className="filterLabel my-2 roboto-bold"
                    style={{ fontSize: "14px" }}
                  >
                    {constLabel?.lbl_search ? constLabel.lbl_search : "Search"}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search by name"
                    value={filterConfig.searchVal}
                    onChange={(val) => {
                      const inputValue = val.target.value; // Trim leading and trailing whitespaces
                      //  console.log("inputValue",inputValue)
                      if (!inputValue.trim()) {
                        // console.log("inputValue2",inputValue)
                        if (filterConfig.searchVal.length == 1) {
                          // console.log("inputValue3",inputValue)
                          setFilterConfig({ ...filterConfig, searchVal: "" });
                        }

                        if (inputValue.length == 0) {
                          setFilterConfig({ ...filterConfig, searchVal: "" });
                        }

                      } else {
                        // console.log("inputValue4",inputValue)
                        setFilterConfig({
                          ...filterConfig,
                          searchVal: inputValue,
                        });
                      }
                    }}
                  />
                </div>

                <div>
                  <label
                    className="filterLabel my-2 roboto-bold"
                    style={{ fontSize: "14px" }}
                  >
                    {constLabel?.lbl_product ? constLabel.lbl_product : "Product"}
                  </label>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="productName"
                    multiple={false}
                    // size="sm"
                    options={prodList.data}
                    isLoading={prodList.loading}
                    selected={filterConfig.product}
                    onChange={(val) => {
                      // console.log("product val", val);
                      if (val.length > 0) {
                        // console.log(val);
                        setFilterConfig({ ...filterConfig, product: val });
                      } else {
                        setFilterConfig({ ...filterConfig, product: [] });
                      }
                    }}
                    placeholder="Select product name"
                  />
                </div>
                <div>
                  <label
                    className="filterLabel my-2 roboto-bold"
                    style={{ fontSize: "14px" }}
                  >
                    {constLabel?.lbl_work_order ? constLabel.lbl_work_order : "Work Order"}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search by work order number"
                    value={filterConfig.work_order_no}
                    onChange={(val) => {
                      const inputValue = val.target.value; // Trim leading and trailing whitespaces
                      //  console.log("inputValue",inputValue)
                      if (!inputValue.trim()) {
                        // console.log("inputValue2",inputValue)
                        if (filterConfig.work_order_no.length == 1) {
                          // console.log("inputValue3",inputValue)
                          setFilterConfig({ ...filterConfig, work_order_no: "" });
                        }

                        if (inputValue.length == 0) {
                          setFilterConfig({ ...filterConfig, work_order_no: "" });
                        }

                      } else {
                        // console.log("inputValue4",inputValue)
                        setFilterConfig({
                          ...filterConfig,
                          work_order_no: inputValue,
                        });
                      }
                    }}
                  />
                </div>
                <div>
                  <label
                    className="filterLabel my-2 roboto-bold"
                    style={{ fontSize: "14px" }}
                  >
                    {constLabel?.lbl_from_location
                      ? constLabel.lbl_from_location
                      : "From Location"}
                  </label>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="locationname"
                    multiple={false}
                    // size="sm"
                    options={locList.data}
                    isLoading={locList.loading}
                    selected={filterConfig.from_location}
                    onChange={(val) => {
                      // console.log("from location val", val);
                      if (val.length > 0) {
                        // console.log(val);
                        setFilterConfig({ ...filterConfig, from_location: val });
                      } else {
                        setFilterConfig({ ...filterConfig, from_location: [] });
                      }
                    }}
                    placeholder="Select from location"
                  />
                </div>

                <div>
                  <label
                    className="filterLabel my-2 roboto-bold"
                    style={{ fontSize: "14px" }}
                  >
                    {constLabel?.lbl_to_location
                      ? constLabel.lbl_to_location
                      : "To Location"}
                  </label>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="locationname"
                    multiple={false}
                    // size="sm"
                    options={locList.data}
                    isLoading={locList.loading}
                    selected={filterConfig.to_location}
                    onChange={(val) => {
                      // console.log("from location val", val);
                      if (val.length > 0) {
                        // console.log(val);
                        setFilterConfig({ ...filterConfig, to_location: val });
                      } else {
                        setFilterConfig({ ...filterConfig, to_location: [] });
                      }
                    }}
                    placeholder="Select to location"
                  />
                </div>

                <div>
                  <label
                    className="filterLabel my-2 roboto-bold"
                    style={{ fontSize: "14px" }}
                  >
                    {constLabel?.lbl_challan_type
                      ? constLabel.lbl_challan_type
                      : "Challan Type"}
                  </label>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="typename"
                    multiple={false}
                    // size="sm"
                    options={challanTypeList.data}
                    isLoading={challanTypeList.loading}
                    selected={filterConfig.challantype}
                    onChange={(val) => {
                      // console.log("from location val", val);
                      if (val.length > 0) {
                        // console.log(val);
                        setFilterConfig({ ...filterConfig, challantype: val });
                      } else {
                        setFilterConfig({ ...filterConfig, challantype: [] });
                      }
                    }}
                    placeholder="Select challan type"
                  />
                </div>

                <div>
                  <label
                    className="filterLabel my-2 roboto-bold"
                    style={{ fontSize: "14px" }}
                  >
                    {constLabel?.lbl_suppllier
                      ? constLabel.lbl_suppllier
                      : "Supplier"}
                  </label>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    multiple={false}
                    // size="sm"
                    options={supplierList.data}
                    isLoading={supplierList.loading}
                    selected={filterConfig.supplier}
                    onChange={(val) => {
                      // console.log("from location val", val);
                      if (val.length > 0) {
                        // console.log(val);
                        setFilterConfig({ ...filterConfig, supplier: val });
                      } else {
                        setFilterConfig({ ...filterConfig, supplier: [] });
                      }
                    }}
                    placeholder="Select supplier "
                  />
                </div>

                <div className="my-3 d-flex justify-content-end">
                  <button
                    onClick={() => clearFilter()}
                    className="btn btn-sm btn-yellow "
                  >
                    <FontAwesomeIcon
                      style={{ color: "#344454" }}
                      icon={faBan}
                      size="sm"
                    />{" "}
                    {constLabel?.lbl_clear_filter
                      ? constLabel.lbl_clear_filter
                      : "Clear Filter"}
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="content-wrapper">
            <div className="card border-0">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="d-md-flex justify-content-start col-12 col-md-6">
                    <Tooltip id={"open-tooltip"} place="top" />
                    <img
                      src={Slide}
                      height="32"
                      width="32"
                      className="cursor_pointer my-1 me-2"
                      onClick={() => toggleNav()}
                      data-tooltip-id={"open-tooltip"}
                      data-tooltip-content={"Filters"}
                    />
                    <h1 className="bold">
                      {constLabel?.lbl_goods_challan
                        ? constLabel?.lbl_goods_challan
                        : "Goods Challan"}
                    </h1>
                  </div>

                  <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">

                  {privilage.report && (
                      <button
                        className="btn btn-light-grey me-0 me-md-2 mt-2 mt-md-0"
                        disabled={lockBtnforITC4}
                        onClick={() => goToDownload2()}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faDownload}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_itc_report
                          ? constLabel.lbl_itc_report
                          : "ITC 4 Report"}{" "}
                        {lockBtnforITC4 ? (
                          <Spinner animation="border" size="sm" />
                        ) : null}{" "}
                      </button>
                    )}
                    {privilage.report && (
                      <button
                        className="btn btn-light-grey me-0 me-md-2 mt-2 mt-md-0"
                        disabled={lockBtn}
                        onClick={() => goToDownload()}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faDownload}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_challan_report
                          ? constLabel.lbl_challan_report
                          : "Challan Report"}{" "}
                        {lockBtn ? (
                          <Spinner animation="border" size="sm" />
                        ) : null}{" "}
                      </button>
                    )}


                    {privilage.write && (
                      <button
                        className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                        onClick={() => {
                          navigate("/pcms/createGoodsChallan");
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_add_goods_challan
                          ? constLabel?.lbl_add_goods_challan
                          : "Create Challan"}
                      </button>
                    )}

                    <Tooltip id={"show-tooltip"} place="top" />
                    <div
                      className="dropdown"
                      data-tooltip-id={"show-tooltip"}
                      data-tooltip-content={"Show / Hide Columns"}
                    >
                      <div
                        className="d-md-flex justify-content-start align-items-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <FontAwesomeIcon icon={faListCheck} size="lg" /> */}
                        {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                        <img
                          src={List}
                          height="32"
                          width="32"
                          className="cursor_pointer"
                        />
                      </div>

                      <ul className="dropdown-menu px-2">
                        {showLabel !== undefined && showLabel.length > 0
                          ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mt-3 ">
                  {dataList.loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : dataList && dataList.data?.length > 0 ? (
                    <>
                      <div className="table-responsive pc">
                        <table
                          className="table table-bordered"
                        >
                          <thead className="table-grey roboto">
                            <tr className="" key={Date.now()}>
                              <th scope="col">{"Sr.No"}</th>
                              {showLabel.map((v, i) =>
                                v.label === "Good Challan Id" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_goods_challan_id
                                      ? constLabel?.lbl_goods_challan_id
                                      : "Good Challan Id"}
                                  </th>
                                ) : null
                              )}
                              {showLabel.map((v, i) =>
                                v.label === "Challan Type" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_challan_type
                                      ? constLabel?.lbl_challan_type
                                      : "Challan Type"}
                                  </th>
                                ) : null
                              )}
                              {showLabel.map((v, i) =>
                                v.label === "Challan Reference" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_out_chllan_ref
                                      ? constLabel?.lbl_out_chllan_ref
                                      : "Challan Reference"}
                                  </th>
                                ) : null
                              )}
                              {showLabel.map((v, i) =>
                                v.label === "Quantity" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_quantity
                                      ? constLabel?.lbl_quantity
                                      : "Quantity"}
                                  </th>
                                ) : null
                              )}
                              {showLabel.map((v, i) =>
                                v.label === "From Location" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_from_location
                                      ? constLabel?.lbl_from_location
                                      : "From Location"}
                                  </th>
                                ) : null
                              )}
                              {showLabel.map((v, i) =>
                                v.label === "To Location" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_to_location
                                      ? constLabel?.lbl_to_location
                                      : "To Location"}
                                  </th>
                                ) : null
                              )}
                               {showLabel.map((v, i) =>
                                v.label === "Product Name" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_productname
                                      ? constLabel?.lbl_productname
                                      : "Product Name"}
                                  </th>
                                ) : null
                              )}
                              {showLabel.map((v, i) =>
                                v.label === "Date" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_date
                                      ? constLabel?.lbl_date
                                      : "Date"}
                                  </th>
                                ) : null
                              )}
                              {showLabel.map((v, i) =>
                                v.label === "Lot No" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_lot_no
                                      ? constLabel?.lbl_lot_no
                                      : "Lot No"}
                                  </th>
                                ) : null
                              )}
                              {showLabel.map((v, i) =>
                                v.label === "Status" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_status
                                      ? constLabel?.lbl_status
                                      : "Status"}
                                  </th>
                                ) : null
                              )}
                              {showLabel.map((v, i) =>
                                v.label === "Work Order" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_work_order
                                      ? constLabel?.lbl_work_order
                                      : "Work Order"}
                                  </th>
                                ) : null
                              )}
                              {showLabel.map((v, i) =>
                                v.label === "Job Card" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_job_card
                                      ? constLabel?.lbl_job_card
                                      : "Job Card"}
                                  </th>
                                ) : null
                              )}
                              <th scope="col" width="15%">
                                {"Action"}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="roboto">
                            {dataList.data?.map((val, i) => (
                              <>
                                <tr key={i} className="">
                                  {/* <td>{val.NCReasonId}</td> */}
                                  <td>{i + parseInt(srNo) + 1}</td>
                                  {showLabel.map((item, idx) =>
                                    item.label === "Good Challan Id" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.gmchallanid != "" &&
                                          val.gmchallanid !== null
                                          ? val.gmchallanid
                                          : "NA"}
                                      </td>
                                    ) : null
                                  )}

                                  {showLabel.map((item, idx) =>
                                    item.label === "Challan Type" && item.flag ? (
                                      <td key={idx}>
                                        {val.challantype != "" &&
                                          val.challantype !== null
                                          ? val.challantype
                                          : "NA"}
                                      </td>
                                    ) : null
                                  )}
                                  {showLabel.map((item, idx) =>
                                    item.label === "Challan Reference" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.outwardchallanid != "" &&
                                          val.outwardchallanid !== null
                                          ? val.outwardchallanid[0].challanid
                                          : "NA"}
                                      </td>
                                    ) : null
                                  )}
                                  {showLabel.map((item, idx) =>
                                    item.label === "Quantity" && item.flag ? (
                                      <td key={idx}>
                                        {val.qty != "" && val.qty !== null
                                          ? val.qty
                                          : "NA"}
                                      </td>
                                    ) : null
                                  )}
                                  {showLabel.map((item, idx) =>
                                    item.label === "From Location" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.fromDetails !== null
                                          ? val.fromDetails.locationname
                                          : "NA"}
                                      </td>
                                    ) : null
                                  )}
                                  {showLabel.map((item, idx) =>
                                    item.label === "To Location" && item.flag ? (
                                      <td key={idx}>
                                        {val.toDetails !== null
                                          ? val.toDetails.locationname
                                          : "NA"}
                                      </td>
                                    ) : null
                                  )}
                                  {showLabel.map((item, idx) =>
                                    item.label === "Product Name" && item.flag ? (
                                      <td key={idx}>
                                        {val.challan_line_items?.length !== 0 &&
                                          val.challan_line_items?.length !== null
                                          ? (val?.challan_line_items?.length == 1 ? `${val?.challan_line_items?.[0]?.product_name} - ${val?.challan_line_items?.[0]?.qty} - ${val?.challan_line_items?.[0]?.uom} ` : 
                                          <button
                                            onClick={() => { showProducts(); setItem_List({data: val?.challan_line_items}) }}
                                            className="btn btn-sm btn-outline-secondary"
                                          >
                                            {constLabel?.lbl_more
                                              ? constLabel.lbl_more
                                              : "More"}
                                          </button>)
                                          : "NA"}
                                      </td>
                                    ) : null
                                  )}
                                  {showLabel.map((item, idx) =>
                                    item.label === "Date" && item.flag ? (
                                      <td key={idx}>
                                        {val.challandate != "" &&
                                          val.challandate !== null
                                          ? formatDate5(val.challandate)
                                          : "NA"}
                                      </td>
                                    ) : null
                                  )}
                                  {showLabel.map((item, idx) =>
                                    item.label === "Lot No" && item.flag ? (
                                      <td key={idx}>
                                        {val.lotnum != "" && val.lotnum !== null
                                          ? val.lotnum
                                          : "NA"}
                                      </td>
                                    ) : null
                                  )}
                                  {showLabel.map((item, idx) =>
                                    item.label === "Status" && item.flag ? (
                                      <td key={idx}>
                                        {val.challanstate != "" &&
                                          val.challanstate !== null
                                          ? val.challanstate
                                          : "NA"}
                                      </td>
                                    ) : null
                                  )}
                                  {showLabel.map((item, idx) =>
                                    item.label === "Work Order" && item.flag ? (
                                      <td key={idx}>
                                        {val.work_order_id != "" &&
                                          val.work_order_id !== null
                                          ? val.work_order_id
                                          : "NA"}
                                      </td>
                                    ) : null
                                  )}
                                  {showLabel.map((item, idx) =>
                                    item.label === "Job Card" && item.flag ? (
                                      <td key={idx}>
                                        {val.job_card_id != "" &&
                                          val.job_card_id !== null
                                          ? val.job_card_id
                                          : "NA"}
                                      </td>
                                    ) : null
                                  )}
                                  <td>

                                    {privilage.write && (
                                      <>
                                        <Tooltip
                                          id={"edit-tooltip" + i}
                                          place="top"
                                        />
                                        <Edit
                                          data-tooltip-id={"edit-tooltip" + i}
                                          data-tooltip-content={
                                            constLabel?.lbl_edit_goods_challan
                                              ? constLabel.lbl_edit_goods_challan
                                              : "Edit Good Challan"
                                          }
                                          className="menu_icon_grey cursor_pointer me-2"
                                          size={20}
                                          onClick={() => {
                                            navigate("/pcms/editGoodsChallan", {
                                              state: val,
                                            });
                                          }}
                                        />
                                      </>
                                    )}

                                    {privilage.read ? (
                                      <>
                                        <Tooltip
                                          id={"view-tooltip" + i}
                                          place="top"
                                        />

                                        <Eye
                                          data-tooltip-id={"view-tooltip" + i}
                                          data-tooltip-content={
                                            constLabel?.lbl_view_challan
                                              ? constLabel.lbl_view_challan
                                              : "View Challan"
                                          }
                                          onClick={() => {
                                            // console.log("alsdjkf");
                                            toggleDiv(i, val);
                                          }}
                                          className="menu_icon_grey cursor_pointer me-2"
                                          size={20}
                                        />
                                      </>
                                    ) : null}

                                    {privilage.read && (
                                      <>
                                        <Tooltip
                                          id={"pdf-tooltip" + i}
                                          place="top"
                                        />
                                        <FileText
                                          data-tooltip-id={"pdf-tooltip" + i}
                                          data-tooltip-content={
                                            constLabel?.lbl_generate_pdf
                                              ? constLabel.lbl_generate_pdf
                                              : "Generate PDF"
                                          }
                                          className="menu_icon_grey cursor_pointer me-2"
                                          size={20}
                                          onClick={() => {
                                            navigate(
                                              `/pcms/view/goodsChallan-challan-pdf`,
                                              { state: val }
                                            );
                                          }}
                                        />
                                      </>
                                    )}

                                    {privilage.delete && (
                                      <>
                                        <Tooltip
                                          id={"delete-tooltip" + i}
                                          place="top"
                                        />
                                        <Trash2
                                          data-tooltip-id={"delete-tooltip" + i}
                                          data-tooltip-content={
                                            constLabel?.lbl_delete_product_family
                                              ? constLabel?.lbl_delete_product_family
                                              : "Delete Challan"
                                          }
                                          className="menu_icon_red cursor_pointer"
                                          size={20}
                                          onClick={() => del_challan(val)}
                                        />
                                      </>
                                    )}

                                  </td>
                                </tr>
                                <tr
                                  className={"collapsible-row"}
                                  id={"collapse_row" + i}
                                >
                                  <td colSpan="12">
                                    <div className="collapse-content">
                                      <div className="row mb-3">
                                        {/* <div className="col-12 col-md-1">
                                        <label className="roboto-bold">
                                          {"Sr.No"}
                                        </label>
                                      </div> */}
                                        <div className="col-12 col-md-2">
                                          <label className="roboto-bold">
                                            {constLabel?.lbl_work_order
                                              ? constLabel.lbl_work_order
                                              : "Work Order"}
                                          </label>
                                        </div>
                                        <div className="col-12 col-md-2">
                                          <label className="roboto-bold">
                                            {constLabel?.lbl_WO_quantity
                                              ? constLabel.lbl_WO_quantity
                                              : "WO Quantity"}
                                          </label>
                                        </div>
                                        <div className="col-12 col-md-2">
                                          <label className="roboto-bold">
                                            {constLabel?.lbl_job_card
                                              ? constLabel.lbl_job_card
                                              : "Job Card"}
                                          </label>
                                        </div>
                                        <div className="col-12 col-md-2">
                                          <label className="roboto-bold">
                                            {constLabel?.lbl_productname
                                              ? constLabel.lbl_productname
                                              : "Product Name"}
                                          </label>
                                        </div>
                                        <div className="col-12 col-md-2">
                                          <label className="roboto-bold">
                                            {constLabel?.lbl_operation
                                              ? constLabel.lbl_operation
                                              : "Operation"}
                                          </label>
                                        </div>
                                        <div className="col-12 col-md-1">
                                          <label className="roboto-bold">
                                            {constLabel?.lbl_quantity
                                              ? constLabel.lbl_quantity
                                              : "Quantity"}
                                          </label>
                                        </div>
                                        <div className="col-12 col-md-1">
                                          <label className="roboto-bold">
                                            {constLabel?.lbl_unit
                                              ? constLabel.lbl_unit
                                              : "Unit"}
                                          </label>
                                        </div>
                                      </div>
                                      {val.challan_line_items &&
                                        val.challan_line_items.length > 0 &&
                                        val.challan_line_items.map(
                                          (vv, index) => (
                                            <div className="row " key={index}>
                                              {/* <div className="col-12 col-md-1">
                                              <label className="roboto">
                                                {index + 1}
                                              </label>
                                            </div> */}

                                              <div className="col-12 col-md-2">
                                                <label className="roboto">
                                                  {vv.work_order_no
                                                    ? <a href='#' key={val.uuid + i + index} onClick={(e) => {
                                                      e.preventDefault();
                                                      navigate(`/pcms/goodschallan-work-order-view`, {
                                                        state: { work_order_no: vv.work_order_no, startDate: startDate, endDate: endDate },
                                                      });
                                                    }

                                                    }>{vv.work_order_no}</a>
                                                    : "NA"}
                                                </label>
                                              </div>
                                              <div className="col-12 col-md-2">
                                                <label className="roboto">
                                                  {vv.work_order_quantity
                                                    ? vv.work_order_quantity
                                                    : "NA"}
                                                </label>
                                              </div>
                                              <div className="col-12 col-md-2">
                                                <label className="roboto">
                                                  {vv.job_card_no
                                                    ? vv.job_card_no
                                                    : "NA"}
                                                </label>
                                              </div>

                                              <div className="col-12 col-md-2">
                                                <label className="roboto">
                                                  {vv.product_name}
                                                </label>
                                              </div>

                                              <div className="col-12 col-md-2">
                                                <label className="roboto">
                                                  {vv.operation_name
                                                    ? vv.operation_name
                                                    : "NA"}
                                                </label>
                                              </div>
                                              <div className="col-12 col-md-1">
                                                <label className="roboto">
                                                  {vv.qty}
                                                </label>
                                              </div>
                                              <div className="col-12 col-md-1">
                                                <label className="roboto">
                                                  {vv.uom}
                                                </label>
                                              </div>
                                            </div>
                                          )
                                        )}
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="mb-0">
                          Show
                          <select
                            className="mx-1"
                            defaultValue={entriesPerPage}
                            onChange={(e) => {
                              setEntriesPerPage(e.target.value);
                              fetchchallan(1, e.target.value);
                            }}
                          >
                            <option value={"10"}>10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          Entries
                        </p>
                        <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                          } to ${Math.min(
                            parseInt(entriesPerPage) + parseInt(srNo),
                            dataList.totalRecords
                          )} of ${dataList.totalRecords} entries`}</p>
                        <Pagination className="my-2">
                          <Pagination.Prev
                            disabled={page === 1 ? true : false}
                            onClick={() => {
                              fetchchallan(
                                page - 1,
                                entriesPerPage,
                                parseInt(srNo) - parseInt(entriesPerPage)
                              );
                              setSrNo((prevC) =>
                                page - 1 == 1
                                  ? 0
                                  : prevC - parseInt(entriesPerPage)
                              );
                              setPage(page - 1);
                            }}
                          >
                            {"Prev"}
                          </Pagination.Prev>

                          <Pagination.Item active>{page}</Pagination.Item>

                          <Pagination.Next
                            disabled={
                              page === maxPage ||
                                maxPage === 0 ||
                                entriesPerPage > dataList.data.length
                                ? true
                                : false
                            }
                            onClick={() => {
                              fetchchallan(
                                page + 1,
                                entriesPerPage,
                                parseInt(srNo) + parseInt(entriesPerPage)
                              );
                              setSrNo(
                                (prevC) => prevC + parseInt(entriesPerPage)
                              );
                              setPage(page + 1);
                            }}
                          >
                            {"Next"}
                          </Pagination.Next>
                        </Pagination>
                      </div>
                    </>
                  ) : (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"Challan not found !"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {/* Modal to display full image */}
      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        <Modal.Header style={{ background: "#2A3643", color: "white" }} closeButton>
          <Modal.Title>{constLabel?.lbl_product ? constLabel?.lbl_product : "Product"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {item_list.loading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : item_list.data?.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className="table-grey roboto-bold">
                  <tr className="">
                    <th scope="col">{"Sr No"}</th>
                    <th scope="col">{"Product"}</th>
                  </tr>
                </thead>
                <tbody className="roboto">
                  {item_list.data?.map((val, i) => (
                    <tr key={i}>
                      <td style={{ verticalAlign: "middle" }}>
                        {i + 1}
                      </td>
                      <td>
                        {`${val?.product_name} - ${val?.qty} - ${val?.uom} `}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-center text-danger mb-0">{"No data found !"}</p>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default GoodsChallan;