// components/EmployeeSlider.js
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

const localizer = momentLocalizer(moment);

const EmployeeSlider = ({ onJobCardCreate, onJobCardDelete }) => {
  const { list } = useSelector((state) => state.employees);
  const [currentIndex, setCurrentIndex] = useState(0);

  const employees = list.slice(currentIndex, currentIndex + 3);

  return (
    <div>
      <button onClick={() => setCurrentIndex(Math.max(0, currentIndex - 1))}>Prev</button>
      <button onClick={() => setCurrentIndex(Math.min(list.length - 3, currentIndex + 1))}>
        Next
      </button>
      <div className="slider">
        {employees.map((employee) => (
          <div key={employee.id}>
            <h3>{employee.name}</h3>
            <Calendar
              localizer={localizer}
              events={employee.jobCards.map((jobCard) => ({
                id: jobCard.id,
                title: jobCard.name,
                start: new Date(jobCard.startDate),
                end: new Date(jobCard.endDate),
              }))}
              style={{ height: 400 }}
              onSelectEvent={(event) => onJobCardDelete(event.id)}
              onSelectSlot={(slotInfo) =>
                onJobCardCreate(employee.id, slotInfo.start, slotInfo.end)
              }
              selectable
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmployeeSlider;
