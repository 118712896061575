import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { saveCustomer, updateCustomer } from "../../../../api";

import { ToastContainer, toast } from "react-toastify";
import { getLabels } from "../../../../common/Functions/CommonFunctions";

function Preview(props) {
  let navigate = useNavigate();
  const location = useLocation();
  const {
    customerType,
    organization,
    website,
    name,
    primaryContactNo,
    secondaryContactNo,
    email,
    address,
    postalCode,
    country_name,
    state_name,
    city_name,
    TIN,
    TAN,
    VATNO,
    CSTNO,
    gst,
    serviceTaxNo,
    gst_treatment,
    party_contact,
    billingAddresse,
    shippingAddresse,
    PANNO,
  } = props.main_obj;

  const {
    country_name: country_name_bill,
    city_name: city_name_bill,
    state_name: state_name_bill,
    postalCode: postalCode_bill,
    addressLine1: addressLine1_bill,
    addressLine2: addressLine2_bill,
  } = billingAddresse.length > 0 && billingAddresse[0];

  const {
    country_name: country_name_s,
    city_name: city_name_s,
    state_name: state_name_s,
    postalCode: postalCode_s,
    addressLine1: addressLine1_s,
    addressLine2: addressLine2_s,
  } = shippingAddresse.length > 0 && shippingAddresse[0];

  const p_contact_array = [];

  const [constLabel, setConstLabel] = useState({});

  if (party_contact.length > 0) {
    for (let i = 0; i < party_contact.length; i++) {
      let obj = {};
      obj["First Name"] = party_contact[i].firstName;
      obj["Last Name"] = party_contact[i].lastName;
      obj["Mobile Number"] = party_contact[i].mobileNo;
      obj["Alt. Mobile No"] = party_contact[i].alternateMobileNo;
      obj["Landline Number"] =
        party_contact[i].landlineExtentionNo &&
        party_contact[i].landlineNo &&
        party_contact[i].landlineExtentionNo.length > 0 &&
        party_contact[i].landlineNo.length > 0
          ? `${party_contact[i].landlineExtentionNo}-${party_contact[i].landlineNo}`
          : "";
      obj["Alt. Landline No."] =
        party_contact[i].alternateLandlineExtentionNo &&
        party_contact[i].alternateLandlineNo &&
        party_contact[i].alternateLandlineExtentionNo.length > 0 &&
        party_contact[i].alternateLandlineNo.length > 0
          ? `${party_contact[i].alternateLandlineExtentionNo}-${party_contact[i].alternateLandlineNo}`
          : "";
      obj["Email"] = party_contact[i].emailid;
      obj["Designation"] = party_contact[i].designation;
      obj["Additional Information"] = party_contact[i].remark;

      p_contact_array.push(obj);
    }
  }

  useEffect(() => {
    setConstLabel(getLabels("Party Master", "Customer"));
  }, []);

  const onSubmit = () => {
    if (location.state == null && location.pathname == "/pcms/addCustomer") {
      saveCustomer(props.main_obj).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Customer details created Successfully !", {
              autoClose: 2000,
            });
            // props.setKey("bankDetail");
            // localStorage.removeItem("basic");
            // localStorage.removeItem("contacts");
            // localStorage.removeItem("billing");
            // localStorage.removeItem("shipping");
            setTimeout(() => {
              navigate(`/pcms/customer`);
            }, 2200);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 3000,
            });
          }
        }
      );
    } else {
      updateCustomer(props.main_obj).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Customer details updated Successfully !", {
              autoClose: 2000,
            });
            setTimeout(() => {
              navigate(`/pcms/customer`);
            }, 2200);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, { autoClose: 3000 });
          }
        }
      );
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="row d-flex justify-content-between">
        <div className="col-sm-6 text-start">
          {" "}
          <h4 className="mb-4 roboto-bold">
            {constLabel?.lbl_customer_detail
              ? constLabel?.lbl_customer_detail
              : "Customer Detail"}
          </h4>
        </div>
        <div className="col-sm-2 text-end">
          <button
            onClick={() => {
              props.previousStep(1);
            }}
            className="px-3 btn btn-grey "
          >
            {constLabel?.lbl_edit ? constLabel?.lbl_edit : "Edit"}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_customer_type
                ? constLabel?.lbl_customer_type
                : `Customer Type`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {customerType || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_company_name
                ? constLabel?.lbl_company_name
                : `Company Name`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {organization || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_name ? constLabel?.lbl_name : `Name`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {name || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_gst_treatment
                ? constLabel?.lbl_gst_treatment
                : `GST Registration Type`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {gst_treatment || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_gst_no ? constLabel?.lbl_gst_no : `GST No`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {gst || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_pan ? constLabel?.lbl_pan : `PAN`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {PANNO || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_contact_no
                ? constLabel?.lbl_contact_no
                : `Contact No`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {primaryContactNo || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_alternate_contact
                ? constLabel?.lbl_alternate_contact
                : `Alternate Contact`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {secondaryContactNo || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_website ? constLabel?.lbl_website : `Website`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {website || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_email_id ? constLabel?.lbl_email_id : `Email Id`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {email || "NA"}
            </p>
          </div>
        </div>

        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_address ? constLabel?.lbl_address : `Address`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {address || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_country ? constLabel?.lbl_country : `Country`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {country_name || "NA"}
            </p>
          </div>
        </div>

        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_state ? constLabel?.lbl_state : `State`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {state_name || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_city ? constLabel?.lbl_city : `City`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {city_name || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_postal_code
                ? constLabel?.lbl_postal_code
                : `Postal Code`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {postalCode || "NA"}
            </p>
          </div>
        </div>
      </div>
      <hr className="my-3 mx-2" style={{ width: "98%" }} />
      <div className="row d-flex justify-content-between">
        <div className="col-sm-6 text-start">
          {" "}
          <h4 className="mb-4 roboto-bold">
            {constLabel?.lbl_contact_detail
              ? constLabel?.lbl_contact_detail
              : "Contact Detail"}
          </h4>
        </div>
        <div className="col-sm-2 text-end">
          <button
            onClick={() => {
              props.previousStep(2);
            }}
            className="px-3 btn btn-grey "
          >
            {constLabel?.lbl_edit ? constLabel?.lbl_edit : "Edit"}
          </button>
        </div>
      </div>

      {party_contact.length > 0
        ? p_contact_array.map((item, ind) => {
            return (
              <div className={ind > 0 ? "row mt-4" : "row "}>
                {Object.keys(item).map((key) => (
                  <div className="col-12 col-md-4 d-flex" key={key}>
                    <div className="col-12 col-md-6">
                      <p
                        className="m-0 roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {key}
                      </p>
                    </div>
                    <div className="col-12 col-md-6">
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {item[key] || "NA"}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            );
          })
        : null}

      <hr className="my-3 mx-2" style={{ width: "98%" }} />
      <div className="row d-flex justify-content-between">
        <div className="col-sm-6 text-start">
          {" "}
          <h4 className="mb-4 roboto-bold">
            {constLabel?.lbl_billing_address
              ? constLabel?.lbl_billing_address
              : "Billing Address"}
          </h4>
        </div>
        <div className="col-sm-2 text-end">
          <button
            onClick={() => {
              props.previousStep(3);
            }}
            className="px-3 btn btn-grey "
          >
            {constLabel?.lbl_edit ? constLabel?.lbl_edit : "Edit"}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_address_line1
                ? constLabel?.lbl_address_line1
                : `Address Line1`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {addressLine1_bill || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_address_line2
                ? constLabel?.lbl_address_line2
                : `Address Line 2`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {addressLine2_bill || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_country ? constLabel?.lbl_country : `Country`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {country_name_bill || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_state ? constLabel?.lbl_state : `State`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {state_name_bill || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_city ? constLabel?.lbl_city : `City`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {city_name_bill || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_postal_code
                ? constLabel?.lbl_postal_code
                : `Postal Code`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {postalCode_bill || "NA"}
            </p>
          </div>
        </div>
      </div>
      <hr className="my-3 mx-2" style={{ width: "98%" }} />
      <div className="row d-flex justify-content-between">
        <div className="col-sm-6 text-start">
          {" "}
          <h4 className="mb-4 roboto-bold">
            {constLabel?.lbl_shipping_address
              ? constLabel?.lbl_shipping_address
              : "Shipping Address"}
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_address_line1
                ? constLabel?.lbl_address_line1
                : `Address Line1`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {addressLine1_s || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_address_line2
                ? constLabel?.lbl_address_line2
                : `Address Line2`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {addressLine2_s || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_country ? constLabel?.lbl_country : `Country`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {country_name_s || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_state ? constLabel?.lbl_state : `State`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {state_name_s || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_city ? constLabel?.lbl_city : `City`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {city_name_s || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_postal_code
                ? constLabel?.lbl_postal_code
                : `Postal Code`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {postalCode_s || "NA"}
            </p>
          </div>
        </div>
      </div>
      {/* <hr className="my-3 mx-2" style={{ width: "98%" }} />
      <div className="row d-flex justify-content-between">
        <div className="col-sm-6 text-start">
          {" "}
          <h4 className="mb-4 roboto-bold">
            {constLabel?.lbl_tax_detail
              ? constLabel?.lbl_tax_detail
              : "Tax Detail"}
          </h4>
        </div>
        <div className="col-sm-2 text-end">
          <button
            onClick={() => {
              props.previousStep(4);
            }}
            className="px-3 btn btn-grey "
          >
            {constLabel?.lbl_edit ? constLabel?.lbl_edit : "Edit"}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_gst_treatment
                ? constLabel?.lbl_gst_treatment
                : `GST Registration Type`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {gst_treatment || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_gst_no ? constLabel?.lbl_gst_no : `GST No`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {gst || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_tin ? constLabel?.lbl_tin : `TIN`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {TIN || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_tan ? constLabel?.lbl_tan : `TAN`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {TAN || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_cst_no ? constLabel?.lbl_cst_no : `CST No`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {CSTNO || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_service_tax_no
                ? constLabel?.lbl_service_tax_no
                : `Service Tax No`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {serviceTaxNo || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_vat ? constLabel?.lbl_vat : `VAT`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {VATNO || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p className="m-0  roboto-bold" style={{ fontSize: "14px" }}>
              {constLabel?.lbl_pan ? constLabel?.lbl_pan : `PAN`}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {PANNO || "NA"}
            </p>
          </div>
        </div>
      </div> */}
      <hr className="my-3 mx-2" style={{ width: "98%" }} />
      <div className="row d-flex justify-content-between">
        <div className="col-sm-2 text-start">
          <button
            onClick={() => {
              props.previousStep();
            }}
            className="px-3 btn btn-yellow "
          >
            {"Previous"}
          </button>
        </div>

        <div className="col-sm-2 text-end">
          <button onClick={onSubmit} className="px-3 btn button-primary ">
            {constLabel?.lbl_submit ? constLabel?.lbl_submit : "Submit"}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Preview;
