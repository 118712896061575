import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import Roboto from "../../assets/fonts/Roboto-Regular.ttf";
import Roboto_Bold from "../../assets/fonts/Roboto-Bold.ttf";
import legimage from "../../assets/images/leg.png";
import {
  PDFViewer,
  Document,
  Text,
  View,
  Image,
  Page,
  Link,
  StyleSheet,
  Font,
  pdf,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import {
  formatDate5,
  formatDateString,
  groupRecordsBySequence,
} from "../../common/Functions/CommonFunctions";

function SalesOrderPDF() {
  let navigate = useNavigate();
  let location = useLocation();
  const s3_url = process.env.REACT_APP_IMAGE_URL;
  const [pdfBlobUrl, setPdfBlobUrl] = useState("");
  const [isPdfLoaded, setIsPdfLoaded] = useState(false);

  const [measurements, setMesurements] = useState([
    { name: "ABOVE BUST", value: null },
    { name: "BUST", value: null },
    { name: "BELOW BUST", value: null },
    { name: "WAIST", value: null },
    { name: "STOMACH", value: null },
    { name: "HIPS", value: null },
    { name: "SHOULDER", value: null },
    { name: "INCUT SLV-LESS SH", value: null },
    { name: "ARMHOLE", value: null },
    { name: "BICEP", value: null },
    { name: "SLEEVES", value: null },
    { name: "", value: null },
    { name: "", value: null },
    { name: "NECK FRONT", value: null },
    { name: "NECK BACK", value: null },
    { name: "COLLAR", value: null },
    { name: "ACROSS FRONT", value: null },
    { name: "ACROSS BACK", value: null },
  ]);
  const [below_waist_measurements, setBelow_waist_measurements] = useState([
    { name: "TROUSER WAIST", value: null },
    { name: "HIPS", value: null },
    { name: "FORK", value: null },
    { name: "SIDE SLIT", value: null },
    { name: "SALWAR PONCHA", value: null },
    { name: "CHURIDAR BOTTOM", value: null },
    { name: "TROUSER BOTTOM", value: null },
  ]);
  const [measurements_checkboxes, setMesurements_checkboxes] = useState([
    { name: "DOWN ROUND SH", value: null },
    { name: "SQUARE SH", value: null },
    { name: "V. DOWN SH", value: null },
    { name: "FORWARD CHEST", value: null },
    { name: "ROUND BACK", value: null },
    { name: "BELLY OUT", value: null },
    { name: "HIGH/FLAT HIP", value: null },
    { name: "HIGH NECK", value: null },
    { name: "LOW NECK", value: null },
    { name: "RUBBING THIGH", value: null },
  ]);
  const [sleeves_measurement, setSleeves_measurement] = useState([
    { name: "HALF", value1: null, value2: null },
    { name: "3/4TH", value1: null, value2: null },
    { name: "FULL", value1: null, value2: null },
  ]);
  const [sleeves_short, setsleeves_shot] = useState([
    { name: "SHORT", value1: null, value2: null },
  ]);
  const [points1, setpoints1] = useState([]);

  const [points3, setpoints3] = useState([]);

  const [total_tax, setTotal_tax] = useState("0.00");

  const [legs, setlegs] = useState(["", "", "", "", "", ""]);
  const [length_data, setlength_data] = useState({
    name: "LENGTH",
    value: null,
  });
  const localData = JSON.parse(localStorage.getItem("localData"));

  const downloadLinkRef = useRef(null);
  const data = location.state;

  console.log("data", data);

  Font.register({
    family: "roboto, roboto-light,roboto-bold",
    fonts: [
      {
        src: Roboto,
      },
      {
        src: Roboto_Bold,
        fontWeight: 600,
      },
    ],
  });

  const styles = StyleSheet.create({
    viewer: {
      width: "100%",
      height: window.innerHeight,
    },
    page: {
      paddingTop: 15,
      paddingBottom: 40,
      paddingHorizontal: 15,
      color: "black",
      fontFamily: "roboto, roboto-light,roboto-bold",
      border: "1px solid black",
    },
    page2: {
      paddingTop: 15,
      paddingBottom: 40,
      paddingHorizontal: 8,
      color: "black",
      fontFamily: "roboto, roboto-light,roboto-bold",
      border: "1px solid black",
    },
    page3: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 10,
    },
    image: {
      width: "100%",
      height: "85%",
      // objectFit: "contain",
      objectFit: "cover",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 28,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "black",
    },
    footer: {
      position: "absolute",
      fontSize: 12,
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "black",
      paddingTop: "50px",
      paddingBottom: "10",
      paddingLeft: "15",
      paddingRight: "15",
      // borderTop:'1px solid black',
    },
    title: {
      textAlign: "center",
      fontWeight: 600,
      fontSize: "16px",
    },
    title1: {
      textAlign: "center",
      fontWeight: 500,
      fontSize: "30px",
    },
    title2: {
      textAlign: "center",
      // fontWeight: 500,
      fontSize: "21px",
    },
    header: {
      margin: 5,
      color: "black",
      fontSize: "10px",
    },
    headerrow: {
      flexDirection: "row",
      alignItems: "flex-start",
      margin: 5,
      alignItems: "justify-content-between",
      width: "100%",
      gap: "50px",
      // border: "1px solid black",
    },
    headerbox: {
      margin: 5,
      color: "black",
      fontSize: "10px",
      width: "50%",
    },

    tofromrow: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 5,
      alignItems: "justify-content-between",
      width: "100%",
      gap: "100Px",
    },
    tofrombox: {
      margin: 5,
      color: "black",
      fontSize: "12px",
      width: "50%",
      // border:"1px soild black"
    },
    tofrombox2: {
      margin: 5,
      color: "black",
      fontSize: "12px",
      width: "60%",
      // border:"1px soild black"
    },
    headerrow4: {
      flexDirection: "row",
      marginBottom: 5,
      padding: "5px",
      width: "100%",
      fontSize: "12px",
      backgroundColor: "#f5f5f5",
    },
    headerbox4: {
      margin: 5,
      color: "black",
      fontSize: "12px",
      width: "50%",
      // border: "1px solid black",
    },
    tofrominsiderow: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 5,
    },
    tofromlabel: {
      fontSize: "10px",
      fontWeight: "bold",
    },
    tofromvalue: {
      flex: 1,
      fontSize: "10px",
      marginLeft: "5px",
    },
    pricetableheader: {
      flexDirection: "row",
      marginTop: 5,
      marginBottom: 5,
      width: "100%",
      fontSize: "10px",
      backgroundColor: "#f5f5f5",
    },
    pricetablerows: {
      flexDirection: "row",
      marginBottom: 5,
      width: "100%",
      fontSize: "10px",
    },
    tablecolumn1: {
      width: "6%",
    },
    tablecolumn2: {
      width: "35%",
    },
    tablecolumn3: {
      width: "15%",
    },
    tablecolumn4: {
      width: "10%",
    },
    tablecolumn5: {
      width: "12%",
    },
    tablerows: {
      flexDirection: "row",
      marginBottom: 5,
      width: "100%",
      fontSize: "10px",
      gap: "2%",
    },
    bottomSection: {
      marginTop: 20,
      flexDirection: "column",
      alignItems: "flex-end",
    },
    bottomSectiontext: {
      fontSize: 12,
      // marginBottom: 5,
      fontWeight: "bold",
    },
    bottomSectionValue: {
      fontSize: 12,
      // marginBottom: 10,
      // fontWeight: "bold",
    },

    // Measurement Section
    headerrow2: {
      flexDirection: "row",
      alignItems: "flex-start",
      //   marginBottom: 5,
      alignItems: "justify-content-between",
      width: "100%",
      gap: "0px",
      //   backgroundColor: "#BCF2F6",
      // border: "1px solid black",
    },
    headerbox2: {
      //   margin: 5,
      color: "black",
      fontSize: "12px",
      width: "60%",
      // border: "1px solid black",
    },
    headerbox3: {
      //   margin: 5,
      color: "black",
      fontSize: "12px",
      width: "40%",
      // border: "1px solid black",
    },

    headerbox2row: {
      width: "100%",
      marginLeft: 5,
      //   textAlign: "center",
      // border:"1px solid black"
    },
    headerbox2row2: {
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 5,
      border: "1px solid black",
    },
    headerrow3: {
      fontSize: "14px",
      //   margin: 5,
      width: "100%",
      color: "white",
      backgroundColor: "black",
      // border: "1px solid black",
    },
    headerrow4: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginTop: 3,
      alignItems: "justify-content-between",
      width: "100%",
      gap: "10px",
      //   backgroundColor: "#BCF2F6",
      // border: "1px solid black",
    },
    headerbox41: {
      //   margin: 5,
      color: "black",
      fontSize: "10px",
      width: "32%",
      // border: "1px solid black",
    },
    headerbox41row1: {
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      //   marginBottom: 5,
      border: "1px solid black",
      fontSize: "10px",
    },

    headerbox41rowforline: {
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      //   marginBottom: 5,
      // border: "1px solid black",
      fontSize: "10px",
    },

    headerbox41row1label: {
      fontSize: "10px",
      margin: 3,
      //   border: "1px solid black",
      width: "58%",
    },
    headerbox41row1value: {
      flex: 1,
      fontSize: "10px",
      margin: 3,
      width: "40%",

      //   borderLeft: "1px solid black",
    },

    measuredboxlabel: {
      fontSize: "10px",
      margin: 3,
      //   border: "1px solid black",
      width: "40%",
    },
    measuredboxvalue: {
      flex: 1,
      fontSize: "10px",
      margin: 3,
      width: "60%",

      //   borderLeft: "1px solid black",
    },

    headerbox41sleeverow: {
      width: "60%",
      flexDirection: "row",
      alignItems: "flex-start",
      marginTop: 220,
      marginLeft: -10,
      border: "1px solid black",
      fontSize: "10px",
    },
    headerbox41sleeverow2: {
      width: "60%",
      flexDirection: "row",
      alignItems: "flex-start",
      marginTop: 0,
      marginLeft: -10,
      border: "1px solid black",
      fontSize: "10px",
    },
    headerbox41sleeverow3: {
      width: "60%",
      flexDirection: "row",
      alignItems: "flex-start",
      marginTop: 0,
      marginLeft: -10,
      border: "1px solid black",
      fontSize: "10px",
    },
    headerbox41sleevelabel: {
      fontSize: "10px",
      margin: 3,
      textAlign: "center",
      //   border: "1px solid black",
      width: "32%",
    },

    headerbox42: {
      marginLeft: 20,
      color: "black",
      fontSize: "10px",
      width: "10%",
      //   border: "1px solid black",
    },
    headerbox43: {
      // marginLeft: 10,
      color: "black",
      fontSize: "10px",
      width: "16%",
    },
    row: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 5,
    },
    label: {
      fontSize: "12px",
      margin: 5,
      //   fontWeight: "bold",
    },
    value: {
      flex: 1,
      fontSize: "12px",
      marginTop: 5,
      marginBottom: 5,
      marginRight: 5,

      //   marginLeft: "5px",
    },
    line: {
      position: "absolute",
      backgroundColor: "black",
    },
    point: {
      position: "absolute",
      width: 6, // Diameter of the point
      height: 6,
      backgroundColor: "black",
      borderRadius: 3, // Makes it circular
    },
    labelline: {
      position: "absolute",
      fontSize: 10,
      textAlign: "right", // Aligns to the right of the label area
      // border:"1px solid black"
    },
    valueline: {
      position: "absolute",
      fontSize: 10,
      textAlign: "left",
    },

    footer1: {
      color: "black",
      marginTop: 5,
    },

    image2: {
      width: 50,
      height: 180,
    },
    lineContainer: {
      width: 50,
      height: "350px",
      position: "relative",
      marginTop: -280,
      marginLeft: 190,
      // border:"1px solid black"
    },

    lineContainer3: {
      width: 50,
      height: "200px",
      position: "relative",
      marginTop: -200,
      marginLeft: 190,
      // border:"1px solid black"
    },

    verticalLine: {
      position: "absolute",
      left: "50%",
      top: 0,
      bottom: 0,
      width: 2,
      backgroundColor: "black",
    },
    horizontalLine: {
      position: "absolute",
      left: "45%",
      width: 20,
      height: 2,
      marginLeft: -5,
      backgroundColor: "black",
    },

    // pointContainer: {
    //   position: "absolute",
    //   left: "50%",
    //   transform: "translateX(-50%)",
    //   flexDirection: "row",
    //   alignItems: "center",
    //   justifyContent: "center",
    // },

    point: {
      width: 6,
      height: 6,
      backgroundColor: "black",
      borderRadius: 3,
      marginLeft: 48,
    },

    label2: {
      fontSize: 10,
      textAlign: "left",
      marginLeft: 5,
    },

    pointContainer: {
      position: "absolute",
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: "24px",
    },
    point: {
      width: 5,
      height: 5,
      borderRadius: 5,
      backgroundColor: "black",
      zIndex: 1,
    },
    labelLeft: {
      position: "absolute",
      left: "-100px", // Move slightly farther to accommodate up to 10 characters
      width: "90px", // Set width to handle 8–10 characters
      top: "-3px",
      fontSize: 9,
      color: "black",
      textAlign: "right", // Align text closer to the point
      overflow: "hidden", // Avoid text overflow
    },
    labelRight: {
      position: "absolute",
      left: "10px", // Position label consistently to the right of the point
      width: "55px", // Set width to handle 8–10 characters
      top: "-3px",
      fontSize: 9,
      color: "black",
      textAlign: "left", // Align text closer to the point
      overflow: "hidden", // Avoid text overflow
    },
    remarks: {
      fontSize: "10px",
      fontWeight: "bold",
    },
  });

  const addPositionsToArray = (array) => {
    // Sort the array by sequence_no
    const sortedArray = [...array].sort(
      (a, b) => a.sequence_no - b.sequence_no
    );

    // Calculate positions and add to each object
    const length = sortedArray.length;
    const updatedArray = sortedArray.map((item, index) => ({
      ...item,
      position: (index + 1) / (length + 1), // Calculate position dynamically
    }));

    return updatedArray;
  };

  useEffect(() => {
    if (localData.industry_type == "Fashion") {
      const result = measurements.map((measurement) => {
        const match = data?.so_order_lines[0].measurement?.attributes.find(
          (item) => item.name.toLowerCase() === measurement.name.toLowerCase()
        );
        if (measurement.name.includes("BUST")) {
          if (data?.so_order_lines[0]?.party?.gender == "Male") {
            let inputString = measurement.name.replace("BUST", "CHEST");
            // console.log("inputString",inputString)
            const match1 = data?.so_order_lines[0].measurement?.attributes.find(
              (item) => item.name.toLowerCase() === inputString.toLowerCase()
            );
            // console.log("match1",match1)

            return { name: inputString, value: match1 ? match1.value : null };
          } else {
            return {
              name: measurement.name,
              value: match ? match.value : null,
            };
          }
        } else {
          return { name: measurement.name, value: match ? match.value : null };
        }
      });
      const result2 = below_waist_measurements.map((measurement) => {
        const match = data?.so_order_lines[0].measurement?.attributes.find(
          (item) => item.name.toLowerCase() === measurement.name.toLowerCase()
        );
        return { name: measurement.name, value: match ? match.value : null };
      });
      const result3 = measurements_checkboxes.map((measurement) => {
        const match = data?.so_order_lines[0].measurement?.attributes.find(
          (item) => item.name.toLowerCase() === measurement.name.toLowerCase()
        );
        return { name: measurement.name, value: match ? match.value : null };
      });

      const line1_data = data?.so_order_lines[0].measurement?.attributes.filter(
        (item) =>
          item.field_type === "line" && item.sub_attribute_type === "upperbody"
      );
      const leg_data = data?.so_order_lines[0].measurement?.attributes.find(
        (item) => item.name == "Leg"
      );

      const line3_data = data?.so_order_lines[0].measurement?.attributes.filter(
        (item) =>
          item.field_type === "line" && item.sub_attribute_type === "lowerbody"
      );

      const length_data_find =
        data?.so_order_lines[0].measurement?.attributes.find(
          (item) => item.name == "LENGTH"
        );
      if (length_data_find) {
        if (length_data_find.value) {
          setlength_data({ name: "LENGTH", value: length_data_find.value });
        }
      }
      if (line1_data.length > 0) {
        let poi = addPositionsToArray(line1_data);
        setpoints1(poi);
      }
      if (leg_data) {
        if (leg_data.value) {
          let newValues = JSON.parse(leg_data.value);
          // console.log("newValues",newValues);
          let arr = newValues.map((value) => (value == "" ? " " : value));
          setlegs(arr);
        }
      }

      if (line3_data.length > 0) {
        //  console.log("line3_data",line3_data)
        let poi = addPositionsToArray(line3_data);
        // console.log("poi", poi);

        setpoints3(poi);
      }

      const updatedResult5 = sleeves_measurement.map((entry) => {
        const matchedData =
          data?.so_order_lines[0].measurement?.attributes.filter((item) =>
            item.name.includes(`SLEEVES ${entry.name}`)
          );
        const values = { value1: null, value2: null };

        matchedData.forEach((item) => {
          const suffix = item.name.endsWith("1") ? "value1" : "value2";
          values[suffix] = item.value;
        });

        return { name: entry.name, ...values };
      });

      setSleeves_measurement(updatedResult5);

      const updatedResult6 = sleeves_short.map((entry) => {
        const matchedData =
          data?.so_order_lines[0].measurement?.attributes.filter((item) =>
            item.name.includes(`SLEEVES ${entry.name}`)
          );
        const values = { value1: null, value2: null };

        matchedData.forEach((item) => {
          const suffix = item.name.endsWith("1") ? "value1" : "value2";
          values[suffix] = item.value;
        });

        return { name: entry.name, ...values };
      });
      console.log("updatedResult6", updatedResult6);
      setsleeves_shot(updatedResult6);
      // console.log("line1",JSON.parse(line1_data.value));
      // console.log("leg_data",JSON.parse(leg_data.value));
      // console.log("line2_data",JSON.parse(line2_data.value));
      // console.log("line3_data",JSON.parse(line3_data.value));
      // console.log("line4_data",JSON.parse(line4_data.value));
      setMesurements_checkboxes(result3);

      setBelow_waist_measurements(result2);

      setMesurements(result);
      // console.log("result", result);
    }
    if (
      [data.ugst_cost, data.igst_cost, data.sgst_cost, data.cgst_cost].every(
        (value) => value !== undefined && value !== null
      )
    ) {
      const total =
        +data.ugst_cost + +data.igst_cost + +data.sgst_cost + +data.cgst_cost;
      // console.log("total",total)
      setTotal_tax(total.toFixed(2));
    }
  }, [location.state]);

  useEffect(() => {
    const generatePdfBlob = async () => {
      const doc = <SaleOrderDocument data={data} />;
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      const url = URL.createObjectURL(blob);
      setPdfBlobUrl(url);
      setIsPdfLoaded(true);
    };

    generatePdfBlob();
  }, [data]);

  const handleDownload = () => {
    const link = downloadLinkRef.current;
    link.href = pdfBlobUrl;
    link.download = `Sales Order- ${data.so_id}.pdf`;
    link.click();
  };

  // working Line Component
  const Line = ({ x1, y1, x2, y2, points = [] }) => {
    // Calculate the line's length and angle
    const length = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    const angle = Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI);

    // Function to calculate offset positions for labels and values
    const calculatePosition = (offset, angle) => {
      const angleRad = (angle * Math.PI) / 180;
      return {
        deltaX: offset * Math.cos(angleRad),
        deltaY: offset * Math.sin(angleRad),
      };
    };

    return (
      <View
        style={[
          styles.line,
          {
            width: length,
            top: y1,
            left: x1,
            height: 1, // Thickness of the main line
            transform: `rotate(${angle}deg)`,
            transformOrigin: "top left",
          },
        ]}
      >
        {/* Render Points with Labels and Values */}
        {points.map((point, index) => {
          const { position, label, value } = point;

          // Calculate position for the point along the line
          const pointX = position * length;

          // Calculate positions for label and value
          const { deltaX: labelOffsetX, deltaY: labelOffsetY } =
            calculatePosition(-15, angle); // Left of point
          const { deltaX: valueOffsetX, deltaY: valueOffsetY } =
            calculatePosition(15, angle); // Right of point

          return (
            <View key={index}>
              {/* Point (Circle) */}
              <View
                style={[
                  styles.point,
                  {
                    left: pointX - 3, // Center the point horizontally
                    top: -3, // Center the point vertically
                  },
                ]}
              />

              {/* Label (Left side of the line) */}
              <Text
                style={[
                  styles.labelline,
                  {
                    left: pointX + labelOffsetX,
                    top: labelOffsetY - 3, // Slight adjustment to make closer
                    transform: `rotate(-${angle}deg)`, // Rotate back to horizontal
                  },
                ]}
              >
                {value}
              </Text>

              {/* Value (Right side of the line) */}
              <Text
                style={[
                  styles.valueline,
                  {
                    left: pointX + valueOffsetX,
                    top: valueOffsetY - 3, // Slight adjustment to make closer
                    transform: `rotate(-${angle}deg)`, // Rotate back to horizontal
                  },
                ]}
              >
                {label}
              </Text>
            </View>
          );
        })}
      </View>
    );
  };

  const SaleOrderDocument = ({ data }) => (
    <Document title={`Sales Order`}>
      <Page size="A4" style={styles.page}>
        <View>
          <View style={[{ border: "1px solid black" }]}>
            <Text style={[styles.title, { marginTop: 5 }]}>Sales Order</Text>
            {/*Sales Order date & Delevery date  */}
            <View style={styles.headerrow}>
              {/* Sales order Number */}
              <View style={styles.headerbox}>
                <View style={styles.tofrominsiderow}>
                  <Text style={styles.tofromlabel}>Sales Order: </Text>
                  <Text style={styles.tofromvalue}>
                    {data.so_id ? data.so_id : "SO"}
                  </Text>
                </View>
                <View style={styles.tofrominsiderow}>
                  <Text style={styles.tofromlabel}>Sales Order Date: </Text>
                  <Text style={styles.tofromvalue}>
                    {data.order_date
                      ? formatDateString(data.order_date, "-")
                      : "NA"}
                  </Text>
                </View>
              </View>
              <View style={styles.headerbox}>
                <View style={styles.tofrominsiderow}>
                  <Text style={styles.tofromlabel}>Sales Order Name: </Text>
                  <Text style={styles.tofromvalue}>
                    {data?.sales_order_name ? data.sales_order_name : "NA"}
                  </Text>
                </View>
                <View style={styles.tofrominsiderow}>
                  <Text style={styles.tofromlabel}>Valid Till Date: </Text>
                  <Text style={styles.tofromvalue}>
                    {data.final_date
                      ? formatDateString(data.final_date, "-")
                      : "NA"}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {/* Sales order to & sales order from header */}
          <View style={[{ border: "1px solid black" }]}>
            <View style={styles.tofromrow}>
              <View style={styles.tofrombox2}>
                <Text>From:</Text>
              </View>
              <View style={styles.tofrombox}>
                <Text>To:</Text>
              </View>
            </View>

            {/*  to & from infomation  */}
            <View style={styles.tofromrow}>
              <View style={styles.tofrombox2}>
                <View style={styles.tofrominsiderow}>
                  <Text style={styles.tofromlabel}>Company Name:</Text>
                  <Text style={styles.tofromvalue}>
                    {data?.org_id?.comName ? data.org_id.comName : "NA"}
                  </Text>
                </View>
                <View style={styles.tofrominsiderow}>
                  <Text style={styles.tofromlabel}>Address:</Text>
                  <Text style={styles.tofromvalue}>
                    {data?.org_id?.comaddress ? data.org_id.comaddress : "NA"}
                  </Text>
                </View>

                <View style={styles.tofrominsiderow}>
                  <Text style={styles.tofromlabel}>Email:</Text>
                  <Text style={styles.tofromvalue}>
                    {data?.org_id?.mail ? data.org_id.mail : "NA"}
                  </Text>
                </View>
              </View>

              <View style={styles.tofrombox}>
                <View style={styles.tofrominsiderow}>
                  <Text style={styles.tofromlabel}>Customer Name:</Text>
                  <Text style={styles.tofromvalue}>
                    {data.customer_name ? data.customer_name : "NA"}
                  </Text>
                </View>
                <View style={styles.tofrominsiderow}>
                  <Text style={styles.tofromlabel}>Address:</Text>
                  <Text style={styles.tofromvalue}>
                    {data.delivery_address ? data.delivery_address : "NA"}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {/* Product  information */}

          <View style={[{ border: "1px solid black", padding: 5 }]}>
            <View style={styles.pricetableheader}>
              <View style={styles.tablecolumn1}>
                <Text>Sr.No</Text>
              </View>
              <View style={styles.tablecolumn2}>
                <Text>Product</Text>
              </View>
              <View style={styles.tablecolumn4}>
                <Text>Quantity</Text>
              </View>
              <View style={styles.tablecolumn4}>
                <Text>Unit</Text>
              </View>
              <View style={styles.tablecolumn4}>
                <Text>Rate ₹</Text>
              </View>

              <View style={styles.tablecolumn4}>
                <Text>GST %</Text>
              </View>
              <View style={styles.tablecolumn5}>
                <Text>Discount %</Text>
              </View>
              <View style={styles.tablecolumn5}>
                <Text>Amount ₹</Text>
              </View>
            </View>
            {data.so_order_lines.map((ele, index) => (
              <View key={index} style={styles.pricetablerows}>
                <View style={styles.tablecolumn1}>
                  <Text>{index + 1}</Text>
                </View>
                <View style={styles.tablecolumn2}>
                  <Text>{ele?.productname ? ele.productname : "NA"}</Text>
                </View>
                <View style={styles.tablecolumn4}>
                  <Text>{ele?.quantity ? ele.quantity : "NA"}</Text>
                </View>
                <View style={styles.tablecolumn4}>
                  <Text>{ele?.unit ? ele.unit : "NA"}</Text>
                </View>
                <View style={styles.tablecolumn4}>
                  <Text>{ele?.rate ? ele.rate : "NA"}</Text>
                </View>

                <View style={styles.tablecolumn4}>
                  <Text>{ele?.gst_rate ? ele.gst_rate : "NA"}</Text>
                </View>
                <View style={styles.tablecolumn5}>
                  <Text>{ele?.discount ? ele.discount : "NA"}</Text>
                </View>
                <View style={styles.tablecolumn5}>
                  <Text>{ele?.amount ? ele.amount : "NA"}</Text>
                </View>
              </View>
            ))}
          </View>

          {/* Bottom section */}
          <View style={[{ border: "1px solid black", padding: 5 }]}>
            <View style={styles.bottomSection}>
              <View style={styles.row}>
                <Text style={styles.bottomSectiontext}>Sub Total:{" "} </Text>
                <Text style={styles.bottomSectionValue}>
                  {data.sub_total ? data.sub_total.toFixed(2) : "0.00"}
                </Text>
              </View>
              <View style={styles.row}>
              <Text style={styles.bottomSectiontext}>
                Total Tax: {" "}
              </Text>
              <Text style={styles.bottomSectionValue}>
              {total_tax}
                </Text>
              </View>
              

              
              {localData?.industry_type == "Fashion" && (
                <View style={styles.row}>
                <Text style={styles.bottomSectiontext}>
                  Advance Amount:{" "}
                 
                </Text>
                <Text style={styles.bottomSectionValue}>
                  {data.advance_amount
                    ? data.advance_amount.toFixed(2)
                    : "0.00"}
                </Text>
                </View>
              )}

              {localData?.industry_type == "Fashion" ? (
                <View style={styles.row}>
                <Text style={styles.bottomSectiontext}>
                  Outstanding Amount:{" "}
                 
                </Text>
                <Text style={styles.bottomSectionValue}>
                 
                  {data.outstanding_amount
                    ? data.outstanding_amount.toFixed(2)
                    : "0.00"}
                </Text>
                </View>
              ) : (
                <View style={styles.row}>
                <Text style={styles.bottomSectiontext}>
                  Total Amount:{" "}
                </Text>
                <Text style={styles.bottomSectionValue}>
                  {data.final_total ? data.final_total.toFixed(2) : "0.00"}
                </Text>
                </View>
              )}
            </View>
          </View>

          {/* Special instruction section */}
          <View style={[{ border: "1px solid black", padding: 5 }]}>
            <View style={styles.tofrominsiderow}>
              <Text style={styles.tofromlabel}>Special Instruction: </Text>
              <Text style={styles.tofromvalue}>
                {data.internal_remark ? data.internal_remark : " "}
              </Text>
            </View>
            {/* <Text style={styles.remarks}>
              Special Instruction:
              {data.internal_remark ? data.internal_remark : " "}
            </Text> */}
          </View>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />

        <Text style={styles.footer} fixed>
          This is a Computer Generated Document
        </Text>
      </Page>
      {localData?.industry_type == "Fashion" && (
        <Page size="A4" style={styles.page2}>
          <View>
            <View style={styles.headerrow2}>
              <View style={styles.headerbox2}>
                <View style={styles.headerbox2row}>
                  <Text style={styles.title1}>NIVEDITA SABOO</Text>
                </View>

                <View style={styles.headerbox2row}>
                  <Text style={styles.title2}>COUTURE</Text>
                </View>
                <View style={styles.headerbox2row}>
                  <Text style={[{ fontSize: "10px", textAlign: "center" }]}>
                    Bungalow 20,Samrat Society,Lane No.7, Kalyani Nagar,
                    Pune:411014
                  </Text>
                </View>
                <View style={styles.headerbox2row}>
                  <Text style={[{ fontSize: "10px", textAlign: "center" }]}>
                    Phone No.: +91 92204 8000
                  </Text>
                </View>
              </View>
              <View style={styles.headerbox3}>
                <View style={styles.headerbox2row2}>
                  <Text style={styles.label}>NAME:</Text>
                  <Text style={styles.value}>
                    {data?.dispatch_to?.name ? data.dispatch_to.name : "NA"}
                  </Text>
                </View>

                {/* <View style={styles.headerbox2row2}>
                  <Text style={styles.label}>CONTACT NO.:</Text>
                  <Text style={styles.value}>
                    {data?.dispatch_to?.primaryContactNo
                      ? data.dispatch_to.primaryContactNo
                      : "NA"}
                  </Text>
                </View> */}

                <View style={styles.headerbox2row2}>
                  <Text style={styles.label}>EMAIL ID:</Text>
                  <Text style={styles.value}>
                    {data?.dispatch_to?.email ? data.dispatch_to.email : "NA"}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.headerrow3}>
              <Text
                style={[{ textAlign: "center", margin: 5, fontWeight: "bold" }]}
              >
                {`MEASUREMENT CHART:  ${data?.so_id ? data.so_id : ""}`}
              </Text>
            </View>
            {/*Date & Measured by information */}
            <View style={styles.headerrow4}>
              <View style={styles.headerbox41}>
                {[1, 2].map((item, ind) => {
                  return (
                    <View key={ind} style={styles.headerbox41row1}>
                      <View style={styles.headerbox41row1label}>
                        {ind == 0 ? (
                          <Text>DATE</Text>
                        ) : (
                          <Text>MEASUREMENTS</Text>
                        )}
                      </View>
                      <View
                        style={[
                          {
                            border: "1px solid black",
                            width: "1%",
                            height: "20px",
                          },
                        ]}
                      ></View>
                      <View style={styles.headerbox41row1value}>
                        {ind == 0 ? (
                          <Text>
                            {/* {data.order_date
                              ? formatDateString(data.order_date, "-")
                              : "NA"} */}
                               {data?.so_order_lines[0].measurement?.measured_date
                                                            ? formatDateString(data?.so_order_lines[0].measurement?.measured_date, "-")
                                                            : "NA"}
                          </Text>
                        ) : (
                          <Text>{""}</Text>
                        )}
                      </View>
                    </View>
                  );
                })}
              </View>
              <View style={styles.headerbox41}>
                {[1, 2].map((item, ind) => {
                  return (
                    <View style={styles.headerbox41row1}>
                      <View style={styles.measuredboxlabel}>
                        {ind == 0 ? (
                          <Text>MEASURED BY</Text>
                        ) : (
                          <Text>NOTED BY</Text>
                        )}
                      </View>
                      <View
                        style={[
                          {
                            border: "1px solid black",
                            width: "1%",
                            height: "20px",
                          },
                        ]}
                      ></View>
                      <View style={styles.measuredboxvalue}>
                        {ind == 0 ? (
                          <Text>
                            {data?.so_order_lines[0].measurement
                              ?.measured_by_name
                              ? data?.so_order_lines[0].measurement
                                  ?.measured_by_name
                              : " "}
                          </Text>
                        ) : (
                          <Text>
                            {data?.so_order_lines[0].measurement?.noted_by_name
                              ? data?.so_order_lines[0].measurement
                                  ?.noted_by_name
                              : " "}
                          </Text>
                        )}
                      </View>
                    </View>
                  );
                })}
              </View>
              {/* <View style={styles.headerbox42}>
                {[1, 2].map((item, ind) => {
                  return (
                    <View style={styles.headerbox41row1}>
                      <View style={styles.headerbox41row1label}>
                        {ind == 0 ? (
                          <Text>{"   "}</Text>
                        ) : (
                          <Text style={[{ height: "16px" }]}>BLOUSE</Text>
                        )}
                      </View>
                    </View>
                  );
                })}
              </View> */}
            </View>
            {/* Till Waist Measurement */}
            <View style={styles.headerrow4}>
              <View style={styles.headerbox41}>
                {measurements.map((item, ind) => {
                  return (
                    <View key={ind + item} style={styles.headerbox41row1}>
                      <View style={styles.headerbox41row1label}>
                        <Text>{item.name}</Text>
                      </View>
                      <View
                        style={[
                          {
                            border: "1px solid black",
                            width: "1%",
                            height: "20px",
                          },
                        ]}
                      ></View>
                      <View style={styles.headerbox41row1value}>
                        {/* {console.log("item.name",item.name, ind)} */}
                        {ind == 11 && <Text>{sleeves_short[0].value1}</Text>}
                        {ind == 12 && <Text>{sleeves_short[0].value2}</Text>}
                        {item.name == "SLEEVES" && <Text>{"SHORT"}</Text>}
                        {item.name !== "SLEEVES" && <Text>{item.value}</Text>}
                      </View>
                    </View>
                  );
                })}
              </View>
              <View style={styles.headerbox41}>
                <View style={styles.headerbox41sleeverow}>
                  {sleeves_measurement.map((item, ind) => {
                    return (
                      <>
                        <View style={styles.headerbox41sleevelabel}>
                          <Text>{item.name}</Text>
                        </View>
                        {ind < 2 ? (
                          <View
                            style={[
                              {
                                border: "1px solid black",
                                width: "1%",
                                height: "20px",
                              },
                            ]}
                          ></View>
                        ) : null}
                      </>
                    );
                  })}
                </View>
                <View style={styles.headerbox41sleeverow2}>
                  {sleeves_measurement.map((item, ind) => {
                    return (
                      <>
                        <View style={styles.headerbox41sleevelabel}>
                          <Text>{item.value1 ? item.value1 : ""}</Text>
                        </View>
                        {ind < 2 ? (
                          <View
                            style={[
                              {
                                border: "1px solid black",
                                width: "1%",
                                height: "20px",
                              },
                            ]}
                          ></View>
                        ) : null}
                      </>
                    );
                  })}
                </View>
                <View style={styles.headerbox41sleeverow3}>
                  {sleeves_measurement.map((item, ind) => {
                    return (
                      <>
                        <View style={styles.headerbox41sleevelabel}>
                          <Text>{item.value2 ? item.value2 : ""}</Text>
                        </View>
                        {ind < 2 ? (
                          <View
                            style={[
                              {
                                border: "1px solid black",
                                width: "1%",
                                height: "20px",
                              },
                            ]}
                          ></View>
                        ) : null}
                      </>
                    );
                  })}
                </View>

                <View style={styles.lineContainer}>
                  {/* Start Horizontal Line */}
                  <View style={[styles.horizontalLine, { top: 0 }]} />

                  {/* Vertical Line */}
                  <View style={styles.verticalLine} />

                  {/* Points and Labels */}
                  {points1.map((ele, index) => (
                    <View
                      key={index}
                      style={[
                        styles.pointContainer,
                        { top: `${ele.position * 100}%` },
                      ]}
                    >
                      {/* Label (to the left of the point) */}
                      <Text style={styles.labelLeft}>{ele.name}</Text>

                      {/* Point */}
                      <View style={styles.point} />

                      {/* Value (to the right of the point) */}
                      <Text style={styles.labelRight}>{ele.value}</Text>
                    </View>
                  ))}

                  {/* End Horizontal Line */}
                  <View style={[styles.horizontalLine, { bottom: 0 }]} />
                </View>
              </View>
              <View style={styles.headerbox42}>
                {/* {measurements.map((item, ind) => {
                  return (
                    <View style={styles.headerbox41row1}>
                      <View
                        style={[
                          styles.headerbox41row1label,
                          { height: "14px" },
                        ]}
                      >
                        <Text>{"   "}</Text>
                      </View>
                    </View>
                  );
                })} */}
              </View>
              <View style={styles.headerbox43}>
                {measurements_checkboxes.map((item, ind) => {
                  return (
                    <View style={styles.headerbox41row1}>
                      <View style={styles.headerbox41row1label}>
                        <Text>{item.name}</Text>
                      </View>
                      <View
                        style={[
                          {
                            border: "1px solid black",
                            width: "1%",
                            height: "27px",
                          },
                        ]}
                      ></View>
                      <View style={styles.headerbox41row1value}>
                        <Text>{item.value == "true" ? "Yes" : ""}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
            {/* Below Waist Measurement */}
            <View style={styles.headerrow4}>
              <View style={styles.headerbox41}>
                {below_waist_measurements.map((item, ind) => {
                  return (
                    <View style={styles.headerbox41row1}>
                      <View style={styles.headerbox41row1label}>
                        <Text>{item.name}</Text>
                      </View>
                      <View
                        style={[
                          {
                            border: "1px solid black",
                            width: "1%",
                            height: "20px",
                          },
                        ]}
                      ></View>
                      <View style={styles.headerbox41row1value}>
                        <Text>{item.value}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
              <View style={styles.headerbox41}>
                {/* arrow  with line   */}
                <Line x1={85} y1={-5} x2={90} y2={0} />
                <Line x1={40} y1={0} x2={90} y2={0} />{" "}
                <Text style={[{ marginLeft: 92, marginTop: -6 }]}>
                  {legs[0] ? legs[0] : " "}
                </Text>
                <Line x1={85} y1={5} x2={90} y2={0} />
                <Line x1={85} y1={15} x2={90} y2={20} />
                <Line x1={38} y1={20} x2={90} y2={20} />{" "}
                <Text style={[{ marginLeft: 92, marginTop: 10 }]}>
                  {legs[1] ? legs[1] : " "}
                </Text>
                <Line x1={85} y1={25} x2={90} y2={20} />
                <Line x1={85} y1={35} x2={90} y2={40} />
                <Line x1={38} y1={40} x2={90} y2={40} />{" "}
                <Text style={[{ marginLeft: 92, marginTop: 8 }]}>
                  {legs[2] ? legs[2] : " "}
                </Text>
                <Line x1={85} y1={45} x2={90} y2={40} />
                <Line x1={85} y1={55} x2={90} y2={60} />
                <Line x1={39} y1={60} x2={90} y2={60} />{" "}
                <Text style={[{ marginLeft: 92, marginTop: 8 }]}>
                  {legs[3] ? legs[3] : " "}
                </Text>
                <Line x1={85} y1={65} x2={90} y2={60} />
                <Line x1={85} y1={75} x2={90} y2={80} />
                <Line x1={38} y1={80} x2={90} y2={80} />{" "}
                <Text style={[{ marginLeft: 92, marginTop: 8 }]}>
                  {legs[4] ? legs[4] : " "}
                </Text>
                <Line x1={85} y1={85} x2={90} y2={80} />
                <Line x1={85} y1={125} x2={90} y2={130} />
                <Line x1={36} y1={130} x2={90} y2={130} />{" "}
                <Text style={[{ marginLeft: 92, marginTop: 36 }]}>
                  {legs[5] ? legs[5] : " "}
                </Text>
                <Line x1={85} y1={135} x2={90} y2={130} />
                <View style={[{ marginTop: -145 }]}>
                  {/* Load the local image */}
                  <Image src={legimage} style={styles.image2} />
                </View>
                {/* Line 3 */}
                {/* <View style={[styles.lineContainer3]}>
                  <View style={[styles.horizontalLine, { top: 0 }]} />

                  <View style={styles.verticalLine} />

                  {points3.map((ele, index) => (
                    <View
                      key={index}
                      style={[
                        styles.pointContainer,
                        { top: `${ele.position * 100}%` },
                      ]}
                    >
                      <View style={styles.point} />

                      <Text style={styles.label2}>{ele.value}</Text>
                    </View>
                  ))}

                  <View style={[styles.horizontalLine, { bottom: 0 }]} />
                </View> */}
                <View style={styles.lineContainer3}>
                  {/* Start Horizontal Line */}
                  <View style={[styles.horizontalLine, { top: 0 }]} />

                  {/* Vertical Line */}
                  <View style={styles.verticalLine} />

                  {/* Points and Labels */}
                  {points3.map((ele, index) => (
                    <View
                      key={index}
                      style={[
                        styles.pointContainer,
                        { top: `${ele.position * 100}%` },
                      ]}
                    >
                      {/* Label (to the left of the point) */}
                      <Text style={styles.labelLeft}>{ele.name}</Text>

                      {/* Point */}
                      <View style={styles.point} />

                      {/* Value (to the right of the point) */}
                      <Text style={styles.labelRight}>{ele.value}</Text>
                    </View>
                  ))}

                  {/* End Horizontal Line */}
                  <View style={[styles.horizontalLine, { bottom: 0 }]} />
                </View>
              </View>
              <View style={styles.headerbox42}>
                {/* {below_waist_measurements.map((item, ind) => {
                  return (
                    <View style={styles.headerbox41row1}>
                      <View
                        style={[
                          styles.headerbox41row1label,
                          { height: "14px" },
                        ]}
                      >
                        {ind == 5 && <Text>{length_data.name}</Text>}
                        {ind == 6 && <Text>{length_data.value}</Text>}
                        {ind !== 5 && ind !== 6 && <Text>{"   "}</Text>}
                      </View>
                    </View>
                  );
                })} */}
              </View>
              <View style={styles.headerbox43}>
                {below_waist_measurements.map((item, ind) => {
                  return (
                    <View style={styles.headerbox41row1}>
                      <View
                        style={[
                          styles.headerbox41row1label,
                          { height: "14px" },
                        ]}
                      >
                        {ind == 5 && <Text>{length_data.name}</Text>}
                        {ind == 6 && <Text>{length_data.value}</Text>}
                        {/* {ind !== 5 && ind !== 6 && <Text>{"   "}</Text>} */}
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
            <View style={styles.footer1}>
              <Text>NIVEDITA SABOO COUTURE</Text>
              <Text style={[{ fontSize: "10px" }]}>
                Bungalow 20,Samrat Society,Lane No.7, Kalyani Nagar, Pune:411014
                Phone No.: +91 92204 8000
              </Text>
            </View>
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
      )}
      {data?.salesOrderattachments &&
        data?.salesOrderattachments?.length > 0 &&
        localData?.industry_type == "Fashion" &&
        data?.salesOrderattachments
          .filter((item) => item.document_type == "image")
          .map((img, index) => (
            <Page key={index} size="A4" style={styles.page3}>
              <View
                style={[
                  {
                    border: "3px solid black",
                    marginBottom: 10,
                    marginTop: -20,
                    width: "100%",
                    textAlign: "center",
                  },
                ]}
              >
                <Text style={[{ margin: 5 }]}>
                  {data?.so_id ? data.so_id : "SO"}
                </Text>
              </View>

              {img.document_type == "image" && (
                <Image
                  src={`${s3_url}${img.attachment}`}
                  style={styles.image}
                />
              )}

              <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </Page>
          ))}
    </Document>
  );
  return (
    <React.Fragment>
      <ToastContainer />

      <div className="manufac_goods_form mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center justify-content-between">
            <div>
              <div className="p-2 d-flex align-items-center">
                <button
                  className="btn btn-yellow"
                  onClick={() => {
                    navigate("/pcms/sales-order");
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} size="sm" />
                </button>

                <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
                  Sales Order PDF
                </p>
              </div>
            </div>

            {/* <div className=" p-2">
              <button
                className="btn btn-yellow"
                style={styles.downloadButton}
                onClick={handleDownload}
                disabled={!isPdfLoaded}
              >
                <FontAwesomeIcon icon={faDownload} size="sm" /> Download PDF
              </button>
            </div> */}
          </div>
          <div className="card-body">
            <div className="createNc container-fluid">
              <PDFViewer style={styles.viewer}>
                <SaleOrderDocument data={data} />
              </PDFViewer>
              <a ref={downloadLinkRef} style={{ display: "none" }} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SalesOrderPDF;
