import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faPlus,
  faAdd,
  faEye,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import {
  getMachineList,
  getStatusMaster,
  getTaskCategory,
  updateTask,
  fetch_Supp_Customer,
  getSupplier,
  getOperatorList,
  get_master_files,
} from "../../../api";
import { getUsers } from "../../../api/userManagement";
import SweetAlert from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";
import { convertDateYYMMDD } from "../../../common/Functions/CommonFunctions";
import UpdateTaskGallary from "./UpdateTaskGallary";

const schema = yup
  .object()
  .shape({
    // ncPrefix: yup.string().required("Prefix is required !").matches(/^[A-Z0-9]*$/, "Only alphabets and space Allowed !"),,
    // machine: yup
    //   .array()
    //   .min(1, "Please select machine !")
    //   .required("Please select machine !"),
    category: yup
      .array()
      .min(1, "Please select task category !")
      .required("Please select task category !"),
    machine: yup.array().when(["category"], (category, schema) => {
      // Check if 'machine_' exists in any of the selected categories

      if (category !== undefined && category.length > 0) {
        const machineCategorySelected = category[0].name.includes("machine_");

        // If 'machine_' is in the category, make 'supplier' mandatory
        return machineCategorySelected
          ? schema
              .min(1, "Please select machine !")
              .required("Please select machine !")
          : schema;
      } else {
        return schema;
      }
    }),
    assignTo: yup
      .array()
      .min(1, "Please select assignee !")
      .required("Please select assignee !"),
    // supplier: yup
    //   .array()
    //   .when(['category'], (category, schema) => {
    //     // Check if 'machine_' exists in any of the selected categories

    //     if (category !== undefined && category.length > 0) {

    //       const machineCategorySelected = category[0].name.includes('machine_');

    //       // If 'machine_' is in the category, make 'supplier' mandatory
    //       return machineCategorySelected
    //         ? schema.min(1, "Please select supplier !").required("Please select supplier !")
    //         : schema;
    //     } else {
    //       return schema
    //     }
    //   }),
    supplier: yup
      .array()
      .min(1, "Please select supplier !")
      .required("Please select supplier !"),
    status: yup
      .array()
      .min(1, "Please select status !")
      .required("Please select status !"),

    task_name: yup.string().required("Task name is required !"),
    start_date: yup
      .date()
      .typeError("Please select start date !")
      .required("Please select start date !"),
    // end_date: yup
    //   .date()
    //   .when("start_date", (start_date, schema) => {
    //     // Check if lastServiceDate is undefined or not a valid date
    //     if (!start_date || isNaN(new Date(start_date))) {
    //       return schema.notRequired();
    //     }
    //     return schema.min(
    //       start_date,
    //       "End date date should be greater than start date"
    //     );
    //   })
    //   .typeError("Please select end date !"),
    end_date: yup
      .date()
      .typeError("Please select end date")
      .required("Please select end date")
      .when("start_date", (start_date, schema) => {
        if (!start_date || isNaN(new Date(start_date))) {
          return schema.test({
            test: (value) => {
              // Check if the end_date is not selectable when start_date is not selected or not valid
              return !value;
            },
            message: "Please select the start date first",
          });
        }

        // return schema.min(
        //   start_date,
        //   "End date should be greater than or equal to the start date"
        // );
        return schema.test({
          test: (end_date) => {
            // Custom validation to allow end_date to be equal to start_date
            return end_date >= start_date;
          },
          message: "End date should be greater than or equal to the start date",
        });
      }),
    service_cost: yup
      .string()
      .required("Service cost is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid cost"),
  })
  .required();

function EditTaskMaster(props) {
  let navigate = useNavigate();
  let location = useLocation();
  const [task_cat, setTask_Cat] = useState({ data: [], loading: false });
  const [task_status, setTask_Status] = useState({ data: [], loading: false });
  const [machineList, setMachineList] = useState({ data: [], loading: false });
  const [suppList, setSuppList] = useState({ data: [], loading: false });
  const [galVisible, setGalVisible] = useState(false);
  const [drawingFiles, setDrawingFiles] = useState([]);
  const [empList, setEmpList] = useState({ data: [], loading: false });
  const [taskImgs, setTaskImgs] = useState({ loading: false, data: [] });
  const [star, setStar] = useState();

  const { handleSubmit, control, register, watch, formState, reset, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    });

  const { locVal, constLabel } = location.state;

  let { errors } = formState;

  const taskCat = watch("category");
  useEffect(() => {
    if (taskCat !== undefined && taskCat.length > 0) {
      const res = taskCat[0].name.includes("machine_");

      setStar(res);
      if (!res) {
        setValue("machine", []);
        setValue("supplier", []);
      }
    }
  }, [taskCat]);

  useEffect(() => {
    fetchStatus();
    fetchTaskCategory();
    fetchSupplier();
    fetchEmployees();
  }, []);

  useEffect(() => {
    if (locVal !== undefined) {
      fetchImage();
      fetchMachine();
    }
  }, [locVal]);

  function fetchImage() {
    setTaskImgs({ ...taskImgs, loading: true, data: [] });
    get_master_files({
      ref_model_id: "",
      ref_model_uuid: locVal?.uuid,
      model_name: "Task",
      document_type: "image",
    }).then(
      (res) => {
        setTaskImgs({ ...taskImgs, loading: false, data: res.data.data });
      },
      (err) => {
        setTaskImgs({ ...taskImgs, loading: false, data: [] });
      }
    );
  }

  function fetchEmployees() {
    setEmpList({ ...empList, data: [], loading: true });

    getOperatorList({ linked: 1 }).then(
      (res) => {
        const result = res.data.data.map((val) => {
          val.full_name = val.FirstName + " " + val.LastName;
          return val;
        });

        setEmpList({ ...empList, data: result, loading: false });

        const ress = res.data.data.filter(
          (val) => val.uuid == locVal.assigned_to
        );

        setValue("assignTo", ress);
      },
      (err) => {
        setEmpList({ ...empList, data: [], loading: false });
      }
    );
  }

  function fetchSupplier() {
    setSuppList({ ...suppList, data: [], loading: true });

    // getSupplier().then((res) => {
    fetch_Supp_Customer({ partyType: "Supplier" }).then(
      (res) => {
        setSuppList({ ...suppList, data: res.data.data, loading: false });

        const result = res.data.data.filter(
          (val) => val.uuid == locVal.serviced_by
        );

        setValue("supplier", result);
      },
      (err) => {
        setSuppList({ ...suppList, data: [], loading: false });
      }
    );
  }

  function fetchTaskCategory() {
    setTask_Cat({ ...task_cat, data: [], loading: true });
    getTaskCategory().then(
      (res) => {
        setTask_Cat({ ...task_cat, data: res.data.data, loading: false });
        // const result = res.data.data.filter((item) => item.status_name === "Open");

        // setValue('status', result)
      },
      (err) => {
        setTask_Cat({ ...task_cat, data: [], loading: false });
      }
    );
  }

  function fetchStatus() {
    setTask_Status({ ...task_status, data: [], loading: true });
    getStatusMaster().then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.model_name === "task"
        );
        setTask_Status({ ...task_status, data: result, loading: false });

        // setValue('status', result)
      },
      (err) => {
        setTask_Status({ ...task_status, data: [], loading: false });
      }
    );
  }

  function fetchMachine() {
    setMachineList({ ...machineList, loading: true });

    getMachineList().then(
      (res) => {
        const result = res.data.data.filter(
          (item) =>
            item.machineName !== "" &&
            item.machineName !== null &&
            item.machineName !== undefined
        );

        const ress = result.map((item) => ({
          ...item,
          machineNameSerialNo: item.machineName + " - " + item.serialNo,
        }));

        let pre_machine = ress.filter((item) => item.uuid == locVal?.machine_id.uuid);

          if (pre_machine) {
            setValue('machine', pre_machine);
          } else {
            setValue("machine", []);
          }

        setMachineList({ ...machineList, data: ress, loading: false });
      },
      (err) => {
        setMachineList({ ...machineList, data: [], loading: false });
      }
    );
  }

  const onSubmit = (data) => {
    console.log("data", data);
    if (data !== "") {
      const reqPayload = {
        uuid: locVal?.uuid,
        task_name: data.task_name,
        machine_id:
          data.machine !== undefined && data.machine.length > 0
            ? data.machine[0]?.uuid
            : null,
        task_category: data.category[0]?.name,

        start_date: convertDateYYMMDD(data.start_date, "-"),
        end_date: convertDateYYMMDD(data.end_date, "-"),
        service_cost: data.service_cost,
        status: data.status[0]?.status_name,
        comments: data.comments,
        assigned_to: data.assignTo[0].uuid,
        assigned_name: data.assignTo[0].full_name,
        serviced_by:
          data.supplier !== undefined && data.supplier.length > 0
            ? data.supplier[0]?.uuid
            : null,
        serviced_by_name:
          data.supplier !== undefined && data.supplier.length > 0
            ? data.supplier[0]?.name
            : null,
      };

      // if (data.supplier.length == 0) {
      //   delete reqPayload.serviced_by;
      //   delete reqPayload.serviced_by_name;
      // }

      updateTask(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Task updated Successfully !", {
              autoClose: 2000,
            });
            setTimeout(() => {
              navigate("/pcms/taskMaster");
              reset();
            }, 2000);
          }
        },
        (err) => {
          if (err.response.status !== 401) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <UpdateTaskGallary
        setGalVisible={setGalVisible}
        galVisible={galVisible}
        taskImgs={taskImgs}
        fetchImage={fetchImage}
        locData={locVal}
        constLabel={constLabel}
      />

      <div className="manufac_goods_form mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => navigate("/pcms/taskMaster")}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_update_task
                ? constLabel.lbl_update_task
                : "Update Task"}
            </p>
          </div>

          <div className="card-body">
            <div className="createNc container-fluid">
              <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_task_detail
                  ? constLabel.lbl_task_detail
                  : "Task Detail"}
              </p>
              <hr className="my-2" />

              <div className="row my-4">
                <div className="col-12 col-md-4">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {" "}
                    {constLabel?.lbl_task_name
                      ? constLabel.lbl_task_name
                      : "Task Name"}
                    <span className='text-danger'> *</span>
                  </label>

                    <input
                      className={
                        errors.task_name
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="task_name"
                      name="task_name"
                      placeholder="Enter task name"
                      defaultValue={locVal?.task_name}
                      {...register("task_name")}
                    />
                    {!task_cat.loading && !errors.category && <span className='arrow-icon'></span>}
                    {errors.task_name && (
                      <span className="text-danger err-msg">
                        {errors.task_name.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_category_name
                      ? constLabel.lbl_category_name
                      : "Category Name"}
                    <span className='text-danger'> *</span>
                  </label>

                    <Controller
                      name={`category`}
                      control={control}
                      defaultValue={[{ name: locVal?.task_category }]}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="name"
                          className="mt-2"
                          multiple={false}
                          options={task_cat.data}
                          isLoading={task_cat.loading}
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          placeholder="Select task category"
                          isInvalid={errors.category ? true : false}
                        />
                      )}
                    />
                    {!task_cat.loading && !errors.category && <span className='arrow-icon'></span>}
                    {errors.category && (
                      <span className="text-danger err-msg">
                        {errors.category.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_machine_name
                      ? constLabel.lbl_machine_name
                      : "Machine Name"}
                    {star ? (
                      <span className='text-danger'> *</span>
                    ) : null}
                  </label>

                    <Controller
                      name={`machine`}
                      control={control}
                      // defaultValue={
                      //   locVal?.machine_id !== null
                      //     ? [
                      //         {
                      //           uuid: locVal?.machine_id.uuid,
                      //           machineName: locVal.machine_id.machineName,
                      //         },
                      //       ]
                      //     : []
                      // }
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          // defaultSelected={
                          //   locVal?.machine_id !== null
                          //     ? [
                          //         {
                          //           uuid: locVal?.machine_id.uuid,
                          //           machineName: locVal.machine_id.machineName,
                          //         },
                          //       ]
                          //     : []
                          // }
                          labelKey="machineNameSerialNo"
                          className="mt-2"
                          multiple={false}
                          options={machineList.data}
                          isLoading={machineList.loading}
                          placeholder="Select machine"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.machine ? true : false}
                        />
                      )}
                    />
                    {!machineList.loading && !errors.machine && <span className='arrow-icon'></span>}
                    {errors.machine && (
                      <span className="text-danger err-msg">
                        {errors.machine.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_serviceBy_name
                      ? constLabel.lbl_serviceBy_name
                      : "Service By"}
                    (Supplier)
                    {star == true ? (
                      <span className='text-danger'> *</span>
                    ) : null}
                  </label>

                    <Controller
                      name={`supplier`}
                      control={control}
                      defaultValue={
                        locVal?.serviced_by !== null
                          ? [
                              {
                                uuid: locVal?.serviced_by,
                                name: locVal?.serviced_by_name,
                              },
                            ]
                          : []
                      }
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          defaultSelected={
                            locVal?.serviced_by !== null
                              ? [
                                  {
                                    uuid: locVal?.serviced_by,
                                    name: locVal?.serviced_by_name,
                                  },
                                ]
                              : []
                          }
                          className="mt-2"
                          labelKey="name"
                          multiple={false}
                          options={suppList.data}
                          isLoading={suppList.loading}
                          placeholder="Select supplier"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.supplier ? true : false}
                        />
                      )}
                    />
                    {!suppList.loading && !errors.supplier && <span className='arrow-icon'></span>}
                    {errors.supplier && (
                      <span className="text-danger err-msg">
                        {errors.supplier.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_assignTo_name
                      ? constLabel.lbl_assignTo_name
                      : "Assign To Name"}
                    <span className='text-danger'> *</span>
                  </label>
                    <Controller
                      name={`assignTo`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="full_name"
                          className="mt-2"
                          multiple={false}
                          options={empList.data}
                          isLoading={empList.loading}
                          placeholder="Select Assignee"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.assignTo ? true : false}
                        />
                      )}
                    />
                    {!empList.loading && !errors.assignTo && <span className='arrow-icon'></span>}
                    {errors.assignTo && (
                      <span className="text-danger err-msg">
                        {errors.assignTo.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_start_date
                      ? constLabel.lbl_start_date
                      : "Start Date"}
                    <span className='text-danger'> *</span>
                  </label>

                    <Controller
                      control={control}
                      name="start_date"
                      defaultValue={new Date(locVal?.start_date)}
                      render={({ field }) => (
                        <DatePicker
                          className={
                            errors.quote_date
                              ? "form-control mt-2 is-invalid"
                              : "form-control mt-2"
                          }
                          placeholderText="Select start date"
                          defaultSelected={new Date(locVal?.start_date)}
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                          // defaultValue={new Date()}

                          minDate={new Date(locVal?.start_date)}
                          // maxDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                        />
                      )}
                    />

                    {errors.start_date && (
                      <span className="text-danger err-msg">
                        {errors.start_date.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_end_date
                      ? constLabel.lbl_end_date
                      : "End Date"}
                    <span className='text-danger'> *</span>
                  </label>

                    <Controller
                      control={control}
                      name="end_date"
                      defaultValue={new Date(locVal?.end_date)}
                      render={({ field }) => (
                        <DatePicker
                          className={
                            errors.quote_date
                              ? "form-control mt-2 is-invalid"
                              : "form-control mt-2"
                          }
                          placeholderText="Select end date"
                          defaultSelected={new Date(locVal?.end_date)}
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                          // defaultValue={new Date()}
                          // maxDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                        />
                      )}
                    />

                    {errors.end_date && (
                      <span className="text-danger err-msg">
                        {errors.end_date.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_service_cost
                      ? constLabel.lbl_service_cost
                      : "Service Cost"}
                    <span className='text-danger'> *</span>
                  </label>

                    <input
                      className={
                        errors.service_cost
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="service_cost"
                      name="service_cost"
                      defaultValue={locVal?.service_cost}
                      placeholder="Enter service cost"
                      {...register("service_cost")}
                    />
                    {errors.service_cost && (
                      <span className="text-danger err-msg">
                        {errors.service_cost.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_status ? constLabel.lbl_status : "Status"}
                    <span className='text-danger'> *</span>
                  </label>

                    <Controller
                      name={`status`}
                      control={control}
                      defaultValue={[{ status_name: locVal?.status }]}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="status_name"
                          className='mt-2'
                          defaultSelected={[{ status_name: locVal?.status }]}
                          multiple={false}
                          options={task_status.data}
                          isLoading={task_status.loading}
                          onChange={onChange}
                          onBlur={onBlur}
                          // selected={value}
                          placeholder="Select status"
                          isInvalid={errors.status ? true : false}
                        />
                      )}
                    />
                    {!task_status.loading && !errors.status && <span className='arrow-icon'></span>}
                    {errors.status && (
                      <span className="text-danger err-msg">
                        {errors.status.message}
                      </span>
                    )}
                  </div>
                </div>

                {/* <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    Status
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className={
                        errors.status
                          ? "form-control  is-invalid"
                          : "form-control "
                      }
                      id="status"
                      name="status"
                      defaultValue={locVal?.status}
                      {...register("status")}
                    />
                    {errors.status && (
                      <span className="text-danger err-msg">
                        {errors.status.message}
                      </span>
                    )}
                  </div>
                </div> */}

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_comment
                      ? constLabel.lbl_comment
                      : "Comment"}
                  </label>

                    <textarea
                      className="form-control mt-2"
                      id="comments"
                      name="comments"
                      placeholder="Enter comments"
                      defaultValue={locVal?.comments}
                      {...register("comments")}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end py-3">
            <button
              onClick={() => setGalVisible(true)}
              className="btn btn-yellow px-3"
            >
              {constLabel?.lbl_image_gallary
                ? constLabel.lbl_image_gallary
                : "Image Gallary"}
              <FontAwesomeIcon
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginLeft: "5px",
                }}
                icon={faImage}
              />
            </button>

            <button
              className="btn btn-grey px-4 ms-0 ms-md-2"
              onClick={handleSubmit(onSubmit)}
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditTaskMaster;
