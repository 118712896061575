import React, { useState, useEffect, useReducer } from "react";
import { useForm, } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { Edit2, } from "react-feather";
import { getProdList, getProductType, getBOM } from "../../../api";
import {
  getLabels, setAccessPrivilage
} from "../../../common/Functions/CommonFunctions";
import CreateInventory from "./CreateInventory";
import EditInventory from "./EditInventory";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import AccessDenied from "../../Common/AccessDenied";

function Inventory() {
  const [selStatus, setSelStatus] = useState({
    view: true,
    add: false,
    edit: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [prodList, setProdList] = useState({ data: [], loading: false });

  const [prodTypeList, setProdTypeList] = useState({
    data: [],
    loading: false,
  });
  const [searchVal, setSearchVal] = useState("");

  var productTrack = [];

  const [selProdToDisplay, setselProdToDisplay] = useState({});
  const [bomList, setBomList] = useState({ data: [], loading: false });
  const [selectedType, setSelectedType] = useState({});

  const [constLabel, setConstLabel] = useState({});

  const [loader, setLoader] = useState(true);
  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
   no_access: false
  });

  var selProdTypeEle;
  var selProd;

  var margin = 0;

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    setPrivilage(setAccessPrivilage(loginData, "Inventory"));

    setConstLabel(getLabels("Inventory", "Inventory"));
    openNav();
    fetchProdType();
    // fetchProdHirar();
  }, []);

  useEffect(() => {



    if (Object.keys(selectedType).length > 0) {
      fetchProd(selectedType, searchVal);
    }
  }, [searchVal]);

  function fetchScecificProd(prod_uuid) {
    setIsLoading(true);
    getProdList({ product_id: prod_uuid }).then(
      (res) => {


        if (res.data?.data?.length > 0) {

          setselProdToDisplay(res.data.data[0]);
          setIsLoading(false);

          const eleToRemove = document.getElementById('loader_extra_div');


          // if our loader exist only then remove else not required
          if (eleToRemove !== null) {
            eleToRemove.remove();
          }
        } else {
          setselProdToDisplay({});
          setIsLoading(false);

          const eleToRemove = document.getElementById('loader_extra_div');


          // if our loader exist only then remove else not required
          if (eleToRemove !== null) {
            eleToRemove.remove();
          }
        }
      },
      (err) => {
        setIsLoading(false);

      }
    );
  }


  function fetchProdType() {
    setProdTypeList({ ...prodTypeList, data: [], loading: true });
    setLoader(true);

    getProductType().then(
      (res) => {

        setProdTypeList({
          ...prodTypeList,
          data: res.data.data,
          loading: false,
        });

        const main_parent_content = document.getElementById("content");


        setLoader(false);



        res.data.data.filter((type, typei) => {
          if (type.productType !== null) {
            const checkBoxParent = document.createElement("div");
            checkBoxParent.id = "checkBoxParent" + typei;
            checkBoxParent.style =
              "border-top:1px solid #AAB1BA; padding:5px 10px 5px 10px";



            const checkBox = document.createElement("input");
            checkBox.classList.add("form-check-input");
            checkBox.classList.add("first_level");
            checkBox.type = "checkbox";
            checkBox.id = "checkbox" + typei;
            // checkBox.checked = typei === 0;

            if (typei == 0) {


              fetchProd(type);
              localStorage.setItem("typeIndex", typei);
              setSelectedType(type);
              checkBox.checked = true;



            }

            checkBox.addEventListener("change", (e) => {

              const checkboxContainer = document.getElementById("content");

              const checkboxes_array =
                checkboxContainer.querySelectorAll(".first_level");

              let a = [];
              let b = [];
              let c = [];
              for (let i = 0; i < checkboxes_array.length; i++) {
                let che = checkboxes_array[i];
                let c2 = che.id;
                if (c2 !== e.srcElement.id) {
                  a.push(che);
                }
              }

              for (let i = 0; i < a.length; i++) {
                let box = a[i];
                if (box.checked) {
                  b.push(box);
                } else {
                  c.push(box);
                }
              }

              if (e.target.checked) {
                fetchProd(type);

                localStorage.setItem("typeIndex", typei);

                setSelectedType(type);
                // here we are adding the bold to selected label
                e.target.nextSibling.classList.add("roboto-bold");

                for (let i = 0; i < c.length; i++) {
                  let c1 = c[i];

                  c1.checked = false;
                }

                if (b.length > 0) {
                  b[0].checked = false;
                  b[0].nextSibling.classList.remove("roboto-bold");
                  let del_div = b[0].parentElement;
                  const div_to_del = del_div.querySelector("#prod_outer_div");
                  div_to_del.remove();
                  margin = 0;
                }


                const extra_div = document.createElement('div');
                extra_div.id = "loader_extra_div";
                extra_div.className = "d-flex justify-content-center p-1"
                const span = document.createElement("span");
                span.className = "spinner-border spinner-border-sm";
                extra_div.appendChild(span);
                checkBoxParent.append(extra_div);

                // selProd = checkBoxParent;
              } else {

                setSelectedType({});
                setselProdToDisplay({});
                setBomList({ data: [], loading: false });
                // here we are removing the bold from unselected label
                e.target.nextSibling.classList.remove("roboto-bold");

                const divToDelete =
                  checkBoxParent.querySelector("#prod_outer_div");

                divToDelete.remove();
                margin = 0;
              }
            });

            const label = document.createElement("label");
            label.innerText = type.productType;
            label.style = "color:#2A3643";
            label.classList.add("ms-2");


            checkBoxParent.append(checkBox, label);

            main_parent_content.append(checkBoxParent);
            if (typei == 0) {
              label.classList.add("roboto-bold");

              const extra_div = document.createElement('div');
              extra_div.id = "loader_extra_div";
              extra_div.className = "d-flex justify-content-center p-1"
              const span = document.createElement("span");
              span.className = "spinner-border spinner-border-sm";
              extra_div.appendChild(span);
              checkBoxParent.append(extra_div);
            }

          }
        });






      },
      (err) => {

        setProdTypeList({ ...prodTypeList, data: [], loading: false });
      }
    );
  }

  function generateGenericHierarychy(prefix, productList, searchByProductName) {
    // if user is searching by product name, first we have to remove existing elements from dom then insert new with new values.
    if (searchByProductName) {
      // const ele = document.getElementById("checkBoxParent" + localStorage.getItem("typeIndex"))
      const eleTORemove = document.getElementById(prefix + "_outer_div");
      eleTORemove.remove();
    }


    const checkBoxOuterParent = document.createElement("div");
    checkBoxOuterParent.id = prefix + "_outer_div";
    checkBoxOuterParent.style = "margin-top:5px";
    margin = margin + 15;


    const extra_div = document.createElement('div');
    extra_div.id = prefix + "_outer_extra_div";
    extra_div.className = "d-flex justify-content-center p-1"
    const span = document.createElement("span");
    span.className = "spinner-border spinner-border-sm";
    extra_div.appendChild(span);


    const ele = document.getElementById(
      "checkBoxParent" + localStorage.getItem("typeIndex")
    );
    // ele.appendChild(extra_div);



    if (prefix == "prod") {
      // const ele = document.getElementById(
      //   "checkBoxParent" + localStorage.getItem("typeIndex")
      // );

      ele.append(checkBoxOuterParent);


      const eleToRemove = document.getElementById('loader_extra_div');


      // if our loader exist only then remove else not required
      if (eleToRemove !== null) {
        eleToRemove.remove();
      }

      // if(loader){
      //   ele.appendChild(extra_div);
      //   // checkBoxOuterParent.remove();
      // } else {
      //   ele.removeChild(extra_div);
      // ele.append(checkBoxOuterParent);
      // }


    } else {

      const eleToRemove = document.getElementById('loader_extra_div');


      // if our loader exist only then remove else not required
      if (eleToRemove !== null) {
        eleToRemove.remove();
      }
      selProdTypeEle.append(checkBoxOuterParent);

    }


    productList.filter((prod, prodi) => {
      if (prod.uuid !== null) {
        const checkBoxParent = document.createElement("div");
        checkBoxParent.id = prefix + "_parent" + prodi;
        checkBoxParent.style =
          "border-top:1px solid #AAB1BA; padding:5px 10px 5px 10px";

        const checkBox = document.createElement("input");
        checkBox.type = "checkbox";
        checkBox.classList.add("form-check-input");
        checkBox.classList.add("second_level");
        checkBox.style = `margin-left:${margin}px`;

        checkBox.id = prefix + "_checkbox" + prodi;
        // checkBox.checked = prodi === 0;

        // if (prodi == 0) {



        //   selProdTypeEle = checkBoxParent;
        //   fetchProdBom(prefix == "prod" ? prod.uuid : prod.product_id);
        //   productTrack.push(prod.uuid);
        //   fetchScecificProd(prefix == "prod" ? prod.uuid : prod.product_id);

        //   checkBox.checked = true;
        // } 
        // else {

        checkBox.addEventListener("change", (e) => {


          // checkBoxOuterParent.id

          let checkboxes_array = checkBoxOuterParent.querySelectorAll(".second_level");

          let a = [];
          let b = [];
          let c = [];
          for (let i = 0; i < checkboxes_array.length; i++) {
            let che = checkboxes_array[i];
            let c2 = che.id;
            if (c2 !== e.srcElement.id) {
              a.push(che);
            }
          }

          for (let i = 0; i < a.length; i++) {
            let box = a[i];
            if (box.checked) {
              b.push(box);
            } else {
              c.push(box);
            }
          }



          if (e.target.checked) {


            // here we are adding the bold to selected label
            e.target.nextSibling.classList.add("roboto-bold");

            selProdTypeEle = checkBoxParent;
            fetchProdBom(prefix == "prod" ? prod.uuid : prod.product_id);



            productTrack.push(prod.uuid);

            fetchScecificProd(prefix == "prod" ? prod.uuid : prod.product_id);


            for (let i = 0; i < c.length; i++) {
              let c1 = c[i];

              c1.checked = false;
            }

            if (b.length > 0) {
              b[0].checked = false;
              b[0].nextSibling.classList.remove("roboto-bold");
              let del_div = b[0].parentElement;
              const div_to_del = del_div.querySelector("#child_outer_div");
              if (div_to_del !== null) {
                div_to_del.remove();
                margin = margin - 15;


              }
            }


            const extra_div = document.createElement('div');
            extra_div.id = "loader_extra_div";
            extra_div.className = "d-flex justify-content-center p-1"
            const span = document.createElement("span");
            span.className = "spinner-border spinner-border-sm";
            extra_div.appendChild(span);
            checkBoxParent.append(extra_div);

          } else {



            const index = productTrack.findIndex(
              (item) => item === prod.uuid
            );


            // deleting the unselected product from tracking list
            // let newTemp = productTrack.splice(index, productTrack.length - index);
            let newTemp = productTrack.splice(2, 2);


            productTrack = [...newTemp];

            const divToDelete =
              checkBoxParent.querySelector("#child_outer_div");

            // here we are removing the bold from unselected label
            e.target.nextSibling.classList.remove("roboto-bold");
            setselProdToDisplay({});
            setBomList({ data: [], loading: false });

            if (divToDelete !== null) {
              divToDelete.remove();
              margin = margin - 15;


              // margin = 0;
              // for (let i = 0; i < productTrack.length; i++) {
              //   margin = margin + 15;
              // }
            }
          }
        });
        //}

        const label = document.createElement("label");
        label.innerText =
          prefix == "prod" ? prod.productName : prod.productname;
        label.style = "color:#4E7DAE";
        label.classList.add("ms-2");

        if (prodi == 0) {
          label.classList.add("roboto-bold");
        }

        checkBoxParent.append(checkBox, label);

        checkBoxOuterParent.append(checkBoxParent);
      }
    });


    // ele.removeChild(extra_div);

  }

  function fetchProd(prodType, productName) {
    setProdList({ ...prodList, data: [], loading: true });


    const params = {};

    if (prodType !== undefined && prodType !== null) {
      params.product_type_id_list = '["' + prodType.uuid + '"]';

      if (
        productName !== undefined &&
        productName !== null &&
        productName.length > 0
      ) {
        params.productName_list = '["' + productName + '"]';
      }

      // this is api to fetch products as per selected product type
      // in payload we sending uuid of product type
      getProdList(params).then(
        (res) => {

          setProdList({ ...prodList, data: res.data.data, loading: false });

          if (productName !== undefined && productName !== null) {

            generateGenericHierarychy("prod", res.data.data, true);
          } else {

            generateGenericHierarychy("prod", res.data.data, false);
          }
        },
        (err) => {

          setProdList({ ...prodList, data: [], loading: false });
        }
      );
    }
  }

  function fetchProdBom(prodUuid) {


    setBomList({ ...bomList, loading: true, data: [] });

    // fetching bom line items for selected product

    getBOM({ product_id: prodUuid }).then(
      (res) => {


        // if product bom line items are present only then we need to append next products to hierarchy
        setBomList({ ...bomList, loading: false, data: res.data.data });
        if (res.data.data.length > 0) {
          // one product may have multiple boms soo we are merging all bom items from all boms into one
          let mergedArray = res.data.data.reduce(
            (accumulator, currentObject) => {
              return accumulator.concat(currentObject.bom_line_item);
            },
            []
          );



          mergedArray = mergedArray.filter((val) => {
            if (val.productname == null) {
              val.productname = "";
            }

            // In some product bom line items product is not present on product fam is present in such case we need to remove it from list.
            return val.product_id !== null;
          });

          // here we have create dynamic UI for bom lineitems and append it to our UI.

          generateGenericHierarychy("child", mergedArray);
        }
      },
      (err) => {

        setBomList({ ...bomList, loading: false, data: [] });
      }
    );
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  return (
    <React.Fragment>
      <ToastContainer />

      {!privilage.read ?
        <AccessDenied />
        :
        <div className="min-vh-100" id="root_div_main">
          <div id="mySidebar" className="inventory_customsidebar">

            {privilage.read && <>
              <div className="d-flex justify-content-between m-2">
                <p className="roboto-bold pt-2" style={{ fontSize: "24px" }}>
                  {constLabel?.lbl_inventory
                    ? constLabel.lbl_inventory
                    : "Inventory"}
                </p>
                <div>
                  {/* <button
                className="btn button-primary btn-sm me-4"                
                onClick={() =>
                  setSelStatus({ view: false, add: true, edit: false })
                }
              >
                Add Inventory
              </button> */}
                  <a className="custom-close-btn" onClick={() => closeNav()}>
                    ×
                  </a>
                </div>
              </div>

              {/* <div className="col-12 pb-2 px-2">
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Search by name"
              onChange={(e) => {
                setSearchVal(e.target.value);
              }}
              value={searchVal}
            />
          </div> */}

              <div id="content" className="mt-4" style={{ marginTop: "0px" }}>
                {/* <Spinner /> */}
                {loader ?
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  : null}
              </div>
            </>}
          </div>

          <div className="machine_view">
            <div className="row">
              <div className="d-flex justify-content-between m-0">
                <button
                  style={{ maxHeight: "30px" }}
                  onClick={() => openNav()}
                  className="btn btn-sm btn-grey px-2 my-auto"
                >
                  <FontAwesomeIcon
                    style={{
                      color: "white",
                    }}
                    icon={faList}
                    size="sm"
                  />
                </button>
                <div>
                  <button className="btn btn-sm button-primary my-2 me-2">
                    {constLabel?.lbl_add_inventory_item
                      ? constLabel.lbl_add_inventory_item
                      : "Add Inventory Item"}
                  </button>
                  {/* <button
                  className="btn btn-sm btn-lightblue my-2"
                >
                  Export to Invoice
                </button> */}
                </div>
              </div>

              {selStatus.add == true ? (
                <CreateInventory setSelStatus={setSelStatus} />
              ) : null}

              {selStatus.edit == true ? (
                <EditInventory setSelStatus={setSelStatus} />
              ) : null}

              {Object.keys(selProdToDisplay).length > 0 ? (
                <div className="col-12 mt-3">
                  <div className="card border-0">
                    <div className="card-body p-4">
                      <div className="d-flex justify-content-between pb-3 position-relative">
                        <p className="roboto-bold" style={{ fontSize: "24px" }}>
                          {selProdToDisplay.productName}
                        </p>

                        <div className="col-12 col-md-8"></div>

                        {privilage.write && <>

                          <Tooltip id={"edit-tooltip"} place="top" />
                          <div
                            data-tooltip-id={"edit-tooltip"}
                            data-tooltip-content={
                              constLabel?.lbl_edit_inventory
                                ? constLabel.lbl_edit_inventory
                                : "Edit Inventory"
                            }
                            className="editIcon-sm me-2 "
                            style={{ position: "absolute", right: "0" }}
                          >
                            <Edit2
                              color="#FFFFFF"
                              size={16}
                              className="cursor-pointer"
                              onClick={() => {
                                // navigate('/pcms/editMachine');
                                setSelStatus({
                                  view: false,
                                  add: false,
                                  edit: true,
                                });
                              }}
                            />
                          </div>
                        </>}
                      </div>


                      {privilage.read && Object.keys(selProdToDisplay).length > 0 ? (
                        <div className="row">
                          <div className="col-12 col-md-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_product_category
                                ? constLabel.lbl_product_category
                                : "Product Category"}
                            </label>
                            <div style={{ color: "#667080", fontSize: "18px" }}>
                              {selProdToDisplay.category !== null
                                ? selProdToDisplay.category
                                : "NA"}
                            </div>
                          </div>
                          <div className="col-12 col-md-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_market_group
                                ? constLabel.lbl_market_group
                                : "Market group"}
                            </label>
                            <div style={{ color: "#667080", fontSize: "18px" }}>
                              {selProdToDisplay.market_grp_id?.groupname !== null
                                ? selProdToDisplay.market_grp_id?.groupname
                                : "NA"}
                            </div>
                          </div>
                          <div className="col-12 col-md-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_product_family
                                ? constLabel.lbl_product_family
                                : "Product Family"}
                            </label>
                            <div style={{ color: "#667080", fontSize: "18px" }}>
                              {selProdToDisplay.prod_family_id?.familyname !== null
                                ? selProdToDisplay.prod_family_id?.familyname
                                : "NA"}
                            </div>
                          </div>

                          <div className="col-12 col-md-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_product_name
                                ? constLabel.lbl_product_name
                                : "Product Name"}
                            </label>
                            <div style={{ color: "#667080", fontSize: "18px" }}>
                              {selProdToDisplay.productName !== null
                                ? selProdToDisplay.productName
                                : "NA"}
                            </div>
                          </div>

                          <div className="col-12 col-md-3 mt-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_opening_stock
                                ? constLabel.lbl_opening_stock
                                : "Opening Stock"}
                            </label>
                            <div style={{ color: "#667080", fontSize: "18px" }}>
                              {selProdToDisplay.openingstock !== null
                                ? selProdToDisplay.openingstock
                                : "NA"}
                            </div>
                          </div>

                          <div className="col-12 col-md-3 mt-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_hsn ? constLabel.lbl_hsn : "HSN"}
                            </label>
                            <div style={{ color: "#667080", fontSize: "18px" }}>
                              {selProdToDisplay.hsn !== null
                                ? selProdToDisplay.hsn
                                : "NA"}
                            </div>
                          </div>

                          <div className="col-12 col-md-3 mt-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_unit
                                ? constLabel.lbl_unit
                                : "Unit"}
                            </label>
                            <div style={{ color: "#667080", fontSize: "18px" }}>
                              {selProdToDisplay.unit_id !== null
                                ? selProdToDisplay.unit_id?.uom
                                : "NA"}
                            </div>
                          </div>

                          <div className="col-12 col-md-3 mt-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_cost
                                ? constLabel.lbl_cost
                                : "Cost"}
                            </label>
                            <div style={{ color: "#667080", fontSize: "18px" }}>
                              {selProdToDisplay.machineCost !== null
                                ? selProdToDisplay.machineCost
                                : "NA"}
                            </div>
                          </div>

                          <div className="col-12 col-md-3 mt-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_sales_price
                                ? constLabel.lbl_sales_price
                                : "Sales Price"}
                            </label>
                            <div style={{ color: "#667080", fontSize: "18px" }}>
                              {selProdToDisplay.salesPrice !== null
                                ? selProdToDisplay.salesPrice
                                : "NA"}
                            </div>
                          </div>

                          <div className="col-12 col-md-3 mt-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_selling_price
                                ? constLabel.lbl_selling_price
                                : "Selling Price"}
                            </label>
                            <div style={{ color: "#667080", fontSize: "18px" }}>
                              {selProdToDisplay.selling_price !== null
                                ? selProdToDisplay.selling_price
                                : "NA"}
                            </div>
                          </div>

                          <div className="col-12 col-md-3 mt-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_purchase_price
                                ? constLabel.lbl_purchase_price
                                : "Purchase Price"}
                            </label>
                            <div style={{ color: "#667080", fontSize: "18px" }}>
                              {selProdToDisplay.purchase_price !== null
                                ? selProdToDisplay.purchase_price
                                : "NA"}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="alert alert-danger mb-0 text-center my-4"
                          role="alert"
                        >
                          {"Please select inventory item !"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : isLoading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div
                  className="alert alert-danger mb-0 text-center my-4"
                  role="alert"
                >
                  {"Please select product !"}
                </div>
              )}

              <hr className="my-4" />

              {privilage.read &&
                <div className="col-12">
                  <div
                    className="row "
                    style={{ paddingTop: "10px", paddingBottom: "25px" }}
                  >
                    <div className="col-12">
                      {bomList.loading ? (
                        <div className="text-center">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : bomList.data !== undefined && bomList.data.length > 0 ? (
                        <>
                          <div
                            className="table-responsive"
                            style={{ minHeight: "60vh" }}
                          >
                            <table className="table table-borderless">
                              <thead>
                                <tr>
                                  <th style={{ background: "#f5f5f5" }}>
                                    {constLabel?.lbl_item_detail
                                      ? constLabel.lbl_item_detail
                                      : "Item Detail"}
                                  </th>
                                  <th style={{ background: "#f5f5f5" }}>
                                    {constLabel?.lbl_bom_type
                                      ? constLabel.lbl_bom_type
                                      : "Bom Type"}
                                  </th>
                                  <th style={{ background: "#f5f5f5" }}>
                                    {constLabel?.lbl_quantity
                                      ? constLabel.lbl_quantity
                                      : "Quantity"}
                                  </th>
                                  <th style={{ background: "#f5f5f5" }}>
                                    {constLabel?.lbl_unit
                                      ? constLabel.lbl_unit
                                      : "Unit"}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {bomList.data.map((val, index) => (
                                  <tr
                                    key={index}
                                    style={{ borderBottom: "10px solid #f5f5f5" }}
                                  >
                                    <td>{val.productname}</td>
                                    <td>{val.bom_type}</td>
                                    <td>{val.qty}</td>
                                    <td>{val.unitname}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>

                          {/* <div className="mt-4 d-flex justify-content-between">
                        <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                          } to ${taskList.data.length +
                          entriesPerPage * page -
                          entriesPerPage
                          } of ${taskList.data.length} entries`}</p>
                        <Pagination className="my-2">
                          <Pagination.Prev
                            disabled={page === 1 ? true : false}
                            onClick={() => {

                              setSrNo((prevC) =>
                                page - 1 == 1 ? 0 : prevC - entriesPerPage
                              );
                              setPage(page - 1);
                            }}
                          >
                            {"Prev"}
                          </Pagination.Prev>

                          <Pagination.Item active>{page}</Pagination.Item>

                          <Pagination.Next
                            disabled={
                              page === maxPage ||
                                maxPage === 0 ||
                                entriesPerPage > taskList.data.length
                                ? true
                                : false
                            }
                            onClick={() => {
                              

                              setSrNo(
                                (prevC) => prevC + parseInt(entriesPerPage)
                              );
                              setPage(page + 1);
                            }}
                          >
                            {"Next"}
                          </Pagination.Next>
                        </Pagination>
                      </div> */}
                        </>
                      ) : (
                        <div
                          className="alert alert-danger mb-0 text-center my-2"
                          role="alert"
                        >
                          {"Bill of material items not found !"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default Inventory;
