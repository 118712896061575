import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faFileAlt, faMusic } from "@fortawesome/free-solid-svg-icons";
import { Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import {
  del_master_file,
  master_multi_file_upload,
  update_master_file,
} from "../../api";
import {
  convertBase64,
  getLabels,
  truncateText,
} from "../../common/Functions/CommonFunctions";
import { s3_image_upload } from "../../constant";
import axios from "axios";
import { screenList } from "../../common/Labels/screenList";
import audio from "../../assets/images/audio.png"

const imageFileTypes = ["image/jpeg", "image/jpg", "image/png"];
const audioFileTypes = ["audio/mpeg", "audio/wav", "audio/mp3"];

const schema = yup
  .object()
  .shape({
    image: yup
      .mixed()
      .test(
        "fileSize",
        "File can be uploaded up to 10 MB",
        (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024)
      )
      .test("fileType", "Only image or audio files are allowed", (value) => {
        if (!value) return true; // If no file is selected, it's considered valid.
        const fileType = value[0]?.type;
        return imageFileTypes.includes(fileType) || audioFileTypes.includes(fileType);
      })
      .required("File is required"),
  })
  .required();

function UpdateSOImageGallary(props) {
  const { taskImgs, fetchImage, setTaskImgs, locData } = props;
  const [filenameUpdate, setFilenameUpdate] = useState({
    status: false,
    value: "",
  });

  const [lockBtn, setLockBtn] = useState(false);

  const [formData, setFormData] = useState({});
  const [eroor, setEroor] = useState({ filename1: "" });

  const [constLabel, setConstLabel] = useState({});

  const localData = JSON.parse(localStorage.getItem("localData"));

  const {
    register,
    handleSubmit,
    formState,
    // control,
    reset,
    // watch,
    setValue,
    // setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  // useEffect(() => {
  //   setConstLabel(getLabels("Sales", "Sales Order"));
  // }, [filenameUpdate]);

  useEffect(() => {
    setConstLabel(getLabels("Sales", "Sales Order"));
    // props.setDrawingFiles({ ...props.drawingFiles, data: taskImgs.data });
    fetchImage();
  }, []);

  function deleteFile(val) {
    SweetAlert.fire({
      title: "Are you sure to delete file ? \n",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        del_master_file({ uuid: val.uuid }).then(
          (res) => {
            toast.success("File deleted successfully", {
              autoClose: 2000,
            });
            fetchImage();
            // props.setGalVisible(false);
          },
          (err) => {
            toast.error("Something went wrong", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newFormData = { ...formData, [name]: value };
    setFormData(newFormData);

    // Perform custom validation for the specific input field
    const newErrors = { ...eroor };
    if (!value || value.trim() === "") {
      newErrors[name] = "Image name is required";
    } else {
      newErrors[name] = ""; // Clear the error message if the input is valid
    }
    setEroor(newErrors);
  };

  const updateName = (e) => {
    e.preventDefault();

    // Check for any remaining validation errors before submitting
    const hasErrors = Object.values(eroor).some((error) => error !== "");
    if (!hasErrors) {
      // Validation passed, perform your submit logic here

      let imgArr = {
        uuid: filenameUpdate.value?.uuid,
        img_count: 1,
        // attachment1: data.image[0],
        // ref_model_id: selMachine.machineId,
        // ref_model_uuid: selMachine.uuid,
        model_name: "SO",
        name: formData.filename1,
        document_type: "image",
      };

      let formDataToSend = new FormData();

      for (const [key, value] of Object.entries(imgArr)) {
        formDataToSend.append(key, value);
      }

      update_master_file(formDataToSend).then(
        (res) => {
          reset();
          toast.success("Image name updated successfully", {
            autoClose: 2000,
          });
          // fetchImage();
          setFilenameUpdate({ ...filenameUpdate, status: false });
        },
        (err) => {
          toast.error(err.response.data, {
            autoClose: 2000,
          });
        }
      );
    } else {
      toast.error(eroor, {
        autoClose: 2000,
      });
    }
  };

  // const onsubmit = (data) => {
  //   let tempImg = [...props.drawingFiles.data];
  //   tempImg.push({ filename: data.fileName, file: data.image[0] });
  //   props.setDrawingFiles({ ...props.drawingFiles, data: tempImg });

  //   reset({
  //     image: null,
  //     fileName: "",
  //   });
  // };

  // const onsubmit = async (data) => {
  //   // console.log("Data", data);
  //   const getExt = data.image[0].name?.split(".").pop();

  //   // console.log("get", getExt);
  //   const dataimage = await convertBase64(data.image[0]);
  //   const newImage = dataimage.replace(
  //     /data:image\/[a-zA-Z]+;/,
  //     "data:image/" + getExt + ";"
  //   );

  //   const payload = {
  //     model_name: "SO",
  //     ref_model_id: "",
  //     ref_model_uuid: locData.uuid,
  //     files: [
  //       {
  //         document: newImage,
  //         document_type: "image",
  //         document_name: data.fileName + `.${getExt}`,
  //       },
  //     ],
  //   };
  //   // console.log("payload", payload);
  //   master_multi_file_upload(payload).then(
  //     (res) => {
  //       reset({
  //         image: {},
  //         fileName: "",
  //       });
  //       toast.success("Image uploaded successfully", {
  //         autoClose: 2000,
  //       });
  //       // fetchImage();
  //     },
  //     (err) => {
  //       toast.error(err.response.data, {
  //         autoClose: 2000,
  //       });
  //     }
  //   );
  // };

  

  const onsubmit = (data) => {
    setLockBtn(true);
    if (data.image?.length > 0) {
      const url = process.env.REACT_APP_COGNITO_URL + s3_image_upload;
      const idToken = localStorage.getItem("id");

      const imgObj = {
        fileName: data.image[0]?.name,
        contentType: data.image[0]?.type,
        model: {
          ref_model_uuid: locData?.uuid,
          model_name: screenList.sales_order_screen.replace(/\s+/g, ""),
          name: data.image[0]?.name,
          document_type: data.image[0]?.type.startsWith("image/")
            ? "image"
            : data.image[0]?.type.startsWith("audio/")
            ? "audio"
            : "video",
          created_by_user: localData?.user_id,
          client_id: localData?.client_id,
        },
      };

      const payload = {
        files: [imgObj],
      };

      axios
        .post(url, payload, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        })
        .then(
          (res) => {
            // console.log("res", res);
            for (let x = 0; x < res.data.data?.body?.length; x++) {
              const url2 = res.data.data?.body?.[x]?.url;

              axios
                .put(url2, data.image[0], {
                  headers: {
                    "Content-Type": data.image[0]?.type,
                  },
                })
                .then(
                  (ress) => {
                    toast.success("File uploaded successfully !", {
                      autoClose: 4000,
                    });
                    setTimeout(() => {
                      setValue("image", {});
                      fetchImage();
                    }, [4500]);
                    setLockBtn(false);
                  },
                  (errr) => {
                    console.log("errr", errr);
                    if (errr.response.status == 413) {
                      toast.error("File upload failed !", {
                        autoClose: 5000,
                      });
                    }
                    setLockBtn(true);
                  }
                );
            }
          },
          (err) => {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            setLockBtn(true);
          }
        );
    }
  };

  // function removeFile(i) {
  //   let tempImg = [...props.drawingFiles.data];

  //   tempImg.splice(i, 1);
  //   props.setDrawingFiles({ ...props.drawingFiles, data: tempImg });
  // }

  const renderAttachment = (type, attachment, name) => {
    const fileURL = `${process.env.REACT_APP_IMAGE_URL}${attachment}`;
  
    switch (type) {
      case "image":
        return (
          <a href={fileURL} target="_blank" rel="noopener noreferrer">
          <img src={fileURL} className="img-fluid" alt={name || "Image"} />
          </a>
        );
      case "audio":
        return (
          <a href={fileURL} target="_blank" rel="noopener noreferrer">
            <img 
              src={audio} 
              alt="Audio File" 
              className="audio-icon" 
              style={{ width: "100px", height: "50px" }} 
            />
          </a>
        );
      default:
        return (
          <div>
            <FontAwesomeIcon
              className="icon-file-default"
              style={{ color: "#fff" }}
              icon={faFileAlt} // Icon for default file type
              size="3x"
            />
            <span className="ms-2 text-secondary">{name || "Unsupported File"}</span>
          </div>
        );
    }
  };
  
  return (
    <React.Fragment>
      <ToastContainer />

      <Modal
        show={props.galVisible}
        onHide={() => {
          props.setGalVisible(false);
          // setFilenameUpdate({ ...filenameUpdate, status: false });
        }}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_image_gallary
              ? constLabel.lbl_image_gallary
              : "Image Gallery"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {filenameUpdate.status == false ? (
            <div className="row">
              <div className="col-12 col-md-4">
                <label style={{ fontSize: "14px" }} className="col-form-label">
                  {constLabel?.lbl_file ? constLabel.lbl_file : "File"}
                </label>
                <input
                  className={
                    errors.image
                      ? "form-control form-control-sm is-invalid"
                      : "form-control form-control-sm"
                  }
                  type="file"
                  {...register("image")}
                  accept="image/*, audio/*"
                />
                {errors.image && (
                  <span className="text-danger err-msg">
                    {errors.image.message}
                  </span>
                )}
              </div>
              {/* <div className="col-12 col-md-4">
                <label style={{ fontSize: "14px" }} className="col-form-label">
                  {constLabel?.lbl_file_name
                    ? constLabel.lbl_file_name
                    : "Filename"}
                </label>
                <input
                  className={
                    errors.fileName
                      ? "form-control form-control-sm is-invalid"
                      : "form-control form-control-sm"
                  }
                  type="text"
                  {...register("fileName")}
                  placeholder="Enter filename"
                />
                {errors.fileName && (
                  <span className="text-danger err-msg">
                    {errors.fileName.message}
                  </span>
                )}
              </div> */}

              <div className="col-12 col-md-3">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label invisible d-block"
                >
                  {constLabel?.lbl_file_name
                    ? constLabel.lbl_file_name
                    : "Filename"}
                </label>

                <button
                  className="btn btn-sm btn-yellow px-3"
                  onClick={handleSubmit(onsubmit)}
                  disabled={lockBtn}
                >
                  {constLabel?.lbl_submit ? constLabel.lbl_submit : "Submit"}{" "}
                  {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
                </button>
              </div>
            </div>
          ) : null}

          {/* {filenameUpdate.status == true ? (
            <form onSubmit={updateName}>
              <div className="row">
                <div className="col-12 col-md-4">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label"
                  >
                    {constLabel?.lbl_file_name
                      ? constLabel.lbl_file_name
                      : "Filename"}
                  </label>
                  <input
                    className={
                      eroor.filename1
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    type="text"
                    name="filename1"
                    id="filename1"
                    // value={formData.filename1}
                    onChange={handleChange}
                    defaultValue={
                      filenameUpdate !== undefined
                        ? filenameUpdate.value?.name
                        : ""
                    }
                  />
                  {eroor.filename1 && (
                    <span className="text-danger err-msg">
                      {eroor.filename1}
                    </span>
                  )}
                </div>

                <div className="col-12 col-md-3">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label invisible d-block"
                  >
                    {constLabel?.lbl_file_name
                      ? constLabel.lbl_file_name
                      : "Filename"}
                  </label>

                  <button type="submit" className="btn btn-sm btn-yellow px-3">
                    {constLabel?.lbl_submit ? constLabel.lbl_submit : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          ) : null} */}

          {taskImgs?.loading ? (
            <div className="text-center mt-4">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="row mt-4 drawing_gallery row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
              {taskImgs?.data.map((val, i) =>
                val.attachment !== null ? (
                  <div className="col text-center" key={i}>
                    <div className="image-container">
                      {renderAttachment(val.document_type, val.attachment, val.name)}

                      <FontAwesomeIcon
                        onClick={() => deleteFile(val)}
                        // onClick={() => removeFile(i)}
                        className="icon-close"
                        style={{ color: "#000" }}
                        icon={faClose}
                        size="lg"
                      />
                    </div>

                    <span
                      // onClick={() =>
                      //   setFilenameUpdate({
                      //     ...filenameUpdate,
                      //     status: true,
                      //     value: val,
                      //   })
                      // }
                      className="image-name cursor_pointer"
                    >
                      {truncateText((val.name).toUpperCase(), 15)}
                    </span>
                  </div>
                ) : null
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-grey px-4"
            onClick={() => {
              props.setGalVisible(false);
              // setFilenameUpdate({ ...filenameUpdate, status: false });
            }}
          >
            {constLabel?.lbl_close ? constLabel.lbl_close : "Close"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default UpdateSOImageGallary;
