import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trash2, Edit, Edit2 } from "react-feather";
import { Modal, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { delete_material_item, deleteMaterialRequest, getProdList, getProductType, updateMaterialRequest } from "../../../api";
import { convertDateYYMMDD } from "../../../common/Functions/CommonFunctions";
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";
import { faAdd, faPlus } from "@fortawesome/free-solid-svg-icons";
import * as name_const from "../../../common/Constants/constants";
import SweetAlert from "sweetalert2";
import { TRUE } from "sass";

const schema = yup
  .object()
  .shape({
    req_date: yup
      .date()
      .required("Please select material request date!")
      .typeError("Please select material request date!"),
    required_date: yup
      .date()
      .nullable() // Allows the field to be optional
      .typeError("Please select a valid required date!")
      .when("req_date", (req_date, schema) =>
        req_date
          ? schema.min(req_date, "Required date must be later than request date!")
          : schema
      ),
    req_status: yup
      .array()
      .min(1, "Please select status!")
      .required("Please select status!"),
    remarks: yup.string().max(1000).notRequired("Please enter remarks !"),
    items: yup.array().of(
      yup.object().shape({
        product: yup
          .array()
          .min(1, "Please select product!")
          .required("Please select product!"),
        quantity: yup
          .string()
          .required("Quantity is required !")
          .matches(/^\d+(\.\d+)?$/, "Enter valid quantity!"),
      })
    ),
  })
  .required();

function EditMaterialRequest(props) {
  // console.log("props", props);
  const [visible, setVisible] = useState(false);
  const [prodList, setProdList] = useState({ data: [], loading: false });

  const [lockBtn, setLockBtn] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: {
        items: [
          {
            product: [],
            quantity: 0,
          },
        ],
      },
    }); // initialise the hook

  let { errors } = formState;
  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  const [statusList, setStatusList] = useState({
    data: [
      { id: 1, name: name_const.DRAFT },
      { id: 2, name: name_const.APPROVED },
      { id: 3, name: name_const.CLOSED },
      { id: 4, name: name_const.PENDING_APPROVAL },
      { id: 5, name: name_const.REJECTED },
    ],
    loading: false,
  });

  const localData = JSON.parse(localStorage.getItem("localData"));
  const def_org = localData?.organizations?.filter(
    (item) => item.default_org == true
  );

  useEffect(() => {
    if(visible){
      fetchProductType();
      setValue("req_date", new Date(props.data.material_req_date), {shouldValidate : true});
      setValue("required_date", props.data.required_date !== undefined && props.data.required_date !== null ? new Date(props.data.required_date) : null, {shouldValidate : true});
      setValue("req_status", props.data.status !== "" && props.data.status !== null ? [{name: props.data.status}] : [], {shouldValidate : true});
      setValue("remark", props.data.remarks, {shouldValidate : true});
    }
  }, [visible])

  useEffect(() => {
    var temp = [];
    if (props.data?.material_req_lineitems?.length > 0) {
      for (let i = 0; i < props.data?.material_req_lineitems.length; i++) {
        temp.push({
          uuid: props.data.material_req_lineitems?.[i]?.uuid,
          product: [props.data.material_req_lineitems?.[i]?.prod_data],
          quantity: props.data.material_req_lineitems?.[i]?.qty,
        });
      }
      reset({
        items: temp,
      });
    } else {
      reset({
        items: [{}],
      });
    }
  }, [props.data.material_req_lineitems]);

  function fetchProductType() {
    getProductType().then(
      (res) => {
        if (res && res.data.data.length > 0) {
          // Filter and extract uuid values
          let uuidArray = res.data.data
            .filter(item =>
              item.productType === name_const.GENERAL_ITEM ||
              item.productType === name_const.CONSUMABLE_ITEMS ||
              item.productType === name_const.RAW_MATERIAL
            )
            .map(item => item.uuid); // Extract the uuid values
  
          if (uuidArray.length > 0) {
            fetchProdList(uuidArray); // Pass uuidArray to fetchProdList
          }
        }
      },
      (err) => {
        // console.log("err", err);
      }
    );
  }

  function fetchProdList(uuidArray) {
    setProdList({ ...prodList, data: [], loading: true });

    getProdList({product_type_id_list: JSON.stringify(uuidArray)}).then(
      (res) => {
        let arr = res.data.data.filter(
          (item) => item.productName !== null || item.productName !== undefined
        );
        setProdList({ ...prodList, data: arr, loading: false });
      },
      (err) => {
        setProdList({ ...prodList, data: [], loading: false });
      }
    );
  }

  const onSubmit = (data) => {
    // console.log("data", data);
    setLockBtn(true);
    if (data !== "") {
        let reqPayload = [];
        for (let i = 0; i < data.items.length; i++) {
            const lineItems = {
                uuid: data.items?.[i]?.uuid,
                product_uuid: data.items?.[i]?.product?.[0]?.uuid,
                quantity: data.items?.[i]?.quantity
            };
            reqPayload.push(lineItems);
        }

    const payload = {
        uuid: props.data?.uuid,
        material_req_date: convertDateYYMMDD(data.req_date, "-"),
        required_date: data?.required_date !== undefined && data?.required_date !== null ? convertDateYYMMDD(data.required_date, "-") : null,
        material_req_remarks: data.remark,
        material_req_status: data.req_status?.[0]?.name,
        material_req_line_items: reqPayload,
        supplier_id: props.data?.supplier_id
    }

    if(data.req_status?.[0]?.name == name_const.APPROVED){
      payload.organisation_id = def_org?.[0]?.uuid;
      payload.approved_by = localData?.employee_id !== null && localData.employee_id?.uuid !== null ? localData.employee_id?.uuid : localData?.user_id;
    }

      updateMaterialRequest(payload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Material request updated successfully !", {
              autoClose: 2000,
            });

            setTimeout(() => {
              setLockBtn(false);
              setVisible(false);
              props.fetchMaterialRequestList(props.page, props.entriesPerPage);
            }, 2300);
            
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
            setLockBtn(false);
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  const delLineItem = (index) => {
    // console.log("del_uuid", del_uuid);
    SweetAlert.fire({
      title: "Are you sure to delete ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (fields[index].uuid !== null && fields[index].uuid !== undefined) {
          delete_material_item({ uuid: fields[index].uuid }).then(
            (res) => {
              remove(index);
              toast.success("Material item deleted successfully !", {
                autoClose: 1000,
              });
              // setTimeout(() => {
              //   setVisible(false);
              // }, 1200);
            },
            (err) => {
              toast.error(err.response.data.message, {autoClose: 1000});
            }
          );
        } else {
          remove(index);
        }
      }
    });
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <Edit
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_edit_material_request
            ? props.constLabel.lbl_edit_material_request
            : "Edit Material Request"
        }
        className="menu_icon_grey cursor_pointer me-2"
        size={20}
        onClick={() => {
          // setUpdateForm(val)
          setVisible(true);
        }}
      />
      <Modal
        show={visible}
        onHide={() => {
          setVisible(false);
          props.fetchMaterialRequestList(props.page, props.entriesPerPage);
        }}
        size={"lg"}
        backdrop={"static"}
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_update_material_request
              ? props.constLabel.lbl_update_material_request
              : "Update Material Request"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mt-md-0 mt-3">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {props.constLabel?.lbl_material_request_date ? props.constLabel.lbl_material_request_date : "Material Request Date"}
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    control={control}
                    name="req_date"
                    // defaultValue={new Date()}
                    render={({ field }) => (
                      <DatePicker
                        className={
                          errors.req_date
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholderText="Select material request date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        minDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        disabled
                      />
                    )}
                  />
                  {errors.req_date && (
                    <span className="text-danger err-msg">
                      {errors.req_date.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-6">
                    <div className="form-group mt-md-0 mt-3">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold"
                      >
                        {props.constLabel?.lbl_required_date ? props.constLabel.lbl_required_date : "Required Date"}
                      </label>
                      <Controller
                        control={control}
                        name="required_date"
                        // defaultValue={new Date()}
                        render={({ field }) => (
                          <DatePicker
                            className={
                              errors.required_date
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            placeholderText="Select required date"
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                            minDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                      {errors.required_date && (
                        <span className="text-danger err-msg">
                          {errors.required_date.message}
                        </span>
                      )}
                    </div>
                  </div>

              <div className="col-12 col-md-6">
                <div className="form-group mt-md-0 mt-3 typeahead-container">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {props.constLabel?.lbl_status ? props.constLabel.lbl_status : "Status"}
                    <span className="text-danger"> *</span>
                  </label>

                  <Controller
                    name="req_status"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        multiple={false}
                        // size="sm"
                        options={statusList.data}
                        isLoading={statusList.loading}
                        placeholder="Select status"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.req_status ? true : false}
                        filterBy={() => true}
                      />
                    )}
                  />
                  {!statusList.loading && !errors.req_status && (
                    <span className="arrow-icon"></span>
                  )}
                  <span className="text-danger err-msg">
                    {errors.req_status?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mt-md-0 mt-3">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {props.constLabel?.lbl_remark ? props.constLabel.lbl_remark : "Remark"}
                  </label>

                  <textarea
                    type="text"
                    className="form-control"
                    id="remark"
                    name="remark"
                    defaultValue={props.data.remarks}
                    placeholder="Enter remark"
                    {...register("remark")}
                  ></textarea>
                </div>
              </div>
            </div>
            <hr className="mb-1" />
            <div className="d-flex justify-content-between align-items-center">
              <p className="roboto-bold mb-0" style={{ fontSize: "16px" }}>
                {props.constLabel?.lbl_item_detail
                  ? props.constLabel?.lbl_item_detail
                  : "Item Detail"}
              </p>
              {/* <div>
                <a
                  style={{ fontSize: "16px" }}
                  className="btn btn-sm button-primary px-2 mt-2"
                  onClick={() => {
                    append({
                      product: [],
                      quantity: 0,
                    });
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      color: "#ffffff",
                      marginRight: "5px",
                    }}
                    icon={faAdd}
                    size="sm"
                  />{" "}
                  {props.constLabel?.lbl_add ? props.constLabel?.lbl_add : "Add"}
                </a>
              </div> */}
            </div>
            <hr className="mt-1" />
            <div className="col-12">
              {/* {fields.length > 0 ? ( */}
              <div className="">
                <table className="table my-2 table-stirped table-bordered">
                  <thead>
                    <tr>
                      <th>
                        {props.constLabel?.lbl_product
                          ? props.constLabel?.lbl_product
                          : "Product"}
                        <span className="text-danger"> *</span>
                      </th>
                      <th>
                        {props.constLabel?.lbl_quantity
                          ? props.constLabel?.lbl_quantity
                          : "Quantity"}<span className="text-danger"> *</span>
                      </th>
                      <th>{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map(({ id }, index) => (
                      <tr key={id}>
                        <td style={{ verticalAlign: "top" }} width={"70%"}>
                          <Controller
                            name={`items[${index}].product`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                size="sm"
                                id="basic-typeahead"
                                labelKey="productName"
                                multiple={false}
                                options={prodList.data}
                                isLoading={prodList.loading}
                                placeholder="Select product"
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.product ? true : false
                                }
                              />
                            )}
                          />
                          {errors.items?.[index]?.product && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.product.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.quantity
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].quantity`}
                            {...register(`items[${index}].quantity`)}
                            placeholder="Enter quantity"
                          />

                          {errors.items?.[index]?.quantity && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.quantity.message}
                            </span>
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "top",
                          }}
                        >
                          <Tooltip id={"delete-tooltip" + index} place="top" />
                          <Trash2
                            data-tooltip-id={"delete-tooltip" + index}
                            data-tooltip-content={"Delete"}
                            className="menu_icon_red cursor_pointer"
                            size={20}
                            onClick={
                              index > 0
                                ? () => {
                                    delLineItem(index);
                                  }
                                : null
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
            disabled={lockBtn}
          >
            {props.constLabel?.lbl_save ? props.constLabel.lbl_save : "Save"}{" "}
            {lockBtn ? <Spinner animation="border" size="sm" /> : null}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditMaterialRequest;
